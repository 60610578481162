export const admissionsPetientsMock = {
    fields: [
        {
            key: 'number',
            sortable: false,
        },
        {
            key: 'name',
            sortable: false,
        },
        {
            key: 'sex',
            sortable: true,
        },
        {
            key: 'age',
            sortable: true,
        },
        {
            key: 'waiting',
            sortable: true,
        },
        {
            key: 'triage',
            sortable: true,
        },
        {
            key: 'accuracy',
            sortable: false,
        },
        {
            key: 'Admitted to',
            sortable: false,
        },
    ],
    predictions: [
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Emma Tremblay',
            gender: 'F',
            age: 24,
            number: '1649917',
            prediction: 0,
            admService: null,
            waiting: '2024-03-04T10:59:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N73      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-12-26(9d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 59.6,
            },
        },
        {
            chiefComplaint: 'Anxiety / Situational crisis',
            name: 'Liam Tremblay',
            gender: 'M',
            age: 40,
            number: '892262',
            prediction: 0,
            admService: null,
            waiting: '2024-03-05T13:13:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N76      ',
            covid: '',
            consultation: null,
            origin: 'Clinic',
            pastAdmitsDate: '2022-02-11(4d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 69.8,
            },
        },
        {
            chiefComplaint: 'Hallucinations / Delusions',
            name: 'Olivia Roy',
            gender: 'F',
            age: 50,
            number: '519978',
            prediction: 1,
            admService: null,
            waiting: '2024-03-06T14:12:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N70      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2024-01-03(21d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 73.1,
            },
        },
        {
            chiefComplaint: 'Anxiety / Situational crisis',
            name: 'Noah Roy',
            gender: 'M',
            age: 45,
            number: '1332162',
            prediction: 1,
            admService: null,
            waiting: '2024-03-06T15:06:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '4',
            accuracy: 0,
            stretcher: 'N80      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '2024-01-18(6d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 72.9,
            },
        },
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Ethan Gagnon',
            gender: 'M',
            age: 24,
            number: '1780450',
            prediction: -1,
            admService: null,
            waiting: '2024-03-06T21:55:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N78      ',
            covid: '',
            consultation: null,
            origin: 'Homeless',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 16.7,
            },
        },
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Lucas Côté',
            gender: 'M',
            age: 44,
            number: '1780594',
            prediction: 0,
            admService: null,
            waiting: '2024-03-07T16:19:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N77      ',
            covid: '',
            consultation: null,
            origin: 'Hospital',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 64.1,
            },
        },
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Oliver Bélanger',
            gender: 'M',
            age: 55,
            number: '1287579',
            prediction: 1,
            admService: null,
            waiting: '2024-03-07T17:05:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N49      ',
            covid: '',
            consultation: null,
            origin: 'Other',
            pastAdmitsDate: '2018-07-02(1d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 82.4,
            },
        },
        {
            chiefComplaint: 'Anxiety / Situational crisis',
            name: 'Isabella Côté',
            gender: 'F',
            age: 36,
            number: '1675559',
            prediction: 0,
            admService: null,
            waiting: '2024-03-07T18:21:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '4',
            accuracy: 0,
            stretcher: 'N71      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2024-01-22(28d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 64.1,
            },
        },
        {
            chiefComplaint: 'Abnormal lab values',
            name: 'Sophia Bélanger',
            gender: 'F',
            age: 72,
            number: '1462317',
            prediction: 1,
            admService: null,
            waiting: '2024-03-08T13:34:00',
            filter: 'adm',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N39      ',
            covid: '',
            consultation: null,
            origin: 'Clinic',
            pastAdmitsDate: '2024-02-28(4d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Charlotte Pelletier',
            gender: 'F',
            age: 28,
            number: '1327600',
            prediction: 1,
            admService: null,
            waiting: '2024-03-08T17:53:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N72      ',
            covid: '',
            consultation: null,
            origin: 'Hospital',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 80.2,
            },
        },
        {
            chiefComplaint: 'Cough and fever',
            name: 'William Lefebvre',
            gender: 'M',
            age: 43,
            number: '281047',
            prediction: 0,
            admService: null,
            waiting: '2024-03-08T18:07:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N30      ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '2022-11-02(6d)',
            admissionColumn: {
                admService: 'Family Medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Syncope / Pre-syncope',
            name: 'Benjamin Pelletier',
            gender: 'M',
            age: 26,
            number: '1780800',
            prediction: 0,
            admService: null,
            waiting: '2024-03-08T23:09:00',
            filter: 'adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N38      ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: ' Bizarre behaviour',
            name: 'Logan Gauthier',
            gender: 'M',
            age: 57,
            number: '482896',
            prediction: 0,
            admService: null,
            waiting: '2024-03-08T23:29:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N02      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2024-02-14(5d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Hallucinations / Delusions',
            name: 'Jacob Fortin',
            gender: 'M',
            age: 41,
            number: '321936',
            prediction: 1,
            admService: null,
            waiting: '2024-03-08T23:35:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N74      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2022-04-12(7d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 72.2,
            },
        },
        {
            chiefComplaint: 'Abnormal lab values',
            name: 'Mason Ouellet',
            gender: 'M',
            age: 51,
            number: '124299',
            prediction: 1,
            admService: null,
            waiting: '2024-03-09T00:54:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N46B     ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2021-03-05(5d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Flank pain',
            name: 'Abigail Ouellet',
            gender: 'F',
            age: 73,
            number: '117211',
            prediction: -1,
            admService: null,
            waiting: '2024-03-09T03:48:00',
            filter: 'adm',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N44B     ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Family Medicine Non Teaching',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Chest pain (cardiac features)',
            name: 'Alexander Bergeron',
            gender: 'M',
            age: 82,
            number: '446971',
            prediction: 1,
            admService: null,
            waiting: '2024-03-09T05:27:00',
            filter: 'adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N46      ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Scarlett Leclerc',
            gender: 'F',
            age: 67,
            number: '895642',
            prediction: 0,
            admService: null,
            waiting: '2024-03-09T09:15:00',
            filter: 'adm',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'S25      ',
            covid: 'COVID -',
            consultation: [
                {
                    consultationTitle: 'Physiotherapy (14:03:11)',
                    consultationTime: '14:03:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Chest pain (cardiac features)',
            name: 'Grace Monette',
            gender: 'F',
            age: 84,
            number: '372322',
            prediction: -1,
            admService: null,
            waiting: '2024-03-09T10:24:00',
            filter: 'adm',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N31      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Cardiology',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Hallucinations / Delusions',
            name: 'Carter Leclerc',
            gender: 'M',
            age: 40,
            number: '697519',
            prediction: 1,
            admService: null,
            waiting: '2024-03-09T14:18:00',
            filter: 'adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N07      ',
            covid: '',
            consultation: null,
            origin: 'Work',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Confusion',
            name: 'Chloe Joly',
            gender: 'F',
            age: 80,
            number: '643154',
            prediction: 1,
            admService: null,
            waiting: '2024-03-09T17:55:00',
            filter: 'adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N45      ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Vomiting and/or nausea',
            name: 'Daniel Joly',
            gender: 'M',
            age: 69,
            number: '287447',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T03:27:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N37      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '2024-02-12(4d)',
            admissionColumn: {
                admService: 'SHORT STAY UNIT',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Henry Lavoie',
            gender: 'M',
            age: 81,
            number: '100770',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T05:22:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N35      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Social Services (9:06:11)',
                    consultationTime: '9:06:11',
                    consultationDelayFlag: true,
                },
                {
                    consultationTitle: 'Physiotherapy (9:05:11)',
                    consultationTime: '9:05:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '2024-01-22(2d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Victoria Lavoie',
            gender: 'F',
            age: 80,
            number: '1642831',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T05:25:00',
            filter: 'adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N03      ',
            covid: 'COVID +',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2021-07-17(3d)',
            admissionColumn: {
                admService: 'Family Medicine Non Teaching',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Jackson Caron',
            gender: 'M',
            age: 83,
            number: '236598',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T06:12:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N08      ',
            covid: 'COVID RULE OUT',
            consultation: [
                {
                    consultationTitle: 'SSU (10:32:11)',
                    consultationTime: '10:32:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '2022-11-09(4d)',
            admissionColumn: {
                admService: 'SHORT STAY UNIT',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Sebastian Beaulieu',
            gender: 'M',
            age: 77,
            number: '294380',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T09:51:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'R06      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Intensive Care',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Cough / Congestion',
            name: 'Aiden Poirier',
            gender: 'M',
            age: 88,
            number: '377886',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T10:05:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'S15      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-04-06(1d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Cough / Congestion',
            name: 'Aubrey Caron',
            gender: 'F',
            age: 87,
            number: '1755843',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T10:13:00',
            filter: 'adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S28      ',
            covid: 'COVID RULE OUT',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'SHORT STAY UNIT',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Cough / Congestion',
            name: 'Zoey Beaulieu',
            gender: 'F',
            age: 69,
            number: '296398',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T11:05:00',
            filter: 'adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N32      ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '2024-02-26(1d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Violent / Homicidal behaviour',
            name: 'Natalie Poirier',
            gender: 'F',
            age: 37,
            number: '425241',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T11:11:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N50      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-12-06(1d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 80.7,
            },
        },
        {
            chiefComplaint: 'Confusion',
            name: 'Matthew Fournier',
            gender: 'M',
            age: 87,
            number: '564412',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T11:20:00',
            filter: 'adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N42B     ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-03-13(6d)',
            admissionColumn: {
                admService: 'Geriatrics',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Abnormal lab values',
            name: 'Samuel Royer',
            gender: 'M',
            age: 53,
            number: '1506568',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T12:10:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N44      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-05-08(4d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Addison Fournier',
            gender: 'F',
            age: 86,
            number: '23058',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T12:30:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S27      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Occupational Therapy (10:21:11)',
                    consultationTime: '10:21:11',
                    consultationDelayFlag: true,
                },
                {
                    consultationTitle: 'Physiotherapy (10:21:11)',
                    consultationTime: '10:21:11',
                    consultationDelayFlag: true,
                },
                {
                    consultationTitle: 'Discharge Planning (10:20:11)',
                    consultationTime: '10:20:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 72.8,
            },
        },
        {
            chiefComplaint:
                'COVID-19 suspected / Exposure to communicable disease',
            name: 'Lillian Royer',
            gender: 'F',
            age: 90,
            number: '20362',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T12:39:00',
            filter: 'adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S21      ',
            covid: 'COVID RULE OUT',
            consultation: null,
            origin: 'Hospital',
            pastAdmitsDate: '2024-02-12(1d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Upper extremity injury',
            name: 'David Gagné',
            gender: 'M',
            age: 36,
            number: '1780193',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T12:52:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z17      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Physiotherapy (3:57:11)',
                    consultationTime: '3:57:11',
                    consultationDelayFlag: true,
                },
                {
                    consultationTitle: 'Neurosurgery (3:55:11)',
                    consultationTime: '3:55:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 84.2,
            },
        },
        {
            chiefComplaint: 'Localized swelling / redness',
            name: 'Joseph Cloutier',
            gender: 'M',
            age: 65,
            number: '1348578',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T13:15:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N41      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2022-10-16(9d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Allergic reaction',
            name: 'Owen Langlois',
            gender: 'M',
            age: 79,
            number: '912233',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T13:42:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'S19B     ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Allergy / Immunology (11:54:11)',
                    consultationTime: '11:54:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 88.3,
            },
        },
        {
            chiefComplaint: 'Blood in stool / Melena',
            name: 'Brooklyn Gagné',
            gender: 'F',
            age: 78,
            number: '851483',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T14:13:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'Z11      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 54.7,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Lily Cloutier',
            gender: 'F',
            age: 68,
            number: '1575298',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T14:25:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N42      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Discharge Planning (5:28:11)',
                    consultationTime: '5:28:11',
                    consultationDelayFlag: true,
                },
                {
                    consultationTitle: 'Orthopaedics (5:29:11)',
                    consultationTime: '5:29:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 72.2,
            },
        },
        {
            chiefComplaint: 'Chest pain (cardiac features)',
            name: 'Hannah Langlois',
            gender: 'F',
            age: 69,
            number: '270309',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T14:47:00',
            filter: 'adm',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N40      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Cardiology',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Layla Rousseau',
            gender: 'F',
            age: 80,
            number: '1647937',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T14:56:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S22      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 74.9,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Wyatt Rousseau',
            gender: 'M',
            age: 77,
            number: '810574',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T15:08:00',
            filter: 'predict_adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'S16      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Haematology (7:27:11)',
                    consultationTime: '7:27:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '2021-08-13(2d)',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 71.5,
            },
        },
        {
            chiefComplaint: 'Depression  / Deliberate self harm',
            name: 'Zoe Vincent',
            gender: 'F',
            age: 51,
            number: '602664',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T15:33:00',
            filter: 'Psychiatry',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N83      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2022-06-29(119d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 18.2,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'John Vincent',
            gender: 'M',
            age: 88,
            number: '85148',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T15:39:00',
            filter: 'under_evaluation',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N01      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Geriatrics (9:09:11)',
                    consultationTime: '9:09:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 68.8,
            },
        },
        {
            chiefComplaint: 'Direct referral for consultation',
            name: 'Jack Carpentier',
            gender: 'M',
            age: 50,
            number: '1654895',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T15:53:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N43B     ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2024-02-01(17d)',
            admissionColumn: {
                admService: 'General surgery',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Mia Carpentier',
            gender: 'F',
            age: 78,
            number: '28148',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T16:40:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S17      ',
            covid: 'COVID RULE OUT',
            consultation: [
                {
                    consultationTitle: 'Geriatrics (3:05:11)',
                    consultationTime: '3:05:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Reabilitation center',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 43.9,
            },
        },
        {
            chiefComplaint: 'Confusion',
            name: 'Emma Boucher',
            gender: 'F',
            age: 93,
            number: '1761702',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T17:35:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N43      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 91.7,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Luke Boucher',
            gender: 'M',
            age: 75,
            number: '1397499',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T17:40:00',
            filter: 'predict_adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'S18B     ',
            covid: 'COVID -',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 73.8,
            },
        },
        {
            chiefComplaint: 'Abnormal lab values',
            name: 'Ava Desrosiers',
            gender: 'F',
            age: 96,
            number: '587001',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T17:44:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N11      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Geriatrics (3:14:11)',
                    consultationTime: '3:14:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 53.3,
            },
        },
        {
            chiefComplaint: 'Chest pain (cardiac features)',
            name: 'Sofia St-Pierre',
            gender: 'F',
            age: 27,
            number: '1405057',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T18:30:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'S26      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 69.9,
            },
        },
        {
            chiefComplaint: 'Cough / Congestion',
            name: 'Riley Lemieux',
            gender: 'F',
            age: 91,
            number: '403997',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T18:43:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S20      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Respirology (3:53:11)',
                    consultationTime: '3:53:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 66.7,
            },
        },
        {
            chiefComplaint: 'Hallucinations / Delusions',
            name: 'Aria Corbeil',
            gender: 'F',
            age: 26,
            number: '665592',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T18:48:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N85      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 53.6,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Jayden Desrosiers',
            gender: 'M',
            age: 74,
            number: '171053',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T19:14:00',
            filter: 'under_evaluation',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N29      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Respirology (5:37:11)',
                    consultationTime: '5:37:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Hospital',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 52.8,
            },
        },
        {
            chiefComplaint: 'Diarrhea and fever',
            name: 'Dylan St-Pierre',
            gender: 'M',
            age: 61,
            number: '733645',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T20:07:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N04      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2024-02-12(3d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Hallucinations / Delusions',
            name: 'Zachary Lemieux',
            gender: 'M',
            age: 61,
            number: '19092',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T20:29:00',
            filter: 'Psychiatry',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N05      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-10-20(7d)',
            admissionColumn: {
                admService: 'Psychiatry',
                accuracy: 90.3,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Amelia Fontaine',
            gender: 'F',
            age: 36,
            number: '1698051',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T20:56:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N36      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'cardiology',
                accuracy: 80.2,
            },
        },
        {
            chiefComplaint: 'Direct referral for consultation',
            name: 'Harper Ducharme',
            gender: 'F',
            age: 78,
            number: '97129',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T21:06:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N31B     ',
            covid: '',
            consultation: null,
            origin: 'Hospital',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'surgery',
                accuracy: 95.5,
            },
        },
        {
            chiefComplaint: 'General weakness',
            name: 'Isaac Corbeil',
            gender: 'M',
            age: 69,
            number: '596163',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T21:14:00',
            filter: 'adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'R03      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '2023-06-02(4d)',
            admissionColumn: {
                admService: 'Internal medicine',
                accuracy: 100,
            },
        },
        {
            chiefComplaint: 'Altered level of consciousness',
            name: 'Evelyn Lemire',
            gender: 'F',
            age: 96,
            number: '22469',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T21:28:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '1',
            accuracy: 0,
            stretcher: 'R05      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 80.5,
            },
        },
        {
            chiefComplaint: 'Suicidal Ideation',
            name: 'Nathan Larouche',
            gender: 'M',
            age: 48,
            number: '693173',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T21:43:00',
            filter: 'under_evaluation',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'S51      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Psychiatry (5:53:11)',
                    consultationTime: '5:53:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 59.9,
            },
        },
        {
            chiefComplaint: 'Back pain',
            name: 'Ryan Fontaine',
            gender: 'M',
            age: 56,
            number: '435899',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T22:06:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z15      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 91.4,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Abigail Renaud',
            gender: 'F',
            age: 51,
            number: '1706446',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T22:16:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 98.2,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Connor Ducharme',
            gender: 'M',
            age: 30,
            number: '1411956',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T22:28:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z19      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'General surgery (0:25:11)',
                    consultationTime: '0:25:11',
                    consultationDelayFlag: false,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 63.5,
            },
        },
        {
            chiefComplaint: 'Diarrhea',
            name: 'Elijah Lemire',
            gender: 'M',
            age: 61,
            number: '21076',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T22:33:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'S24      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 73.4,
            },
        },
        {
            chiefComplaint: 'Abdominal mass / distention',
            name: 'Ella Guay',
            gender: 'F',
            age: 22,
            number: '803669',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T22:38:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 96.3,
            },
        },
        {
            chiefComplaint: 'Hyperglycemia',
            name: 'Scarlett Moreau',
            gender: 'F',
            age: 83,
            number: '1307493',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T22:50:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S23      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 43,
            },
        },
        {
            chiefComplaint: 'Substance misuse / Intoxication',
            name: 'Chloe Barbeau',
            gender: 'F',
            age: 41,
            number: '844443',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T22:56:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S52      ',
            covid: '',
            consultation: null,
            origin: 'Homeless',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 64.7,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Victoria Demers',
            gender: 'F',
            age: 77,
            number: '1427981',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T23:23:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N09      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 60.2,
            },
        },
        {
            chiefComplaint: 'Anxiety / Situational crisis',
            name: 'Gabriel Renaud',
            gender: 'M',
            age: 63,
            number: '438638',
            prediction: 1,
            admService: null,
            waiting: '2024-03-10T23:36:00',
            filter: 'predict_adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N79      ',
            covid: '',
            consultation: [
                {
                    consultationTitle: 'Psychiatry (3:45:11)',
                    consultationTime: '3:45:11',
                    consultationDelayFlag: true,
                },
            ],
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'psychiatry',
                accuracy: 72.7,
            },
        },
        {
            chiefComplaint: 'Upper extremity pain',
            name: 'Aubrey Pelletier',
            gender: 'F',
            age: 29,
            number: '1780926',
            prediction: -1,
            admService: null,
            waiting: '2024-03-10T23:42:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '4',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 95.5,
            },
        },
        {
            chiefComplaint: 'Back pain',
            name: 'Natalie Beauchamp',
            gender: 'F',
            age: 59,
            number: '1321873',
            prediction: 0,
            admService: null,
            waiting: '2024-03-10T23:48:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N34B     ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 42.3,
            },
        },
        {
            chiefComplaint: 'Head injury',
            name: 'Lillian Giroux',
            gender: 'F',
            age: 36,
            number: '1694707',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T00:00:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z50      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 92.1,
            },
        },
        {
            chiefComplaint: 'Flank pain',
            name: 'Addison Thibault',
            gender: 'F',
            age: 40,
            number: '1780927',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T00:25:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z08      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 96.6,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Lily Lapointe',
            gender: 'F',
            age: 22,
            number: '1677302',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T00:38:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 96.2,
            },
        },
        {
            chiefComplaint: 'Hyperventilation',
            name: 'Brooklyn Brisson',
            gender: 'F',
            age: 89,
            number: '170658',
            prediction: 1,
            admService: null,
            waiting: '2024-03-11T00:43:00',
            filter: 'predict_adm',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'S18      ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 71.6,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Hannah Martineau',
            gender: 'F',
            age: 62,
            number: '510585',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T00:46:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'Z04      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 96.9,
            },
        },
        {
            chiefComplaint: 'Flank pain',
            name: 'Benjamin Guay',
            gender: 'M',
            age: 44,
            number: '1780928',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T00:53:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 97.4,
            },
        },
        {
            chiefComplaint: 'Abdominal mass / distention',
            name: 'Layla Fortier',
            gender: 'F',
            age: 67,
            number: '419517',
            prediction: 0,
            admService: null,
            waiting: '2024-03-11T00:58:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: 'N12      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 40,
            },
        },
        {
            chiefComplaint: 'Suicidal Ideation',
            name: 'Zoe Couture',
            gender: 'F',
            age: 41,
            number: '1333148',
            prediction: 0,
            admService: null,
            waiting: '2024-03-11T01:03:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'N06      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 53.3,
            },
        },
        {
            chiefComplaint: 'Hypertension',
            name: 'Mia Drouin',
            gender: 'F',
            age: 53,
            number: '1068853',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:18:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '4',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 97.7,
            },
        },
        {
            chiefComplaint: 'Headache',
            name: 'Evan Moreau',
            gender: 'M',
            age: 29,
            number: '1751923',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:26:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 92.1,
            },
        },
        {
            chiefComplaint: "Concern for patient's welfare",
            name: 'Edward Barbeau',
            gender: 'M',
            age: 24,
            number: '1403784',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:31:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '4',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Homeless',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 96.2,
            },
        },
        {
            chiefComplaint: 'Hypertension',
            name: 'Scarlett Dubé',
            gender: 'F',
            age: 68,
            number: '1238648',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:35:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '3',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 97.7,
            },
        },
        {
            chiefComplaint: 'Lumps, bumps, calluses',
            name: 'Alexander Demers',
            gender: 'M',
            age: 34,
            number: '1511854',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:52:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '4',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Homeless',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 91.6,
            },
        },
        {
            chiefComplaint: 'Hyperventilation',
            name: 'Abigail Thériault',
            gender: 'F',
            age: 30,
            number: '1779820',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T01:58:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 92.5,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Maxime Pelletier',
            gender: 'M',
            age: 64,
            number: '1780929',
            prediction: 1,
            admService: null,
            waiting: '2024-03-11T02:01:00',
            filter: 'predict_adm',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: 'N45B     ',
            covid: '',
            consultation: null,
            origin: 'Nursing home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 71.8,
            },
        },
        {
            chiefComplaint: 'Hypertension',
            name: 'Ethan Beauchamp',
            gender: 'M',
            age: 64,
            number: '792733',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T02:06:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '4',
            accuracy: 0,
            stretcher: 'S14      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 63.8,
            },
        },
        {
            chiefComplaint: 'Difficulty swallowing / Dysphagia',
            name: 'Brooklyn Bérubé',
            gender: 'F',
            age: 67,
            number: '1749405',
            prediction: 0,
            admService: null,
            waiting: '2024-03-11T02:43:00',
            filter: 'under_evaluation',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: 'Z12      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'None',
                accuracy: 40.8,
            },
        },
        {
            chiefComplaint: 'Shortness of breath',
            name: 'Christian Thibault',
            gender: 'M',
            age: 70,
            number: '1744672',
            prediction: 1,
            admService: null,
            waiting: '2024-03-11T02:57:00',
            filter: 'predict_adm',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: 'N33      ',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'medicine',
                accuracy: 76.7,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Ava Lachance',
            gender: 'F',
            age: 63,
            number: '1439917',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T03:35:00',
            filter: 'predict_home',
            sex: 'F',
            triage: '2',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 95.1,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Hunter Giroux',
            gender: 'M',
            age: 41,
            number: 'TMP0190893',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T04:01:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '3',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 98.6,
            },
        },
        {
            chiefComplaint: 'Abdominal pain',
            name: 'Charles Brisson',
            gender: 'M',
            age: 33,
            number: 'TMP0190894',
            prediction: -1,
            admService: null,
            waiting: '2024-03-11T04:03:00',
            filter: 'predict_home',
            sex: 'M',
            triage: '2',
            accuracy: 0,
            stretcher: '',
            covid: '',
            consultation: null,
            origin: 'Home',
            pastAdmitsDate: '',
            admissionColumn: {
                admService: 'Discharge',
                accuracy: 97.9,
            },
        },
    ],
}
