import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    resizeWH: {
        width: 400,
        height: 200,
    }
}

const managemenSlice = createSlice({
    name: 'management',
    initialState,
    reducers: {
        setResizeWH(state, action) {
            state.resizeWH = action.payload
        },
    },
    extraReducers: (builder) => {

    },
})

export const { setResizeWH } = managemenSlice.actions

export default managemenSlice.reducer