export const alertsMockData = [
    {
        "id": "B3B64C46-8621-68D4-1149-A8AD004E4681",
        "author": "Dorian Wallace",
        "alert": "quam dignissim pharetra.",
        "details": "amet metus. Aliquam erat volutpat. Nulla facilisis. Suspendisse commodo",
        "time": "3:36 PM",
        "been_read": true,
        "protected": true,
        "expected": "7:28 AM",
        "discharge_plan": [{
            "id": "42C87DF2-6A4E-2768-9EDB-87AC848DF532",
            "author": "Serena Lucas",
            "text": "augue. Sed molestie. Sed id",
            "time": "3:57 AM",
            "status_closed": false,
            "expected": "1:41 AM",
            "alert_id": "30707"
        }]
    },
    {
        "id": "42E17B64-FA19-869F-86A7-A62E0C4E36A7",
        "author": "Murphy Hansen",
        "alert": "arcu. Vestibulum",
        "details": "sem ut dolor dapibus gravida. Aliquam tincidunt, nunc",
        "time": "12:08 PM",
        "been_read": true,
        "protected": true,
        "expected": "9:50 PM",
        "discharge_plan": [{
            "id": "C2863F5A-26F3-3871-25A6-7C1475E22156",
            "author": "Carly Mcclure",
            "text": "et ultrices posuere cubilia Curae Donec tincidunt. Donec",
            "time": "1:06 PM",
            "status_closed": true,
            "expected": "10:41 PM",
            "alert_id": "43455"
        },
        {
            "id": "91DED528-9E0B-9907-A47C-47BDEC989C9E",
            "author": "Maia Donovan",
            "text": "mollis lectus pede et risus. Quisque libero lacus, varius et,",
            "time": "4:22 PM",
            "status_closed": true,
            "expected": "5:54 AM",
            "alert_id": "27628-34024"
        },]
    },
    {
        "id": "01CB4FB2-2F5D-A972-7EA9-98F6099912DE",
        "author": "Elijah Sweeney",
        "alert": "vehicula risus. Nulla",
        "details": "augue, eu tempor erat neque non",
        "time": "7:08 AM",
        "been_read": true,
        "protected": false,
        "expected": "4:14 PM",
        "discharge_plan": [{
            "id": "D53600EE-8F8E-2B92-117E-6415C113FEF8",
            "author": "Paloma Blevins",
            "text": "mi felis, adipiscing fringilla, porttitor vulputate, posuere vulputate, lacus.",
            "time": "6:08 PM",
            "status_closed": false,
            "expected": "8:51 PM",
            "alert_id": "38301"
        },
        {
            "id": "C6CB6B19-48A6-325A-7853-EAAA077ED4BD",
            "author": "Margaret Robbins",
            "text": "justo nec ante. Maecenas mi felis, adipiscing fringilla, porttitor",
            "time": "8:42 PM",
            "status_closed": false,
            "expected": "10:31 AM",
            "alert_id": "445487"
        },
        {
            "id": "499B9D4A-21AE-CB01-1A54-2F4A01EE843D",
            "author": "Glenna Byers",
            "text": "senectus et netus et malesuada fames ac",
            "time": "5:15 AM",
            "status_closed": false,
            "expected": "10:59 PM",
            "alert_id": "5726"
        },]
    },
    {
        "id": "F81E65E2-2A4C-3504-1CEF-4A639299365C",
        "author": "Portia Mckenzie",
        "alert": "id, ante. Nunc",
        "details": "dolor sit amet, consectetuer adipiscing elit. Curabitur",
        "time": "8:05 PM",
        "been_read": true,
        "protected": false,
        "expected": "9:01 AM",
        "discharge_plan": [{
            "id": "DDB96529-C51E-E599-DD5B-52E716932A2C",
            "author": "Channing Cochran",
            "text": "augue. Sed molestie. Sed id risus",
            "time": "1:42 AM",
            "status_closed": true,
            "expected": "9:57 PM",
            "alert_id": "153028"
        },]
    },
    {
        "id": "D6163DF9-A774-A1E6-3F2C-9827776066E1",
        "author": "Eric Gray",
        "alert": "diam vel arcu.",
        "details": "in lobortis tellus justo sit",
        "time": "9:47 PM",
        "been_read": false,
        "protected": true,
        "expected": "8:41 AM",
        "discharge_plan": []
    },
    {
        "id": "96F26061-BBCE-B849-E991-4EB7E95328D4",
        "author": "Maris Spears",
        "alert": "enim. Nunc",
        "details": "nisi sem semper erat, in consectetuer ipsum nunc id",
        "time": "1:22 PM",
        "been_read": true,
        "protected": true,
        "expected": "9:26 AM",
        "discharge_plan": [{
            "id": "257EE9EA-E144-CA70-3C62-4C748A399ECA",
            "author": "Yoshio Randall",
            "text": "enim mi tempor lorem, eget mollis lectus pede et",
            "time": "10:54 PM",
            "status_closed": false,
            "expected": "2:45 PM",
            "alert_id": "5747"
        },
        {
            "id": "88A05C48-8AB2-7202-1AA4-DB16B2FDE2C7",
            "author": "Dominic Kline",
            "text": "Morbi neque tellus, imperdiet non, vestibulum nec, euismod",
            "time": "12:57 AM",
            "status_closed": false,
            "expected": "2:56 AM",
            "alert_id": "51206"
        },]
    },
    {
        "id": "577CABAD-AB78-C790-B8E3-1F13728C9265",
        "author": "Dolan Bender",
        "alert": "facilisi. Sed",
        "details": "sapien. Aenean massa. Integer vitae nibh. Donec est",
        "time": "6:24 PM",
        "been_read": true,
        "protected": false,
        "expected": "10:48 PM",
        "discharge_plan": [{
            "id": "5DB1A4C5-7D56-91EB-FB75-25062529F12B",
            "author": "Quinn Kaufman",
            "text": "Vestibulum accumsan neque et nunc. Quisque ornare tortor at risus.",
            "time": "10:52 AM",
            "status_closed": false,
            "expected": "1:12 AM",
            "alert_id": "55675"
        },
        {
            "id": "6D79606C-2C48-89D2-EC37-07527C26DB77",
            "author": "Cherokee Webb",
            "text": "nibh lacinia orci, consectetuer euismod est arcu ac orci.",
            "time": "11:35 AM",
            "status_closed": false,
            "expected": "3:58 PM",
            "alert_id": "42323"
        },
        {
            "id": "BEF9BF8B-5553-DD44-BCB8-66619D7E8947",
            "author": "Urielle Kramer",
            "text": "tristique pellentesque, tellus sem mollis dui, in",
            "time": "4:39 AM",
            "status_closed": true,
            "expected": "9:00 PM",
            "alert_id": "63573"
        },
        {
            "id": "D2979AA7-366D-86F3-969E-4C281E6C872D",
            "author": "Athena Mills",
            "text": "posuere cubilia Curae Phasellus ornare. Fusce mollis. Duis sit",
            "time": "5:28 AM",
            "status_closed": true,
            "expected": "6:01 PM",
            "alert_id": "09480"
        },]
    },
    {
        "id": "C4C3B9E0-C5DE-2B5A-6DE4-0AB4F248DBF1",
        "author": "Raven Porter",
        "alert": "vestibulum nec, euismod",
        "details": "dolor. Nulla semper tellus id nunc interdum feugiat.",
        "time": "8:20 PM",
        "been_read": false,
        "protected": false,
        "expected": "7:29 AM",
        "discharge_plan": [{
            "id": "EB6F2B61-8471-8D8B-2AFB-43819052DD4E",
            "author": "Nola Kramer",
            "text": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est.",
            "time": "3:56 AM",
            "status_closed": true,
            "expected": "12:56 PM",
            "alert_id": "16468-26819"
        },]
    },
    {
        "id": "F2B663A0-A0F8-16B3-319D-87910B7ABE54",
        "author": "Gloria Hensley",
        "alert": "ornare, lectus ante",
        "details": "scelerisque neque. Nullam nisl. Maecenas",
        "time": "10:10 PM",
        "been_read": false,
        "protected": false,
        "expected": "7:34 AM",
        "discharge_plan": [{
            "id": "62B4C8BF-91EA-B250-9219-ED8C56D5D55A",
            "author": "Yuli Ratliff",
            "text": "arcu ac orci. Ut semper pretium neque. Morbi",
            "time": "12:31 AM",
            "status_closed": false,
            "expected": "7:52 AM",
            "alert_id": "2170-6674"
        },]
    },
    {
        "id": "FD25FDC2-744A-A3EC-88F8-55510FDF16B9",
        "author": "Jamal Morse",
        "alert": "libero nec",
        "details": "fermentum risus, at fringilla purus mauris a",
        "time": "12:37 PM",
        "been_read": false,
        "protected": true,
        "expected": "12:16 PM",
        "discharge_plan": [{
            "id": "5AB8C1CB-32D9-CDD7-4231-F54A6D1274E3",
            "author": "Katell Houston",
            "text": "egestas, urna justo faucibus lectus, a sollicitudin orci",
            "time": "4:13 AM",
            "status_closed": true,
            "expected": "1:56 AM",
            "alert_id": "25526"
        },
        {
            "id": "A4A479E6-219E-DBEB-8E3F-D395D15E7B3A",
            "author": "Mason Cummings",
            "text": "euismod in, dolor. Fusce feugiat. Lorem",
            "time": "3:51 AM",
            "status_closed": true,
            "expected": "10:52 PM",
            "alert_id": "667865"
        },]
    },
    {
        "id": "CA5633D2-B72A-BE74-8C98-560D16FEFB62",
        "author": "Blaze Mcmillan",
        "alert": "lorem fringilla",
        "details": "convallis ligula. Donec luctus aliquet odio. Etiam ligula tortor, dictum",
        "time": "12:30 AM",
        "been_read": true,
        "protected": true,
        "expected": "1:13 AM",
        "discharge_plan": [{
            "id": "B4C19856-E6D3-D8A9-509B-EB39212CB506",
            "author": "Kyra Lowery",
            "text": "gravida sagittis. Duis gravida. Praesent eu nulla",
            "time": "3:17 PM",
            "status_closed": false,
            "expected": "11:01 PM",
            "alert_id": "29930"
        },
        {
            "id": "742989EA-8082-2236-2B42-E6B4DADEEE08",
            "author": "Hilel Valenzuela",
            "text": "a tortor. Nunc commodo auctor velit. Aliquam nisl.",
            "time": "11:54 AM",
            "status_closed": false,
            "expected": "3:13 PM",
            "alert_id": "628418"
        },
        {
            "id": "CA3B47DE-11E5-AEEB-DDA8-2C852BFE1A5C",
            "author": "Hiram Stone",
            "text": "imperdiet non, vestibulum nec, euismod in,",
            "time": "8:59 AM",
            "status_closed": false,
            "expected": "10:19 AM",
            "alert_id": "571098"
        }]
    },
    {
        "id": "2E381459-52BA-85D7-77C4-A81BDA28045E",
        "author": "Aretha Abbott",
        "alert": "pellentesque massa lobortis",
        "details": "pede, nonummy ut, molestie in, tempus eu, ligula. Aenean",
        "time": "10:48 PM",
        "been_read": false,
        "protected": false,
        "expected": "4:08 AM",
        "discharge_plan": [{
            "id": "C2863F5A-26F3-3871-25A6-7C1475E22156",
            "author": "Carly Mcclure",
            "text": "et ultrices posuere cubilia Curae Donec tincidunt. Donec",
            "time": "1:06 PM",
            "status_closed": true,
            "expected": "10:41 PM",
            "alert_id": "43455"
        },]
    },
    {
        "id": "5469FBAC-5F30-8316-E2A7-AE8AA3A208DE",
        "author": "Ursula Fernandez",
        "alert": "nec, cursus",
        "details": "sed consequat auctor, nunc nulla vulputate dui,",
        "time": "8:31 AM",
        "been_read": false,
        "protected": false,
        "expected": "1:54 AM",
        "discharge_plan": [{
            "id": "91DED528-9E0B-9907-A47C-47BDEC989C9E",
            "author": "Maia Donovan",
            "text": "mollis lectus pede et risus. Quisque libero lacus, varius et,",
            "time": "4:22 PM",
            "status_closed": true,
            "expected": "5:54 AM",
            "alert_id": "27628-34024"
        },
        {
            "id": "D53600EE-8F8E-2B92-117E-6415C113FEF8",
            "author": "Paloma Blevins",
            "text": "mi felis, adipiscing fringilla, porttitor vulputate, posuere vulputate, lacus.",
            "time": "6:08 PM",
            "status_closed": false,
            "expected": "8:51 PM",
            "alert_id": "38301"
        },]
    },
    {
        "id": "261E7E6E-396E-917C-7728-13768863D51E",
        "author": "Bethany Dickerson",
        "alert": "amet, faucibus ut,",
        "details": "Nunc ut erat. Sed nunc est, mollis",
        "time": "3:56 AM",
        "been_read": true,
        "protected": false,
        "expected": "2:29 AM",
        "discharge_plan": [{
            "id": "C6CB6B19-48A6-325A-7853-EAAA077ED4BD",
            "author": "Margaret Robbins",
            "text": "justo nec ante. Maecenas mi felis, adipiscing fringilla, porttitor",
            "time": "8:42 PM",
            "status_closed": false,
            "expected": "10:31 AM",
            "alert_id": "445487"
        },
        {
            "id": "499B9D4A-21AE-CB01-1A54-2F4A01EE843D",
            "author": "Glenna Byers",
            "text": "senectus et netus et malesuada fames ac",
            "time": "5:15 AM",
            "status_closed": false,
            "expected": "10:59 PM",
            "alert_id": "5726"
        },]
    },
    {
        "id": "71767953-9E30-3F0A-A123-1EE26CB42527",
        "author": "Harrison Waters",
        "alert": "arcu. Vestibulum ante",
        "details": "blandit mattis. Cras eget nisi dictum augue",
        "time": "7:45 AM",
        "been_read": true,
        "protected": false,
        "expected": "6:39 AM",
        "discharge_plan": [{
            "id": "499B9D4A-21AE-CB01-1A54-2F4A01EE843D",
            "author": "Glenna Byers",
            "text": "senectus et netus et malesuada fames ac",
            "time": "5:15 AM",
            "status_closed": false,
            "expected": "10:59 PM",
            "alert_id": "5726"
        },
        {
            "id": "DDB96529-C51E-E599-DD5B-52E716932A2C",
            "author": "Channing Cochran",
            "text": "augue. Sed molestie. Sed id risus",
            "time": "1:42 AM",
            "status_closed": true,
            "expected": "9:57 PM",
            "alert_id": "153028"
        },
        {
            "id": "257EE9EA-E144-CA70-3C62-4C748A399ECA",
            "author": "Yoshio Randall",
            "text": "enim mi tempor lorem, eget mollis lectus pede et",
            "time": "10:54 PM",
            "status_closed": false,
            "expected": "2:45 PM",
            "alert_id": "5747"
        },
        {
            "id": "88A05C48-8AB2-7202-1AA4-DB16B2FDE2C7",
            "author": "Dominic Kline",
            "text": "Morbi neque tellus, imperdiet non, vestibulum nec, euismod",
            "time": "12:57 AM",
            "status_closed": false,
            "expected": "2:56 AM",
            "alert_id": "51206"
        },]
    },
    {
        "id": "1E013740-5EC8-A46E-2C2B-A925ED09D7E3",
        "author": "Lev Sears",
        "alert": "urna suscipit",
        "details": "Proin eget odio. Aliquam vulputate ullamcorper magna. Sed eu",
        "time": "11:19 AM",
        "been_read": false,
        "protected": true,
        "expected": "9:37 PM",
        "discharge_plan": [{
            "id": "5DB1A4C5-7D56-91EB-FB75-25062529F12B",
            "author": "Quinn Kaufman",
            "text": "Vestibulum accumsan neque et nunc. Quisque ornare tortor at risus.",
            "time": "10:52 AM",
            "status_closed": false,
            "expected": "1:12 AM",
            "alert_id": "55675"
        },
        {
            "id": "6D79606C-2C48-89D2-EC37-07527C26DB77",
            "author": "Cherokee Webb",
            "text": "nibh lacinia orci, consectetuer euismod est arcu ac orci.",
            "time": "11:35 AM",
            "status_closed": false,
            "expected": "3:58 PM",
            "alert_id": "42323"
        },]
    },
    {
        "id": "7A7875D0-E969-827C-2461-3843B89CE1A5",
        "author": "Christine Shaffer",
        "alert": "mattis velit justo",
        "details": "non enim commodo hendrerit. Donec porttitor tellus",
        "time": "10:03 PM",
        "been_read": true,
        "protected": true,
        "expected": "11:43 AM",
        "discharge_plan": [{
            "id": "BEF9BF8B-5553-DD44-BCB8-66619D7E8947",
            "author": "Urielle Kramer",
            "text": "tristique pellentesque, tellus sem mollis dui, in",
            "time": "4:39 AM",
            "status_closed": true,
            "expected": "9:00 PM",
            "alert_id": "63573"
        },
        {
            "id": "D2979AA7-366D-86F3-969E-4C281E6C872D",
            "author": "Athena Mills",
            "text": "posuere cubilia Curae Phasellus ornare. Fusce mollis. Duis sit",
            "time": "5:28 AM",
            "status_closed": true,
            "expected": "6:01 PM",
            "alert_id": "09480"
        },]
    },
    {
        "id": "1BCA9E3C-DBAE-9FAB-3CA4-15ADF816BA8A",
        "author": "Gretchen Daniel",
        "alert": "turpis nec mauris",
        "details": "dolor elit, pellentesque a, facilisis non,",
        "time": "9:42 AM",
        "been_read": true,
        "protected": true,
        "expected": "1:02 AM",
        "discharge_plan": [{
            "id": "EB6F2B61-8471-8D8B-2AFB-43819052DD4E",
            "author": "Nola Kramer",
            "text": "neque pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est.",
            "time": "3:56 AM",
            "status_closed": true,
            "expected": "12:56 PM",
            "alert_id": "16468-26819"
        },
        {
            "id": "62B4C8BF-91EA-B250-9219-ED8C56D5D55A",
            "author": "Yuli Ratliff",
            "text": "arcu ac orci. Ut semper pretium neque. Morbi",
            "time": "12:31 AM",
            "status_closed": false,
            "expected": "7:52 AM",
            "alert_id": "2170-6674"
        },
        {
            "id": "5AB8C1CB-32D9-CDD7-4231-F54A6D1274E3",
            "author": "Katell Houston",
            "text": "egestas, urna justo faucibus lectus, a sollicitudin orci",
            "time": "4:13 AM",
            "status_closed": true,
            "expected": "1:56 AM",
            "alert_id": "25526"
        },
        {
            "id": "A4A479E6-219E-DBEB-8E3F-D395D15E7B3A",
            "author": "Mason Cummings",
            "text": "euismod in, dolor. Fusce feugiat. Lorem",
            "time": "3:51 AM",
            "status_closed": true,
            "expected": "10:52 PM",
            "alert_id": "667865"
        },
        {
            "id": "B4C19856-E6D3-D8A9-509B-EB39212CB506",
            "author": "Kyra Lowery",
            "text": "gravida sagittis. Duis gravida. Praesent eu nulla",
            "time": "3:17 PM",
            "status_closed": false,
            "expected": "11:01 PM",
            "alert_id": "29930"
        },
        {
            "id": "742989EA-8082-2236-2B42-E6B4DADEEE08",
            "author": "Hilel Valenzuela",
            "text": "a tortor. Nunc commodo auctor velit. Aliquam nisl.",
            "time": "11:54 AM",
            "status_closed": false,
            "expected": "3:13 PM",
            "alert_id": "628418"
        },]
    },
    {
        "id": "2E912472-7E29-E683-1651-49BA861C135A",
        "author": "Donovan Calhoun",
        "alert": "scelerisque, lorem ipsum",
        "details": "sit amet massa. Quisque porttitor eros nec tellus. Nunc lectus",
        "time": "4:10 PM",
        "been_read": false,
        "protected": false,
        "expected": "7:48 AM",
        "discharge_plan": [{
            "id": "CA3B47DE-11E5-AEEB-DDA8-2C852BFE1A5C",
            "author": "Hiram Stone",
            "text": "imperdiet non, vestibulum nec, euismod in,",
            "time": "8:59 AM",
            "status_closed": false,
            "expected": "10:19 AM",
            "alert_id": "571098"
        }]
    },
    {
        "id": "B73FC99B-B190-3E9F-8690-1EF885346EDF",
        "author": "Theodore Buckner",
        "alert": "tellus. Phasellus elit",
        "details": "dictum augue malesuada malesuada. Integer id",
        "time": "12:04 AM",
        "been_read": true,
        "protected": true,
        "expected": "12:02 AM",
        "discharge_plan": [{
            "id": "42C87DF2-6A4E-2768-9EDB-87AC848DF532",
            "author": "Serena Lucas",
            "text": "augue. Sed molestie. Sed id",
            "time": "3:57 AM",
            "status_closed": false,
            "expected": "1:41 AM",
            "alert_id": "30707"
        },
        {
            "id": "C2863F5A-26F3-3871-25A6-7C1475E22156",
            "author": "Carly Mcclure",
            "text": "et ultrices posuere cubilia Curae Donec tincidunt. Donec",
            "time": "1:06 PM",
            "status_closed": true,
            "expected": "10:41 PM",
            "alert_id": "43455"
        },
        {
            "id": "91DED528-9E0B-9907-A47C-47BDEC989C9E",
            "author": "Maia Donovan",
            "text": "mollis lectus pede et risus. Quisque libero lacus, varius et,",
            "time": "4:22 PM",
            "status_closed": true,
            "expected": "5:54 AM",
            "alert_id": "27628-34024"
        },
        {
            "id": "D53600EE-8F8E-2B92-117E-6415C113FEF8",
            "author": "Paloma Blevins",
            "text": "mi felis, adipiscing fringilla, porttitor vulputate, posuere vulputate, lacus.",
            "time": "6:08 PM",
            "status_closed": false,
            "expected": "8:51 PM",
            "alert_id": "38301"
        },
        {
            "id": "C6CB6B19-48A6-325A-7853-EAAA077ED4BD",
            "author": "Margaret Robbins",
            "text": "justo nec ante. Maecenas mi felis, adipiscing fringilla, porttitor",
            "time": "8:42 PM",
            "status_closed": false,
            "expected": "10:31 AM",
            "alert_id": "445487"
        },
        {
            "id": "499B9D4A-21AE-CB01-1A54-2F4A01EE843D",
            "author": "Glenna Byers",
            "text": "senectus et netus et malesuada fames ac",
            "time": "5:15 AM",
            "status_closed": false,
            "expected": "10:59 PM",
            "alert_id": "5726"
        },
        {
            "id": "DDB96529-C51E-E599-DD5B-52E716932A2C",
            "author": "Channing Cochran",
            "text": "augue. Sed molestie. Sed id risus",
            "time": "1:42 AM",
            "status_closed": true,
            "expected": "9:57 PM",
            "alert_id": "153028"
        },
        {
            "id": "257EE9EA-E144-CA70-3C62-4C748A399ECA",
            "author": "Yoshio Randall",
            "text": "enim mi tempor lorem, eget mollis lectus pede et",
            "time": "10:54 PM",
            "status_closed": false,
            "expected": "2:45 PM",
            "alert_id": "5747"
        },
        {
            "id": "88A05C48-8AB2-7202-1AA4-DB16B2FDE2C7",
            "author": "Dominic Kline",
            "text": "Morbi neque tellus, imperdiet non, vestibulum nec, euismod",
            "time": "12:57 AM",
            "status_closed": false,
            "expected": "2:56 AM",
            "alert_id": "51206"
        },]
    }
]

export const discharge_plan = [{
    "id": "D53600EE-8F8E-2B92-117E-6415C113FEF8",
    "author": "Paloma Blevins",
    "text": "mi felis, adipiscing fringilla, porttitor vulputate, posuere vulputate, lacus.",
    "time": "6:08 PM",
    "status_closed": false,
    "expected": "8:51 PM",
    "alert_id": "38301"
},
{
    "id": "C6CB6B19-48A6-325A-7853-EAAA077ED4BD",
    "author": "Margaret Robbins",
    "text": "justo nec ante. Maecenas mi felis, adipiscing fringilla, porttitor",
    "time": "8:42 PM",
    "status_closed": false,
    "expected": "10:31 AM",
    "alert_id": "445487"
},
{
    "id": "499B9D4A-21AE-CB01-1A54-2F4A01EE843D",
    "author": "Glenna Byers",
    "text": "senectus et netus et malesuada fames ac",
    "time": "5:15 AM",
    "status_closed": false,
    "expected": "10:59 PM",
    "alert_id": "5726"
},]