import { createSlice } from '@reduxjs/toolkit'
import { actionCards, huddleActionItemChat, huddleNotes, meetingDigest, meetingNotes } from '../../../utils/mockData'
import { Status } from '../types/Status'
import { getActionCards, getHuddleActionItemChat, getHuddleNotes, getHuddlePageNotes, getHuddleUpdates } from './asyncActions'

const initialState = {
    selectedHuddlePatients: [] as any,
    huddlePatients: [] as any,
    huddlePatient: {} as any,
    isShowHuddleAdditionInfo: false,
    huddleNotes: [] as any,
    // huddleNotes: huddleNotes,
    huddleActionItemChat: [] as any,
    // huddleActionItemChat: huddleActionItemChat,
    actionCards: [] as any,
    // actionCards: actionCards,
    huddlePatientChoice: {} as any,
    notesHuddlePage: {} as any,
    updatesForPatientsHuddlePage: {} as any,
    huddleMeetingNotes: meetingNotes,
    huddleMeetingDigest: meetingDigest,
    selectetPatientNote: {} as any,
    selectedActionItem: {} as any
    // LabResultsDataStatus: Status.LOADING
}

const huddleSlice = createSlice({
    name: 'huddle',
    initialState,
    reducers: {
        setSelectedHuddlePatients(state, action) {
            state.selectedHuddlePatients = action.payload
        },
        setHuddlePatients(state, action) {
            console.log("🚀 ~ file: huddleSlice.ts:35 ~ setHuddlePatients ~ action:", action)
            state.huddlePatients = action.payload
        },
        setHuddlePatient(state, action) {
            state.huddlePatient = action.payload
        },
        setShowHuddleAdditionInfo(state, action) {
            state.isShowHuddleAdditionInfo = action.payload
        },
        setHuddleNotes(state, action) {
            console.log("🚀 ~ file: huddleSlice.ts:45 ~ setHuddleNotes ~ action:", action)
            state.huddleNotes = action.payload
        },
        setHuddlePatientChoice(state, action) {
            state.huddlePatientChoice = action.payload
        },
        setHuddleActionItemChat(state, action) {
            state.huddleActionItemChat = action.payload
        },
        setActionCards(state, action) {
            console.log("🚀 ~ file: huddleSlice.ts:52 ~ setActionCards ~ action:", action)
            state.actionCards = action.payload
        },
        setNotesHuddlePage(state, action) {
            console.log("🚀 ~ file: huddleSlice.ts:58 ~ setNotesHuddlePage ~ action:", action)
            state.notesHuddlePage = action.payload
        },
        setHuddleMeetingNotes(state, action) {
            state.huddleMeetingNotes = action.payload
        },
        setHuddleMeetingDigest(state, action) {
            state.huddleMeetingDigest = action.payload
        },
        setSelectetPatientNote(state, action) {
            state.selectetPatientNote = action.payload
        },
        setSelectedActionItem(state, action) {
            state.selectedActionItem = action.payload
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getHuddleNotes.pending, (state, action) => {

            state.huddleNotes = initialState.huddleNotes
        })
        builder.addCase(getHuddleNotes.fulfilled, (state, action) => {

            state.huddleNotes = action.payload
        })
        builder.addCase(getHuddleNotes.rejected, (state, action) => {

            state.huddleNotes = initialState.huddleNotes
        })


        builder.addCase(getHuddleActionItemChat.pending, (state, action) => {

            state.huddleActionItemChat = initialState.huddleActionItemChat
        })
        builder.addCase(getHuddleActionItemChat.fulfilled, (state, action) => {

            state.huddleActionItemChat = action.payload
        })
        builder.addCase(getHuddleActionItemChat.rejected, (state, action) => {

            state.huddleActionItemChat = initialState.huddleActionItemChat
        })

        builder.addCase(getActionCards.pending, (state, action) => {

            state.actionCards = initialState.actionCards
        })
        builder.addCase(getActionCards.fulfilled, (state, action) => {

            state.actionCards = action.payload
        })
        builder.addCase(getActionCards.rejected, (state, action) => {

            state.actionCards = initialState.actionCards
        })


        builder.addCase(getHuddlePageNotes.pending, (state, action) => {

            state.notesHuddlePage = initialState.notesHuddlePage
        })
        builder.addCase(getHuddlePageNotes.fulfilled, (state, action) => {

            state.notesHuddlePage = action.payload
        })
        builder.addCase(getHuddlePageNotes.rejected, (state, action) => {

            state.notesHuddlePage = initialState.notesHuddlePage
        })

        builder.addCase(getHuddleUpdates.pending, (state, action) => {

            state.updatesForPatientsHuddlePage = initialState.updatesForPatientsHuddlePage
        })
        builder.addCase(getHuddleUpdates.fulfilled, (state, action) => {

            state.updatesForPatientsHuddlePage = action.payload
        })
        builder.addCase(getHuddleUpdates.rejected, (state, action) => {

            state.updatesForPatientsHuddlePage = initialState.updatesForPatientsHuddlePage
        })
    },
})

export const {
    setSelectedHuddlePatients,
    setHuddlePatients,
    setHuddlePatient,
    setShowHuddleAdditionInfo,
    setHuddleNotes,
    setHuddlePatientChoice,
    setHuddleActionItemChat,
    setActionCards,
    setNotesHuddlePage,
    setHuddleMeetingNotes,
    setHuddleMeetingDigest,
    setSelectetPatientNote,
    setSelectedActionItem
} = huddleSlice.actions

export default huddleSlice.reducer
