import React from 'react'
import { IconTypeProps } from '../IconType'

const GeriatricsIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="7.27778" cy="3.77778" r="1.77778" fill={fillColor} />
            <circle cx="15.5" cy="18" r="1" fill={fillColor} />
            <rect
                x="5.05469"
                y="6"
                width="4"
                height="6"
                rx="2"
                transform="rotate(15 5.05469 6)"
                fill={fillColor}
            />
            <path
                d="M6.5 11.5L7.85749 13.7625C7.95074 13.9179 8 14.0957 8 14.277V18"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M5 10.5V13.7344C5 13.9085 4.95458 14.0795 4.86824 14.2306L3 17.5"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M7.5 8L8.24856 9.87139C8.40042 10.251 8.76813 10.5 9.17703 10.5H11.5"
                stroke={fillColor}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6181 11.5C12.8556 11.2346 13 10.8842 13 10.5H15.5C15.7761 10.5 16 10.7239 16 11V16.063C15.8402 16.0219 15.6726 16 15.5 16C15.3274 16 15.1598 16.0219 15 16.063V11.5H12.6181ZM12.0441 11.8983C11.8754 11.964 11.6919 12 11.5 12C11.2842 12 11.079 11.9544 10.8935 11.8723L10.0713 13.2428C10.0246 13.3205 10 13.4094 10 13.5V18.5C10 18.7761 10.2239 19 10.5 19C10.7761 19 11 18.7761 11 18.5V13.6385L12.0441 11.8983Z"
                fill={fillColor}
            />
            <line x1="10.5" y1="14.5" x2="15.5" y2="14.5" stroke={fillColor} />
        </svg>
    )
}

export default GeriatricsIcon
