import { generateDate, generateFurureDate } from './date/generateDate'

export const departmentsMock = [
    {
        id: 1,
        name: 'Internal medicine',
        type: 'internal_medicine',
        totalNumber: 60,
        numberOfPatients: 54,
    },
    {
        id: 2,
        name: 'Family medicine',
        type: 'family_medicine',
        totalNumber: 30,
        numberOfPatients: 27,
    },
    {
        id: 3,
        name: 'Geriatrics',
        type: 'geriatrics',
        totalNumber: 60,
        numberOfPatients: 51,
    },
    {
        id: 4,
        name: 'Paliative Care',
        type: 'paliative_care',
        totalNumber: 44,
        numberOfPatients: 44,
    },
    {
        id: 5,
        name: 'Cardiovascular',
        type: 'cardiology',
        totalNumber: 30,
        numberOfPatients: 35,
    },
    {
        id: 6,
        name: 'Surgery',
        type: 'surgery',
        totalNumber: 32,
        numberOfPatients: 40,
    },
    {
        id: 7,
        name: 'Psychiatry',
        type: 'psychiatry',
        totalNumber: 40,
        numberOfPatients: 33,
    },
    {
        id: 8,
        name: 'Home Care',
        type: 'home_care',
        totalNumber: 20,
        numberOfPatients: 20,
    },
]

export const NEW_DEPARTMENTS_MOCK = {
    departments: [
        {
            name: 'Psychiatry',
            capacity: 48,
            maxCapacity: 0,
            currentCountOfPatients: 56,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'B3AU',
                    capacity: 28,
                },
                {
                    name: 'B3IU',
                    capacity: 12,
                },
                {
                    name: 'B3HC',
                    capacity: 8,
                },
            ],
        },
        {
            name: 'Palliative',
            capacity: 17,
            maxCapacity: 0,
            currentCountOfPatients: 17,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: '4M',
                    capacity: 17,
                },
            ],
        },
        {
            name: 'Oncology',
            capacity: 28,
            maxCapacity: 0,
            currentCountOfPatients: 29,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'D41',
                    capacity: 28,
                },
            ],
        },
        {
            name: 'Cardiology',
            capacity: 50,
            maxCapacity: 0,
            currentCountOfPatients: 50,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'K2CI',
                    capacity: 14,
                },
                {
                    name: 'K2CU',
                    capacity: 36,
                },
            ],
        },
        {
            name: 'Surgery',
            capacity: 117,
            maxCapacity: 0,
            currentCountOfPatients: 107,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'C48W',
                    capacity: 35,
                },
                {
                    name: 'D53N',
                    capacity: 18,
                },
                {
                    name: 'K9',
                    capacity: 32,
                },
                {
                    name: 'K8',
                    capacity: 32,
                },
            ],
        },
        {
            name: 'Neurology',
            capacity: 23,
            maxCapacity: 0,
            currentCountOfPatients: 26,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'K10',
                    capacity: 23,
                },
            ],
        },
        {
            name: 'Special Units',
            capacity: 231,
            maxCapacity: 0,
            currentCountOfPatients: 34,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: '5W',
                    capacity: 40,
                },
                {
                    name: 'K3HR',
                    capacity: 11,
                },
                {
                    name: 'NURS',
                    capacity: 180,
                },
            ],
        },
        {
            name: 'SSU',
            capacity: 22,
            maxCapacity: 0,
            currentCountOfPatients: 24,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'C61',
                    capacity: 22,
                },
            ],
        },
        {
            name: 'Geriatrics',
            capacity: 53,
            maxCapacity: 0,
            currentCountOfPatients: 33,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'D6',
                    capacity: 27,
                },
                {
                    name: 'HD6',
                    capacity: 26,
                },
            ],
        },
        {
            name: 'IM non Teach',
            capacity: 27,
            maxCapacity: 0,
            currentCountOfPatients: 35,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'CD7',
                    capacity: 27,
                },
            ],
        },
        {
            name: 'Family Med',
            capacity: 25,
            maxCapacity: 0,
            currentCountOfPatients: 27,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'C3',
                    capacity: 25,
                },
            ],
        },
        {
            name: 'Critical Care',
            capacity: 25,
            maxCapacity: 0,
            currentCountOfPatients: 25,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'K1IC',
                    capacity: 25,
                },
            ],
        },
        {
            name: 'HAD',
            capacity: 5,
            maxCapacity: 0,
            currentCountOfPatients: 6,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'V2',
                    capacity: 5,
                },
            ],
        },
        {
            name: 'IM Teach',
            capacity: 64,
            maxCapacity: 0,
            currentCountOfPatients: 67,
            sortOrder: 0,
            hasAlerts: false,
            units: [
                {
                    name: 'K6',
                    capacity: 32,
                },
                {
                    name: 'K7',
                    capacity: 32,
                },
            ],
        },
    ],
}

export const MSSS_NSA = [
    {
        id: 1,
        current_nsa: '08b - Evaluation en cours',
        // current_nsa: 'Other (RPA, CHSLD prive)',
        inpatient: {
            totalCount: 1,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
            ],
        },
        ccomtl: '0',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 2,
        signed: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 2,
        current_nsa: '08c - En attente d approbation',
        // current_nsa: 'Var 08b (Orientation)',
        inpatient: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '67',
        HoursDelay1: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
            ],
        },
        HoursDelay2: 1,
        signed: [
            {
                count: 28,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 20,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 3,
        current_nsa: '09 - Organisation SAD',
        // current_nsa: 'Var 08c (Approbation)',
        inpatient: {
            totalCount: 1,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
            ],
        },
        ccomtl: '38',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 2,
        signed: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 22,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 4,
        current_nsa: '10a - AVC / Traumatologie',
        // current_nsa: 'Var 09 (Home Care/SAD)',
        inpatient: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '50',
        HoursDelay1: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
            ],
        },
        HoursDelay2: 5,
        signed: [
            {
                count: 18,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 45,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 42,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 43,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 5,
        current_nsa: '10a - Réadaptation intensive ou spécialisée',
        // current_nsa: 'Var 10 (Rehab)',
        inpatient: {
            totalCount: 16,
            details: [
                {
                    id: 1,
                    count: 6,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 4,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 6,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '38',
        HoursDelay1: {
            totalCount: 1,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
            ],
        },
        HoursDelay2: 5,
        signed: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 6,
        current_nsa: '10b - Réadaptation modérée et progressive',
        // current_nsa: 'Var 11 (Palliative)',
        inpatient: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
            ],
        },
        ccomtl: '0',
        HoursDelay1: {
            totalCount: 2,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 4,
        signed: [
            {
                count: 20,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 42,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 46,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 7,
        current_nsa: '11 - Place ou org. soins palliatifs',
        // current_nsa: 'Var 12a (RI-RTF)',
        inpatient: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '33',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
            ],
        },
        HoursDelay2: 1,
        signed: [
            {
                count: 33,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 28,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 28,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 22,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 8,
        current_nsa: '12a - RI-RTF',
        // current_nsa: 'Var 12b (CHSLD)',
        inpatient: {
            totalCount: 23,
            details: [
                {
                    id: 1,
                    count: 10,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 8,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 5,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '48',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 2,
        signed: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 28,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 28,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 9,
        current_nsa: '12b - Place d’hébergement en CHSLD',
        // current_nsa: 'Other (RPA, CHSLD prive)',
        inpatient: {
            totalCount: 1,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
            ],
        },
        ccomtl: '0',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 2,
        signed: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 10,
        current_nsa: '14 - Hébergement en santé mentale',
        // current_nsa: 'Var 08b (Orientation)',
        inpatient: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '67',
        HoursDelay1: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
            ],
        },
        HoursDelay2: 1,
        signed: [
            {
                count: 28,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 20,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 11,
        current_nsa: '15b - Ordonnance Hébergement',
        // current_nsa: 'Var 08c (Approbation)',
        inpatient: {
            totalCount: 1,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
            ],
        },
        ccomtl: '38',
        HoursDelay1: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 2,
        signed: [
            {
                count: 35,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 25,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 22,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
    {
        id: 12,
        current_nsa: 'Total # NSA pts',
        inpatient: {
            totalCount: 73,
            details: [
                {
                    id: 1,
                    count: 40,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 20,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 13,
                    text: 'planned admissions',
                },
            ],
        },
        ccomtl: '42',
        HoursDelay1: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 1,
                    text: 'planned admissions',
                },
            ],
        },
        HoursDelay2: 1,
        signed: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 38,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-08T00:00:00',
            },
        ],
        potential: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 32,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        kpi: [
            {
                count: 36,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 33,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 30,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
        chart: [
            {
                count: 25,
                date: '2023-06-01T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-02T00:00:00',
            },
            {
                count: 42,
                date: '2023-06-03T00:00:00',
            },
            {
                count: 40,
                date: '2023-06-04T00:00:00',
            },
            {
                count: 43,
                date: '2023-06-05T00:00:00',
            },
            {
                count: 36,
                date: '2023-06-06T00:00:00',
            },
            {
                count: 45,
                date: '2023-06-07T00:00:00',
            },
            {
                count: 35,
                date: '2023-06-08T00:00:00',
            },
        ],
    },
]

export const operatingRoomsMock = [
    {
        id: 1,
        title: 'On-time',
        number: 15,
    },
    {
        id: 2,
        title: 'Turnover (m)',
        number: 24,
    },
    {
        id: 3,
        title: 'Used Room',
        number: 23,
    },
    {
        id: 4,
        title: 'Cancellations',
        number: 115,
    },
]

export const ICUMock = [
    {
        id: 1,
        title: 'Inflow',
        number: 2,
    },
    {
        id: 2,
        title: 'Outflow Med',
        number: 3,
    },
    {
        id: 3,
        title: 'Outflow  Surg',
        number: 1,
    },
    {
        id: 4,
        title: 'Outflow Geri',
        number: 2,
    },
]

export const OperationalNsaMock = [
    {
        id: 1,
        type: 'internal_medicine',
        departmentName: 'Internal medicine',
        // Waiting: '4',
        Waiting: {
            totalCount: 4,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '10d',
        HoursDelay: 4,
    },
    {
        id: 2,
        type: 'family_medicine',
        departmentName: 'Family medicine',
        // Waiting: '11',
        Waiting: {
            totalCount: 11,
            details: [
                {
                    id: 1,
                    count: 5,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 3,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 5,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '4d',
        HoursDelay: 2,
    },
    {
        id: 3,
        type: 'geriatrics',
        departmentName: 'Geriatrics',
        // Waiting: '7',
        Waiting: {
            totalCount: 7,
            details: [
                {
                    id: 1,
                    count: 1,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 4,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 3,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '15d',
        HoursDelay: 3,
    },
    {
        id: 4,
        type: 'paliative_care',
        departmentName: 'Paliative Care',
        // Waiting: '3',
        Waiting: {
            totalCount: 3,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '6d',
        HoursDelay: 4,
    },
    {
        id: 5,
        type: 'cardiology',
        departmentName: 'Cardiovascular',
        // Waiting: '2',
        Waiting: {
            totalCount: 5,
            details: [
                {
                    id: 1,
                    count: 3,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 3,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '2d',
        HoursDelay: 5,
    },
    {
        id: 6,
        type: 'surgery',
        departmentName: 'Surgery',
        // Waiting: '1',
        Waiting: {
            totalCount: 6,
            details: [
                {
                    id: 1,
                    count: 3,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 1,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 3,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '4d',
        HoursDelay: 6,
    },
    {
        id: 7,
        type: 'psychiatry',
        departmentName: 'Psychiatry',
        // Waiting: '2',
        Waiting: {
            totalCount: 8,
            details: [
                {
                    id: 1,
                    count: 4,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 3,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 4,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '3d',
        HoursDelay: 6,
    },
    {
        id: 8,
        type: 'home_care',
        departmentName: 'Home Care',
        // Waiting: '2',
        Waiting: {
            totalCount: 2,
            details: [
                {
                    id: 1,
                    count: 2,
                    text: 'patient from ED',
                },
                {
                    id: 2,
                    count: 2,
                    text: 'predicted',
                },
                {
                    id: 3,
                    count: 2,
                    text: 'planned admissions',
                },
            ],
        },
        Highest: '6d',
        HoursDelay: 6,
    },
]

export const outflowDataMock = [
    {
        id: 1,
        type: 'Psychiatry',
        Total: {
            count: 12,
            alert: true,
            alerts: [{ id: 1, text: 'Total alert text' }],
        },
        Home: {
            count: 6,
            alert: true,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 4,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 2,
        type: 'Palliative',
        Total: {
            count: 6,
            alert: true,
            alerts: [{ id: 1, text: 'Total alert text' }],
        },
        Home: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Rehab: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 1,
            alert: true,
            alerts: [{ id: 1, text: 'CHSLD alert text' }],
        },
    },
    {
        id: 3,
        type: 'Oncology',
        Total: {
            count: 4,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 0,
            alert: true,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 4,
        type: 'Cardiology',
        Total: {
            count: 8,
            alert: true,
            alerts: [{ id: 1, text: 'Total alert text' }],
        },
        Home: {
            count: 5,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Rehab: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 5,
        type: 'Surgery',
        Total: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 2,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Rehab: {
            count: 0,
            alert: false,
            alerts: [{ id: 1, text: 'Rehab alert text' }],
        },
        CHSLD: {
            count: 0,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 6,
        type: 'Neurology',
        Total: {
            count: 9,
            alert: true,
            alerts: [{ id: 1, text: 'Total alert text' }],
        },
        Home: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Rehab: {
            count: 5,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'CHSLD alert text' }],
        },
    },
    {
        id: 7,
        type: 'IM Teach',
        Total: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Rehab: {
            count: 0,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 0,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 8,
        type: 'Special Units',
        Total: {
            count: 12,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 7,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 9,
        type: 'SSU',
        Total: {
            count: 12,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 7,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 2,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 10,
        type: 'Geriatrics',
        Total: {
            count: 11,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 7,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 11,
        type: 'IM non Teach',
        Total: {
            count: 8,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 3,
            alert: true,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 4,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 1,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 12,
        type: 'Family Med',
        Total: {
            count: 14,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 5,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 5,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 4,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 13,
        type: 'Critical Care',
        Total: {
            count: 11,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 4,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 5,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 2,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
    {
        id: 13,
        type: 'HAD',
        Total: {
            count: 9,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        Home: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'Home alert text' }],
        },
        Rehab: {
            count: 3,
            alert: true,
            alerts: [{ id: 1, text: 'alert text' }],
        },
        CHSLD: {
            count: 3,
            alert: false,
            alerts: [{ id: 1, text: 'alert text' }],
        },
    },
]

export const NEW_OUTFLOW_DATA = {
    outflows: [
        {
            department: 'Psychiatry',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 6,
            other: 0,
            total: 6,
        },
        {
            department: 'Palliative',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 2,
            other: 0,
            total: 2,
        },
        {
            department: 'Surgery',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 33,
            other: 0,
            total: 33,
        },
        {
            department: 'Neurology',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 5,
            other: 0,
            total: 5,
        },
        {
            department: 'Special Units',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 31,
            other: 1,
            total: 32,
        },
        {
            department: 'Geriatrics',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 2,
            other: 0,
            total: 2,
        },
        {
            department: 'IM non Teach',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 0,
            other: 0,
            total: 0,
        },
        {
            department: 'Family Med',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 2,
            other: 0,
            total: 2,
        },
        {
            department: 'Critical Care',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 12,
            other: 0,
            total: 12,
        },
        {
            department: 'HAD',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 3,
            other: 0,
            total: 3,
        },
        {
            department: 'SSU',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 1,
            other: 0,
            total: 1,
        },
        {
            department: 'Cardiology',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 14,
            other: 0,
            total: 14,
        },
        {
            department: 'Oncology',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 12,
            other: 0,
            total: 12,
        },
        {
            department: 'IM Teach',
            date: '2024-02-13T00:00:00',
            home: 0,
            rehab: 4,
            other: 0,
            total: 4,
        },
    ],
}

export const LengthofStayMockData = [
    {
        id: 1,
        type: 'internal_medicine',
        Days: 12,
    },
    {
        id: 2,
        type: 'family_medicine',
        Days: 8,
    },
    {
        id: 3,
        type: 'geriatrics',
        Days: 21,
    },
    {
        id: 4,
        type: 'paliative_care',
        Days: 53,
    },
    {
        id: 5,
        type: 'cardiology',
        Days: 5,
    },
    {
        id: 6,
        type: 'surgery',
        Days: 17,
    },
    {
        id: 7,
        type: 'geriatrics',
        Days: 67,
    },
    {
        id: 8,
        type: 'psychiatry',
        Days: 53,
    },
]

export const InflowMockData = [
    {
        id: 1,
        type: 'internal_medicine',
        Census: 10,
    },
    {
        id: 2,
        type: 'family_medicine',
        Census: 3,
    },
    {
        id: 3,
        type: 'geriatrics',
        Census: 1,
    },
    {
        id: 4,
        type: 'paliative_care',
        Census: 1,
    },
    {
        id: 5,
        type: 'cardiology',
        Census: 1,
    },
    {
        id: 6,
        type: 'surgery',
        Census: 1,
    },
    {
        id: 7,
        type: 'geriatrics',
        Census: 10,
    },
    {
        id: 8,
        type: 'psychiatry',
        Census: 4,
    },
]

export const EmergencyDepartmentMockData = {
    stretcher: [
        {
            id: 1,
            name: 'LoS',
            type: 'los',
            time: '13.5',
            chart: [
                {
                    patients: 12,
                    time: '2023-01-20T17:57:00',
                },
                {
                    patients: 10,
                    time: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    time: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    time: '2023-04-20T17:58:00',
                },
                {
                    patients: 15,
                    time: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    time: '2023-06-20T17:59:00',
                },
                {
                    patients: 13,
                    time: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    time: '2023-08-20T18:59:00',
                },
            ],
            predictions: {},
        },
        {
            id: 2,
            name: 'LoS 75+',
            type: 'los',
            time: '10.5',
            chart: [
                {
                    patients: 6,
                    time: '2023-01-20T17:57:00',
                },
                {
                    patients: 8,
                    time: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    time: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    time: '2023-04-20T17:58:00',
                },
                {
                    patients: 9,
                    time: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    time: '2023-06-20T17:59:00',
                },
                {
                    patients: 8,
                    time: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    time: '2023-08-20T18:59:00',
                },
            ],
        },
        {
            id: 3,
            name: 'Total Occupancy',
            type: 'total',
            time: '24',
            chart: [
                {
                    patients: 6,
                    time: '2023-01-20T17:57:00',
                },
                {
                    patients: 8,
                    time: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    time: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    time: '2023-04-20T17:58:00',
                },
                {
                    patients: 9,
                    time: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    time: '2023-06-20T17:59:00',
                },
                {
                    patients: 8,
                    time: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    time: '2023-08-20T18:59:00',
                },
            ],
        },
    ],
    ambulatory: [
        {
            id: 1,
            name: 'LoS',
            type: 'los',
            time: '5.0',
            chart: [
                {
                    patients: 12,
                    date: '2023-01-20T17:57:00',
                },
                {
                    patients: 10,
                    date: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    date: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    date: '2023-04-20T17:58:00',
                },
                {
                    patients: 15,
                    date: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    date: '2023-06-20T17:59:00',
                },
                {
                    patients: 13,
                    date: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    date: '2023-08-20T18:59:00',
                },
            ],
        },
        {
            id: 2,
            name: 'Less than 4 hours',
            type: 'percentage',
            time: '84',
            chart: [
                {
                    patients: 12,
                    date: '2023-01-20T17:57:00',
                },
                {
                    patients: 10,
                    date: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    date: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    date: '2023-04-20T17:58:00',
                },
                {
                    patients: 15,
                    date: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    date: '2023-06-20T17:59:00',
                },
                {
                    patients: 13,
                    date: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    date: '2023-08-20T18:59:00',
                },
            ],
        },
        {
            id: 3,
            name: 'Total Occupancy',
            type: 'total',
            time: '84',
            chart: [
                {
                    patients: 6,
                    time: '2023-01-20T17:57:00',
                },
                {
                    patients: 8,
                    time: '2023-02-20T17:57:00',
                },
                {
                    patients: 12,
                    time: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    time: '2023-04-20T17:58:00',
                },
                {
                    patients: 9,
                    time: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    time: '2023-06-20T17:59:00',
                },
                {
                    patients: 8,
                    time: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    time: '2023-08-20T18:59:00',
                },
            ],
        },
    ],
    delay: [
        {
            id: 1,
            name: 'Adm. delay > 2h',
            type: 'percentage',
            time: '84',
            chart: [
                {
                    patients: 12,
                    date: '2023-01-20T17:57:00',
                },
                {
                    patients: 8,
                    date: '2023-02-20T17:57:00',
                },
                {
                    patients: 10,
                    date: '2023-03-20T17:57:00',
                },
                {
                    patients: 13,
                    date: '2023-04-20T17:58:00',
                },
                {
                    patients: 14,
                    date: '2023-05-20T17:58:00',
                },
                {
                    patients: 12,
                    date: '2023-06-20T17:59:00',
                },
                {
                    patients: 10,
                    date: '2023-07-20T18:59:00',
                },
                {
                    patients: 11,
                    date: '2023-08-20T18:59:00',
                },
            ],
        },
    ],
}

export const ViralMockData = [
    {
        id: 1,
        name: 'COVID-19',
        number: 12,
        chart: [
            {
                patients: 7,
                date: generateDate(7),
            },
            {
                patients: 6,
                date: generateDate(6),
            },
            {
                patients: 8,
                date: generateDate(5),
            },
            {
                patients: 12,
                date: generateDate(4),
            },
            {
                patients: 10,
                date: generateDate(3),
            },
            {
                patients: 9,
                date: generateDate(2),
            },
            {
                patients: 8,
                date: generateDate(1),
            },
            {
                patients: 5,
                date: generateDate(0),
            },
        ],
        predictions: [
            {
                patients: 9,
                time: generateFurureDate(1),
            },
            {
                patients: 11,
                time: generateFurureDate(2),
            },
            {
                patients: 9,
                time: generateFurureDate(3),
            },
        ],
    },
    {
        id: 2,
        name: 'Monkeypox',
        number: 2,
        chart: [
            {
                patients: 3,
                date: generateDate(7),
            },
            {
                patients: 2,
                date: generateDate(6),
            },
            {
                patients: 4,
                date: generateDate(5),
            },
            {
                patients: 1,
                date: generateDate(4),
            },
            {
                patients: 3,
                date: generateDate(3),
            },
            {
                patients: 2,
                date: generateDate(2),
            },
            {
                patients: 3,
                date: generateDate(1),
            },
            {
                patients: 4,
                date: generateDate(0),
            },
        ],
        predictions: [
            {
                patients: 2,
                time: generateFurureDate(1),
            },
            {
                patients: 1,
                time: generateFurureDate(2),
            },
            {
                patients: 2,
                time: generateFurureDate(3),
            },
        ],
    },
    {
        id: 3,
        name: 'Ebolavirus',
        number: 5,
        chart: [
            {
                patients: 3,
                date: generateDate(7),
            },
            {
                patients: 5,
                date: generateDate(6),
            },
            {
                patients: 4,
                date: generateDate(5),
            },
            {
                patients: 1,
                date: generateDate(4),
            },
            {
                patients: 6,
                date: generateDate(3),
            },
            {
                patients: 2,
                date: generateDate(2),
            },
            {
                patients: 4,
                date: generateDate(1),
            },
            {
                patients: 5,
                date: generateDate(0),
            },
        ],
        predictions: [
            {
                patients: 2,
                time: generateFurureDate(1),
            },
            {
                patients: 1,
                time: generateFurureDate(2),
            },
            {
                patients: 2,
                time: generateFurureDate(3),
            },
        ],
    },
]

export const LengthofStayNewStructureMock = {
    departments: [
        {
            departmentName: 'Special Units',
            items: [
                {
                    medianValue: 0,
                    date: generateDate(4),
                },
                {
                    medianValue: 1,
                    date: generateDate(3),
                },
                {
                    medianValue: 0,
                    date: generateDate(7),
                },
                {
                    medianValue: 0,
                    date: generateDate(5),
                },
                {
                    medianValue: 0,
                    date: generateDate(1),
                },
                {
                    medianValue: 0,
                    date: generateDate(6),
                },
                {
                    medianValue: 3,
                    date: generateDate(2),
                },
                {
                    medianValue: 1,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'SSU',
            items: [
                {
                    medianValue: 0,
                    date: generateDate(4),
                },
                {
                    medianValue: 0,
                    date: generateDate(6),
                },
                {
                    medianValue: 0,
                    date: generateDate(2),
                },
                {
                    medianValue: 0,
                    date: generateDate(7),
                },
                {
                    medianValue: 0,
                    date: generateDate(3),
                },
                {
                    medianValue: 0,
                    date: generateDate(1),
                },
                {
                    medianValue: 0,
                    date: generateDate(5),
                },
                {
                    medianValue: 0,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Geriatrics',
            items: [
                {
                    medianValue: 12,
                    date: generateDate(2),
                },
                {
                    medianValue: 10,
                    date: generateDate(4),
                },
                {
                    medianValue: 10,
                    date: generateDate(6),
                },
                {
                    medianValue: 11,
                    date: generateDate(3),
                },
                {
                    medianValue: 9,
                    date: generateDate(7),
                },
                {
                    medianValue: 12,
                    date: generateDate(1),
                },
                {
                    medianValue: 10,
                    date: generateDate(5),
                },
                {
                    medianValue: 13,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'IM non Teach',
            items: [
                {
                    medianValue: 0,
                    date: generateDate(2),
                },
                {
                    medianValue: 0,
                    date: generateDate(6),
                },
                {
                    medianValue: 0,
                    date: generateDate(4),
                },
                {
                    medianValue: 0,
                    date: generateDate(5),
                },
                {
                    medianValue: 0,
                    date: generateDate(1),
                },
                {
                    medianValue: 0,
                    date: generateDate(3),
                },
                {
                    medianValue: 0,
                    date: generateDate(7),
                },
                {
                    medianValue: 0,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Family Med',
            items: [
                {
                    medianValue: 4,
                    date: generateDate(6),
                },
                {
                    medianValue: 4,
                    date: generateDate(2),
                },
                {
                    medianValue: 4,
                    date: generateDate(4),
                },
                {
                    medianValue: 4,
                    date: generateDate(5),
                },
                {
                    medianValue: 4,
                    date: generateDate(7),
                },
                {
                    medianValue: 4,
                    date: generateDate(3),
                },
                {
                    medianValue: 5,
                    date: generateDate(1),
                },
                {
                    medianValue: 6,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'HAD',
            items: [
                {
                    medianValue: 9,
                    date: generateDate(2),
                },
                {
                    medianValue: 5,
                    date: generateDate(6),
                },
                {
                    medianValue: 7,
                    date: generateDate(4),
                },
                {
                    medianValue: 4,
                    date: generateDate(7),
                },
                {
                    medianValue: 8,
                    date: generateDate(3),
                },
                {
                    medianValue: 6,
                    date: generateDate(5),
                },
                {
                    medianValue: 10,
                    date: generateDate(1),
                },
                {
                    medianValue: 10,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Critical Care',
            items: [
                {
                    medianValue: 6,
                    date: generateDate(4),
                },
                {
                    medianValue: 8,
                    date: generateDate(2),
                },
                {
                    medianValue: 6,
                    date: generateDate(6),
                },
                {
                    medianValue: 6,
                    date: generateDate(7),
                },
                {
                    medianValue: 6,
                    date: generateDate(3),
                },
                {
                    medianValue: 6,
                    date: generateDate(5),
                },
                {
                    medianValue: 5,
                    date: generateDate(1),
                },
                {
                    medianValue: 5,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'IM Teach',
            items: [
                {
                    medianValue: 7,
                    date: generateDate(2),
                },
                {
                    medianValue: 6,
                    date: generateDate(6),
                },
                {
                    medianValue: 8,
                    date: generateDate(4),
                },
                {
                    medianValue: 7,
                    date: generateDate(5),
                },
                {
                    medianValue: 11,
                    date: generateDate(1),
                },
                {
                    medianValue: 5,
                    date: generateDate(7),
                },
                {
                    medianValue: 9,
                    date: generateDate(3),
                },
                {
                    medianValue: 12,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Psychiatry',
            items: [
                {
                    medianValue: 16,
                    date: generateDate(3),
                },
                {
                    medianValue: 27,
                    date: generateDate(7),
                },
                {
                    medianValue: 27,
                    date: generateDate(5),
                },
                {
                    medianValue: 17,
                    date: generateDate(2),
                },
                {
                    medianValue: 26,
                    date: generateDate(6),
                },
                {
                    medianValue: 18,
                    date: generateDate(1),
                },
                {
                    medianValue: 22,
                    date: generateDate(4),
                },
                {
                    medianValue: 32,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Palliative',
            items: [
                {
                    medianValue: 4,
                    date: generateDate(7),
                },
                {
                    medianValue: 5,
                    date: generateDate(3),
                },
                {
                    medianValue: 5,
                    date: generateDate(5),
                },
                {
                    medianValue: 9,
                    date: generateDate(1),
                },
                {
                    medianValue: 6,
                    date: generateDate(2),
                },
                {
                    medianValue: 5,
                    date: generateDate(6),
                },
                {
                    medianValue: 4,
                    date: generateDate(4),
                },
                {
                    medianValue: 10,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Oncology',
            items: [
                {
                    medianValue: 4,
                    date: generateDate(7),
                },
                {
                    medianValue: 5,
                    date: generateDate(3),
                },
                {
                    medianValue: 5,
                    date: generateDate(5),
                },
                {
                    medianValue: 6,
                    date: generateDate(1),
                },
                {
                    medianValue: 4,
                    date: generateDate(6),
                },
                {
                    medianValue: 6,
                    date: generateDate(2),
                },
                {
                    medianValue: 6,
                    date: generateDate(4),
                },
                {
                    medianValue: 7,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Cardiology',
            items: [
                {
                    medianValue: 3,
                    date: generateDate(3),
                },
                {
                    medianValue: 3,
                    date: generateDate(7),
                },
                {
                    medianValue: 3,
                    date: generateDate(5),
                },
                {
                    medianValue: 3,
                    date: generateDate(1),
                },
                {
                    medianValue: 2,
                    date: generateDate(6),
                },
                {
                    medianValue: 3,
                    date: generateDate(2),
                },
                {
                    medianValue: 2,
                    date: generateDate(4),
                },
                {
                    medianValue: 4,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Surgery',
            items: [
                {
                    medianValue: 4,
                    date: generateDate(7),
                },
                {
                    medianValue: 4,
                    date: generateDate(3),
                },
                {
                    medianValue: 5,
                    date: generateDate(5),
                },
                {
                    medianValue: 6,
                    date: generateDate(1),
                },
                {
                    medianValue: 5,
                    date: generateDate(2),
                },
                {
                    medianValue: 2,
                    date: generateDate(6),
                },
                {
                    medianValue: 3,
                    date: generateDate(4),
                },
                {
                    medianValue: 6,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
        {
            departmentName: 'Neurology',
            items: [
                {
                    medianValue: 0,
                    date: generateDate(7),
                },
                {
                    medianValue: 4,
                    date: generateDate(3),
                },
                {
                    medianValue: 2,
                    date: generateDate(5),
                },
                {
                    medianValue: 5,
                    date: generateDate(1),
                },
                {
                    medianValue: 5,
                    date: generateDate(2),
                },
                {
                    medianValue: 1,
                    date: generateDate(6),
                },
                {
                    medianValue: 3,
                    date: generateDate(4),
                },
                {
                    medianValue: 7,
                    date: generateDate(0),
                },
            ],
            kpi: 0,
        },
    ],
}

export const inflowNewStructureMock = {
    departments: [
        {
            departmentName: 'Psychiatry',
            capacity: 273,
            inflow: [
                {
                    count: 284,
                    date: generateDate(7),
                    currentInflow: 0,
                },
                {
                    count: 283,
                    date: generateDate(6),
                    currentInflow: 0,
                },
                {
                    count: 281,
                    date: generateDate(5),
                    currentInflow: 0,
                },
                {
                    count: 283,
                    date: generateDate(4),
                    currentInflow: 0,
                },
                {
                    count: 283,
                    date: generateDate(3),
                    currentInflow: 0,
                },
                {
                    count: 286,
                    date: generateDate(2),
                    currentInflow: 0,
                },
                {
                    count: 286,
                    date: generateDate(1),
                    currentInflow: 0,
                },
                {
                    count: 285,
                    date: generateDate(0),
                    currentInflow: 7,
                },
            ],
            // predictions: [
            //     {
            //         "count": 284,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 282,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 283,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Cardiology',
            capacity: 217,
            inflow: [
                {
                    count: 208,
                    date: generateDate(7),
                    currentInflow: 215,
                },
                {
                    count: 211,
                    date: generateDate(6),
                    currentInflow: 215,
                },
                {
                    count: 210,
                    date: generateDate(5),
                    currentInflow: 215,
                },
                {
                    count: 213,
                    date: generateDate(4),
                    currentInflow: 215,
                },
                {
                    count: 215,
                    date: generateDate(3),
                    currentInflow: 215,
                },
                {
                    count: 214,
                    date: generateDate(2),
                    currentInflow: 215,
                },
                {
                    count: 213,
                    date: generateDate(1),
                    currentInflow: 215,
                },
                {
                    count: 215,
                    date: generateDate(0),
                    currentInflow: 11,
                },
            ],
            // predictions: [
            //     {
            //         "count": 218,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 217,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 218,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Special Units',
            capacity: 423,
            inflow: [
                {
                    count: 430,
                    date: generateDate(7),
                    currentInflow: 421,
                },
                {
                    count: 429,
                    date: generateDate(6),
                    currentInflow: 421,
                },
                {
                    count: 427,
                    date: generateDate(5),
                    currentInflow: 421,
                },
                {
                    count: 426,
                    date: generateDate(4),
                    currentInflow: 421,
                },
                {
                    count: 429,
                    date: generateDate(3),
                    currentInflow: 421,
                },
                {
                    count: 426,
                    date: generateDate(2),
                    currentInflow: 421,
                },
                {
                    count: 423,
                    date: generateDate(1),
                    currentInflow: 421,
                },
                {
                    count: 421,
                    date: generateDate(0),
                    currentInflow: 5,
                },
            ],
            // predictions: [
            //     {
            //         "count": 425,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 427,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 426,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'IM non Teach',
            capacity: 87,
            inflow: [
                {
                    count: 106,
                    date: generateDate(7),
                    currentInflow: 90,
                },
                {
                    count: 103,
                    date: generateDate(6),
                    currentInflow: 90,
                },
                {
                    count: 102,
                    date: generateDate(5),
                    currentInflow: 90,
                },
                {
                    count: 99,
                    date: generateDate(4),
                    currentInflow: 90,
                },
                {
                    count: 96,
                    date: generateDate(3),
                    currentInflow: 90,
                },
                {
                    count: 95,
                    date: generateDate(2),
                    currentInflow: 90,
                },
                {
                    count: 92,
                    date: generateDate(1),
                    currentInflow: 90,
                },
                {
                    count: 90,
                    date: generateDate(0),
                    currentInflow: 3,
                },
            ],
            // predictions: [
            //     {
            //         "count": 88,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 85,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 87,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'HAD',
            capacity: 50,
            inflow: [
                {
                    count: 34,
                    date: generateDate(7),
                    currentInflow: 43,
                },
                {
                    count: 37,
                    date: generateDate(6),
                    currentInflow: 43,
                },
                {
                    count: 38,
                    date: generateDate(5),
                    currentInflow: 43,
                },
                {
                    count: 39,
                    date: generateDate(4),
                    currentInflow: 43,
                },
                {
                    count: 42,
                    date: generateDate(3),
                    currentInflow: 43,
                },
                {
                    count: 41,
                    date: generateDate(2),
                    currentInflow: 43,
                },
                {
                    count: 42,
                    date: generateDate(1),
                    currentInflow: 43,
                },
                {
                    count: 43,
                    date: generateDate(0),
                    currentInflow: 3,
                },
            ],
            // predictions: [
            //     {
            //         "count": 42,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 44,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 40,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Geriatrics',
            capacity: 150,
            inflow: [
                {
                    count: 149,
                    date: generateDate(7),
                    currentInflow: 147,
                },
                {
                    count: 150,
                    date: generateDate(6),
                    currentInflow: 147,
                },
                {
                    count: 147,
                    date: generateDate(5),
                    currentInflow: 147,
                },
                {
                    count: 147,
                    date: generateDate(4),
                    currentInflow: 147,
                },
                {
                    count: 148,
                    date: generateDate(3),
                    currentInflow: 147,
                },
                {
                    count: 149,
                    date: generateDate(2),
                    currentInflow: 147,
                },
                {
                    count: 149,
                    date: generateDate(1),
                    currentInflow: 147,
                },
                {
                    count: 147,
                    date: generateDate(0),
                    currentInflow: 8,
                },
            ],
            // predictions: [
            //     {
            //         "count": 150,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 147,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 143,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Family Med',
            capacity: 46,
            inflow: [
                {
                    count: 43,
                    date: generateDate(7),
                    currentInflow: 48,
                },
                {
                    count: 46,
                    date: generateDate(6),
                    currentInflow: 48,
                },
                {
                    count: 46,
                    date: generateDate(5),
                    currentInflow: 48,
                },
                {
                    count: 46,
                    date: generateDate(4),
                    currentInflow: 48,
                },
                {
                    count: 48,
                    date: generateDate(3),
                    currentInflow: 48,
                },
                {
                    count: 40,
                    date: generateDate(2),
                    currentInflow: 48,
                },
                {
                    count: 50,
                    date: generateDate(1),
                    currentInflow: 48,
                },
                {
                    count: 48,
                    date: generateDate(0),
                    currentInflow: 0,
                },
            ],
            // predictions: [
            //     {
            //         "count": 49,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 52,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 51,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'IM Teach',
            capacity: 30,
            inflow: [
                {
                    count: 28,
                    date: generateDate(7),
                    currentInflow: 29,
                },
                {
                    count: 29,
                    date: generateDate(6),
                    currentInflow: 29,
                },
                {
                    count: 29,
                    date: generateDate(5),
                    currentInflow: 29,
                },
                {
                    count: 29,
                    date: generateDate(4),
                    currentInflow: 29,
                },
                {
                    count: 30,
                    date: generateDate(3),
                    currentInflow: 29,
                },
                {
                    count: 29,
                    date: generateDate(2),
                    currentInflow: 29,
                },
                {
                    count: 30,
                    date: generateDate(1),
                    currentInflow: 29,
                },
                {
                    count: 29,
                    date: generateDate(0),
                    currentInflow: 1,
                },
            ],
            // predictions: [
            //     {
            //         "count": 31,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 31,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 29,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Palliative',
            capacity: 37,
            inflow: [
                {
                    count: 34,
                    date: generateDate(7),
                    currentInflow: 0,
                },
                {
                    count: 35,
                    date: generateDate(6),
                    currentInflow: 0,
                },
                {
                    count: 38,
                    date: generateDate(5),
                    currentInflow: 0,
                },
                {
                    count: 40,
                    date: generateDate(4),
                    currentInflow: 0,
                },
                {
                    count: 37,
                    date: generateDate(3),
                    currentInflow: 0,
                },
                {
                    count: 37,
                    date: generateDate(2),
                    currentInflow: 0,
                },
                {
                    count: 35,
                    date: generateDate(1),
                    currentInflow: 0,
                },
                {
                    count: 32,
                    date: generateDate(0),
                    currentInflow: 6,
                },
            ],
            // predictions: [
            //     {
            //         "count": 36,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 35,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 32,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Surgery',
            capacity: 673,
            inflow: [
                {
                    count: 649,
                    date: generateDate(7),
                    currentInflow: 651,
                },
                {
                    count: 652,
                    date: generateDate(6),
                    currentInflow: 651,
                },
                {
                    count: 652,
                    date: generateDate(5),
                    currentInflow: 651,
                },
                {
                    count: 649,
                    date: generateDate(4),
                    currentInflow: 651,
                },
                {
                    count: 650,
                    date: generateDate(3),
                    currentInflow: 651,
                },
                {
                    count: 653,
                    date: generateDate(2),
                    currentInflow: 651,
                },
                {
                    count: 653,
                    date: generateDate(1),
                    currentInflow: 651,
                },
                {
                    count: 651,
                    date: generateDate(0),
                    currentInflow: 32,
                },
            ],
            // predictions: [
            //     {
            //         "count": 655,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 659,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 660,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'SSU',
            capacity: 110,
            inflow: [
                {
                    count: 112,
                    date: generateDate(7),
                    currentInflow: 110,
                },
                {
                    count: 112,
                    date: generateDate(6),
                    currentInflow: 110,
                },
                {
                    count: 114,
                    date: generateDate(5),
                    currentInflow: 110,
                },
                {
                    count: 113,
                    date: generateDate(4),
                    currentInflow: 110,
                },
                {
                    count: 115,
                    date: generateDate(3),
                    currentInflow: 110,
                },
                {
                    count: 116,
                    date: generateDate(2),
                    currentInflow: 110,
                },
                {
                    count: 113,
                    date: generateDate(1),
                    currentInflow: 110,
                },
                {
                    count: 110,
                    date: generateDate(0),
                    currentInflow: 5,
                },
            ],
            // predictions: [
            //     {
            //         "count": 107,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 107,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 103,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Critical Care',
            capacity: 63,
            inflow: [
                {
                    count: 70,
                    date: generateDate(7),
                    currentInflow: 64,
                },
                {
                    count: 68,
                    date: generateDate(6),
                    currentInflow: 64,
                },
                {
                    count: 70,
                    date: generateDate(5),
                    currentInflow: 64,
                },
                {
                    count: 68,
                    date: generateDate(4),
                    currentInflow: 64,
                },
                {
                    count: 69,
                    date: generateDate(3),
                    currentInflow: 64,
                },
                {
                    count: 67,
                    date: generateDate(2),
                    currentInflow: 64,
                },
                {
                    count: 65,
                    date: generateDate(1),
                    currentInflow: 64,
                },
                {
                    count: 64,
                    date: generateDate(0),
                    currentInflow: 7,
                },
            ],
            // predictions: [
            //     {
            //         "count": 65,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 68,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 69,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Oncology',
            capacity: 473,
            inflow: [
                {
                    count: 476,
                    date: generateDate(7),
                    currentInflow: 465,
                },
                {
                    count: 473,
                    date: generateDate(6),
                    currentInflow: 465,
                },
                {
                    count: 472,
                    date: generateDate(5),
                    currentInflow: 465,
                },
                {
                    count: 469,
                    date: generateDate(4),
                    currentInflow: 465,
                },
                {
                    count: 469,
                    date: generateDate(3),
                    currentInflow: 465,
                },
                {
                    count: 467,
                    date: generateDate(2),
                    currentInflow: 465,
                },
                {
                    count: 464,
                    date: generateDate(1),
                    currentInflow: 465,
                },
                {
                    count: 465,
                    date: generateDate(0),
                    currentInflow: 15,
                },
            ],
            // predictions: [
            //     {
            //         "count": 464,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 467,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 467,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
        {
            departmentName: 'Neurology',
            capacity: 53,
            inflow: [
                {
                    count: 47,
                    date: generateDate(7),
                    currentInflow: 49,
                },
                {
                    count: 46,
                    date: generateDate(6),
                    currentInflow: 49,
                },
                {
                    count: 47,
                    date: generateDate(5),
                    currentInflow: 49,
                },
                {
                    count: 49,
                    date: generateDate(4),
                    currentInflow: 49,
                },
                {
                    count: 51,
                    date: generateDate(3),
                    currentInflow: 49,
                },
                {
                    count: 48,
                    date: generateDate(2),
                    currentInflow: 49,
                },
                {
                    count: 48,
                    date: generateDate(1),
                    currentInflow: 49,
                },
                {
                    count: 49,
                    date: generateDate(0),
                    currentInflow: 2,
                },
            ],
            // predictions: [
            //     {
            //         "count": 52,
            //         "date": generateFurureDate(1),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 51,
            //         "date": generateFurureDate(2),
            //         "currentInflow": 0
            //     },
            //     {
            //         "count": 55,
            //         "date": generateFurureDate(3),
            //         "currentInflow": 0
            //     }
            // ]
        },
    ],
}

export const NewEmergencyDepartmentMockData = {
    stretcherSection: {
        loS: {
            currentValue: '47h',
            avgValue: 10,
            positive: false,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 47,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 46,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 45,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 44,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 43,
                },
            ],
        },
        loSOver75: {
            currentValue: '0h',
            avgValue: 5,
            positive: false,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 0,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '1',
            avgValue: 20,
            positive: true,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 1,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 1,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 1,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 1,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 1,
                },
            ],
        },
    },
    ambulatorySection: {
        loS: {
            currentValue: '206h',
            avgValue: 20,
            positive: false,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 206,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 205,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 204,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 203,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 202,
                },
            ],
        },
        loSUnder4Hours: {
            currentValue: '0%',
            avgValue: 4,
            positive: false,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 0,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 0,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '2',
            avgValue: 20,
            positive: true,
            values: [
                {
                    date: '2023-07-26T15:29:59.778194Z',
                    value: 2,
                },
                {
                    date: '2023-07-26T14:29:59.7961927Z',
                    value: 2,
                },
                {
                    date: '2023-07-26T13:29:59.7962778Z',
                    value: 2,
                },
                {
                    date: '2023-07-26T12:29:59.7962869Z',
                    value: 2,
                },
                {
                    date: '2023-07-26T11:29:59.7963006Z',
                    value: 2,
                },
            ],
        },
    },
    admDelay: {
        currentValue: '100%',
        avgValue: 80,
        positive: true,
        values: [
            {
                date: '2023-07-26T15:29:59.778194Z',
                value: 100,
            },
            {
                date: '2023-07-26T14:29:59.7961927Z',
                value: 100,
            },
            {
                date: '2023-07-26T13:29:59.7962778Z',
                value: 100,
            },
            {
                date: '2023-07-26T12:29:59.7962869Z',
                value: 100,
            },
            {
                date: '2023-07-26T11:29:59.7963006Z',
                value: 100,
            },
        ],
    },
}
