import { Progress } from '@mantine/core'
import React from 'react'
import { AdmissionColumnType } from '../../../../redux/rtk/edLive/types'
import styles from './AdmissionsColumn.module.scss'

type AdmissionColumnProps = {
    cellValue: AdmissionColumnType | string
    filterValue?: string
}

const AdmissionColumn: React.FC<AdmissionColumnProps> = ({
    cellValue,
    filterValue,
}) => {
    let backgroundColor = '#1624DE'
    let shouldShowPredictionBar = true

    if (filterValue === 'predict_home') {
        backgroundColor = '#2BBC19'
    }

    if (filterValue === 'adm' || filterValue === 'Psychiatry') {
        shouldShowPredictionBar = false
    }

    return (
        <div className={styles.container}>
            {filterValue !== 'under_evaluation' &&
                typeof cellValue !== 'string' && (
                    <div>
                        {cellValue?.admService && (
                            <span className={styles.service}>
                                {cellValue?.admService}
                            </span>
                        )}
                        {cellValue?.accuracy && shouldShowPredictionBar && (
                            <Progress.Root size="xl">
                                <Progress.Section
                                    value={cellValue?.accuracy}
                                    color="#6A95BD"
                                >
                                    <Progress.Label>
                                        {cellValue?.accuracy}%
                                    </Progress.Label>
                                </Progress.Section>
                            </Progress.Root>
                        )}
                    </div>
                )}
        </div>
    )
}

export default AdmissionColumn
