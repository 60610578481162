import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../types/Status'
import {
    getDischargeDispositions,
    getPatientTableData,
    getPatientTablePopupBloodpreasures,
    getPatientTablePopupHeartrates,
    getPatientTablePopupSaturations,
    getPatientTablePopupTemperatures,
    updateLenghtOfStay,
} from './asyncActions'
import {
    DashboardPopupPatientBloodpreasuresType,
    DashboardPopupPatientHeartratesType,
    DashboardPopupPatientSaturationsType,
    DashboardPopupPatientTemperaturesType,
    DashboardPopupPatientType,
} from './types'

const dashboardPopupPatient = {} as DashboardPopupPatientType
const dashboardPopupPatientSaturations =
    {} as DashboardPopupPatientSaturationsType
const dashboardPopupPatientTemperatures =
    {} as DashboardPopupPatientTemperaturesType
const dashboardPopupPatientBloodpreasures =
    {} as DashboardPopupPatientBloodpreasuresType
const dashboardPopupPatientHeartrates =
    {} as DashboardPopupPatientHeartratesType

const initialState = {
    dashboardPatientModalPopup: false,
    dashboardPopupPatient,
    dashboardPatientPopupFetchDate: {},
    isForceTasksRequest: false,
    getPatientTableDataStatus: Status.LOADING,
    dashboardPopupPatientSaturations,
    dashboardPopupPatientTemperatures,
    dashboardPopupPatientBloodpreasures,
    dashboardPopupPatientHeartrates,
    calendarModalCounters: {},
    updateLenghtStatus: false,
    dischargeDispositions: [''],
    selectedDischargeDispositions: '',
    patientMedicalHistory: '',

    // getTasksForHuddlePageStatus: Status.LOADING,

    // nursesStatus: Status.LOADING,
    // setShiftsDataStatus: Status.LOADING
}

const patientModalSlice = createSlice({
    name: 'patientModal',
    initialState,
    reducers: {
        setDashboardPatientModalPopup(state, action) {
            state.dashboardPatientModalPopup = action.payload
        },
        setDashboardPopupPatient(state, action) {
            state.dashboardPopupPatient = action.payload
        },
        setDashboardPatientPopupFetchDate(state, action) {
            state.dashboardPatientPopupFetchDate = action.payload.fetchDate
        },
        addCalendarModalCounters(state, action) {
            state.calendarModalCounters = action.payload
        },
        setSelectedDischargeDispositions(state, action) {
            state.selectedDischargeDispositions = action.payload
        },
        getMedicalHistory(state, action) {
            state.patientMedicalHistory = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPatientTableData.pending, (state, action) => {
            state.getPatientTableDataStatus = Status.LOADING
            state.dashboardPopupPatient = initialState.dashboardPopupPatient
        })
        builder.addCase(getPatientTableData.fulfilled, (state, action) => {
            state.getPatientTableDataStatus = Status.SUCCESS
            state.dashboardPopupPatient = action.payload
            state.dashboardPatientPopupFetchDate = action.payload.fetchDate
        })
        builder.addCase(getPatientTableData.rejected, (state, action) => {
            state.getPatientTableDataStatus = Status.ERROR
            state.dashboardPopupPatient = initialState.dashboardPopupPatient
        })

        builder.addCase(
            getPatientTablePopupSaturations.pending,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.LOADING;
            }
        )
        builder.addCase(
            getPatientTablePopupSaturations.fulfilled,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.SUCCESS;
                state.dashboardPopupPatientSaturations = action.payload
            }
        )
        builder.addCase(
            getPatientTablePopupSaturations.rejected,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.ERROR;
                // state.dashboardPopupPatientSaturations =
                //     initialState.dashboardPopupPatientSaturations
            }
        )

        ///
        builder.addCase(
            getPatientTablePopupTemperatures.pending,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.LOADING;
                // state.dashboardPopupPatientTemperatures =
                //     initialState.dashboardPopupPatientTemperatures
            }
        )
        builder.addCase(
            getPatientTablePopupTemperatures.fulfilled,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.SUCCESS;
                state.dashboardPopupPatientTemperatures = action.payload
            }
        )
        builder.addCase(
            getPatientTablePopupTemperatures.rejected,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.ERROR;
                // state.dashboardPopupPatientTemperatures =
                //     initialState.dashboardPopupPatientTemperatures
            }
        )

        builder.addCase(
            getPatientTablePopupBloodpreasures.pending,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.LOADING;
                // state.dashboardPopupPatientBloodpreasures =
                //     initialState.dashboardPopupPatientBloodpreasures
            }
        )
        builder.addCase(
            getPatientTablePopupBloodpreasures.fulfilled,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.SUCCESS;
                state.dashboardPopupPatientBloodpreasures = action.payload
            }
        )
        builder.addCase(
            getPatientTablePopupBloodpreasures.rejected,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.ERROR;
                // state.dashboardPopupPatientBloodpreasures =
                //     initialState.dashboardPopupPatientBloodpreasures
            }
        )

        builder.addCase(
            getPatientTablePopupHeartrates.pending,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.LOADING;
                // state.dashboardPopupPatientHeartrates =
                //     initialState.dashboardPopupPatientHeartrates
            }
        )
        builder.addCase(
            getPatientTablePopupHeartrates.fulfilled,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.SUCCESS;
                state.dashboardPopupPatientHeartrates = action.payload
            }
        )
        builder.addCase(
            getPatientTablePopupHeartrates.rejected,
            (state, action) => {
                // state.getPatientTableDataStatus = Status.ERROR;
                // state.dashboardPopupPatientHeartrates =
                //     initialState.dashboardPopupPatientHeartrates
            }
        )

        builder.addCase(updateLenghtOfStay.pending, (state, action) => {
            // state.getPatientTableDataStatus = Status.LOADING;
            state.updateLenghtStatus = initialState.updateLenghtStatus
        })
        builder.addCase(updateLenghtOfStay.fulfilled, (state, action) => {
            state.updateLenghtStatus = true
        })
        builder.addCase(updateLenghtOfStay.rejected, (state, action) => {
            // state.getPatientTableDataStatus = Status.ERROR;
            state.updateLenghtStatus = initialState.updateLenghtStatus
        })

        builder.addCase(getDischargeDispositions.pending, (state, action) => {
            // state.getPatientTableDataStatus = Status.LOADING;
            state.dischargeDispositions = initialState.dischargeDispositions
        })
        builder.addCase(getDischargeDispositions.fulfilled, (state, action) => {
            state.dischargeDispositions = action.payload
        })
        builder.addCase(getDischargeDispositions.rejected, (state, action) => {
            // state.getPatientTableDataStatus = Status.ERROR;
            state.dischargeDispositions = initialState.dischargeDispositions
        })
    },
})

export const {
    setDashboardPatientModalPopup,
    setDashboardPopupPatient,
    setDashboardPatientPopupFetchDate,
    addCalendarModalCounters,
    setSelectedDischargeDispositions,
    getMedicalHistory,
} = patientModalSlice.actions

export default patientModalSlice.reducer
