import React from 'react'
import { CriticalCareTotalCurrentOutflow } from '../../../../redux/rtk/criticalCare/types'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CcTable from '../../CcTable/CcTable'
import Row from '../../CcTable/Row/Row'
import TableHead from '../../CcTable/TableHead/TableHead'
import { ccOutflowOptions } from '../tableOptions'

import styles from './Item.module.scss'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import CustomAccordion from 'components/common/CustomAccordion/CustomAccordion'
import { icons } from 'utils/iconsMap'

type ItemProps = {
    data: CriticalCareTotalCurrentOutflow[]
    title: string
    fetchStatus: JSX.Element | null
}

const Item: React.FC<ItemProps> = ({ data, title, fetchStatus }) => {
    return (
        <>
            <CustomAccordion
                chevronPosition="left"
                chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
                defaultValue="resus"
            >
                <CustomAccordion.Item key="resus" value="resus">
                    <CustomAccordion.Control>
                        <CcTitle>
                            {title}
                            <div style={{ paddingLeft: '1rem' }}>
                                ({data?.length || 0})
                            </div>
                        </CcTitle>
                    </CustomAccordion.Control>
                    <CustomAccordion.Panel>
                        <BgWrapper>
                            {data?.length > 0 ? (
                                <CcTable>
                                    <TableHead thOptions={ccOutflowOptions} />
                                    <tbody>
                                        {data?.map((item, index) => (
                                            <Row
                                                item={item}
                                                index={index}
                                                key={index}
                                                options={ccOutflowOptions}
                                            />
                                        ))}
                                    </tbody>
                                </CcTable>
                            ) : (
                                <CenteredWrapper>
                                    <NoMoreEntries text={'No patients'} />
                                </CenteredWrapper>
                            )}
                        </BgWrapper>
                    </CustomAccordion.Panel>
                </CustomAccordion.Item>
            </CustomAccordion>
        </>
    )
}

export default Item
