import { useState, useRef, useEffect } from 'react'

function useHoverWithDelay(delayMs = 500, leaveDelayMs?: number) {
    const [isHovered, setIsHovered] = useState(false)
    const hoverTimeoutRef = useRef<number | null>(null)
    const leaveTimeoutRef = useRef<number | null>(null)

    const handleMouseEnter = () => {
        if (hoverTimeoutRef.current !== null) return
        hoverTimeoutRef.current = window.setTimeout(() => {
            setIsHovered(true)
        }, delayMs)
    }

    const handleMouseLeave = (immediate = false) => {
        if (hoverTimeoutRef.current !== null) {
            clearTimeout(hoverTimeoutRef.current)
            hoverTimeoutRef.current = null
        }

        // Clear the previous leave timeout if it exists
        if (leaveTimeoutRef.current !== null) {
            clearTimeout(leaveTimeoutRef.current)
        }

        if (immediate) {
            setIsHovered(false)
        } else {
            // Set a new leave timeout with a default value of 1000ms if leaveDelayMs is not provided
            leaveTimeoutRef.current = window.setTimeout(() => {
                setIsHovered(false)
            }, leaveDelayMs || 1000)
        }
    }

    useEffect(() => {
        return () => {
            if (hoverTimeoutRef.current !== null) {
                clearTimeout(hoverTimeoutRef.current)
            }
            if (leaveTimeoutRef.current !== null) {
                clearTimeout(leaveTimeoutRef.current)
            }
        }
    }, [])

    return { isHovered, handleMouseEnter, handleMouseLeave }
}

export default useHoverWithDelay
