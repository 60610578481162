import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import CcTitle from 'components/common/CcTitle/CcTitle'
import OrSectionHeaders from '../OrSectionHeaders/OrSectionHeaders'
import CustomScrollArea from 'components/common/CustomScrollArea/CustomScrollArea'
import { useGetCurrentFlowInOrQuery } from 'redux/rtk/orStatus/OrStatusApi'
import CurrentList from './CurrentList/CurrentList'
import GridHeader from 'components/common/GridHeader/GridHeader'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'
import { CurrentFlowList, FlowData } from 'redux/rtk/orStatus/types'

import styles from './OrStatusCurrent.module.scss'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const isCurrentFlowList = (
    data: FlowData['currentFlowList']
): data is CurrentFlowList => {
    return data && typeof data === 'object'
}

function isCurrentFlowListEmpty(response?: FlowData): boolean {
    return (
        !response?.currentFlowList ||
        Object.keys(response.currentFlowList).length === 0
    )
}

const gridHeaderStyles = {
    gridTemplateColumns: '1fr 1.5fr minmax(75px, 1fr) 0.5fr 1fr 1fr',
    marginRight: '5.2rem',
    gap: '0.5rem',
}

const OrStatusCurrent = () => {
    const { t } = useTranslation()
    const { data, error, isFetching, isSuccess } = useGetCurrentFlowInOrQuery(
        undefined,
        { pollingInterval: 300000 }
    )

    const isDataEmpty = useMemo(() => isCurrentFlowListEmpty(data), [data])

    const renderCurrentList = (
        title: string,
        dataList: CurrentFlowList[keyof CurrentFlowList]
    ) => <CurrentList title={t(title)} data={dataList ?? []} />

    return (
        <div className={styles.container}>
            <CcTitle style={{ paddingLeft: '3rem' }}>
                {t('Currently in OR')}
                <CorporateDocLink
                    baseKey="operatingRoom"
                    sectionKey="OrCurrent"
                />
            </CcTitle>
            <div className={styles.container__body}>
                <GridHeader style={gridHeaderStyles}>
                    <div style={{ textAlign: 'left' }}>{t('MRN')}</div>
                    <div style={{ textAlign: 'left' }}>{t('Surgery')}</div>
                    <div style={{ textAlign: 'left' }}>{t('Start')}</div>
                    <div style={{ textAlign: 'left' }}>{t('End')}</div>
                    <div style={{ textAlign: 'center' }}>{t('Theatre')}</div>
                    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        {t('Transfer to')}
                    </div>
                </GridHeader>

                <CustomScrollArea.Autosize mah={'42vh'} mx="auto">
                    {data &&
                    isCurrentFlowList(data.currentFlowList) &&
                    !isDataEmpty ? (
                        <>
                            {renderCurrentList('SDS', data.currentFlowList.SDS)}
                            {renderCurrentList(
                                'IP-ED',
                                data.currentFlowList.IpEd
                            )}
                            {renderCurrentList(
                                'IP-UNITS',
                                data.currentFlowList.IPUnits
                            )}
                            {renderCurrentList('ODS', data.currentFlowList.ODS)}
                        </>
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={t('No patients')} />
                        </CenteredWrapper>
                    )}
                </CustomScrollArea.Autosize>
            </div>
        </div>
    )
}

export default OrStatusCurrent
