import { generateDate } from '../date/generateDate'

export const saturation_MOCK = {
    saturations: [
        { oxygen: 97, time: generateDate(8) },
        { oxygen: 96, time: generateDate(7) },
        { oxygen: 98, time: generateDate(6) },
        { oxygen: 97, time: generateDate(5) },
        { oxygen: 95, time: generateDate(4) },
        { oxygen: 97, time: generateDate(3) },
        { oxygen: 98, time: generateDate(2) },
        { oxygen: 97, time: generateDate(1) },
        { oxygen: 95, time: generateDate(0) },
    ]
}
export const temperature_MOCK = {
    temperatures: [
        { celsius: 36.5, time: generateDate(8) },
        { celsius: 36.4, time: generateDate(7) },
        { celsius: 36.6, time: generateDate(6) },
        { celsius: 37.5, time: generateDate(5) },
        { celsius: 37.3, time: generateDate(4) },
        { celsius: 36.4, time: generateDate(3) },
        { celsius: 36.6, time: generateDate(2) },
        { celsius: 37.5, time: generateDate(1) },
        { celsius: 37.3, time: generateDate(0) },
    ]
}
export const bloodPreasure_MOCK = {
    bloodPressure: {
        sbPs: [
            { sbp: 80, time: generateDate(8) },
            { sbp: 85, time: generateDate(7) },
            { sbp: 80, time: generateDate(6) },
            { sbp: 81, time: generateDate(5) },
            { sbp: 86, time: generateDate(4) },
            { sbp: 90, time: generateDate(3) },
            { sbp: 81, time: generateDate(2) },
            { sbp: 86, time: generateDate(1) },
            { sbp: 90, time: generateDate(0) },
        ],
        dbPs: [
            { dbp: 120, time: generateDate(8) },
            { dbp: 125, time: generateDate(7) },
            { dbp: 123, time: generateDate(6) },
            { dbp: 130, time: generateDate(5) },
            { dbp: 132, time: generateDate(4) },
            { dbp: 136, time: generateDate(3) },
            { dbp: 130, time: generateDate(2) },
            { dbp: 132, time: generateDate(1) },
            { dbp: 136, time: generateDate(0) },
        ],
    }
}
export const heartRate_MOCK = {
    heartRates: [
        { bpm: 70, time: generateDate(8) },
        { bpm: 65, time: generateDate(7) },
        { bpm: 71, time: generateDate(6) },
        { bpm: 68, time: generateDate(5) },
        { bpm: 73, time: generateDate(4) },
        { bpm: 65, time: generateDate(3) },
        { bpm: 71, time: generateDate(2) },
        { bpm: 68, time: generateDate(1) },
        { bpm: 73, time: generateDate(0) },
    ]
}

export const currentVitals_MOCK = {
    visitId: 'string',
    saturation: {
        oxygen: 98,
        time: generateDate(0),
    },
    temperature: {
        celsius: 37.7,
        time: generateDate(0),
    },
    bloodPressure: {
        sbp: 120,
        dbp: 80,
        time: generateDate(0),
    },
    heartRate: {
        bpm: 72,
        time: generateDate(0),
    },
    respiratoryRate: {
        bpm: 88,
        time: generateDate(0),
    },
    ews: 0,
    measurementDate: generateDate(0),
}
