type ButtonData = {
    label: string;
    name: string;
};

type ButtonVariant = 'filter-active' | 'filter' | 'filter-muted' | 'outline'

export type ButtonDataWithChecked = ButtonData & {
    isChecked: boolean;
};

export type ButtonDataWithVariant = ButtonData & {
    variant: ButtonVariant;
};


export const consultationFilter: ButtonDataWithVariant[] = [
    {
        label: 'All', name: 'all', variant: 'filter-active'
    },
    { label: '0-2', name: '0-2', variant: 'filter-muted' },
    { label: '2-4', name: '2-4', variant: 'filter-muted' },
    { label: '4+', name: '4+', variant: 'filter-muted' },
    { label: 'Delays in Care', name: 'Delays in Care', variant: 'filter-muted' },
    { label: 'Reset all filters', name: 'reset all filters', variant: 'outline' }
]

export const buttonsFilter: ButtonDataWithVariant[] = [
    {
        label: 'All', name: 'all', variant: 'filter-active'
    },
    { label: 'Admission Requests', name: 'adm', variant: 'filter' },
    { label: 'Predicted Admissions', name: 'predict_adm', variant: 'filter' },
    { label: 'Predicted Discharge', name: 'predict_home', variant: 'filter' },
    { label: 'Under Evaluation', name: 'under_evaluation', variant: 'filter' },
    { label: 'Psychiatry', name: 'Psychiatry', variant: 'filter' },
    { label: 'Reset all filters', name: 'reset all filters', variant: 'outline' }
]

export const originFilters: ButtonDataWithChecked[] = [
    { label: 'Select All', name: 'selectAll', isChecked: true },
    { label: 'Home', name: 'Home', isChecked: true },
    { label: 'Clinic', name: 'Clinic', isChecked: true },
    { label: 'Nursing home', name: 'Nursing home', isChecked: true },
    { label: 'Work', name: 'Work', isChecked: true },
    { label: 'CHSLD', name: 'CHSLD', isChecked: true },
    { label: 'Hospital', name: 'Hospital', isChecked: true },
    { label: 'Reabilitation center', name: 'Reabilitation center', isChecked: true },
    { label: 'CLSC', name: 'CLSC', isChecked: true },
    { label: 'Homeless', name: 'Homeless', isChecked: true },
    { label: 'JAIL', name: 'JAIL', isChecked: true },
    { label: 'Other', name: 'Other', isChecked: true },
]