import React from 'react'
import PopoverItem from '../../PopoverItem/PopoverItem'

import styles from './ListItem.module.scss'
import { InflowOrItem } from 'redux/rtk/orStatus/types'
import ScheduleItem from './ScheduleItem/ScheduleItem'

const ListItem: React.FC<InflowOrItem> = ({
    mrn,
    patientName,
    patientLastName,
    surgerySchedule,
    expectedSurgeryEndDate,
    timeDelayLevel,
    surgeryRoom,
    location,
    admissionType,
}) => {
    return (
        <div className={styles.item}>
            <PopoverItem
                target={mrn}
                items={[
                    {
                        title: 'patient name',
                        data: `${patientName ?? '--'} ${
                            patientLastName ?? '--'
                        }`,
                    },
                ]}
            />
            <div>{location || '--'}</div>
            {surgerySchedule || expectedSurgeryEndDate ? (
                <div className={styles.item__schedule}>
                    <ScheduleItem
                        surgerySchedule={surgerySchedule}
                        expectedSurgeryEndDate={expectedSurgeryEndDate}
                        timeDelayLevel={timeDelayLevel}
                    />
                </div>
            ) : (
                <div>--</div>
            )}

            <div style={{ textAlign: 'center' }}>{surgeryRoom || '--'}</div>
        </div>
    )
}

export default ListItem
