import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CcTitle from '../../../common/CcTitle/CcTitle'
import {
    useGetLosHistoricalQuery,
    useGetOutflowHistoricalQuery,
} from 'redux/rtk/orStatus/OrStatusApi'
import { extractUniqueDestinations } from '../TrendsChartItem/helpers'
import TrendsChartItem from '../TrendsChartItem/TrendsChartItem'

import styles from './OutflowTrends.module.scss'

const OutflowTrends = () => {
    const { t } = useTranslation()
    const {
        data: outflowHistoricalData,
        // error,
        // isFetching,
        // isSuccess,
    } = useGetOutflowHistoricalQuery()

    const uniqueOutflowsLegends = useMemo(
        () =>
            outflowHistoricalData &&
            extractUniqueDestinations(
                outflowHistoricalData?.outflows,
                'destinationAfterSurgery'
            ),
        [outflowHistoricalData]
    )

    const {
        data: losHistoricalData,
        // error,
        // isFetching,
        // isSuccess,
    } = useGetLosHistoricalQuery()

    const uniqueLosLegends = useMemo(
        () =>
            losHistoricalData &&
            extractUniqueDestinations(
                losHistoricalData?.losData,
                'admissionType'
            ),
        [losHistoricalData]
    )

    return (
        <div className={styles.container}>
            <CcTitle style={{ paddingLeft: '1.5rem', paddingBottom: '2rem' }}>
                {t('Outflow from OR')}
            </CcTitle>
            <div className={styles.container__body}>
                {outflowHistoricalData &&
                    outflowHistoricalData?.outflows?.length > 0 && (
                        <TrendsChartItem
                            title={t('Number of patients discharged from or')}
                            data={outflowHistoricalData?.outflows}
                            legends={uniqueOutflowsLegends}
                            xAxisKey={'surgeryScheduleDate'}
                            yAxisKey={'numberOfDischargedPatients'}
                            type={'destinationAfterSurgery'}
                            documentationKey="OrTrendOutflow"
                        />
                    )}
                {losHistoricalData &&
                    losHistoricalData?.losData?.length > 0 && (
                        <TrendsChartItem
                            title={t('Average los of patients in pacu (hours)')}
                            data={losHistoricalData?.losData}
                            legends={uniqueLosLegends}
                            xAxisKey={'surgeryScheduleDate'}
                            yAxisKey={'averageWaitingTimeHours'}
                            lableText={'hour(s)'}
                            documentationKey="OrTrendLosPacu"
                        />
                    )}
            </div>
        </div>
    )
}

export default OutflowTrends
