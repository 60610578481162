import * as React from 'react'

import styles from './NoMoreEntries.module.scss'
import { useTranslation } from 'react-i18next'

type TextProps = {
    text?: string
}

const NoMoreEntries = ({ text }: TextProps): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className={styles.message}>
            <p>{text ? t(text) : 'No additional entries to load'} </p>
        </div>
    )
}

export default NoMoreEntries
