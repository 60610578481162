import React from 'react'
import ManagementHeader from '../../components/ManagementEntries/ManagementHeader/ManagementHeader'
import OrStatusBox from '../../components/OrStatusBox/OrStatusBox'
import OrStatusInfoBlok from '../../components/OrStatusInfoBlok/OrStatusInfoBlok'
import CalendarTimeline from '../../components/TimeLine/TimeLine'
import TimelineDashboard from '../../components/TimelineDashboard/TimelineDashboard'

import styles from './OperatingRoomsStatus.module.scss'
enum Statuses {
    'free' = 'free',
    'setup' = 'setup',
    'procedure' = 'procedure',
    'clearance' = 'clearance',
    'predirect delay' = 'predirect_delay',
    'reserved for emergency' = 'reserved_for_emergency',
    'emergency procedure' = 'emergency_procedure',
    'slack' = 'slack',
    'risk for delay' = 'risk_for_delay',
    'actual delay 1' = 'actual_delay_1',
    'actual delay 2' = 'actual_delay_2',
}
const OperatingRoomsStatus = () => {
    return (
        <div className={styles.rooms_status}>
            <ManagementHeader />
            {/* <div>OperatingRoomsStatus</div> */}
            <div className={styles.management__content_body}>
                {/* <ManagementDashboard /> */}
                {/* <ManagementSidebar /> */}
            </div>
            {/* <TimelineDashboard /> */}
            <CalendarTimeline />
            <OrStatusInfoBlok />
            {/* <OrStatusBox status={'actual delay 2'} boxWidth={'40'} /> */}
        </div>
    )
}

export default OperatingRoomsStatus
