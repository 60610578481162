import React from 'react'
import { ConsultationType } from '../../../../redux/rtk/edLive/types'
import { icons } from '../../../../utils/iconsMap'
import styles from './ConsultationsColumn.module.scss'

type ConsultationsColumnProps = {
    consultations: ConsultationType[]
}

const ConsultationsColumn: React.FC<ConsultationsColumnProps> = ({
    consultations,
}) => {
    return (
        <div className={styles.consultation}>
            {consultations?.length > 0 &&
                consultations?.map((consultation, index) => (
                    <div key={index} className={styles.consultation__item}>
                        {consultation.consultationTitle && (
                            <>
                                {' '}
                                <img
                                    src={icons.flag_dark}
                                    className={`${styles.consultation__item__time__icon}`}
                                    alt={'hazards'}
                                    style={{ marginRight: '10px' }}
                                />
                                <span
                                    className={styles.consultation__item__title}
                                >
                                    {consultation.consultationTitle
                                        .split('(')[0]
                                        .trim()}
                                </span>
                            </>
                        )}
                        {consultation.consultationTime && (
                            <div className={styles.consultation__item__time}>
                                <span>
                                    {' '}
                                    {
                                        consultation.consultationTime.split(
                                            ':'
                                        )[0]
                                    }
                                    h
                                </span>
                            </div>
                        )}
                    </div>
                ))}
        </div>
    )
}

export default ConsultationsColumn
