import React from 'react'
import CriticalCareInflow from '../../components/CriticalCare/CriticalCareInflow/CriticalCareInflow'
import CriticalCareOccupancy from '../../components/CriticalCare/CriticalCareOccupancy/CriticalCareOccupancy'
import CriticalCareOutflows from '../../components/CriticalCare/CriticalCareOutflows/CriticalCareOutflows'
import Header from '../../components/Header/Header'

import styles from './CriticalCare.module.scss'

const CriticalCare: React.FC = () => {
    return (
        <div className={styles.content}>
            <Header />
            <div className={styles.content__body}>
                <div className={styles.content__body__inflow}>
                    <CriticalCareInflow />
                </div>
                <div className={styles.content__body__beds}>
                    <CriticalCareOccupancy />
                </div>
                <div className={styles.content__body__outflow}>
                    <CriticalCareOutflows />
                </div>
            </div>
        </div>
    )
}

export default CriticalCare
