import React from 'react'
import { IconTypeProps } from '../IconType'

const FamilyMedicineIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="5.99957" cy="6.05816" r="2.22222" fill={fillColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12.1719C2 10.515 3.34315 9.17188 5 9.17188H15C16.6569 9.17188 18 10.515 18 12.1719V16.1719C18 16.7242 17.5523 17.1719 17 17.1719H13.5556C13.5556 15.6991 12.3616 14.5052 10.8889 14.5052H9.11111C7.63836 14.5052 6.44445 15.6991 6.44444 17.1719H3C2.44772 17.1719 2 16.7242 2 16.1719V12.1719ZM11.7778 11.8386C11.7778 12.8204 10.9818 13.6163 10 13.6163C9.01816 13.6163 8.22222 12.8204 8.22222 11.8386C8.22222 10.8567 9.01816 10.0608 10 10.0608C10.9818 10.0608 11.7778 10.8567 11.7778 11.8386Z"
                fill={fillColor}
            />
            <circle cx="13.9996" cy="6.05816" r="2.22222" fill={fillColor} />
        </svg>
    )
}

export default FamilyMedicineIcon
