import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './TrendsSection.module.scss'
import BgWrapper from '../../common/BgWrapper/BgWrapper'
import OutflowTrends from './OutflowTrends/OutflowTrends'
import InflowTrends from './InflowTrends/InflowTrends'
const colorMap = {
    SDS: '#56D5E5',
    ODS: '#FFB979',
    'IP-ED': '#7ABD83',
    'IP-units': '#FF7783',
    MSICU: '#7795FF',
    PACU: '#9E5F5F',
    Periop: '#FCFF79',
    Other: '#BD7A9A',
}

const TrendsSection: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <h2 className={styles.container__title}>
                {t('Operating Room: 7-Day Trends')}
            </h2>
            <div className={styles.container__content}>
                <BgWrapper style={{ padding: '0' }}>
                    <div className={styles.container__content__graphs}>
                        <div
                            className={styles.container__content__graphs__left}
                        >
                            <InflowTrends />
                        </div>
                        <div
                            className={styles.container__content__graphs__right}
                        >
                            <OutflowTrends />
                        </div>
                    </div>
                </BgWrapper>
            </div>
        </div>
    )
}

export default TrendsSection
