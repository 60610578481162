import React from 'react'
import IconCell from '../Cells/IconCell/IconCell'
import ProgressCell from '../Cells/ProgressCell/ProgressCell'
import { OptionConfig } from 'components/CriticalCare/CriticalCareOutflows/tableOptions'
import PopupCell from '../Cells/PopupCell/PopupCell'
import Icon from 'components/common/IconMaper/Icons'

import styles from './Row.module.scss'

type RowProps<T> = {
    item: T
    index: number
    options: OptionConfig[]
    style?: React.CSSProperties
}

const renderString = (
    value: string | undefined,
    option: OptionConfig,
    item?: any
) => {
    const { firstName, lastName } = item
    return (
        <td>
            {' '}
            {option.name === 'patientRecord' ? (
                <PopupCell
                    target={value}
                    data={`${firstName ?? '--'} ${lastName ?? '--'}`}
                />
            ) : (
                value || ''
            )}
        </td>
    )
}

const renderIconString = (value: string | undefined) => (
    <td>{value ? <IconCell text={value} /> : '--'}</td>
)

const renderProgress = (
    value: number | undefined,
    option: OptionConfig,
    item: any
) => {
    // if (!value) return <td />
    const displayValue = value ?? '--'
    return (
        <td>
            {typeof displayValue === 'number' ? (
                <ProgressCell
                    delay={`${value} HRS`}
                    time={displayValue}
                    total={96}
                    timeDelayLevel={item.timeDelayLevel}
                />
            ) : (
                '--'
            )}
        </td>
    )
}

const renderIconPopup = (value: string | undefined, item: any) => {
    const { transferType } = item
    const iconName = value === 'Signed Discharge' ? 'TransferType' : 'Ask'
    return (
        <>
            {value && (
                <td>
                    <PopupCell
                        target={
                            <Icon
                                name={value.toLowerCase()}
                                color={'default'}
                            />
                        }
                        data={`${transferType ?? '--'}`}
                    />
                </td>
            )}
        </>
    )
}

const renderCell = (value: any, option: OptionConfig, item: any) => {
    switch (option.type) {
        case 'string':
            return renderString(value as string, option, item)
        case 'icon':
            return renderIconPopup(value as string, item)
        case 'iconString':
            return renderIconString(value as string)
        case 'progress':
            return renderProgress(value as number, option, item)
        default:
            return null
    }
}

const Row = <T,>({ item, index, options, style }: RowProps<T>) => {
    return (
        <tr key={index} className={styles.row} style={{ ...style }}>
            {options
                .sort((a, b) => a.order - b.order)
                .map((option) => {
                    const value = item[option.name as keyof T]
                    return (
                        <React.Fragment key={option.name}>
                            {renderCell(value, option, item)}
                        </React.Fragment>
                    )
                })}
        </tr>
    )
}

export default React.memo(Row)
