import React from 'react'
import { IconTypeProps } from '../IconType'

const OncologyIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M5.27266 8.48416C3.78613 6.58149 4.12348 3.83399 6.02616 2.34745L16.9999 16.3933L13.5548 19.0849L5.27266 8.48416Z"
                fill={fillColor}
            />
            <path
                d="M14.3084 8.48416C15.7949 6.58149 15.4576 3.83399 13.5549 2.34745L2.58111 16.3933L6.02622 19.0849L14.3084 8.48416Z"
                fill={fillColor}
            />
            <path
                d="M7 1.11111L7.91611 0.77181C9.26074 0.2738 10.7393 0.2738 12.0839 0.77181L13 1.11111L10.3959 4.48681C10.1957 4.74627 9.80427 4.74627 9.60411 4.48681L7 1.11111Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default OncologyIcon
