import React, { useEffect, useMemo, useState } from 'react'
import CustomCheckbox from '../../../common/CustomCheckbox/CustomCheckbox'
import { hasNotCheckedItems } from '../../../PatientsTable/TableHeaderCell/ItemWithMenu/ItemScrollBody/helpers'
import {
    ButtonDataWithChecked,
    originFilters,
} from '../../AdmissionsFilterOptions'

import styles from './OriginFiltersList.module.scss'
import { useTranslation } from 'react-i18next'

type OriginFiltersListProps = {
    checkboxes: ButtonDataWithChecked[]
    handleCheckboxChange: (name: string, isChecked: boolean) => void
}

const OriginFiltersList: React.FC<OriginFiltersListProps> = ({
    checkboxes,
    handleCheckboxChange,
}) => {
    const { t } = useTranslation()
    return (
        <div className={styles.list}>
            {checkboxes?.map((item, index) => {
                const isSelectAll = item.name === 'selectAll'
                return (
                    <div
                        className={styles.checkbox_item}
                        key={`OriginFilters_${index}`}
                    >
                        <CustomCheckbox
                            id={item.name}
                            label={t(item.label)}
                            isChecked={item.isChecked}
                            onChange={() =>
                                handleCheckboxChange(item.name, item.isChecked)
                            }
                            variant={!isSelectAll ? 'gradient' : ''}
                            colour={isSelectAll ? '#D8E0EA' : ''}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default OriginFiltersList
