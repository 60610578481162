import React from 'react'
import { IconTypeProps } from '../IconType'

const PaliativeCareIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="3.99957" cy="3.05816" r="2.22222" fill={fillColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 9.17188C0 7.51502 1.34315 6.17188 3 6.17188H13C14.6569 6.17188 16 7.51502 16 9.17188V13.1719C16 13.7242 15.5523 14.1719 15 14.1719H11.5556C11.5556 12.6991 10.3616 11.5052 8.88889 11.5052H7.11111C5.63836 11.5052 4.44445 12.6991 4.44444 14.1719H1C0.447715 14.1719 0 13.7242 0 13.1719V9.17188ZM9.77778 8.83855C9.77778 9.82039 8.98184 10.6163 8 10.6163C7.01816 10.6163 6.22222 9.82039 6.22222 8.83855C6.22222 7.85671 7.01816 7.06077 8 7.06077C8.98184 7.06077 9.77778 7.85671 9.77778 8.83855Z"
                fill={fillColor}
            />
            <circle cx="11.9996" cy="3.05816" r="2.22222" fill={fillColor} />
        </svg>
    )
}

export default PaliativeCareIcon
