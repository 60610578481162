export type PredefinedFilterCountsType = {
    pendingLabs: string | number
    pendingConsultations: string | number
    alerts: string | number
    tasks: string | number
    hazards: string | number
    disch_today: string | number
    disch_within: string | number
    disch_over: string | number
}

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
}

export type DashboardPopupPatientType = {
    id: number
    name: string
    age: number | null
    gender: number
    location: {
        department: string
        room: string
        bed: string | number
        facility: string
        floor: string
    }
    arrivedFrom: string
    doctor: string | null
    ContactPerson: string
    // Outdated
    contact: {
        id: string
        name: string
        relationship: string
        phoneNumber: string
    }
    medicalHistory: string
    painLevel: number
    ews: number
    adl: number
    postOperative: number
    covidStatus: number | null
    responsible: {
        responsiblePersonId: string
        name: string
        phoneNumber: string
    } | null
    careGiver: { id: string; name: string; phoneNumber: string }
    currentSaturation: { oxygen: number; time: Date | string }
    currentTemperature: { celsius: number; time: Date | string }
    currentBloodPresure: { sbp: number; dbp: number; time: Date | string }
    currentHeartRate: { bpm: number; time: Date | string }
    fetchDate: Date | string
    revisitPredictionAfter: number
    lenghtOfStay?: number
    lenghtOfStayUpdatedTimeInUtc: Date | string
    millisecondsSpendOnExecutions: {
        additionalProp1: number
        additionalProp2: number
        additionalProp3: number
    }
    address: any
    temperature?: any
    saturation?: any
    bloodPreasure?: any
    heartRate?: any
    patientReturnsFromLeaveOfAbsence: any
    patientGoesToLeaveOfAbsense: any
    oldMedicalHistory: any
    arrivalTime: string
    admission: number
    physicianInCharge?: string
    patientIsOnLeaveOfAbsence: boolean
    pendingConsultations?: number | string
    discharged?: boolean
    hazards?: any
    expectedLengthOfStay: number
    expectedDischargeValue: string
    dischargePredictionMinValue: number
    dischargePredictionMaxValue: number
    dischargeStatusId: number,
    dischargeBlockerIds: [],
    neceDesc?: string
}

export interface UpdatesForPatientType {
    events: {
        patientId: string
        type: number
        eventDataJson: string | any
        occuredOn: Date | string
    }[]
}

export type PredefinedFilter = {
    fetchDate: Date | string,
    hasPendingLabs: number,
    hasPendingConsultations: number,
    hasAlerts: number,
    hasOpenTasks: number,
    hasHazards: number,
    dischargeGroup1: number,
    dischargeGroup2: number,
    dischargeGroup3: number,
}

type Location = {
    department: string
    room: string
    bed: string
    floor: string
    facility: string
}

type Diagnosis = { name: string; isMain: boolean }

type LabsConsultations = {
    id: string
    name: string
    icon: string
    status: number
}

type AlertsType = {
    count: number | string,
    list: string[]
}

type dischargeBlockersType = {
    dischargeStatusDate: Date | string,
    dischargeStatusName: string
}

type DischargeType = {
    dischargePrediction: string | number
    expectedLengthOfStay: string | number
}

export type PatientsDataType = {
    id: number
    internalId?: number | undefined
    visitId: string
    location: Location
    name: string
    gender: number
    diagnoses: Diagnosis[]
    severityIndex: number
    alerts: number | AlertsType
    tasksCount?: number
    taskCount: number
    physicianInCharge: string
    dischargePrediction?: string
    patientIsOnLeaveOfAbsence: boolean
    hazards?: { name: string }[] | null
    patientId: string
    age?: number
    labs_consultations?: LabsConsultations[]
    ews?: number
    arrivalTime?: Date | string
    admission?: Date | string
    fetchDate?: Date | string
    createDate?: Date | string
    pendingLabs: number | string
    birthDate: Date | string
    address?: string
    primaryDiagnosis?: string
    pendingConsultations?: number | string
    discharge: DischargeType | null
    discharged?: boolean
    dischargeStatus?: string,
    dischargeStatusDate?: string | null,
    dischargeBlockers: dischargeBlockersType[] | [] | null
    neceDesc?: string
    patientHash: string
}

export type VisitsResponce = {
    visits: PatientsDataType[]
} & Pick<PredefinedFilter, keyof PredefinedFilter>;


export type DischargeStatus = {
    id: number;
    name: string;
    statuses: DischargeStatus[] | [];
};

export type DischargeData = {
    dischargeStatuses: DischargeStatus[];
};

export type LocationChecbox = {
    id: string | number;
    name: string;
    isChecked: boolean;
}
