import React from 'react'
import { useTranslation } from 'react-i18next'
import BgWrapper from '../../common/BgWrapper/BgWrapper'
import EfficiencyItem from './EfficiencyItem/EfficiencyItem'
import {
    useGetOrEfficiencyQuery,
    useGetWaitListNumbersQuery,
} from 'redux/rtk/orStatus/OrStatusApi'

import styles from './OrEfficiency.module.scss'

const OrEfficiency: React.FC = () => {
    const { t } = useTranslation()

    const efficiencyItemsConfig = [
        {
            title: t('Number of rooms running'),
            queryHook: useGetOrEfficiencyQuery('NumberRoomRunning'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: 'Number of rooms',
            documentationKey: 'OrRoomRunning',
        },
        {
            title: t('Cases canceled (and reasons)'),
            queryHook: useGetOrEfficiencyQuery('CaseCancelled'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: '',
            documentationKey: 'CasesCancelled',
        },
        {
            title: t('Rooms running late'),
            queryHook: useGetOrEfficiencyQuery('RoomsRunningLate'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: 'Number of rooms',
            documentationKey: 'RoomRunningLate',
        },
        {
            title: t('Late start (and reasons)'),
            queryHook: useGetOrEfficiencyQuery('OnTimeStart'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: '',
            documentationKey: 'LateStart',
        },
        {
            title: t('Average room turnover time (min)'),
            queryHook: useGetOrEfficiencyQuery('AverageRoomTurnover'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: 'Avg (mins)',
            documentationKey: 'AverageRoomTurnover',
        },
        {
            title: t(`Turnover delays (and reasons)`),
            queryHook: useGetOrEfficiencyQuery('OnTimeFinish'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: '',
            documentationKey: 'TurnoverDelay',
        },
        {
            title: t('OR utilization rate (%)'),
            queryHook: useGetOrEfficiencyQuery('OrUtilizationRate'),
            xAxisKeyName: 'surgeryScheduleDate',
            yAxisKeyName: 'value',
            valueKey: 'summaries',
            tooltipLable: 'Utilization %',
            documentationKey: 'OrUtilizationRate',
        },
        {
            title: t('Open surgery requests in Opera'),
            queryHook: useGetWaitListNumbersQuery(),
            xAxisKeyName: 'date',
            yAxisKeyName: 'numberWaitingRequests',
            valueKey: 'waitListNumbers',
            tooltipLable: 'Waiting list',
            documentationKey: 'OpenSurgeryRequest',
        },
    ]

    return (
        <div className={styles.container}>
            <h2 className={styles.container__title}>{t('OR Efficiency')}</h2>
            <div className={styles.container__content}>
                <BgWrapper style={{ padding: '1.5rem 2rem', height: '100%' }}>
                    <div className={styles.container__content__charts}>
                        {efficiencyItemsConfig.map((item, index) => (
                            <EfficiencyItem
                                key={index}
                                {...item}
                                // title={item.title}
                                // queryHook={item.queryHook}
                                // xAxisKeyName={item.xAxisKeyName}
                                // yAxisKeyName={item.yAxisKeyName}
                                // valueKey={item.valueKey}
                                // tooltipLable={item.tooltipLable}
                                // documentationKey={item.documentationKey}
                            />
                        ))}
                    </div>
                </BgWrapper>
            </div>
        </div>
    )
}

export default OrEfficiency
