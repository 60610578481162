import { Loader } from '@mantine/core'
import CenteredWrapper from '../components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from '../components/common/Messages/NoMoreEntries'

interface FetchStatusProps<T> {
    data: T | undefined
    error: any
    isFetching: boolean
    isSuccess: boolean
    text?: string
}

const useFetchStatus = <T,>({
    data,
    error,
    isFetching,
    isSuccess,
    text,
}: FetchStatusProps<T>): JSX.Element | null => {
    if (isFetching)
        return (
            <CenteredWrapper>
                <Loader size="md" />
            </CenteredWrapper>
        )

    if (error)
        return (
            <CenteredWrapper>
                <div>Error loading data {text}</div>
            </CenteredWrapper>
        )

    if (isSuccess && (!data || (Array.isArray(data) && data.length === 0)))
        return (
            <CenteredWrapper>
                <NoMoreEntries />
            </CenteredWrapper>
        )

    return null
}

export default useFetchStatus
