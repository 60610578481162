import React from 'react'
import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { icons } from '../../utils/iconsMap'
import { CSVLink } from 'react-csv'

import styles from './AdmissionExport.module.scss'
import { useSelectedButtons } from '../../contexts/SelectedButtonsContext'

const AddmisionExport = () => {
    const { t } = useTranslation()
    const { filteredDataRow } = useSelectedButtons()

    const headers = [
        { label: 'ID', key: 'number' },
        { label: 'Name', key: 'name' },
        { label: 'Origin', key: 'origin' },
        { label: 'Age', key: 'age' },
        { label: 'Chief Complaint', key: 'chiefComplaint' },
        { label: 'Sex', key: 'sex' },
        { label: 'Gender', key: 'gender' },
        { label: 'Stretcher', key: 'stretcher' },
        { label: 'Triage', key: 'triage' },
        { label: 'Waiting', key: 'waiting' },
        { label: 'High Risk', key: 'highRisk' },
        { label: 'Past admits', key: 'pastAdmits' },
        { label: 'Admission', key: 'admissionColumn' },
        { label: 'Consultation', key: 'consultation' },
    ]

    const csvData = filteredDataRow.map((item) => ({
        ...item,
        admissionColumn: JSON.stringify(item.admissionColumn),
        consultation: JSON.stringify(item.consultation),
        pastAdmits: JSON.stringify(item.pastAdmits),
    }))

    const cvsLink = {
        filename: 'Emergency_department.csv',
        headers: headers,
        data: csvData,
        separator: ';',
    }

    return (
        <div>
            <CSVLink {...cvsLink}>
                <Button
                    leftSection={
                        <img src={icons.download} alt="Export to CSV" />
                    }
                    variant="transparent"
                    classNames={{
                        root: `${styles.button}`,
                        label: `${styles.button__label}`,
                    }}
                >
                    {t('Export to CSV file')}
                </Button>
            </CSVLink>
        </div>
    )
}

export default AddmisionExport
