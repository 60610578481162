import React from 'react'

import styles from './GridHeader.module.scss'

type GridHeaderProps = {
    style?: React.CSSProperties
    children: React.ReactNode[] // Use children for more flexibility
}

const GridHeader: React.FC<GridHeaderProps> = ({ style, children }) => {
    return (
        <div className={styles.headers} style={style}>
            {children.map((child, index) => (
                <div key={index} className={styles.headers__item}>
                    {child}
                </div>
            ))}
        </div>
    )
}

export default GridHeader
