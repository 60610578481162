import React from 'react'
import { IconTypeProps } from '../IconType'

const ImIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor

    return (
        <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.30958 5.75604C7.9303 5.75604 9.24415 4.46751 9.24415 2.87802C9.24415 1.28853 7.9303 0 6.30958 0C4.68885 0 3.375 1.28853 3.375 2.87802C3.375 4.46751 4.68885 5.75604 6.30958 5.75604Z"
                fill="url(#paint0_linear_9454_9715)"
            />
            <path
                d="M16.3995 0H13.1467C12.7733 0 12.4739 0.296907 12.4739 0.659794V4.37773C12.4739 5.14969 12.9045 5.86227 13.5941 6.23175L14.438 6.68107V9.4466C14.438 10.4591 13.5981 11.2825 12.5661 11.2825H11.9061C11.9037 11.2825 11.9014 11.2838 11.899 11.2838C11.8647 9.82697 11.2545 8.50606 10.274 7.54474C9.64832 6.93443 8.87465 6.46598 8.01015 6.19876C7.50895 6.45938 6.9371 6.60454 6.33162 6.60454C5.72614 6.60454 5.15429 6.45938 4.65309 6.19876C2.39598 6.89485 0.757812 8.9666 0.757812 11.4111V11.9423H11.9054H11.9061H12.5661C13.9695 11.9423 15.1108 10.8226 15.1108 9.4466V6.6781L15.9487 6.23208C16.6417 5.8626 17.0722 5.15002 17.0722 4.37806V0.659794C17.0722 0.296907 16.7695 0 16.3995 0ZM15.9184 3.42103H15.1078V4.21608C15.1078 4.40083 14.9598 4.54598 14.7714 4.54598C14.5864 4.54598 14.435 4.40083 14.435 4.21608V3.42103H13.6243C13.4393 3.42103 13.2879 3.27588 13.2879 3.09113C13.2879 2.90969 13.4393 2.76124 13.6243 2.76124H14.435V1.96619C14.435 1.78474 14.5864 1.63629 14.7714 1.63629C14.9598 1.63629 15.1078 1.78474 15.1078 1.96619V2.76124H15.9184C16.1068 2.76124 16.2548 2.90969 16.2548 3.09113C16.2548 3.27588 16.1068 3.42103 15.9184 3.42103Z"
                fill="url(#paint1_linear_9454_9715)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9454_9715"
                    x1="3.375"
                    y1="2.89966"
                    x2="8.98762"
                    y2="4.09958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={defaultColor1} />
                    <stop offset="1" stopColor={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_9454_9715"
                    x1="0.757813"
                    y1="6.01603"
                    x2="15.8352"
                    y2="10.3347"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={defaultColor1} />
                    <stop offset="1" stopColor={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default ImIcon
