import { icons } from './iconsMap'

type IndicatoType = boolean | number | string

type IndicatorData = {
    indicatorValue: IndicatoType
}

const indicatorIconMapper = ({ indicatorValue }: IndicatorData) => {
    const defaultIcon = icons.indicator_grey

    if (typeof indicatorValue === 'boolean') {
        return indicatorValue ? icons.indicator_grey : icons.indicator_green;
    }

    const parsedValue = typeof indicatorValue === 'string' ? parseInt(indicatorValue, 10) : indicatorValue;

    if (typeof parsedValue === 'number' && !isNaN(parsedValue)) {
        // Map number to icon, with a fallback to the default icon
        const numberToIconMap: { [key: number]: string } = {
            1: icons.indicator_blue,
            2: icons.indicator_red,
            3: icons.indicator_orange,
            4: icons.indicator_green,
            5: icons.indicator_grey,
        };

        return numberToIconMap[parsedValue] || defaultIcon;
    }
    // Return default icon if none of the above conditions are met
    return defaultIcon;
}

export default indicatorIconMapper
