export const erStatusMainTableFields = [
    {
        name: 'names',
        title: '',
        location: 1,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 120,
    },
    {
        name: 'ward_1',
        title: 'Ward 01',
        location: 2,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_2',
        title: 'Ward 02',
        location: 3,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_3',
        title: 'Ward 03',
        location: 4,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_4',
        title: 'Ward 04',
        location: 5,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_5',
        title: 'Ward 05',
        location: 6,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_6',
        title: 'Ward 06',
        location: 7,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_7',
        title: 'Ward 07',
        location: 8,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'ward_8',
        title: 'Ward 08',
        location: 9,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
]

export const erStatusConsultationTableFields = [
    {
        name: 'patient_id',
        title: 'Patient ID',
        location: 1,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 70,
    },
    {
        name: 'age',
        title: 'Age',
        location: 2,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 50,
    },
    {
        name: 'chief_complaint',
        title: 'Chief complaint',
        location: 3,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
    {
        name: 'los',
        title: 'Los',
        location: 4,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 50,
    },
    {
        name: 'los_1',
        title: 'Los',
        location: 5,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 50,
    },
    {
        name: 'chief_complaint_1',
        title: 'Chief complaint',
        location: 6,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
    {
        name: 'chief_complaint_2',
        title: 'Chief complaint',
        location: 7,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
    {
        name: 'chief_complaint_3',
        title: 'Chief complaint',
        location: 8,
        format: null,
        type: 'string',
        color: null,
        colors_map: { light: '#142644', dark: '#889BBA' },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
]

export const erStatusMainTableMockData = [
    {
        names: 'Waiting',
        ward_1: '01:32',
        ward_2: '03:15',
        ward_3: '01:32',
        ward_4: '01:32',
        ward_5: '02:15',
        ward_6: '02:15',
        ward_7: '03:15',
        ward_8: '01:32',
    },
    {
        names: 'Awaiting',
        ward_1: '1',
        ward_2: '1',
        ward_3: '1',
        ward_4: '1',
        ward_5: '1',
        ward_6: '1',
        ward_7: '1',
        ward_8: '1',
    },
    {
        names: 'Admitted (24h)',
        ward_1: '3',
        ward_2: '3',
        ward_3: '3',
        ward_4: '3',
        ward_5: '3',
        ward_6: '3',
        ward_7: '3',
        ward_8: '3',
    },
    {
        names: 'Discharged (24h)',
        ward_1: '1',
        ward_2: '1',
        ward_3: '1',
        ward_4: '1',
        ward_5: '1',
        ward_6: '1',
        ward_7: '1',
        ward_8: '1',
    },
]

export const erStatusConsultationTableMockData = [
    {
        patient_id: '4649050',
        age: '3.8',
        chief_complaint: 'Foreign object in the nose',
        los: '02:37',
        los_1: '00:50',
        chief_complaint_1: 'Otolaringology',
        chief_complaint_2: 'Waiting for otolaryngologist',
        chief_complaint_3: 'Home',
    },
    {
        patient_id: 'R202 - 4',
        age: '65',
        chief_complaint: 'Fever',
        los: '5d',
        los_1: '5d',
        chief_complaint_1: 'Abdominal pain',
        chief_complaint_2: 'Abdominal pain',
        chief_complaint_3: 'Abdominal pain',
    },
    {
        patient_id: 'R202 - 5',
        age: '62',
        chief_complaint: 'Abdominal pain',
        los: '5h',
        los_1: '5h',
        chief_complaint_1: 'Fever',
        chief_complaint_2: 'Fever',
        chief_complaint_3: 'Fever',
    },
    {
        patient_id: 'R441 - 2',
        age: '75',
        chief_complaint: 'Headache',
        los: '3d',
        los_1: '3d',
        chief_complaint_1: 'Headache',
        chief_complaint_2: 'Headache',
        chief_complaint_3: 'Headache',
    },
    {
        patient_id: 'R305 - 3',
        age: '59',
        chief_complaint: 'Fever',
        los: '3d',
        los_1: '3d',
        chief_complaint_1: 'Fever',
        chief_complaint_2: 'Fever',
        chief_complaint_3: 'Fever',
    },
    {
        patient_id: 'R201 - 4',
        age: '65',
        chief_complaint: 'Fever',
        los: '5d',
        los_1: '5d',
        chief_complaint_1: 'Fever',
        chief_complaint_2: 'Fever',
        chief_complaint_3: 'Fever',
    },
    {
        patient_id: 'R200 - 5',
        age: '62',
        chief_complaint: 'Abdominal pain',
        los: '5h',
        los_1: '5h',
        chief_complaint_1: 'Abdominal pain',
        chief_complaint_2: 'Abdominal pain',
        chief_complaint_3: 'Abdominal pain',
    },
    {
        patient_id: 'R440 - 2',
        age: '75',
        chief_complaint: 'Headache',
        los: '3d',
        los_1: '3d',
        chief_complaint_1: 'Headache',
        chief_complaint_2: 'Headache',
        chief_complaint_3: 'Headache',
    },
    {
        patient_id: 'R201 - 5',
        age: '62',
        chief_complaint: 'Abdominal pain',
        los: '5h',
        los_1: '5h',
        chief_complaint_1: 'Abdominal pain',
        chief_complaint_2: 'Abdominal pain',
        chief_complaint_3: 'Abdominal pain',
    },
]
