function getFutureInfoTimeFormatted() {
    const now = new Date()
    now.setHours(now.getHours() >= 18 ? 42 : 18, 0, 0, 0) // Set to tomorrow at 6 PM if it's past 6 PM today, otherwise set to today at 6 PM

    // Format the date and time
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    const year = now.getFullYear().toString().slice(-2)
    const hour = now.getHours().toString().padStart(2, '0')
    const minute = now.getMinutes().toString().padStart(2, '0')

    return `${month}/${day}/${year} ${hour}:${minute}`
}

const dateAtSixPMFormatted = getFutureInfoTimeFormatted()

export const currentShiftMock = {
    prediction: [
        {
            id: '2',
            info: {
                actual: 5,
                predicted: 44,
                scaleEnd: 180,
                scaleStart: 0,
            },
            title: 'Shift Prediction',
        },
        {
            id: '3',
            info: {
                actual: 38,
                predicted: 242,
                scaleEnd: 400,
                scaleStart: 0,
            },
            title: 'Daily Prediction',
        },
    ],
    realAdmissions: {
        admissions: '1',
        id: '1',
        title: 'Daily admissions',
    },
    time: {
        id: '1',
        info: dateAtSixPMFormatted,
        title: 'Time Remaining',
    },
}

export const realTimeInfoMock = [
    {
        admission: '21',
        discharge: '27',
        evaluation: '8',
        id: '1',
        predicted: '7',
        psychiatry: '3',
        total: '63',
    },
]

export const responceMock = {
    currentShift: {
        time: {
            id: 1,
            title: 'Time Remaining',
            info: '2024-03-11T08:00:00',
        },
        prediction: [
            {
                id: 2,
                title: 'Shift Prediction',
                info: {
                    scaleStart: 0,
                    scaleEnd: 180,
                    predicted: 36,
                    actual: 23,
                },
            },
            {
                id: 3,
                title: 'Daily Prediction',
                info: {
                    scaleStart: 0,
                    scaleEnd: 400,
                    predicted: 209,
                    actual: 23,
                },
            },
        ],
        realAdmissions: {
            id: 1,
            admissions: 3,
            title: 'Daily admissions',
        },
    },
    realTimeInfo: [
        {
            id: 1,
            total: 79,
            evaluation: 13,
            predicted: 11,
            admission: 27,
            discharge: 28,
            psychiatry: 13,
        },
    ],
}
