import React from 'react'
import { useSelector } from 'react-redux'
import {
    selectActiveEventBoxData,
    selectActiveRoomData,
} from '../../redux/rtk/operatingRoom/selectors'
import { OperatiningRoomsMock } from '../../utils/__Mocks__/OrStatus'
import BoxStatusDetails from './BoxStatusDetails/BoxStatusDetails'

import styles from './OrStatusInfoBlok.module.scss'
import PatientSchedule from './PatientSchedule/PatientSchedule'
import TitleVsTextBox from './TitleVsTextBox/TitleVsTextBox'

const PatientScheduleMOCK = [
    {
        id: 1,
        procedure: 'previous procedure',
        procedureName: '0FSC4ZZ RAV',
        doctor: 'Dimiti Raskin',
        nurse: 'Josh Randor',
    },
    {
        id: 2,
        procedure: 'previous procedure',
        procedureName: '0FSC4ZZ RAV',
        doctor: 'Lidia Sachs',
        nurse: 'Josh Randor',
    },
    {
        id: 3,
        procedure: 'previous procedure',
        procedureName: '820-822 LLMP',
        doctor: 'Lidia Sachs',
        nurse: 'Josh Randor',
    },
]
export const findOperatingRoomsByGroupId = (
    id: number,
    array: any[]
): any[] => {
    const foundRooms: any[] = array.filter((room) => room.groupId === id)
    return foundRooms
}

const OrStatusInfoBlok = () => {
    const activeRoomData = useSelector(selectActiveRoomData)
    const activeEventBoxData = useSelector(selectActiveEventBoxData)
    // Example usage:
    const slectedWithGroupId = findOperatingRoomsByGroupId(
        1,
        OperatiningRoomsMock
    )

    return (
        <>
            <div className={styles.infor_block}>
                {activeRoomData === null ? (
                    <div className={styles.infor_block__title}>INFO</div>
                ) : (
                    <>
                        <div className={styles.infor_block__main}>
                            <div className={styles.infor_block__main__details}>
                                <TitleVsTextBox
                                    header={'CR Type'}
                                    text={
                                        //@ts-ignore
                                        activeRoomData.orType || 'not defined'
                                    }
                                />
                                <TitleVsTextBox
                                    header={'AIR System'}
                                    text={
                                        //@ts-ignore
                                        activeRoomData.airSystem ||
                                        'not defined'
                                    }
                                />
                            </div>
                            <div
                                className={styles.infor_block__main__equipment}
                            >
                                {
                                    //@ts-ignore
                                    activeRoomData?.equipments.map(
                                        (item: any) => (
                                            <TitleVsTextBox
                                                header={item.name}
                                                text={
                                                    item.isAvailable
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        )
                                    )
                                }
                                {/* <TitleVsTextBox
                                header={'Equipment 1'}
                                text={'Yes'}
                            />
                            <TitleVsTextBox
                                header={'Equipment 2'}
                                text={'No'}
                            />
                            <TitleVsTextBox
                                header={'Equipment 3'}
                                text={'No'}
                            /> */}
                            </div>
                        </div>
                        <PatientSchedule
                            data={
                                //@ts-ignore
                                activeRoomData.patientsShedule
                            }
                        />

                        {Object.keys(activeEventBoxData).length > 0 ? (
                            <BoxStatusDetails />
                        ) : null}
                    </>
                )}
            </div>
        </>
    )
}

export default OrStatusInfoBlok
