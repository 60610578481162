import React from 'react'
import { useTranslation } from 'react-i18next'
import ORStatusInflow from '../ORStatusInflow/ORStatusInflow'
import ORStatusOutflow from '../ORStatusOutflow/ORStatusOutflow'

import styles from './OrRealTime.module.scss'
import OrStatusCurrent from '../OrStatusCurrent/OrStatusCurrent'

const ORrealTime: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <h2 className={styles.container__title}>
                {t('Real Time Operating Room Flow')}
            </h2>
            <div className={styles.container__content}>
                <ORStatusInflow />
                <div className={styles.container__content__right}>
                    <OrStatusCurrent />
                    <ORStatusOutflow />
                </div>
            </div>
        </div>
    )
}

export default ORrealTime
