import { RootState } from '../index'

export const selectDashboardPatientModalPopup = (state: RootState) =>
    state.patientModal.dashboardPatientModalPopup
export const selectDashboardPopupPatient = (state: RootState) =>
    state.patientModal.dashboardPopupPatient
export const selectDashboardPatientPopupFetchDate = (state: RootState) =>
    state.patientModal.dashboardPatientPopupFetchDate

export const selectDashboardPopupPatientSaturations = (state: RootState) =>
    state.patientModal.dashboardPopupPatientSaturations

export const selectDashboardPopupPatientTemperatures = (state: RootState) =>
    state.patientModal.dashboardPopupPatientTemperatures
export const selectDashboardPopupPatientBloodpreasures = (state: RootState) =>
    state.patientModal.dashboardPopupPatientBloodpreasures
export const selectDashboardPopupPatientHeartrates = (state: RootState) =>
    state.patientModal.dashboardPopupPatientHeartrates
export const selectCalendarModalCounters = (state: RootState) =>
    state.patientModal.calendarModalCounters
export const selectDischargeDispositions = (state: RootState) =>
    state.patientModal.dischargeDispositions
export const selectSelectedDischargeDispositions = (state: RootState) =>
    state.patientModal.selectedDischargeDispositions

export const selectUpdateLenghtStatus = (state: RootState) =>
    state.patientModal.updateLenghtStatus

export const selectPatientMedicalHistory = (state: RootState) =>
    state.patientModal.patientMedicalHistory

export const selectPatientTableDataStatus = (state: RootState) =>
    state.patientModal.getPatientTableDataStatus