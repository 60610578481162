import React from 'react'
import useHoverWithDelay from '../../../hooks/useHoverWithDelay'
import PopoverWindow from '../../common/PopoverWindow/PopoverWindow'

import styles from './PopoverItem.module.scss'

type PopoverItemProps = {
    target: string | number | React.ReactNode
    items: { title?: string; data: string }[] // Updated prop type
    showShadow?: boolean
}

const PopoverItem: React.FC<PopoverItemProps> = ({
    target,
    items,
    showShadow = true,
}) => {
    const { isHovered, handleMouseEnter, handleMouseLeave } = useHoverWithDelay(
        100,
        100
    )
    return (
        <PopoverWindow
            opened={isHovered}
            truthyValue={isHovered}
            position="right-end"
            offset={-20}
            target={
                <div
                    className={`${styles.item__target} ${
                        showShadow ? styles.item__target__shadow : ''
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    {target !== null ? target : '--'}
                </div>
            }
        >
            <div className={styles.item__body}>
                {items.map((item, index) => (
                    <div key={index} className={styles.item__body__section}>
                        {item.title && (
                            <div className={styles.item__body__section__title}>
                                {item.title}
                            </div>
                        )}
                        <div className={styles.item__body__section__content}>
                            {isHovered && item.data}
                        </div>
                    </div>
                ))}
            </div>
        </PopoverWindow>
    )
}

export default PopoverItem
