export const extractBeforeFirstSpecialSymbol = (input: string): string => {
    // Find the index of the first non-alphanumeric character
    const index = input.search(/[^a-zA-Z0-9]/);

    // If no special character is found, return the original string
    if (index === -1) {
        return input;
    }

    // Return the substring from the start to the first non-alphanumeric character
    return input.substring(0, index);
};