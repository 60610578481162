import * as React from 'react'
import { Link } from 'react-router-dom'

import { icons } from '../../../utils/iconsMap'
import styles from './LogoBar.module.scss'
// logo_tree,
// logo_text_bottom,
// logo_text_top,
const LogoBar = (props: { logoType: string }) => {
    return (
        <div className={styles.logo_bar}>
            {props.logoType === 'main' ? (
                <>
                    <div
                        className={`${styles.logo_bar__item} ${styles.logo_bar__logo}`}
                    >
                        <img src={icons.logo_placeholder} alt={'sun'} />
                    </div>
                    <div
                        className={`${styles.logo_bar__item} ${styles.logo_bar__logo_secondary}`}
                    >
                        <img src={icons.sagit_labs_logo} alt={'moon'} />
                    </div>
                </>
            ) : (
                <div className={`${styles.logo_bar__item}`}>
                    <Link
                        to="/ScrubChartNew"
                        className={`${styles.logo_bar__item_title}`}
                    >
                        <img src={icons.logo_tree} alt={'logo_tree'} />
                        <div className={`${styles.logo_bar__item_title_text}`}>
                            <img
                                src={icons.logo_text_top}
                                alt={'logo_text_top'}
                            />
                            <img
                                src={icons.logo_text_bottom}
                                alt={'logo_text_bottom'}
                            />
                        </div>
                    </Link>
                    <Link to="/ScrubChart">
                        <img src={icons.scrub_chart} alt={'scrub_chart'} />
                    </Link>
                </div>
            )}
        </div>
    )
}

export default LogoBar
