import React, { ReactNode } from 'react'

import styles from './TitleVsTextBox.module.scss'

type TitleVsTextBoxProps = {
    header: string
    text?: string
    children?: ReactNode
}

const TitleVsTextBox: React.FC<TitleVsTextBoxProps> = ({
    header,
    text,
    children,
}) => {
    return (
        <div className={styles.text_box}>
            <div className={styles.text_box__header}>{header}</div>
            {text ? <div className={styles.text_box__text}>{text}</div> : null}
            {children}
        </div>
    )
}

export default TitleVsTextBox
