import React, { useMemo } from 'react'
import { useFetchStatus } from '../../../hooks'
import { useGetCurrentOutflowQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import CcWraperContainer from '../../common/CcWraperContainer/CcWraperContainer'
import Item from './Item/Item'

import styles from './CriticalCareOutflows.module.scss'
import { useTranslation } from 'react-i18next'
import CustomScrollArea from 'components/common/CustomScrollArea/CustomScrollArea'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const CriticalCareOutflows: React.FC = () => {
    const { t } = useTranslation()
    const {
        data: msicuData,
        error: msicuError,
        isLoading: msicuLoading,
        isSuccess: msicuSuccess,
    } = useGetCurrentOutflowQuery('MSICU', {
        pollingInterval: 300000,
    })

    const {
        data: cvicuData,
        error: cvicuError,
        isLoading: cvicuLoading,
        isSuccess: cvicuSuccess,
    } = useGetCurrentOutflowQuery('CVICU', {
        pollingInterval: 300000,
    })

    const fetchStatusMsicu = useFetchStatus({
        data: msicuData,
        error: msicuError,
        isFetching: msicuLoading,
        isSuccess: msicuSuccess,
    })

    const fetchStatusCvicu = useFetchStatus({
        data: cvicuData,
        error: cvicuError,
        isFetching: cvicuLoading,
        isSuccess: cvicuSuccess,
    })

    const totalCount = useMemo(() => {
        return (
            (msicuData?.criticalCareTotalCurrentOutflows?.length || 0) +
            (cvicuData?.criticalCareTotalCurrentOutflows?.length || 0)
        )
    }, [msicuData, cvicuData])

    return (
        <div className={styles.container}>
            <div className={styles.container__title}>
                {t('cc.outflows.outflow')}{' '}
                <span className={styles.container__title_counter}>
                    ({totalCount})
                </span>
                <CorporateDocLink baseKey="criticalCare" sectionKey="outflow" />
            </div>
            <div className={styles.container__content}>
                <CcWraperContainer
                    withIndicator={false}
                    style={{ margin: '0px' }}
                >
                    <CustomScrollArea.Autosize mah={'82vh'} mx="auto">
                        {msicuData && (
                            <Item
                                data={
                                    msicuData?.criticalCareTotalCurrentOutflows
                                }
                                fetchStatus={fetchStatusMsicu}
                                title={t('cc.outflows.msicu')}
                            />
                        )}
                        {/* </CcWraperContainer>
                <CcWraperContainer withIndicator={false}> */}
                        {cvicuData && (
                            <Item
                                data={
                                    cvicuData?.criticalCareTotalCurrentOutflows
                                }
                                fetchStatus={fetchStatusCvicu}
                                title={t('cc.outflows.cvicu')}
                            />
                        )}
                    </CustomScrollArea.Autosize>
                </CcWraperContainer>
            </div>
        </div>
    )
}

export default CriticalCareOutflows
