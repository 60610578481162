import React from 'react'
import { IconTypeProps } from '../IconType'

const AskIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor

    return (
        <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.96704 0.945312C3.96707 0.945312 3.10995 1.21551 2.41609 1.74588C2.08957 1.99606 1.79366 2.28627 1.54877 2.61651C1.30388 2.93673 1.12021 3.28699 0.977358 3.65725C0.916135 3.82737 0.997766 4.0075 1.16103 4.07755L2.66098 4.72801C2.83445 4.79806 3.03852 4.72801 3.10995 4.55789C3.10995 4.55789 3.10995 4.55789 3.10995 4.54789C3.2528 4.21765 3.47729 3.88741 3.7732 3.57719C4.0385 3.29699 4.42624 3.1669 4.95684 3.1669H4.98745C5.52825 3.1669 5.9364 3.307 6.22211 3.59721C6.51802 3.88741 6.66087 4.23766 6.66087 4.67798C6.66087 5.04824 6.54863 5.37848 6.32415 5.70871C6.06905 6.06897 5.70171 6.45925 5.22214 6.88956C5.14051 6.95961 5.06908 7.02966 4.99765 7.09971C4.56909 7.51 4.27318 7.89027 4.09972 8.26054C3.99768 8.48069 3.91605 8.75089 3.86503 9.07112C3.80381 9.4614 3.7732 9.84167 3.7732 10.2319C3.7732 10.4221 3.91605 10.5722 4.10992 10.5722H5.90579C6.08946 10.5722 6.24252 10.4321 6.24252 10.242C6.25272 9.49142 6.34455 9.13116 6.41598 8.95103C6.48741 8.78091 6.71189 8.44067 7.38534 7.80021C7.94655 7.26983 8.3649 6.75947 8.62 6.26912C8.8853 5.76876 9.02815 5.19835 9.02815 4.58791C9.02815 3.5872 8.67102 2.72658 7.96696 2.00607C7.26289 1.30557 6.26292 0.945312 4.99765 0.945312H4.96704ZM4.96704 11.8231C4.08951 11.8231 3.37525 12.5236 3.37525 13.3842C3.37525 14.2448 4.08951 14.9453 4.96704 14.9453H4.99765C5.87518 14.9253 6.57924 14.2148 6.55883 13.3542C6.53842 12.5136 5.85477 11.8331 4.99765 11.8231H4.96704Z"
                fill={defaultColor1}
            />
        </svg>
    )
}

export default AskIcon
