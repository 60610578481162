export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
}


export interface tasksCountHuddlePageType {
    tasksCounts: {
        patientId: string
        tasksCount: number
    }[]
}

export interface ActionItemsType {
    actionItems: {
        asignee: string
        asigneeId: number
        ccId: number
        ccs: string[]
        id: number
        name: string
        patientIds: string[]
        patientInfos: string[]
        status: number
        text: string
        dueDate: Date | string
    }[]
    fetchDate: Date | string
}

export interface TasksForHuddlePageType {
    tasks: {
        id: number
        text: string
        status: number
        patientId: string
    }[]
    fetchDate: Date | string
}