import React from 'react'
import CcWraperContainer from '../../../common/CcWraperContainer/CcWraperContainer'
import SummaryItem from './SummaryItem/SummaryItem'

import styles from './InflowSummary.module.scss'

const InflowSummary: React.FC = () => {
    return (
        <div className={styles.container}>
            <CcWraperContainer style={{ margin: '0' }}>
                <SummaryItem title={'MSICU'} />
            </CcWraperContainer>

            <CcWraperContainer style={{ margin: '0' }}>
                <SummaryItem title={'CVICU'} />
            </CcWraperContainer>
        </div>
    )
}

export default InflowSummary
