export const INFECTION_MOCK_DATA = {
    "infections": [
        {
            "name": "Units under Outbreak:",
            "count": 5,
            "units": [
                "K6",
                "C3",
                "K8",
                "C4 (C8)",
                "D6"
            ]
        },
        {
            "name": "Units requiring Noco to every discharge:",
            "count": 1,
            "units": [
                "K8"
            ]
        },
        {
            "name": "Units closed to admission:",
            "count": 3,
            "units": [
                "K6",
                "D7/CD7",
                "D6"
            ]
        }
    ]
}