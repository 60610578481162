import { generateDate } from '../date/generateDate';

export const LOS_PREDICTION_MOCK = {
    "departments": [
        {
            "departmentName": "Geriatrics",
            "predictions": [
                {
                    "predictions": [
                        90.5,
                        91.5,
                        92.5
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "IM non Teach",
            "predictions": [
                {
                    "predictions": [
                        93,
                        92,
                        91
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Family Med",
            "predictions": [
                {
                    "predictions": [
                        83,
                        82,
                        85
                    ],
                    "date": generateDate(0),
                }
            ]
        }
        ,
        {
            "departmentName": "Critical Care",
            "predictions": [
                {
                    "predictions": [
                        84.25,
                        85.25,
                        86.25
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "HAD",
            "predictions": [
                {
                    "predictions": [
                        89,
                        89.5,
                        91
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "IM Teach",
            "predictions": [
                {
                    "predictions": [
                        86.27,
                        87.27,
                        88.47
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Palliative",
            "predictions": [
                {
                    "predictions": [
                        85.92,
                        86.92,
                        87.91
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Oncology",
            "predictions": [
                {
                    "predictions": [
                        86.25,
                        87.25,
                        88.25
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Psychiatry",
            "predictions": [
                {
                    "predictions": [
                        170,
                        178,
                        170
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Cardiology",
            "predictions": [
                {
                    "predictions": [
                        55,
                        56,
                        57
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Special Units",
            "predictions": [
                {
                    "predictions": [
                        59,
                        60,
                        61
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Surgery",
            "predictions": [
                {
                    "predictions": [
                        65,
                        63,
                        66
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "Neurology",
            "predictions": [
                {
                    "predictions": [
                        76,
                        80,
                        77
                    ],
                    "date": generateDate(0),
                }
            ]
        },
        {
            "departmentName": "SSU",
            "predictions": [
                {
                    "predictions": [
                        202,
                        205,
                        207
                    ],
                    "date": generateDate(0),
                }
            ]
        }
    ]
}
