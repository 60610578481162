import axios, {
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosHeaders,
} from 'axios'
import storeRtk from '../redux/rtk'

export const OcpApimSubscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY

const headers = {
    'Ocp-Apim-Subscription-Key': `${OcpApimSubscriptionKey}`,
}

// New interceptor function to handle token setting
export function handleRequestConfig(
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
    const token = localStorage.getItem('keycloakToken')
    if (!config.headers) {
        config.headers = new AxiosHeaders()
    }

    if (token) {
        ;(config.headers as AxiosHeaders).set('bearer', `${token}`)
    }
    return config
}

// Create Axios instance with the new interceptor function
export const createAxiosInstance = (
    baseURL: string,
    responseType: 'json' | 'blob'
) => {
    const instance = axios.create({
        baseURL,
        withCredentials: true,
        responseType,
        headers,
    })

    instance.interceptors.request.use(handleRequestConfig)

    return instance
}
