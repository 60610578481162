import React from 'react'

import styles from './DelayIndicator.module.scss'
import { icons } from 'utils/iconsMap'

type DelayIndicatorProps = {
    number: number
    style?: React.CSSProperties
}

const DelayIndicator: React.FC<DelayIndicatorProps> = ({ number, style }) => {
    const showIcon = number === 0

    return (
        <div className={styles.container} style={style}>
            {showIcon ? (
                <img
                    src={icons.success}
                    className={`${styles.container__img}`}
                    alt={`gender`}
                />
            ) : (
                <div className={styles.container__indicator}>
                    <div
                        className={
                            number >= 1
                                ? styles.container__indicator_orange
                                : ''
                        }
                    ></div>
                    <div
                        className={
                            number >= 2
                                ? styles.container__indicator_orange
                                : ''
                        }
                    ></div>
                    <div
                        className={
                            number > 2 ? styles.container__indicator_orange : ''
                        }
                    ></div>
                </div>
            )}
        </div>
    )
}

export default DelayIndicator
