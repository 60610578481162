import React from 'react'

import styles from './BgWrapper.module.scss'

type BgWrapperProps = {
    style?: React.CSSProperties
    children: React.ReactNode
    cutoutCorner?: boolean
    text?: string
}
const BgWrapper: React.FC<BgWrapperProps> = ({
    children,
    style,
    cutoutCorner,
    text,
}) => {
    return (
        <div
            className={`${styles.container} ${
                cutoutCorner ? styles.container_corner : ''
            }`}
            style={style}
            data-notification={text}
        >
            <>
                {/* <div
                    className={` ${
                        cutoutCorner ? styles.container_corner_box : ''
                    }`}
                ></div> */}
                {children}
            </>
        </div>
    )
}

export default BgWrapper
