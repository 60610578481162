import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk from '..';
import { patientsTableDataURL } from '../../../utils/API';




export const visitsApi = createApi({
    reducerPath: 'visitsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: patientsTableDataURL, prepareHeaders: (headers, { getState }) => {

            const token = storeRtk.getState().authenticate.userToken
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set('Ocp-Apim-Subscription-Key', `${process.env.REACT_APP_SUBSCRIPTION_KEY}`)
            }
            return headers
        },
    }),
    tagTypes: ['System'],

    endpoints: builder => ({
        getSystem: builder.query<any, any>({
            query: () => `v1/System`,
            providesTags: [{ type: 'System', id: 'SYSTEM' }],
        }),
        // getSystem: builder.query<any, any>({
        //     query: () => `v1/System`,
        //     providesTags: [{ type: 'System', id: 'SYSTEM' }],
        // }),
    })
})

export const { useGetSystemQuery, useLazyGetSystemQuery } = visitsApi;
