import React, { useMemo } from 'react'
import { AdmittingType } from '../../../../../redux/rtk/criticalCare/types'
import BgWrapper from '../../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../../common/CcTitle/CcTitle'
import SandIndicator from '../../../../common/SandIndicator/SandIndicator'
import { useGetTotalCurrentInflowQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import styles from './SummaryItem.module.scss'
import { calculateSumByKey } from 'utils/arrays/calculateSumByKey'
import { useTranslation } from 'react-i18next'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

type SummaryItemProps = {
    title: AdmittingType
}

const SummaryItem: React.FC<SummaryItemProps> = ({ title }) => {
    const { data, error, isFetching, isSuccess } =
        useGetTotalCurrentInflowQuery(title, { pollingInterval: 300000 })
    const { t } = useTranslation()

    const countOver24HoursSum = useMemo(
        () =>
            data
                ? calculateSumByKey(
                      data?.criticalCareTotalCurrentInflows,
                      'waitingOver24Hrs'
                  )
                : 0,
        [data]
    )

    const totalCount = useMemo(
        () =>
            data
                ? calculateSumByKey(
                      data?.criticalCareTotalCurrentInflows,
                      'count'
                  )
                : 0,
        [data]
    )

    return (
        <div className={styles.container}>
            <CcTitle style={{ paddingLeft: '2rem', paddingBottom: '1rem' }}>
                {title}{' '}
                <CorporateDocLink
                    baseKey="criticalCare"
                    sectionKey="inflowSummary"
                />
            </CcTitle>
            {data && (
                <>
                    <BgWrapper>
                        <div className={styles.container__content}>
                            <div className={styles.container__content__total}>
                                {t('cc.inflow.summary.Admission')} :
                                <span>({totalCount})</span>
                            </div>
                            {data?.criticalCareTotalCurrentInflows?.map(
                                (el) => (
                                    <div
                                        className={
                                            styles.container__content__item
                                        }
                                    >
                                        <span>{t(el?.originCategoryType)}</span>
                                        <span>{el?.count}</span>
                                    </div>
                                )
                            )}
                        </div>
                    </BgWrapper>
                    <CcTitle>
                        <div className={styles.container__footer}>
                            <div className={styles.container__footer_first}>
                                <div>
                                    {' '}
                                    {t('cc.inflow.summary.WaitingOver')} :
                                </div>
                                <SandIndicator delay={'24hrs'} />
                            </div>
                            <div>{countOver24HoursSum}</div>
                        </div>
                    </CcTitle>
                </>
            )}
        </div>
    )
}

export default SummaryItem
