
import {
    ButtonDataWithVariant,
} from './AdmissionsFilterOptions'
import { EdPatient } from '../../redux/rtk/edLive/types'

export type Counts = {
    [key: string]: number
}

export function countItemsForFilters(
    buttonsFilter: ButtonDataWithVariant[],
    data: EdPatient[]
) {
    if (buttonsFilter?.length === 0) {
        return {}
    }
    const counts = buttonsFilter?.reduce<Counts>((acc, button) => {
        if (button?.name !== 'all' && button?.name !== 'reset all filters') {
            const count = data?.filter(
                (patient) =>
                    patient?.filter?.toLowerCase() ===
                    button?.name.toLowerCase()
            )?.length
            acc[button.name] = count
        }
        return acc
    }, {})

    if (buttonsFilter?.some((button) => button.name === 'all')) {
        counts['all'] = data?.length
    }

    return counts
}