import React from 'react'
import PopoverItem from '../../../PopoverItem/PopoverItem'
import ProgressBarWrapper from 'components/common/ProgressBarWrapper/ProgressBarWrapper'

import styles from './OutflowListItem.module.scss'
import { OutflowOrItem } from 'redux/rtk/orStatus/types'

const OutflowListItem: React.FC<OutflowOrItem> = ({
    mrn,
    patientName,
    patientLastName,
    transferDelay,
    timeDelayLevel,
    location,
    destinationAfterSurgery,
}) => {
    return (
        <div className={styles.item}>
            <PopoverItem
                target={mrn}
                items={[
                    {
                        title: 'patient name',
                        data: `${patientName ?? '--'} ${
                            patientLastName ?? '--'
                        }`,
                    },
                ]}
            />
            <div>{location || '--'}</div>
            <ProgressBarWrapper
                time={transferDelay}
                delayLevel={timeDelayLevel}
                total={30}
                textSymbol={'M'}
            />
        </div>
    )
}
export default OutflowListItem
