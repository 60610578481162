import { RootState } from '..'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { BaseQueryApi } from '@reduxjs/toolkit/query'

export const prepareHeaders = (
    headers: Headers,
    { getState }: Pick<BaseQueryApi, 'getState'>
): Headers => {
    const token = selectToken(getState() as RootState)
    const userRole = selectActiveUserRole(getState() as RootState)
    if (token) {
        headers.set('bearer', `${token}`)
        headers.set(
            'Ocp-Apim-Subscription-Key',
            `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
        )
        headers.set('Role', userRole)
    }
    return headers
}
