import React, { Fragment, useEffect } from 'react'
import styles from './Login.module.scss'
import { useKeycloak } from '@react-keycloak/web'
import { baseURL } from '../../utils/API'
import Iframe from 'react-iframe'
import { useAppDispatch } from '../../redux/rtk'
import { setKeycloakToken } from '../../redux/rtk/auth/authSlice'

const Login = () => {
    const { keycloak } = useKeycloak()
    console.log('🚀 ~ file: Login.tsx:11 ~ Login ~ keycloak:', keycloak)
    const dispatch = useAppDispatch()
    useEffect(() => {
        // dispatch(setKeycloakToken(keycloak.token))
    }, [keycloak.authenticated])

    return (
        <Fragment>
            <Iframe
                className={styles.login_wrap}
                url={`${keycloak.login({
                    redirectUri: `${baseURL}/`,
                    locale: 'en',
                    prompt: 'login',
                })}`}
                width={`${100}%`}
                height={`${100}vh`}
            />
        </Fragment>
    )
}

export default Login
