import React from 'react'
import { IconTypeProps } from '../IconType'

const CardiologyIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.59142 11.5363L5.33333 12.275L5.30103 12.1826C5.0585 11.4884 4.40356 11.0234 3.66823 11.0234H1.5C1.08579 11.0234 0.75 11.3592 0.75 11.7734C0.75 12.1877 1.08579 12.5234 1.5 12.5234H3.66823C3.76583 12.5234 3.85277 12.5852 3.88497 12.6773L4.31704 13.914C4.65313 14.876 6.01354 14.876 6.34962 13.914L6.76894 12.7138L5.59142 11.5363Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8436 4.94765C9.21548 3.31953 6.57577 3.31953 4.94765 4.94765C3.31953 6.57577 3.31953 9.21548 4.94765 10.8436L5.34343 11.2394L5.31807 11.2647L5.59142 11.5381L5.595 11.5278L5.59881 11.5169L5.60295 11.5062L6.42801 9.35932C6.75061 8.5199 7.89925 8.42259 8.35847 9.19577L9.37831 10.9129C9.41968 10.9825 9.4947 11.0252 9.57571 11.0252H14C14.4142 11.0252 14.75 11.361 14.75 11.7752C14.75 12.1894 14.4142 12.5252 14 12.5252H9.57571C8.96542 12.5252 8.40029 12.2036 8.08864 11.6788L7.51483 10.7127L7.00725 12.0335L6.76894 12.7156L11.214 17.1607L11.2394 17.1353L11.2647 17.1607L17.1607 11.2647L17.1353 11.2394L17.5311 10.8436C19.1592 9.21548 19.1592 6.57577 17.5311 4.94765C15.903 3.31953 13.2633 3.31953 11.6351 4.94765L11.2394 5.34343L10.8436 4.94765Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default CardiologyIcon
