
import { createSlice } from '@reduxjs/toolkit';
import { alertsMockData } from '../../../utils/alertsMockData';
import { getPatientsAlertsData, getGeneralAlertsData } from './asyncActions';
import { AlertType, Status, GeneralAlertsData, Alert } from './types'

const patientsAlert = [] as AlertType[]
const selectedAlert = [] as any
const generalAlerts = [] as any
const initialState = {
    patientsAlert,
    getTasksCountStatus: Status.LOADING,
    getPatientsAlertsDataStatus: Status.LOADING,
    getGeneralAlertsDataStatus: Status.LOADING,
    alertsData: alertsMockData,
    selectedAlert,
    generalAlerts: generalAlerts,
    lastGeneralAlertsFetchDate: ''
}


const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlertsData(state, action) {
            state.alertsData = action.payload
        },
        setSelectedAlert(state, action) {
            state.selectedAlert = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPatientsAlertsData.pending, (state, action) => {
            state.getPatientsAlertsDataStatus = Status.LOADING;
            state.patientsAlert = initialState.patientsAlert;
        });

        builder.addCase(getPatientsAlertsData.fulfilled, (state, action) => {
            state.getPatientsAlertsDataStatus = Status.SUCCESS;
            state.patientsAlert = action.payload.alerts
        });

        builder.addCase(getPatientsAlertsData.rejected, (state, action) => {
            state.getPatientsAlertsDataStatus = Status.ERROR;
            state.patientsAlert = [];
        });

        builder.addCase(getGeneralAlertsData.pending, (state, action) => {
            state.getGeneralAlertsDataStatus = Status.LOADING;
            // state.generalAlerts = initialState.generalAlerts;
            state.lastGeneralAlertsFetchDate = initialState.lastGeneralAlertsFetchDate
        });

        builder.addCase(getGeneralAlertsData.fulfilled, (state, action) => {
            state.getGeneralAlertsDataStatus = Status.SUCCESS;
            state.generalAlerts = [...generalAlerts, ...action.payload.alerts]
            state.lastGeneralAlertsFetchDate = action.payload.lastFetchDateInUtc
        });

        builder.addCase(getGeneralAlertsData.rejected, (state, action) => {
            state.getGeneralAlertsDataStatus = Status.ERROR;
            state.generalAlerts = initialState.generalAlerts;
            state.lastGeneralAlertsFetchDate = initialState.lastGeneralAlertsFetchDate
        });
    },
});

export const { setAlertsData, setSelectedAlert } = alertsSlice.actions;

export default alertsSlice.reducer;