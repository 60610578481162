import React from 'react'
import CcTitle from '../../../common/CcTitle/CcTitle'

import styles from './OccupancyItem.module.scss'
import { useTranslation } from 'react-i18next'

type OccupancyItemProps = {
    UnitName: string
    OccupiedBeds: string | number
    TotalCapacity: string | number
}

const OccupancyItem: React.FC<OccupancyItemProps> = ({
    UnitName,
    OccupiedBeds,
    TotalCapacity,
}) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.container__title}>{`${t(
                'cc.occupancy.' + UnitName
            )}`}</div>
            <div className={styles.container__capacity}>
                {OccupiedBeds}/{TotalCapacity}
            </div>

            <CcTitle>
                <div className={styles.container__footer}>
                    {t('cc.occupancy.Occupied Beds')} /{' '}
                    {t('cc.occupancy.Total Capacity')}
                </div>
            </CcTitle>
        </div>
    )
}

export default OccupancyItem
