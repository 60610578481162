import React from 'react'
import { IconTypeProps } from '../IconType'

const SpecialUnitsIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 4C11.5 3 10.0012 2 10.0012 2C10.001 2 9.99734 2.00297 9.99021 2.0087C9.85107 2.12062 8.40132 3.28665 6.5 4C4.54291 4.73427 2.10766 4.98922 2.00789 4.99966C2.00574 4.99989 2.00467 5 2.00473 5C2.00473 5 1.86425 6.65164 3.11504 11.5781C4.11563 15.514 7.81938 17.7077 9.93696 18.962C9.95853 18.9747 9.97993 18.9874 10.0012 19L10.0491 18.9717C12.1639 17.7222 15.8841 15.5241 16.8873 11.5781C18.1381 6.65488 18 5 18 5C18 5 15.5 5 13.5 4ZM9 7H11V9H13V11H11V13H9V11H7V9H9V7Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default SpecialUnitsIcon
