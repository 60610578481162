import React from 'react'

import styles from './CcTable.module.scss'

type CcTableProps = {
    style?: React.CSSProperties
    children: React.ReactNode
}

const CcTable: React.FC<CcTableProps> = ({ style, children }) => {
    return (
        <table className={styles.table} style={style}>
            {children}
        </table>
    )
}

export default CcTable
