import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import EN from './assets/locales/en/translation.json'
import FR from './assets/locales/fr/translation.json'
import dayjs from 'dayjs'
require('dayjs/locale/fr')

const resources = {
    en: { translation: EN },
    fr: { translation: FR },
}

const userLang = navigator.language.substr(0, 2)
let lang = 'en'

if (userLang === 'fr') {
    dayjs.locale('fr')
    lang = 'fr'
}

i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
})

export default i18n
