import React from 'react'
import { IconTypeProps } from '../IconType'

const CriticalCareIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41391 8.58793C2.63286 9.36898 2.63286 10.6353 3.41391 11.4164L8.68168 16.6841C9.46273 17.4652 10.7291 17.4652 11.5101 16.6841L16.7779 11.4164C17.5589 10.6353 17.5589 9.36898 16.7779 8.58793L11.5101 3.32016C10.7291 2.53911 9.46273 2.53911 8.68168 3.32016L3.41391 8.58793ZM9.05675 6.00052C9.02578 5.45859 9.45689 5.00214 9.9997 5.00214C10.5425 5.00214 10.9736 5.45859 10.9426 6.00052L10.6339 11.4031C10.6147 11.7393 10.3365 12.0021 9.9997 12.0021C9.66293 12.0021 9.38468 11.7393 9.36547 11.4031L9.05675 6.00052ZM10.9997 14.0021C10.9997 14.5544 10.552 15.0021 9.9997 15.0021C9.44741 15.0021 8.9997 14.5544 8.9997 14.0021C8.9997 13.4499 9.44741 13.0021 9.9997 13.0021C10.552 13.0021 10.9997 13.4499 10.9997 14.0021Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default CriticalCareIcon
