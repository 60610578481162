import React, { useMemo } from 'react'
import { icons } from '../../../../utils/iconsMap'

import styles from './GenderColumn.module.scss'

type GenderColumnProps = {
    gender: string
}

const GenderColumn: React.FC<GenderColumnProps> = ({ gender }) => {
    const genderIcon = useMemo(() => {
        let icon

        switch (gender) {
            case 'M':
                icon = icons.male_icon
                break
            case 'F':
                icon = icons.female_icon
                break
            default:
                icon = icons.unknown_gender
                break
        }

        return { icon }
    }, [gender])

    return (
        <img src={genderIcon.icon} className={`${styles.img}`} alt={`gender`} />
    )
}

export default GenderColumn
