import { useCallback, useState } from 'react'
import { Group } from '@mantine/core'

import styles from '../TermsOfService.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    termsApi,
    useAcceptTermsOfServiceMutation,
    useGetShowTermsOfServiceQuery,
} from '../../../redux/rtk/terms/termsConditionsSlice'
import { selectTheme } from '../../../redux/rtk/user/selectors'
import { selectToken } from '../../../redux/rtk/auth/selectors'
import CustomButton from '../../common/CustomButton/CustomButton'
import { setAcceptTerms } from '../../../redux/rtk/termsAndOther/termsAndOtherSlice'
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox'

const TermsAndConditionsFooter = (props: {
    setIsOpen: (value: boolean) => void
}) => {
    const { setIsOpen } = props
    const theme = useSelector(selectTheme)
    const userToken = useSelector(selectToken)
    const dispatch = useDispatch()

    const { data: termsAndConditionsData, refetch } =
        useGetShowTermsOfServiceQuery(undefined, {
            skip: !userToken,
        })
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(
        !termsAndConditionsData?.showTermsAndConditions
    )

    const [acceptTermsOfService] = useAcceptTermsOfServiceMutation()

    const { t } = useTranslation()
    const isLight = theme === 'light'
    const { updateQueryData } = termsApi.util
    const updateTermsData = useCallback(
        () =>
            dispatch(
                updateQueryData('getShowTermsOfService', undefined, (draft) => {
                    // draft.push({ showTermsAndConditions: false, sections: [] })
                    return Object.assign(draft, {
                        showTermsAndConditions: false,
                        sections: [],
                    })
                })
            ),
        []
    )

    const getConfirmAndTermsAndService = useCallback(async () => {
        setIsOpen(false)
        if (userToken) {
            try {
                const response = await acceptTermsOfService().unwrap()
                // dispatch(setAcceptTerms(response?.success))
                if (response?.success) {
                    updateTermsData()
                    window.location.reload()
                }
                // refetch()
            } catch (error) {
                console.error('Failed to update user data:', error)
            }
        }
    }, [])

    return (
        <footer
            className={`${styles.footer} ${
                isLight ? styles.terms_main_light : styles.terms_main_dark
            }`}
        >
            <Group
                style={{ marginRight: '30px', width: '500px' }}
                justify="flex-end"
            >
                {true ? (
                    <>
                        <CustomCheckbox
                            id={1}
                            label={t('I agree')}
                            isChecked={isCheckboxChecked}
                            onChange={() =>
                                setIsCheckboxChecked(!isCheckboxChecked)
                            }
                        />
                        <CustomButton
                            customVariant={
                                isCheckboxChecked ? 'primary' : undefined
                            }
                            radius="xl"
                            disabled={!isCheckboxChecked}
                            onClick={() => {
                                if (isCheckboxChecked) {
                                    getConfirmAndTermsAndService()
                                }
                            }}
                            customStyle={{
                                marginRight: '1.7rem',
                                height: '3rem',
                            }}
                        >
                            {t('Confirm')}
                        </CustomButton>
                    </>
                ) : (
                    <CustomButton
                        variant="outline"
                        customVariant="secondary"
                        radius="xl"
                        size="lg"
                        onClick={() => {
                            setIsOpen(false)
                        }}
                    >
                        {t('Close')}
                    </CustomButton>
                )}
            </Group>
        </footer>
    )
}

export default TermsAndConditionsFooter
