import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@mantine/core'

import ManagementMenuOptions from '../ManagementMenuOptions'
import Menu from '../../../Header/Menu/Menu'
import { selectLocale } from '../../../../redux/rtk/user/selectors'
import { selectPredictiveMode } from '../../../../redux/rtk/filter/selectors'
import { setPredictiveMode } from '../../../../redux/rtk/filter/filterSlice'
import CustomButton from '../../../common/CustomButton/CustomButton'

import styles from './ManagementMenu.module.scss'

const ManagementMenu: React.FC = () => {
    const dispatch = useDispatch()
    const locale = useSelector(selectLocale)
    const predictiveMode = useSelector(selectPredictiveMode)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale])

    const handlePredictiveMode = () => {
        dispatch(setPredictiveMode(!predictiveMode))
    }

    return (
        <div className={styles.menu}>
            <Menu menuItems={ManagementMenuOptions} />
            <Tooltip label="In development" color="blue" withArrow>
                <CustomButton
                    variant="outline"
                    customVariant={predictiveMode ? 'primary' : 'secondary'}
                    radius="xl"
                    size="lg"
                    onClick={handlePredictiveMode}
                >
                    Predictive mode
                </CustomButton>
            </Tooltip>
        </div>
    )
}
export default ManagementMenu
