import React, { useCallback, useMemo } from 'react'
import {
    Chart as ChartJS,
    // CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'
import {
    OutflowHistoricalItem,
    LosHistoricalItem,
    InflowHistoricalItem,
} from 'redux/rtk/orStatus/types'

import styles from './TrendsChart.module.scss'
import {
    createDataSet,
    getUnitsLegendsMap,
    transformOutflowsToDataSetsConfig,
} from '../helpers'
import { getMonthDayFromDateTime } from 'utils/date/getMonthDayFromDateTime'
import { isTimestampWeekend } from 'utils/date/isTimestampWeekend'

ChartJS.register(
    // CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

type TrendsChartProps = {
    medianValue: number
    data: OutflowHistoricalItem[] | LosHistoricalItem[] | InflowHistoricalItem[]
    type?: string
    xAxisKey: string
    yAxisKey: string
    yAxisTitle?: string
    lableText?: string
}

const TrendsChart: React.FC<TrendsChartProps> = ({
    medianValue,
    data,
    type = 'admissionType',
    xAxisKey,
    yAxisKey,
    lableText = 'patient(s)',
    yAxisTitle = 'HOURS',
}) => {
    const horizontalDottedLine = {
        id: 'horizontalDottedLine',
        beforeDatasetsDraw(chart: any, args: any, options: object) {
            const {
                ctx,
                chartArea: { top, right, bottom, left, width, height },
                scales: { x, y },
            } = chart
            ctx.save()

            ctx.strokeStyle = '#FF7783'
            ctx.setLineDash([2, 3])
            ctx.lineWidth = 3
            ctx.strokeRect(left, y.getPixelForValue(medianValue), width, 0)
            ctx.restore()
        },
    }

    const yScaleText = {
        id: 'yScaleText',
        afterDatasetsDraw(chart: any, args: any, options: any) {
            const {
                ctx,
                chartArea: { top },
                scales: { x, y },
            } = chart
            ctx.save()
            ctx.font = `${options.fontWeight} ${options.fontSize}px Arial`
            ctx.fillStyle = options.fontColor
            ctx.fillText(options.yTitle, 10, top - 10)
            ctx.restore()
        },
    }

    const dateHighlighter = {
        id: 'dateHighlighter',
        beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
            const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
                scales: { x, y },
            } = chart
            const weekendsArray = chart.boxes[3].ticks
                .map((item: any) =>
                    isTimestampWeekend(item.value) ? item.value : null
                )
                .filter((value: any) => value !== null)

            const startDate = new Date(weekendsArray[0]).setHours(-12, 0, 0, 0)
            const startDate2 = new Date(weekendsArray[0]).setHours(12, 0, 0, 0)
            const endDate = new Date(weekendsArray[1]).setHours(-12, 0, 0, 0)
            const endDate2 = new Date(weekendsArray[1]).setHours(12, 0, 0, 0)

            ctx.save()
            ctx.fillStyle = 'rgba(142, 149, 149, 0.16)'
            ctx.fillRect(
                x.getPixelForValue(startDate),
                top,
                x.getPixelForValue(startDate2) -
                    x.getPixelForValue(startDate) -
                    1,
                height + 35
            )
            ctx.fillRect(
                x.getPixelForValue(endDate) + 1,
                top,
                x.getPixelForValue(endDate2) - x.getPixelForValue(endDate),
                height + 35
            )
            ctx.restore()
        },
    }

    const dataSets = useMemo(() => {
        const dataSetsConfig = transformOutflowsToDataSetsConfig(data, type)
        return dataSetsConfig?.map((config) =>
            createDataSet({ ...config, xAxisKey, yAxisKey })
        )
    }, [data])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 1,
            },
        },
        layout: {
            padding: {
                bottom: 14, //set that fits the best
                top: 20,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                // text: [chartName],
                // color: 'rgba(62, 105, 164, 1)',
                color: '#0987b1',
                // position: 'top',
                // font: responsiveFonts(),
                // font: {
                //     weight: 'bold',
                //     size: 15,
                //     lineHeight: 3,
                // },
                padding: 10,
            },
            tooltip: {
                // displayColors: false,
                callbacks: {
                    title: (ctx: any) => {
                        const formatedTitle = getMonthDayFromDateTime(
                            ctx[0].label
                        )
                        return formatedTitle
                    },
                    label: (ctx: any) => {
                        const formatedLable = getUnitsLegendsMap(ctx.raw[type])
                        return `${formatedLable}: ${ctx.formattedValue} ${lableText}`
                    },
                },
            },
            yScaleText: {
                fontColor: '#B0D3E7',
                fontSize: 6,
                fontWeight: 700,
                yTitle: yAxisTitle,
            },
        },
        scales: {
            y: {
                // beginAtZero: true,
                grid: {
                    // drawTicks: false,
                    drawOnChartArea: false,
                    borderWidth: 2,
                    borderColor: 'rgba(176, 211, 231, 1)',
                    color: 'rgba(176, 211, 231, 1)',
                    tickWidth: 1,
                    tickLength: 4,
                },
                ticks: {
                    display: true,
                    color: 'rgba(136, 155, 186, 1)',
                    padding: 2,
                    font: {
                        size: 9,
                        weight: 'bold',
                    },
                },
            },
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                // beginAtZero: false,
                grid: {
                    // drawTicks: false,
                    borderWidth: 2,
                    borderColor: 'rgba(176, 211, 231, 1)',
                    tickColor: 'rgba(176, 211, 231, 1)',
                    tickLength: 4,
                    tickWidth: 2,
                },
                ticks: {
                    // display: false,
                    color: (context: any, index: number) => {
                        const isWeekend = isTimestampWeekend(context.tick.value)
                        return isWeekend ? '#F1F4F7' : 'rgba(176, 211, 231, 1)'
                    },
                    padding: 6,
                    font: {
                        size: 9,
                        weight: 'bold',
                    },
                },
            },
        },
    }

    return (
        <div className={styles.containe}>
            <Line
                data={{ datasets: dataSets }}
                //@ts-ignore
                options={options}
                // className={styles.chart}
                plugins={[yScaleText, dateHighlighter]}
            ></Line>
        </div>
    )
}

export default TrendsChart
