import React from 'react'
import { IconTypeProps } from './type'

const MoonIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8.21425 14C6.56613 14 4.98551 13.3453 3.82011 12.1799C2.65471 11.0145 2 9.43386 2 7.78574C2 5.2679 3.44642 3.01042 5.68596 2.03569C5.76489 2.00129 5.85235 1.99146 5.93695 2.00749C6.02154 2.02351 6.09935 2.06464 6.16023 2.12553C6.22111 2.18641 6.26225 2.26421 6.27827 2.34881C6.29429 2.43341 6.28446 2.52087 6.25007 2.59979C5.99292 3.19015 5.85712 3.9463 5.85712 4.78576C5.85712 7.73967 8.26032 10.1429 11.2142 10.1429C12.0537 10.1429 12.8099 10.0071 13.4002 9.74992C13.4791 9.71552 13.5666 9.70569 13.6512 9.72172C13.7358 9.73774 13.8136 9.77887 13.8745 9.83976C13.9354 9.90064 13.9765 9.97845 13.9925 10.063C14.0085 10.1476 13.9987 10.2351 13.9643 10.314C12.9896 12.5536 10.7321 14 8.21425 14Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default MoonIcon
