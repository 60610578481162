import { generateDate } from '../date/generateDate';

export const INFLOW_MOCK_DATA = {
    "departments": [
        {
            "departmentName": "Critical Care",
            "capacity": 25,
            "inflow": [
                {
                    "count": 37,
                    "date": generateDate(7),
                    "currentInflow": 10
                },
                {
                    "count": 35,
                    "date": generateDate(1),
                    "currentInflow": 5
                },
                {
                    "count": 34,
                    "date": generateDate(2),
                    "currentInflow": 2
                },
                {
                    "count": 35,
                    "date": generateDate(6),
                    "currentInflow": 4
                },
                {
                    "count": 34,
                    "date": generateDate(4),
                    "currentInflow": 5
                },
                {
                    "count": 25,
                    "date": generateDate(0),
                    "currentInflow": 2
                },
                {
                    "count": 34,
                    "date": generateDate(5),
                    "currentInflow": 3
                },
                {
                    "count": 35,
                    "date": generateDate(3),
                    "currentInflow": 5
                }
            ]
        },
        {
            "departmentName": "HAD",
            "capacity": 5,
            "inflow": [
                {
                    "count": 9,
                    "date": generateDate(7),
                    "currentInflow": 0
                },
                {
                    "count": 9,
                    "date": generateDate(6),
                    "currentInflow": 0
                },
                {
                    "count": 9,
                    "date": generateDate(4),
                    "currentInflow": 2
                },
                {
                    "count": 9,
                    "date": generateDate(2),
                    "currentInflow": 0
                },
                {
                    "count": 6,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 9,
                    "date": generateDate(5),
                    "currentInflow": 0
                },
                {
                    "count": 9,
                    "date": generateDate(3),
                    "currentInflow": 0
                },
                {
                    "count": 9,
                    "date": generateDate(1),
                    "currentInflow": 1
                }
            ]
        },
        {
            "departmentName": "Neurology",
            "capacity": 23,
            "inflow": [
                {
                    "count": 31,
                    "date": generateDate(1),
                    "currentInflow": 3
                },
                {
                    "count": 29,
                    "date": generateDate(4),
                    "currentInflow": 2
                },
                {
                    "count": 32,
                    "date": generateDate(6),
                    "currentInflow": 4
                },
                {
                    "count": 31,
                    "date": generateDate(2),
                    "currentInflow": 0
                },
                {
                    "count": 31,
                    "date": generateDate(7),
                    "currentInflow": 5
                },
                {
                    "count": 31,
                    "date": generateDate(5),
                    "currentInflow": 1
                },
                {
                    "count": 31,
                    "date": generateDate(3),
                    "currentInflow": 2
                },
                {
                    "count": 26,
                    "date": generateDate(0),
                    "currentInflow": 0
                }
            ]
        },
        {
            "departmentName": "IM Teach",
            "capacity": 64,
            "inflow": [
                {
                    "count": 72,
                    "date": generateDate(7),
                    "currentInflow": 0
                },
                {
                    "count": 74,
                    "date": generateDate(2),
                    "currentInflow": 0
                },
                {
                    "count": 70,
                    "date": generateDate(4),
                    "currentInflow": 0
                },
                {
                    "count": 72,
                    "date": generateDate(6),
                    "currentInflow": 4
                },
                {
                    "count": 67,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 75,
                    "date": generateDate(3),
                    "currentInflow": 1
                },
                {
                    "count": 73,
                    "date": generateDate(5),
                    "currentInflow": 2
                },
                {
                    "count": 76,
                    "date": generateDate(1),
                    "currentInflow": 0
                }
            ]
        },
        {
            "departmentName": "IM non Teach",
            "capacity": 27,
            "inflow": [
                {
                    "count": 35,
                    "date": generateDate(1),
                    "currentInflow": 0
                },
                {
                    "count": 36,
                    "date": generateDate(6),
                    "currentInflow": 0
                },
                {
                    "count": 34,
                    "date": generateDate(4),
                    "currentInflow": 0
                },
                {
                    "count": 36,
                    "date": generateDate(2),
                    "currentInflow": 1
                },
                {
                    "count": 35,
                    "date": generateDate(7),
                    "currentInflow": 2
                },
                {
                    "count": 35,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 35,
                    "date": generateDate(5),
                    "currentInflow": 3
                },
                {
                    "count": 35,
                    "date": generateDate(3),
                    "currentInflow": 0
                }
            ]
        },
        {
            "departmentName": "Cardiology",
            "capacity": 50,
            "inflow": [
                {
                    "count": 65,
                    "date": generateDate(2),
                    "currentInflow": 9
                },
                {
                    "count": 61,
                    "date": generateDate(4),
                    "currentInflow": 15
                },
                {
                    "count": 56,
                    "date": generateDate(7),
                    "currentInflow": 13
                },
                {
                    "count": 58,
                    "date": generateDate(6),
                    "currentInflow": 10
                },
                {
                    "count": 50,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 62,
                    "date": generateDate(3),
                    "currentInflow": 13
                },
                {
                    "count": 57,
                    "date": generateDate(5),
                    "currentInflow": 13
                },
                {
                    "count": 63,
                    "date": generateDate(1),
                    "currentInflow": 9
                }
            ]
        },
        {
            "departmentName": "Surgery",
            "capacity": 117,
            "inflow": [
                {
                    "count": 117,
                    "date": generateDate(1),
                    "currentInflow": 71
                },
                {
                    "count": 107,
                    "date": generateDate(0),
                    "currentInflow": 49
                },
                {
                    "count": 146,
                    "date": generateDate(4),
                    "currentInflow": 100
                },
                {
                    "count": 149,
                    "date": generateDate(6),
                    "currentInflow": 94
                },
                {
                    "count": 145,
                    "date": generateDate(2),
                    "currentInflow": 13
                },
                {
                    "count": 171,
                    "date": generateDate(7),
                    "currentInflow": 80
                },
                {
                    "count": 139,
                    "date": generateDate(5),
                    "currentInflow": 91
                },
                {
                    "count": 138,
                    "date": generateDate(3),
                    "currentInflow": 21
                }
            ]
        },
        {
            "departmentName": "Family Med",
            "capacity": 25,
            "inflow": [
                {
                    "count": 27,
                    "date": generateDate(6),
                    "currentInflow": 5
                },
                {
                    "count": 26,
                    "date": generateDate(7),
                    "currentInflow": 2
                },
                {
                    "count": 28,
                    "date": generateDate(4),
                    "currentInflow": 2
                },
                {
                    "count": 28,
                    "date": generateDate(2),
                    "currentInflow": 3
                },
                {
                    "count": 28,
                    "date": generateDate(1),
                    "currentInflow": 2
                },
                {
                    "count": 27,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 27,
                    "date": generateDate(3),
                    "currentInflow": 3
                },
                {
                    "count": 28,
                    "date": generateDate(5),
                    "currentInflow": 5
                }
            ]
        },
        {
            "departmentName": "Geriatrics",
            "capacity": 53,
            "inflow": [
                {
                    "count": 35,
                    "date": generateDate(7),
                    "currentInflow": 2
                },
                {
                    "count": 34,
                    "date": generateDate(4),
                    "currentInflow": 1
                },
                {
                    "count": 35,
                    "date": generateDate(6),
                    "currentInflow": 1
                },
                {
                    "count": 34,
                    "date": generateDate(2),
                    "currentInflow": 0
                },
                {
                    "count": 33,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 34,
                    "date": generateDate(3),
                    "currentInflow": 0
                },
                {
                    "count": 35,
                    "date": generateDate(5),
                    "currentInflow": 1
                },
                {
                    "count": 35,
                    "date": generateDate(1),
                    "currentInflow": 1
                }
            ]
        },
        {
            "departmentName": "SSU",
            "capacity": 22,
            "inflow": [
                {
                    "count": 24,
                    "date": generateDate(0),
                    "currentInflow": 1
                },
                {
                    "count": 23,
                    "date": generateDate(2),
                    "currentInflow": 5
                },
                {
                    "count": 22,
                    "date": generateDate(7),
                    "currentInflow": 7
                },
                {
                    "count": 20,
                    "date": generateDate(6),
                    "currentInflow": 11
                },
                {
                    "count": 22,
                    "date": generateDate(4),
                    "currentInflow": 10
                },
                {
                    "count": 23,
                    "date": generateDate(5),
                    "currentInflow": 11
                },
                {
                    "count": 23,
                    "date": generateDate(3),
                    "currentInflow": 3
                },
                {
                    "count": 23,
                    "date": generateDate(1),
                    "currentInflow": 10
                }
            ]
        },
        {
            "departmentName": "Psychiatry",
            "capacity": 48,
            "inflow": [
                {
                    "count": 56,
                    "date": generateDate(0),
                    "currentInflow": 0
                },
                {
                    "count": 61,
                    "date": generateDate(4),
                    "currentInflow": 5
                },
                {
                    "count": 65,
                    "date": generateDate(2),
                    "currentInflow": 1
                },
                {
                    "count": 66,
                    "date": generateDate(6),
                    "currentInflow": 5
                },
                {
                    "count": 62,
                    "date": generateDate(7),
                    "currentInflow": 7
                },
                {
                    "count": 62,
                    "date": generateDate(1),
                    "currentInflow": 5
                },
                {
                    "count": 63,
                    "date": generateDate(5),
                    "currentInflow": 0
                },
                {
                    "count": 64,
                    "date": generateDate(3),
                    "currentInflow": 3
                }
            ]
        },
        {
            "departmentName": "Palliative",
            "capacity": 17,
            "inflow": [
                {
                    "count": 16,
                    "date": generateDate(6),
                    "currentInflow": 2
                },
                {
                    "count": 19,
                    "date": generateDate(4),
                    "currentInflow": 1
                },
                {
                    "count": 16,
                    "date": generateDate(7),
                    "currentInflow": 2
                },
                {
                    "count": 18,
                    "date": generateDate(2),
                    "currentInflow": 0
                },
                {
                    "count": 18,
                    "date": generateDate(1),
                    "currentInflow": 1
                },
                {
                    "count": 18,
                    "date": generateDate(3),
                    "currentInflow": 0
                },
                {
                    "count": 18,
                    "date": generateDate(5),
                    "currentInflow": 2
                },
                {
                    "count": 17,
                    "date": generateDate(0),
                    "currentInflow": 1
                }
            ]
        },
        {
            "departmentName": "Oncology",
            "capacity": 28,
            "inflow": [
                {
                    "count": 29,
                    "date": generateDate(0),
                    "currentInflow": 3
                },
                {
                    "count": 33,
                    "date": generateDate(6),
                    "currentInflow": 10
                },
                {
                    "count": 34,
                    "date": generateDate(4),
                    "currentInflow": 8
                },
                {
                    "count": 32,
                    "date": generateDate(7),
                    "currentInflow": 10
                },
                {
                    "count": 34,
                    "date": generateDate(2),
                    "currentInflow": 5
                },
                {
                    "count": 33,
                    "date": generateDate(1),
                    "currentInflow": 8
                },
                {
                    "count": 34,
                    "date": generateDate(5),
                    "currentInflow": 9
                },
                {
                    "count": 34,
                    "date": generateDate(3),
                    "currentInflow": 5
                }
            ]
        },
        {
            "departmentName": "Special Units",
            "capacity": 257,
            "inflow": [
                {
                    "count": 91,
                    "date": generateDate(4),
                    "currentInflow": 62
                },
                {
                    "count": 112,
                    "date": generateDate(7),
                    "currentInflow": 77
                },
                {
                    "count": 75,
                    "date": generateDate(2),
                    "currentInflow": 39
                },
                {
                    "count": 92,
                    "date": generateDate(6),
                    "currentInflow": 64
                },
                {
                    "count": 34,
                    "date": generateDate(0),
                    "currentInflow": 10
                },
                {
                    "count": 79,
                    "date": generateDate(3),
                    "currentInflow": 27
                },
                {
                    "count": 90,
                    "date": generateDate(5),
                    "currentInflow": 56
                },
                {
                    "count": 48,
                    "date": generateDate(1),
                    "currentInflow": 58
                }
            ]
        }
    ]
}