import React, { useMemo } from 'react'
import CcTitle from '../../../common/CcTitle/CcTitle'
import { useTranslation } from 'react-i18next'
import { useGetInflowHistoricalQuery } from '../../../../redux/rtk/orStatus/OrStatusApi'

import styles from './InflowTrends.module.scss'
import TrendsChartItem from '../TrendsChartItem/TrendsChartItem'
import { extractUniqueDestinations } from '../TrendsChartItem/helpers'

const InflowTrends: React.FC = () => {
    const { t } = useTranslation()
    const { data, error, isFetching, isSuccess } = useGetInflowHistoricalQuery()
    // const fetchStatus = useFetchStatus({
    //     data,
    //     error,
    //     isFetching,
    //     isSuccess,
    // })
    const uniqueOutflows = useMemo(
        () => data && extractUniqueDestinations(data?.inflows, 'admissionType'),
        [data]
    )

    return (
        <div className={`${styles.container}`}>
            <CcTitle style={{ paddingLeft: '1.5rem', paddingBottom: '2rem' }}>
                {t('Inflow')}{' '}
            </CcTitle>
            {data && data?.inflows?.length > 0 && (
                <TrendsChartItem
                    title={t('Number of patients by source')}
                    data={data?.inflows}
                    legends={uniqueOutflows}
                    xAxisKey={'surgeryScheduleDate'}
                    yAxisKey={'numberOfAcceptedPatients'}
                    yAxisTitle="PATIENTS"
                    documentationKey="OrTrendInflow"
                />
            )}
        </div>
    )
}

export default InflowTrends
