import React from 'react'
import { PatientInfo } from 'redux/rtk/orStatus/types'

import styles from './CurrentListItem.module.scss'
import PopoverItem from 'components/OrStatus/PopoverItem/PopoverItem'
import { formatTimeFromDatetime } from 'components/OrStatus/OrList/ListItem/ScheduleItem/helper'
import DelayIndicator from 'components/common/DelayIndicator/DelayIndicator'
import { useTranslation } from 'react-i18next'

const CurrentListItem: React.FC<PatientInfo> = ({
    patientRecord,
    patientFirstName,
    patientLastName,
    admissionType,
    surgeryProcedure,
    surgeryScheduleStartDateTime,
    surgeryActualizedStartDateTime,
    surgeryActualizedEndDateTime,
    timeDelayLevel,
    surgeryRoom,
    nextPatientLocation,
    timeDelayMinutes,
}) => {
    const formattedStartTime = surgeryScheduleStartDateTime
        ? formatTimeFromDatetime(surgeryScheduleStartDateTime)
        : '--'
    const formattedEndDateTime = surgeryActualizedEndDateTime
        ? formatTimeFromDatetime(surgeryActualizedEndDateTime)
        : '--'

    const formattedActualizedStartTime = surgeryActualizedStartDateTime
        ? formatTimeFromDatetime(surgeryActualizedStartDateTime)
        : '--'
    const { t } = useTranslation()

    const delayLevelText: {
        [key: number]: string
    } = {
        0: t('On time'),
        1: t('Slight delay'),
        2: t('Medium delay'),
        3: t('Severe delay'),
    }

    const delayText = delayLevelText[timeDelayLevel] || '--'

    return (
        <div className={styles.item}>
            <PopoverItem
                target={patientRecord}
                items={[
                    {
                        title: 'patient name',
                        data: `${patientFirstName ?? '--'} ${
                            patientLastName ?? '--'
                        }`,
                    },
                ]}
            />
            <PopoverItem
                target={surgeryProcedure}
                items={[
                    {
                        title: '',
                        data: surgeryProcedure,
                    },
                ]}
            />
            <PopoverItem
                target={
                    <div className={styles.item__delay}>
                        <span style={{ flex: 1 }}>
                            {formattedActualizedStartTime}
                        </span>
                        <DelayIndicator
                            number={timeDelayLevel}
                            style={{ flex: 1 }}
                        />
                    </div>
                }
                items={[
                    {
                        data: delayText,
                    },
                    {
                        title: t('scheduled'),
                        data: formattedStartTime,
                    },
                    {
                        title: t('delay'),
                        data: `${timeDelayMinutes} ${t('minutes')}`,
                    },
                ]}
                showShadow={false}
            />
            {/* <DelayIndicator number={timeDelayLevel} /> */}
            <div>{formattedEndDateTime}</div>
            <div style={{ textAlign: 'center' }}>{surgeryRoom || '--'}</div>
            <div style={{ textAlign: 'center' }}>
                {nextPatientLocation || '--'}
            </div>
        </div>
    )
}

export default CurrentListItem
