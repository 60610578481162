import dayjs from 'dayjs'

export const convertToHoursMinutes = (isoString: string): string => {
    return dayjs(isoString).utc().format('HH.mm')
}

export function formatTimeFromDatetime(datetime: string): string {
    // Create a new Date object from the datetime string
    const date = new Date(datetime)

    // Extract hours and minutes
    let hours = date.getHours()
    const minutes = date.getMinutes()
    // const hours = date.getUTCHours()
    // const minutes = date.getUTCMinutes()
    // Ensure time is in 12-hour format (you can remove this if you want to keep 24-hour format)
    // const formattedHours = hours % 12 || 12 // Convert 0 to 12 for AM times

    // Format the time as "H:MM" (no leading zero on the hour)
    const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return formattedTime
}
