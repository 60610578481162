import {
    combineReducers,
    configureStore,
    createListenerMiddleware,
    getDefaultMiddleware,
} from '@reduxjs/toolkit'
// import { api } from '../app/auth';
import authenticate from './auth/authSlice'
import userDetails, { setAtiveUserRole } from './user/userSlice'
import filters from './filter/filterSlice'
import patientsTable from './patientsTable/patientsTableSlice'
import nursing from './nursing/nursingSlice'
import tasks from './tasks/tasksSlice'
import patientModal from './patientModal/patientModalSlice'
import alerts from './alerts/alertsSlice'
import labs from './labs/labsSlice'
import huddle from './huddle/huddleSlice'
import someMokedStates from './someMokedStates/someMokedStatesSlice'
import management from './management/managemenSlice'
import operatingRoom from './operatingRoom/operatingRoomSlice'
import termsAndOther from './termsAndOther/termsAndOtherSlice'
import { useDispatch } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { departmentsApi } from './departmentTabs/departmentTabsSlice'
import { termsApi } from './terms/termsConditionsSlice'
import { visitsApi } from './visits/visitsApiSlice'
import { labsEndApi } from './labsEnd/labsEndSlice'
import { nsaApi } from './nsa/nsaSlice'
import { alayaCareApi } from './alayaCare/alayaCareSlice'
import { edLiveApi } from './edLive/edLiveApi'
import { criticalCareApi } from './criticalCare/criticalCareApi'
import { newVisitsApi } from './visitsApi/visitsApi'
import { orStatusApi } from './orStatus/OrStatusApi'
import { unitsApi } from './units/unitsApi'
import { corporateViewApi } from './corporateView/corporateViewApi'

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    // whitelist: ['theme', 'locale'],
    whitelist: ['userDetails'],
    blacklist: [
        'authenticate',
        'filters',
        'departmentsApi',
        'termsApi',
        'visitsApi',
        'patientsTable',
        'nursing',
        'tasks',
        'alerts',
        'patientModal',
        'labsSlice',
        'huddle',
        'management',
        'labsEndApi',
        'nsaApi',
        'operatingRoom',
        'someMokedStates',
        'termsAndOther',
    ],
}

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
    actionCreator: setAtiveUserRole,
    effect: async (action, listenerApi) => {
        // Run whatever additional side-effect-y logic you want here
        console.log('setAtiveUserRole: ', action)

        // Can cancel other running instances
        listenerApi.cancelActiveListeners()
    },
})

const rootReducer = combineReducers({
    authenticate,
    userDetails,
    filters,
    patientsTable,
    nursing,
    tasks,
    alerts,
    patientModal,
    labs,
    huddle,
    management,
    operatingRoom,
    someMokedStates,
    termsAndOther,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [labsEndApi.reducerPath]: labsEndApi.reducer,
    [termsApi.reducerPath]: termsApi.reducer,
    [visitsApi.reducerPath]: visitsApi.reducer,
    [nsaApi.reducerPath]: nsaApi.reducer,
    [alayaCareApi.reducerPath]: alayaCareApi.reducer,
    [edLiveApi.reducerPath]: edLiveApi.reducer,
    [criticalCareApi.reducerPath]: criticalCareApi.reducer,
    [newVisitsApi.reducerPath]: newVisitsApi.reducer,
    [orStatusApi.reducerPath]: orStatusApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [corporateViewApi.reducerPath]: corporateViewApi.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const storeRtk = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .prepend(thunkMiddleware) // Prepend additional middleware like thunk if needed
            .concat(
                departmentsApi.middleware,
                termsApi.middleware,
                visitsApi.middleware,
                labsEndApi.middleware,
                nsaApi.middleware,
                alayaCareApi.middleware,
                edLiveApi.middleware,
                criticalCareApi.middleware,
                newVisitsApi.middleware,
                orStatusApi.middleware,
                unitsApi.middleware,
                corporateViewApi.middleware
            ),
})

export const persistor = persistStore(storeRtk)

export type RootState = ReturnType<typeof storeRtk.getState>

type AppDispatch = typeof storeRtk.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export default storeRtk
