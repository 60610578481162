import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { departmentTabsURL } from '../../../utils/API'
import {
    inflowNewStructureMock,
    LengthofStayNewStructureMock,
    NEW_DEPARTMENTS_MOCK,
    NEW_OUTFLOW_DATA,
} from '../../../utils/ManagementStatusMock'
import { INFLOW_MOCK_DATA } from '../../../utils/__Mocks__/InflowMockdata'
import { INFLOW_PREDICTION_MOCK } from '../../../utils/__Mocks__/InflowPredictionMock'
import { INFECTION_MOCK_DATA } from '../../../utils/__Mocks__/InvectionMock'
import { LOS_PREDICTION_MOCK } from '../../../utils/__Mocks__/LoSPredictionMock'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import {
    Departments,
    LengthOfStayResponse,
    GetDepartmentsResponse,
    GetInflowResponse,
    Units,
    Checbox,
    OutflowsData,
    PredictionDataResponce,
    InfectionResponce,
} from './types'
import { createQueryFn } from '../helpers/createQueryFn'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

const unitsBaseURL = `https://backend-api.${
    isDevelopment ? 'dev' : 'prod'
}.sagit-labs.com/api/v1/units/`

export const departmentsApi = createApi({
    reducerPath: 'DepartmentsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: departmentTabsURL,
        prepareHeaders: (headers, { getState }) => {
            // const token = storeRtk.getState().authenticate.userToken
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }
            return headers
        },
    }),

    tagTypes: [
        'Departments',
        'DepartmentsTabs',
        'Inflow',
        'Lengthofstay',
        'Outflow',
        'Units',
        'LoSPredictions',
        'InflowPredictions',
        'Infections',
    ],
    endpoints: (builder) => ({
        getDepartments: builder.query<GetDepartmentsResponse, any>({
            query: () => ``,
            providesTags: [{ type: 'Departments', id: 'LIST_DEPARTMENT' }],
            // This is for mocking data
            transformResponse: (
                response: GetDepartmentsResponse
            ): GetDepartmentsResponse => {
                if (isDevelopment) {
                    return NEW_DEPARTMENTS_MOCK
                }
                return response
            },
        }),
        getDepartmentsTabs: builder.query<Departments, any>({
            query: () => `departmenttabs`,
            providesTags: [
                { type: 'DepartmentsTabs', id: 'LIST_DEPARTMENT_TABS' },
            ],
        }),
        geInflow: builder.query<GetInflowResponse, any>({
            query: () => `inflow`,
            providesTags: [{ type: 'Inflow', id: 'LIST_INFLOW' }],
            // This is for mocking data
            transformResponse: (
                response: GetInflowResponse
            ): GetInflowResponse => {
                if (isDevelopment) {
                    return INFLOW_MOCK_DATA
                }
                return response
            },
        }),
        getInflowPredictions: builder.query<PredictionDataResponce, any>({
            query: () => `inflow-predictions`,
            providesTags: [
                { type: 'InflowPredictions', id: 'INFLOW_PREDICTIONS' },
            ],
            // This is for mocking data
            transformResponse: (
                response: PredictionDataResponce
            ): PredictionDataResponce => {
                if (isDevelopment) {
                    return INFLOW_PREDICTION_MOCK
                }
                return response
            },
        }),

        getLengthofstay: builder.query<LengthOfStayResponse, any>({
            query: () => `lengthofstay`,
            providesTags: [{ type: 'Lengthofstay', id: 'LIST_LOS' }],
            //This is for mocking data
            transformResponse: (
                response: LengthOfStayResponse
            ): LengthOfStayResponse => {
                if (isDevelopment) {
                    return LengthofStayNewStructureMock
                }
                return response
            },
        }),
        getLoSPredictions: builder.query<PredictionDataResponce, any>({
            // This is for real data that doesn't work on dev, only on the product
            query: () => `LoSPredictions`,
            providesTags: [{ type: 'LoSPredictions', id: 'LOS_PREDICTION' }],
            // This is for mocking data
            transformResponse: (
                response: PredictionDataResponce
            ): PredictionDataResponce => {
                if (isDevelopment) {
                    return LOS_PREDICTION_MOCK
                }
                return response
            },
        }),
        getOutflow: builder.query<OutflowsData, {}>({
            query: () => `outflow`,
            providesTags: [{ type: 'Outflow', id: 'LIST_OUTFLOW' }],
            //This is for mocking data
            transformResponse: (response: OutflowsData): OutflowsData => {
                if (isDevelopment) {
                    return NEW_OUTFLOW_DATA
                }
                return response
            },
        }),
        getUnits: builder.query<Checbox[], string>({
            query: (DepartmentGroupName) => {
                return {
                    url: 'units',
                    params: { DepartmentGroupName },
                }
            },
            providesTags: [{ type: 'Units', id: 'LIST_UNITS' }],
            // This is for mocking data
            transformResponse: (response: Units): any => {
                const newUnits: Checbox[] = []
                response.units.forEach((unit, index) => {
                    newUnits.push({
                        id: index,
                        name: unit,
                        isChecked: true,
                    })
                })
                return newUnits
            },
        }),

        // getInfectionControl: builder.query<InfectionResponce, any>({
        //     // query: () => `infectionControl`,
        //     // providesTags: [{ type: 'Infections', id: 'LIST_INFECTION' }],
        //     // transformResponse: (response: InfectionResponce): InfectionResponce => {
        //     //     if (isDevelopment) {
        //     //         return INFECTION_MOCK_DATA
        //     //     }
        //     //     return response
        //     // },
        //     // This is for mocking data
        //     //@ts-ignore
        //     queryFn: createQueryFn(
        //         () => `${unitsBaseURL}infection-control`,
        //         () => INFECTION_MOCK_DATA
        //     ),
        // }),
    }),
})

export const {
    useGetDepartmentsQuery,
    useGetLengthofstayQuery,
    useGeInflowQuery,
    useGetDepartmentsTabsQuery,
    useGetOutflowQuery,
    useGetUnitsQuery,
    useGetLoSPredictionsQuery,
    useGetInflowPredictionsQuery,
    // useGetInfectionControlQuery,
} = departmentsApi
export const {
    useLazyGetDepartmentsQuery,
    useLazyGetDepartmentsTabsQuery,
    useLazyGetUnitsQuery,
} = departmentsApi
