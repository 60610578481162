import React from 'react'
import ProgressBarWithIcon from '../../../../common/ProgressBarWithIcon/ProgressBarWithIcon'

import styles from './ProgressCell.module.scss'
import { convertMinutesToHours } from 'utils/strings/stringToMinutes'

type ProgressCellProps = {
    delay: string | number
    time: number
    total: number
    timeDelayLevel: number
}

const TOTAL_NUMBER_MINUTES = 30

const ProgressCell: React.FC<ProgressCellProps> = ({
    delay,
    time,
    timeDelayLevel,
    total = 100,
}) => {
    const waitingTimeString = time ? convertMinutesToHours(time) : 0
    return (
        <div className={styles.item}>
            <div className={styles.item__bar}>
                <ProgressBarWithIcon
                    delay={`${30} M`}
                    time={time}
                    total={TOTAL_NUMBER_MINUTES}
                    delayLevel={timeDelayLevel}
                    innerText={`${time}`}
                />
            </div>
        </div>
    )
}

export default ProgressCell
