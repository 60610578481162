import React from 'react'
import styles from './WarningModal.module.scss'
import { Button, Modal } from '@mantine/core'
import { icons } from '../../utils/iconsMap'
import { modalMassage } from '../../utils/mockData'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectTheme } from '../../redux/rtk/user/selectors'

const WarningModal = ({
    setIsModalOpen,
    isModalOpen,
}: {
    setIsModalOpen: any
    isModalOpen: boolean
}): JSX.Element => {
    const theme = useSelector(selectTheme)
    const { t } = useTranslation()
    return (
        <Modal
            opened={isModalOpen}
            onClose={() => {
                setIsModalOpen(false)
                // ReduxHelper.setIn(['newUserAuthenticated'], false)
            }}
            centered
            classNames={{
                overlay: `${styles.modal_root} ${
                    theme === 'light'
                        ? styles.modal_root_light
                        : styles.modal_root_dark
                }`,
                // modal: `${styles.modal} ${
                //     theme === 'light'
                //         ? styles.modal_body_light
                //         : styles.modal_dark
                // }`,
            }}
            withCloseButton={false}
        >
            <div
                className={`${styles.modal_head} ${
                    theme === 'light'
                        ? styles.modal_head_light
                        : styles.modal_head_dark
                }`}
            >
                <img src={icons.attention} alt="attention" />
                <span>{modalMassage.label}</span>
            </div>
            <div
                className={`${styles.modal_body} ${
                    theme === 'light'
                        ? styles.modal_body_light
                        : styles.modal_body_dark
                }`}
            >
                {modalMassage.message}
            </div>
            <Button
                className={`${styles.modal_button} ${
                    theme === 'light'
                        ? styles.modal_button_light
                        : styles.modal_button_dark
                }`}
                onClick={() => {
                    setIsModalOpen(false)
                    // ReduxHelper.setIn(['newUserAuthenticated'], false)
                }}
            >
                {t('OK')}
            </Button>
        </Modal>
    )
}

export default WarningModal
