import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from '../helpers/prepareHeaders'
import {
    AdmissionType,
    DestinationAfterSurgeryType,
    SummaryType,
    OrInflowResponce,
    OrOutflowResponce,
    OutflowHistoricalResponce,
    SummariesListResponce,
    InflowHistoricalResponce,
    LosHistoricalResponce,
    WaitListNumbersResponse,
    FlowData,
} from './types'
import {
    OR_TRENDS_OUTFLOW_OR_MOCK,
    INFLOW_ER_MOCK,
    INFLOW_IP_ID_MOCK,
    INFLOW_IP_UNITS_MOCK,
    INFLOW_ODS_MOCK,
    OUTFLOW_MSICU_MOCK,
    OUTFLOW_PERI_OP_MOCK,
    OUTFLOW_CVICU_MOCK,
    OUTFLOW_FBC_MOCK,
    OUTFLOW_UNK_MOCK,
    OUTFLOW_ROOM_MOCK,
    OUTFLOW_PACU_MOCK,
    OUTFLOW_HOME_MOCK,
    SUMMARIES_CASE_CANSELED_MOCK,
    SUMMARIES_ON_TIME_START_MOCK,
    SUMMARIES_ON_TIME_FINMISH_MOCK,
    SUMMARIES_NUMBER_ROOM_RUNNING_MOCK,
    SUMMARIES_ROOMS_RUNNING_LATE_MOCK,
    SUMMARIES_OR_UTILIZATION_RATE_MOCK,
    SUMMARIES_AVERAGE_ROOM_TURNOVER_MOCK,
    OR_TRENDS_INFLOW_OR_MOCK,
    OR_TRENDS_LOS_OR_MOCK,
    WAIT_LIST_NUMBERS_MOCK,
    CURRENT_FLOW_MOCK,
} from '../../../utils/__Mocks__/ORStatusesMock'
import { createQueryFn } from '../helpers/createQueryFn'

//temporary or for mocking data Opera/inflow
const MocktOrInflowData = {
    ODS: INFLOW_ODS_MOCK,
    SDS: INFLOW_ER_MOCK,
    'IP-UNITS': INFLOW_IP_UNITS_MOCK,
    'IP-ED': INFLOW_IP_ID_MOCK,
}

//temporary or for mocking data Opera/outflow
const MocktOrOutflowData = {
    MSICU: OUTFLOW_MSICU_MOCK,
    PERIOP: OUTFLOW_PERI_OP_MOCK,
    CVICU: OUTFLOW_CVICU_MOCK,
    OTHER: OUTFLOW_MSICU_MOCK,
    FBC: OUTFLOW_FBC_MOCK,
    UNK: OUTFLOW_UNK_MOCK,
    ROOM: OUTFLOW_ROOM_MOCK,
    PACU: OUTFLOW_PACU_MOCK,
    HOME: OUTFLOW_HOME_MOCK,
}

//temporary or for mocking data Opera/summaries/list
const SummariesListData = {
    CaseCancelled: SUMMARIES_CASE_CANSELED_MOCK,
    OnTimeStart: SUMMARIES_ON_TIME_START_MOCK,
    OnTimeFinish: SUMMARIES_ON_TIME_FINMISH_MOCK,
    NumberRoomRunning: SUMMARIES_NUMBER_ROOM_RUNNING_MOCK,
    RoomsRunningLate: SUMMARIES_ROOMS_RUNNING_LATE_MOCK,
    OrUtilizationRate: SUMMARIES_OR_UTILIZATION_RATE_MOCK,
    AverageRoomTurnover: SUMMARIES_AVERAGE_ROOM_TURNOVER_MOCK,
}

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

const baseURL = `https://backend-api.${
    isDevelopment ? 'dev' : 'prod'
}.sagit-labs.com/api/Opera`

export const orStatusApi = createApi({
    reducerPath: 'OrStatusApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: `https://backend-api.${
            isDevelopment ? 'dev' : 'prod'
        }.sagit-labs.com/api/Opera`,
        prepareHeaders,
    }),

    tagTypes: [
        'orInflow',
        'outflowHistorical',
        'losHistorical',
        'inflowHistorical',
        'orOutflow',
        'summaryList',
        'waitlistnumbers',
        'currentFlow',
    ],
    // transformResponse: (response: ResusResponce): ResusResponce => {
    //     if (isDevelopment) {
    //         return resusMock
    //     }
    //     return response
    // },
    endpoints: (builder) => ({
        getOrInflowData: builder.query<OrInflowResponce, AdmissionType>({
            // query: (admissionType) => `/inflow?admissionType=${admissionType}`,
            // providesTags: ['orInflow'],
            //temporary or for mocking data Opera/inflow
            //@ts-ignore
            queryFn: createQueryFn(
                (admissionType: AdmissionType) =>
                    `${baseURL}/inflow?admissionType=${admissionType}`,
                (admissionType: AdmissionType) =>
                    MocktOrInflowData[admissionType]
            ),
        }),
        getOrOutflowData: builder.query<
            OrOutflowResponce,
            DestinationAfterSurgeryType
        >({
            // query: (destinationAfterSurgeryType) =>
            //     `/inflow?destinationAfterSurgeryType=${destinationAfterSurgeryType}`,
            // providesTags: ['orOutflow'],
            //temporary or for mocking data Opera/outflow
            //@ts-ignore
            queryFn: createQueryFn(
                (destinationAfterSurgeryType: DestinationAfterSurgeryType) =>
                    `${baseURL}/outflow?destinationAfterSurgery=${destinationAfterSurgeryType}`,
                (destinationAfterSurgeryType: DestinationAfterSurgeryType) =>
                    MocktOrOutflowData[destinationAfterSurgeryType]
            ),
        }),
        getOutflowHistorical: builder.query<OutflowHistoricalResponce, void>({
            // query: () => `/historical/outflow`,
            // providesTags: ['outflowHistorical'],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${baseURL}/historical/outflow`,
                () => OR_TRENDS_OUTFLOW_OR_MOCK
            ),
        }),
        getLosHistorical: builder.query<LosHistoricalResponce, void>({
            // query: () => `/historical/los`,
            // providesTags: ['losHistorical'],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${baseURL}/historical/los`,
                () => OR_TRENDS_LOS_OR_MOCK
            ),
        }),
        getInflowHistorical: builder.query<InflowHistoricalResponce, void>({
            // query: () => `/historical/inflow`,
            // providesTags: ['inflowHistorical'],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${baseURL}/historical/inflow`,
                () => OR_TRENDS_INFLOW_OR_MOCK
            ),
        }),
        getOrEfficiency: builder.query<SummariesListResponce, SummaryType>({
            // query: (summaryType) => `/summaries/list?summaryType=${summaryType}`,
            // providesTags: ['summaryList'],
            //temporary or for mocking data Opera/summaries/list
            //@ts-ignore
            queryFn: createQueryFn(
                (summaryType: SummaryType) =>
                    `${baseURL}/summaries/list?summaryType=${summaryType}`,
                (summaryType: SummaryType) => SummariesListData[summaryType]
            ),
        }),

        getWaitListNumbers: builder.query<WaitListNumbersResponse, void>({
            // query: (summaryType) => `/summaries/waitlistnumbers`,
            // providesTags: ['waitlistnumbers'],
            //temporary or for mocking data Opera/summaries/waitlistnumbers
            //@ts-ignore
            queryFn: createQueryFn(
                (summaryType: SummaryType) =>
                    `${baseURL}/summaries/waitlistnumbers`,
                (summaryType: SummaryType) => WAIT_LIST_NUMBERS_MOCK
            ),
        }),

        getCurrentFlowInOr: builder.query<FlowData, void>({
            // query: () => `/current-flow'`,
            // providesTags: ['currentFlow''],
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${baseURL}/current-flow`,
                () => CURRENT_FLOW_MOCK
            ),
        }),
    }),
})

export const {
    useGetOrInflowDataQuery,
    useGetOutflowHistoricalQuery,
    useGetLosHistoricalQuery,
    useGetInflowHistoricalQuery,
    useGetOrOutflowDataQuery,
    useGetOrEfficiencyQuery,
    useGetWaitListNumbersQuery,
    useGetCurrentFlowInOrQuery,
} = orStatusApi
