import { Button, Menu } from '@mantine/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelectedButtons } from '../../../contexts/SelectedButtonsContext'
import { icons } from '../../../utils/iconsMap'
import FiltersButton from '../../common/FiltersButton/FiltersButton'
import MenuPopup from '../../common/MenuPopup/MenuPopup'
import {
    ButtonDataWithChecked,
    originFilters,
} from '../AdmissionsFilterOptions'

import styles from './OriginFilters.module.scss'
import OriginFiltersFooter from './OriginFiltersFooter/OriginFiltersFooter'
import OriginFiltersList from './OriginFiltersList/OriginFiltersList'

type OriginFiltersProps = {
    filterId: string
}

type Filter = {
    id: string
    value: string[]
}

function findValuesById(id: string, filters: Filter[]): string[] | [] {
    const filter = filters.find((filter) => filter.id === id)
    return filter ? filter.value : []
}

function updateFilterCheckStatus(
    originFilters: ButtonDataWithChecked[],
    selectedButtons: string[]
) {
    // If selectedButtons is empty, set all to isChecked true
    if (selectedButtons.length === 0) {
        return originFilters.map((filter) => ({ ...filter, isChecked: true }))
    }

    // Otherwise, update isChecked based on whether the name is in selectedButtons
    return originFilters.map((filter) => ({
        ...filter,
        isChecked: selectedButtons.includes(filter.name),
    }))
}

const OriginFilters: React.FC<OriginFiltersProps> = ({ filterId }) => {
    const { selectedButtons, handleButtonClick } = useSelectedButtons()
    const [opened, setOpened] = useState(false)

    const [checkboxes, setCheckboxes] = useState(originFilters)

    useEffect(() => {
        const valuesList = findValuesById(filterId, selectedButtons)
        if (valuesList?.length === 0) {
            setCheckboxes(originFilters)
        }
        const updatedFilters = updateFilterCheckStatus(
            originFilters,
            valuesList
        )
        setCheckboxes(updatedFilters)
    }, [opened])

    const submitOriginHandler = useCallback(() => {
        const checkedNames = checkboxes
            .filter((filter) => filter.isChecked)
            .map((filter) => filter.name)
        if (checkedNames.length !== 0) handleButtonClick(filterId, checkedNames)
    }, [checkboxes])

    const resetOriginHandler = useCallback(() => {
        setCheckboxes(originFilters)
        handleButtonClick(filterId, ['reset all filters'])
    }, [checkboxes])

    const canselOriginHandler = () => {
        setCheckboxes(originFilters)
        setOpened(false)
    }

    const handleCheckboxChange = (name: string, isChecked: boolean) => {
        let updatedFilters = checkboxes.map((item) =>
            item.name === name ? { ...item, isChecked: !item.isChecked } : item
        )

        if (name === 'selectAll') {
            // Update all items to match the isChecked value of 'selectAll'
            updatedFilters = checkboxes.map((item) => ({
                ...item,
                isChecked: !isChecked, // Toggle the current state
            }))
        } else {
            // Update isChecked status for the changed item
            // updatedFilters = checkboxes.map((item) => {
            //     if (item.name === name) {
            //         return { ...item, isChecked: !item.isChecked }
            //     }
            //     return item
            // })

            // Update 'Select All' based on whether all other items are checked
            const allChecked = updatedFilters.every(
                (item) => item.name === 'selectAll' || item.isChecked
            )
            updatedFilters = updatedFilters.map((item) =>
                item.name === 'selectAll'
                    ? { ...item, isChecked: allChecked }
                    : item
            )
        }

        // Update local state
        setCheckboxes(updatedFilters)
    }

    return (
        <MenuPopup
            // theme="dark"
            position="bottom-start"
            transitionProps={{
                transition: 'scale-y',
                duration: 150,
            }}
            closeOnEscape={false}
            opened={opened}
            onChange={setOpened}
            closeCb={setOpened}
            // customClassNames={{ dropdown: 'your-custom-class' }}
        >
            <Menu.Target>
                <div style={{ width: '120px' }}>
                    <FiltersButton
                        radius="xl"
                        size="lg"
                        fullWidth
                        justify="space-between"
                        variant="filter-active"
                        styles={{
                            inner: {
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            },
                        }}
                        rightSection={
                            <img
                                src={icons.filter_arrows}
                                className={styles.button__img}
                                alt="sort icons"
                            />
                        }
                    >
                        Origin
                    </FiltersButton>
                </div>
            </Menu.Target>
            <Menu.Dropdown>
                <OriginFiltersList
                    checkboxes={checkboxes}
                    handleCheckboxChange={handleCheckboxChange}
                />
                <OriginFiltersFooter
                    submit={submitOriginHandler}
                    reset={resetOriginHandler}
                    cansel={canselOriginHandler}
                />
            </Menu.Dropdown>
        </MenuPopup>
    )
}

export default OriginFilters
