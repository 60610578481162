import React from 'react'
import { NumberInput } from '@mantine/core'
import TitleVsTextBox from '../../TitleVsTextBox/TitleVsTextBox'

import styles from './TimeDelay.module.scss'

type TimeDelayProps = {
    value: number
    cb: (time: number) => void
}

const TimeDelay: React.FC<TimeDelayProps> = ({ value, cb }) => {
    return (
        <TitleVsTextBox
            header={'delay'}
            children={
                <NumberInput
                    defaultValue={value}
                    // onChange={(val: number) => cb(val)}
                    min={0}
                    // disabled={revisitPredictionDays ? true : false}
                    classNames={{
                        root: `${styles.root}`,
                        input: `${styles.control_input}`,
                        control: `${styles.control_class}`,
                        // controlUp: `${styles.control_up_class}`,
                        // controlDown: `${styles.control_down_class}`,
                    }}
                />
            }
        />
    )
}

export default TimeDelay
