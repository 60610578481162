import Keycloak from 'keycloak-js'

const baseKeycloakURL = process.env.REACT_APP_KEYCLOAK_HOST
const keycloakRealm: string = process.env.REACT_APP_KEYCLOAK_REALM as string
const keycloakClientId: string = process.env
    .REACT_APP_KEYCLOAK_CLIENT_ID as string

export const keycloak = new Keycloak({
    url: baseKeycloakURL,
    realm: keycloakRealm,
    clientId: keycloakClientId,
})

