import React from 'react'
import { useTranslation } from 'react-i18next'
import CcTitle from '../../common/CcTitle/CcTitle'
import CustomScrollArea from '../../common/CustomScrollArea/CustomScrollArea'
import OrSectionHeaders from '../OrSectionHeaders/OrSectionHeaders'
import OrOutflowList from './OrOutflowList/OrOutflowList'

import styles from './ORStatusOutflow.module.scss'
import GridHeader from 'components/common/GridHeader/GridHeader'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const ORStatusOutflow = () => {
    const { t } = useTranslation()

    const inflowHeaders = ['MRN', 'Transfer location', 'Transfer delay']
    return (
        <div className={styles.container}>
            <CcTitle style={{ paddingLeft: '3rem' }}>
                {t('Outflow from OR')}
                <CorporateDocLink
                    baseKey="operatingRoom"
                    sectionKey="OrOutflow"
                />
            </CcTitle>
            <div className={styles.container__body}>
                {/* <OrSectionHeaders
                    items={inflowHeaders}
                    style={{
                        // gridTemplateColumns: '1fr 2fr 1fr ',
                        gridTemplateColumns:
                            ' minmax(60px, 1fr) minmax(120px, 2fr) minmax(90px, 1.5fr)',
                        marginRight: '5.2rem',
                    }}
                /> */}
                <GridHeader
                    style={{
                        gridTemplateColumns:
                            ' minmax(60px, 1fr)  minmax(90px, 1.5fr) minmax(120px, 2fr)',
                        marginRight: '5.2rem',
                    }}
                >
                    <div style={{ textAlign: 'left' }}>{t('MRN')}</div>
                    <div style={{ textAlign: 'left' }}>
                        {t('Transfer location')}
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        {t('Time since end of surgery')}
                    </div>
                </GridHeader>
                <CustomScrollArea.Autosize mah={'25vh'} mx="auto">
                    <OrOutflowList
                        title={'MSICU'}
                        destinationAfterSurgeryType={'MSICU'}
                    />
                    <OrOutflowList
                        title={'PACU'}
                        destinationAfterSurgeryType={'PACU'}
                    />{' '}
                    <OrOutflowList
                        title={'PERI-OP'}
                        destinationAfterSurgeryType={'PERIOP'}
                    />
                    <OrOutflowList
                        title={'OTHER'}
                        destinationAfterSurgeryType={'OTHER'}
                    />
                </CustomScrollArea.Autosize>
            </div>
        </div>
    )
}

export default ORStatusOutflow
