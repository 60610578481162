export interface UserInfo {
    userName: string
    name: string
    language: number
    colorTheme: number
    lastLoginDate: string
    roles: [string]
    role: string
};

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
}

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum Locale {
    EN = 'en',
    FR = 'fr',
}


export interface UserInfoSliceState {
    userDetails: UserInfo;
    status: Status,
    theme: Theme,
    locale: Locale,
    activeUserRole: string
}