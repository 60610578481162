import React, { useMemo } from 'react'
import { useGetOperatingRoomInflowDataQuery } from '../../../../redux/rtk/criticalCare/criticalCareApi'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CustomAccordion from '../../../common/CustomAccordion/CustomAccordion'
import { icons } from '../../../../utils/iconsMap'
import OrListItem from './OrListItem/OrListItem'

import styles from './OperatingRoom.module.scss'
import { useTranslation } from 'react-i18next'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const OperatingRoom: React.FC = () => {
    const { data, error, isFetching, isSuccess } =
        useGetOperatingRoomInflowDataQuery(undefined, {
            pollingInterval: 300000,
        })

    const { t } = useTranslation()

    const orItems = useMemo(() => {
        if (!isSuccess || !data?.operatingRoomCurrentInflows) return []

        return data?.operatingRoomCurrentInflows?.map((item) => ({
            ...item,
        }))
    }, [isSuccess, data])

    const renderItems = (data: any) => (
        <CustomAccordion.Item key="operatingRoom" value="operatingRoom">
            <CustomAccordion.Control>
                <CcTitle>
                    {t('cc.inflow.Operating Room')} ({orItems?.length || 0}){' '}
                    <CorporateDocLink
                        baseKey="criticalCare"
                        sectionKey="inflowOperatingRoom"
                    />
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {orItems?.length > 0 ? (
                        orItems?.map((item, index) => (
                            <OrListItem {...item} key={index} />
                        ))
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )
    return (
        <div>
            <CustomAccordion
                chevronPosition="left"
                defaultValue="operatingRoom"
                chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
            >
                {renderItems(data)}
            </CustomAccordion>
        </div>
    )
}

export default OperatingRoom
