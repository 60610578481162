import { generateDate } from '../date/generateDate'

export const NEW_MSSS_NSA_MOCK = {
    categories: [
        {
            name: 'Var 08b - Evaluation in Progress',
            patientsCount: 15,
            outPatientsCount: 4,
            ccomtl: 53,
            hoursDelay: 7,
            hoursDelayPercent: 37,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 1,
                },
                {
                    facility: 'Hôpital Richardson',
                    count: 3,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 8,
                },
                {
                    date: generateDate(5),
                    count: 8,
                },
                {
                    date: generateDate(4),
                    count: 8,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 14,
                },
                {
                    date: generateDate(5),
                    count: 16,
                },
                {
                    date: generateDate(4),
                    count: 18,
                },
                {
                    date: generateDate(3),
                    count: 16,
                },
                {
                    date: generateDate(2),
                    count: 16,
                },
                {
                    date: generateDate(1),
                    count: 16,
                },
                {
                    date: generateDate(0),
                    count: 15,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 10,
                },
                {
                    date: generateDate(5),
                    count: 9,
                },
                {
                    date: generateDate(4),
                    count: 9,
                },
                {
                    date: generateDate(3),
                    count: 8,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 4,
                },
            ],
        },
        {
            name: 'Var 08c - Orientation Pending',
            patientsCount: 12,
            outPatientsCount: 2,
            ccomtl: 29,
            hoursDelay: 5,
            hoursDelayPercent: 36,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 2,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 5,
                },
                {
                    date: generateDate(4),
                    count: 5,
                },
                {
                    date: generateDate(3),
                    count: 5,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 5,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 14,
                },
                {
                    date: generateDate(5),
                    count: 8,
                },
                {
                    date: generateDate(4),
                    count: 12,
                },
                {
                    date: generateDate(3),
                    count: 15,
                },
                {
                    date: generateDate(2),
                    count: 14,
                },
                {
                    date: generateDate(1),
                    count: 14,
                },
                {
                    date: generateDate(0),
                    count: 12,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 1,
                },
                {
                    date: generateDate(5),
                    count: 1,
                },
                {
                    date: generateDate(4),
                    count: 1,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
        },
        {
            name: 'Var 09 - Home Services required',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 50,
            hoursDelay: 1,
            hoursDelayPercent: 17,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 3,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 1,
                },
                {
                    date: generateDate(0),
                    count: 1,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 11,
                },
                {
                    date: generateDate(5),
                    count: 11,
                },
                {
                    date: generateDate(4),
                    count: 10,
                },
                {
                    date: generateDate(3),
                    count: 10,
                },
                {
                    date: generateDate(2),
                    count: 8,
                },
                {
                    date: generateDate(1),
                    count: 6,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 10a - Intense rehab',
            patientsCount: 7,
            outPatientsCount: 0,
            ccomtl: 57,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 9,
                },
                {
                    date: generateDate(5),
                    count: 12,
                },
                {
                    date: generateDate(4),
                    count: 10,
                },
                {
                    date: generateDate(3),
                    count: 4,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 7,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 10b - Moderate Rehab',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 17,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 1,
                },
                {
                    date: generateDate(5),
                    count: 1,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 6,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 6,
                },
                {
                    date: generateDate(3),
                    count: 4,
                },
                {
                    date: generateDate(2),
                    count: 4,
                },
                {
                    date: generateDate(1),
                    count: 4,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 11 - Palliative',
            patientsCount: 2,
            outPatientsCount: 0,
            ccomtl: 50,
            hoursDelay: 2,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            inPatientsStatistics: [
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 4,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 4,
                },
            ],
            outPatientsStatistics: [
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 12a - Ressources de type Famille ou Intermediaire RTF/RI',
            patientsCount: 2,
            outPatientsCount: 0,
            ccomtl: 0,
            hoursDelay: 2,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 2,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 12b - Long term care (Not charged)',
            patientsCount: 23,
            outPatientsCount: 5,
            ccomtl: 64,
            hoursDelay: 19,
            hoursDelayPercent: 68,
            hoursDelays: [],
            outPatients: [
                {
                    facility: 'CH Catherine Booth',
                    count: 4,
                },
                {
                    facility: 'Hôpital Richardson',
                    count: 1,
                },
            ],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 26,
                },
                {
                    date: generateDate(5),
                    count: 24,
                },
                {
                    date: generateDate(4),
                    count: 21,
                },
                {
                    date: generateDate(3),
                    count: 20,
                },
                {
                    date: generateDate(2),
                    count: 20,
                },
                {
                    date: generateDate(1),
                    count: 20,
                },
                {
                    date: generateDate(0),
                    count: 19,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 30,
                },
                {
                    date: generateDate(5),
                    count: 29,
                },
                {
                    date: generateDate(4),
                    count: 26,
                },
                {
                    date: generateDate(3),
                    count: 26,
                },
                {
                    date: generateDate(2),
                    count: 26,
                },
                {
                    date: generateDate(1),
                    count: 26,
                },
                {
                    date: generateDate(0),
                    count: 23,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 4,
                },
                {
                    date: generateDate(4),
                    count: 4,
                },
                {
                    date: generateDate(3),
                    count: 5,
                },
                {
                    date: generateDate(2),
                    count: 5,
                },
                {
                    date: generateDate(1),
                    count: 5,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
        },
        {
            name: 'Var 14 - Sante Mentale',
            patientsCount: 6,
            outPatientsCount: 0,
            ccomtl: 33,
            hoursDelay: 6,
            hoursDelayPercent: 100,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 7,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 7,
                },
                {
                    date: generateDate(5),
                    count: 7,
                },
                {
                    date: generateDate(4),
                    count: 7,
                },
                {
                    date: generateDate(3),
                    count: 7,
                },
                {
                    date: generateDate(2),
                    count: 7,
                },
                {
                    date: generateDate(1),
                    count: 7,
                },
                {
                    date: generateDate(0),
                    count: 6,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Var 15b - User awaiting housing order',
            patientsCount: 3,
            outPatientsCount: 0,
            ccomtl: 100,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 3,
                },
                {
                    date: generateDate(5),
                    count: 3,
                },
                {
                    date: generateDate(4),
                    count: 3,
                },
                {
                    date: generateDate(3),
                    count: 3,
                },
                {
                    date: generateDate(2),
                    count: 3,
                },
                {
                    date: generateDate(1),
                    count: 3,
                },
                {
                    date: generateDate(0),
                    count: 3,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
        {
            name: 'Other',
            patientsCount: 1,
            outPatientsCount: 0,
            ccomtl: 0,
            hoursDelay: 0,
            hoursDelayPercent: 0,
            hoursDelays: [],
            outPatients: [],
            hoursDelaysStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
            inPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 2,
                },
                {
                    date: generateDate(5),
                    count: 2,
                },
                {
                    date: generateDate(4),
                    count: 2,
                },
                {
                    date: generateDate(3),
                    count: 2,
                },
                {
                    date: generateDate(2),
                    count: 2,
                },
                {
                    date: generateDate(1),
                    count: 2,
                },
                {
                    date: generateDate(0),
                    count: 1,
                },
            ],
            outPatientsStatistics: [
                // {
                //     "date": generateDate(7),
                //     "count": 8
                // },
                {
                    date: generateDate(6),
                    count: 0,
                },
                {
                    date: generateDate(5),
                    count: 0,
                },
                {
                    date: generateDate(4),
                    count: 0,
                },
                {
                    date: generateDate(3),
                    count: 0,
                },
                {
                    date: generateDate(2),
                    count: 0,
                },
                {
                    date: generateDate(1),
                    count: 0,
                },
                {
                    date: generateDate(0),
                    count: 0,
                },
            ],
        },
    ],
}
