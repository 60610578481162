import React from 'react'
import WidgetTitle from '../WidgetTitle/WidgetTitle'

interface StyledWidgetTitleProps {
    style?: React.CSSProperties
    children: React.ReactNode
}

const defaultStyle: React.CSSProperties = {
    color: '#B0D3E7',
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
    paddingBottom: 0,
    fontSize: '14px',
}

const CcTitle: React.FC<StyledWidgetTitleProps> = ({ style, children }) => {
    const combinedStyle = { ...defaultStyle, ...style }
    return <WidgetTitle style={combinedStyle}>{children}</WidgetTitle>
}

export default CcTitle
