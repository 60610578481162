import React, { useEffect } from 'react'

import styles from './ProgersBar.module.scss'

type ProgersBarProps = {
    value: number | string
    total: number | string
    color?: string
    predicted?: number | string
    showValue?: boolean
    innerText?: string | number
}

const colorsMap = {
    red: 'linear-gradient(187.26deg, #CB4444 8.68%, #DC6B6B 94.35%)',
    green: '#7DCC74',
    orange: '#F8A95E',
    blue: 'linear-gradient(101.84deg, #3E69A4 8.73%, #3F7DAB 91.4%)',
}

const getBackgroundColor = (percentage: number) => {
    if (percentage > 75) return colorsMap.red
    if (percentage > 50) return colorsMap.orange
    return colorsMap.green
}

const ProgersBar: React.FC<ProgersBarProps> = ({
    value,
    total,
    color,
    predicted,
    showValue = true,
    innerText,
}) => {
    const percentage = (Number(value) / Number(total)) * 100

    const progressBarStyle = {
        width: `${percentage}%`,
        // background: color ? colorsMap[color] : getBackgroundColor(percentage),
        minWidth: `${innerText ? '20px' : '0px'}`,
        background: color ? color : getBackgroundColor(percentage),
    }
    const isTextLong = innerText && innerText?.toString().length > 2
    return (
        <div className={styles.container_progress_bar} style={progressBarStyle}>
            {innerText && (
                <span
                    className={styles.container_progress_bar__text}
                    style={{ fontSize: isTextLong ? '9px' : '10px' }}
                >
                    {innerText || value}
                </span>
            )}
        </div>
    )
}

export default ProgersBar
