import { createSlice } from '@reduxjs/toolkit'
import {
    erStatusConsultationTableMockData,
    erStatusMainTableMockData,
} from '../../../utils/__Mocks__/erStatusMockData'
import {
    imagineResourcesFilters,
    imagineTableMockData,
} from '../../../utils/__Mocks__/imaginePageMockData'

const initialState = {
    erStatusConsultationTable: erStatusConsultationTableMockData,
    erStatusWaitingPatients: erStatusMainTableMockData,
    imagineResourcesPatients: imagineTableMockData,
    imagineResourcesPatientsFiltered: [],
    imagineResourcesFilters: imagineResourcesFilters,
}

const someMokedStatesSlice = createSlice({
    name: 'someMokedStates',
    initialState,
    reducers: {
        setErStatusConsultationTable(state, action) {
            state.erStatusConsultationTable = action.payload
        },
        setErStatusWaitingPatients(state, action) {
            state.erStatusWaitingPatients = action.payload
        },

        setImagineResourcesPatients(state, action) {
            state.imagineResourcesPatients = action.payload
        },
        setImagineResourcesFilters(state, action) {
            // state.imagineResourcesFilters = action.payload
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(getOrStatus.pending, (state, action) => {
        //     state.shiftsStatus = orStatus.LOADING;
        //     state.shifts = initialState.orStatus;
        // });
    },
})

export const {
    setErStatusConsultationTable,
    setErStatusWaitingPatients,
    setImagineResourcesPatients,
    setImagineResourcesFilters,
} = someMokedStatesSlice.actions

export default someMokedStatesSlice.reducer
