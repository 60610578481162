import {
    FloatingPosition,
    Popover,
    PopoverProps,
    PopoverTarget,
} from '@mantine/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../redux/rtk/user/selectors'

import styles from './PopoverWindow.module.scss'

// export interface IPopoverWindowProps {
//     className?: any
//     children?: any
//     target?: any
//     opened: boolean
//     theme?: string
//     setOpened?: (el: boolean) => void
//     truthyValue?: boolean
//     width?: number
//     // position?: 'right' | 'top' | 'top-start' | 'left' | 'bottom'
//     position?: FloatingPosition
//     withArrow?: boolean
//     offset?:number
// }

interface IPopoverWindowProps extends PopoverProps {
    className?: any
    target: any
    // opened: boolean
    truthyValue?: boolean
    theme?: string
    // Add more props as needed
}

const PopoverWindow: React.FC<IPopoverWindowProps> = ({
    className,
    children,
    target,
    // opened,
    position,
    truthyValue,
    // withArrow,
    ...props
}) => {
    const theme = useSelector(selectTheme)
    return (
        <Popover
            // opened={opened}
            transitionProps={{
                transition: 'scale-x',
                duration: 150,
            }}
            // width={260}
            position={position ? position : 'right'}
            // placement="center"
            // withArrow={withArrow}
            classNames={{
                // root: `${styles[theme + '-theme']} ${styles.popover_root}`,
                dropdown: `${styles[theme + '-theme']} ${styles.popover_body}`,
                arrow: styles.popover_arrow,
                // body: `${styles[theme + '-theme']} ${styles.popover_body}`,
                // inner: styles.popover_inner,
            }}
            {...props}
        >
            <Popover.Target>{target}</Popover.Target>
            <Popover.Dropdown>{children}</Popover.Dropdown>
        </Popover>
    )
}

export default PopoverWindow
