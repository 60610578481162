export interface Shift {
    beds: string
    nursId: number
    nurseName: string
}

export interface ShiftsData {
    shifts: Shift[]
}

export interface Nurse {
    id: number
    name: string
}

export interface NursesData {
    nurses: Nurse[]
}

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
}