import React from 'react'
import { getTrendColor, getUnitsLegendsMap } from '../helpers'
import { useTranslation } from 'react-i18next'

import styles from './ColorLegend.module.scss'

type ColorLegendProps = {
    legends: Array<string>
}

const ColorLegend: React.FC<ColorLegendProps> = ({ legends }) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            {legends.map((legend) => (
                <div className={styles.container__item} key={legend}>
                    <div
                        className={styles.container__item__box}
                        style={{
                            backgroundColor: getTrendColor(legend),
                        }}
                    />
                    <span className={styles.container__item__lable}>
                        {/* {t(getUnitsLegendsMap(legend))} */}
                        {t(legend)}
                    </span>
                </div>
            ))}
        </div>
    )
}

export default ColorLegend
