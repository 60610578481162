import React, { useMemo } from 'react'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CustomAccordion from '../../../common/CustomAccordion/CustomAccordion'
import { useGetResusPodsInflowDataQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import CcListItem from '../CcListItem/CcListItem'
import { useTranslation } from 'react-i18next'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'

import { icons } from '../../../../utils/iconsMap'
import styles from './EDPods.module.scss'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const EDPods: React.FC = () => {
    const { data, error, isFetching, isSuccess } =
        useGetResusPodsInflowDataQuery('PODS')
    const { t } = useTranslation()
    const resusItems = useMemo(() => {
        if (!isSuccess || !data?.resusList) return []

        return data?.resusList?.map((item) => ({
            ...item,
        }))
    }, [isSuccess, data])

    const renderItems = () => (
        <CustomAccordion.Item key="ed" value="ed">
            <CustomAccordion.Control>
                <CcTitle>
                    {t('cc.inflow.ED Pods')} ({resusItems?.length}){' '}
                    <CorporateDocLink
                        baseKey="criticalCare"
                        sectionKey="inflowPods"
                    />
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {resusItems?.length > 0 ? (
                        resusItems?.map((item, index) => (
                            <CcListItem {...item} key={index} />
                        ))
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <div>
            <CustomAccordion
                chevronPosition="left"
                defaultValue="ed"
                chevron={<img src={icons.chevron} alt="sort arrow" />}
            >
                {renderItems()}
            </CustomAccordion>
        </div>
    )
}
export default EDPods
