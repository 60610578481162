import React from 'react'
import PopoverWindow from 'components/common/PopoverWindow/PopoverWindow'
import useHoverWithDelay from 'hooks/useHoverWithDelay'

import styles from './PopupCell.module.scss'

type PopupProps = {
    target: React.ReactNode
    data: string
    title?: string
}

const PopupCell: React.FC<PopupProps> = ({ target, data, title }) => {
    const { isHovered, handleMouseEnter, handleMouseLeave } = useHoverWithDelay(
        100,
        100
    )
    return (
        <PopoverWindow
            opened={isHovered}
            truthyValue={isHovered}
            position="right-end"
            offset={-20}
            target={
                <div
                    className={styles.item__target}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    {target ?? '--'}
                </div>
            }
        >
            <div className={styles.item__body}>
                <div className={styles.item__body__content}>
                    {isHovered && data}
                </div>
            </div>
        </PopoverWindow>
    )
}

export default PopupCell
