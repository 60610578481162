import React from 'react'
import { Button } from '@mantine/core'
import { icons } from '../../../utils/iconsMap'

import styles from './ExternalLinkButton.module.scss'

type ExternalLinkButtonProps = {
    url?: string
    ariaLabel?: string
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({
    url,
    ariaLabel,
}) => {
    const handleClick = () => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    return (
        <Button
            justify="center"
            onClick={handleClick}
            classNames={{ inner: styles.button_inner }}
            leftSection={<img src={icons.info} alt={`info icon`} />}
            variant="transparent"
        >
            {ariaLabel || ''}
        </Button>
    )
}

export default ExternalLinkButton
