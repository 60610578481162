import { createRoot } from 'react-dom/client'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import './index.scss'
import App from './App'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)
