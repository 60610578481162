import React from 'react'
import { calculatePosition, calculateWidth } from '../helpers'

import styles from './EventBox.module.scss'

enum Statuses {
    'free' = 'free',
    'setup' = 'setup',
    'procedure' = 'procedure',
    'clearance' = 'clearance',
    'predirect delay' = 'predirect_delay',
    'reserved for emergency' = 'reserved_for_emergency',
    'emergency procedure' = 'emergency_procedure',
    'slack' = 'slack',
    'risk for delay' = 'risk_for_delay',
    'actual delay 1' = 'actual_delay_1',
    'actual delay 2' = 'actual_delay_2',
}

type EventBox = {
    item: any
    startTime: number
    endTime: number
    handleSelectedBox: (el: number) => void
    selectedBox: number | null
}

const EventBox: React.FC<EventBox> = ({
    item,
    startTime,
    endTime,
    handleSelectedBox,
    selectedBox,
}) => {
    const left = calculatePosition(item.start, startTime, endTime)
    const width = calculateWidth(item.start, item.end, startTime, endTime)
    // const [selectedBox, setSelectedBox] = useState<null | number>(null)

    // const handleSelectedBox = (index: number) => {
    //     setSelectedBox(index)
    // }

    const style = {
        // left: `${left}%`,
        width: `${width}%`,
    }
    if (!(item.status in Statuses)) {
        item.status = 'free'
    }

    const boxStyleClass: string = Statuses[item.status as keyof typeof Statuses]

    return (
        <div
            className={`${styles.event_box} ${styles[boxStyleClass]} ${
                selectedBox === item.id ? styles.event_box__active : null
            }`}
            style={style}
            key={item.id}
            onClick={() => handleSelectedBox(item)}
        >
            {item.title}
        </div>
    )
}

export default EventBox
