import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activeRoomId: null,
    activeRoomData: null,
    activeEventBoxId: null,
    activeEventBoxData: {} as any,

}


const operatingRoomSlice = createSlice({
    name: 'operatingRoom',
    initialState,
    reducers: {
        setActiveRoom(state, action) {
            console.log("🚀 ~ file: operatingRoomSlice.ts:17 ~ setActiveRoom ~ action:", action)
            state.activeRoomData = action.payload
        },
        setActiveEventBox(state, action) {
            console.log("🚀 ~ file: operatingRoomSlice.ts:21 ~ setActiveEventBox ~ action:", action)
            state.activeEventBoxData = action.payload
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(getOrStatus.pending, (state, action) => {
        //     state.shiftsStatus = orStatus.LOADING;
        //     state.shifts = initialState.orStatus;
        // });
    },
});

export const { setActiveRoom, setActiveEventBox } = operatingRoomSlice.actions;

export default operatingRoomSlice.reducer;