import React from 'react'
import { SECTIONS, createNotionUrl } from '../../../utils/notionURLs'
import ExternalLinkButton from '../../common/ExternalLinkButton/ExternalLinkButton'

type CorporateDocLinkProps = {
    baseKey: keyof typeof SECTIONS // Base key (e.g., 'corporate', 'ward')
    sectionKey?: any // Optional section key within the base
    ariaLabel?: string // Optional aria-label for accessibility
}

// Define the CorporateDocLink component
const CorporateDocLink: React.FC<CorporateDocLinkProps> = ({
    baseKey,
    sectionKey,
    ariaLabel,
}) => {
    // Generate the full URL based on baseKey and sectionKey
    const urlForLink = createNotionUrl(baseKey, sectionKey)

    // Render the ExternalLinkButton with the generated URL
    return <ExternalLinkButton url={urlForLink} ariaLabel={ariaLabel} />
}

export default CorporateDocLink
