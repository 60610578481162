const BASE_CORPORATE_URL =
    'https://www.notion.so/Data-Dictionary-Corporate-View-Documentation-96fd1e4e073d4d14a7c0ac860b897eaf?pvs=4'
const BASE_WARD_URL =
    'https://www.notion.so/Ward-View-24fb5e8aa23c4aba83ce4277f6df41ea?pvs=4'
const BASE_PATIENT_URL =
    'https://www.notion.so/Patient-View-86e893a7b00f4e59bec5cf5c592579c2?pvs=4'
const BASE_OR_URL =
    'https://www.notion.so/Operating-Room-View-889b674c06c84012920e611a159841a0?pvs=4'
const BASE_CC_URL =
    'https://www.notion.so/Critical-Care-View-5d9a1b75af2d4569995f9935f2406b68?pvs=4'

// Sections for each view
export const SECTIONS = {
    corporate: {
        census: '#e08784bbb6a54c71940079229e571a21',
        lengthOfStay: '#b3e14df0c5534d6a9ec2942a6890fb42',
        inflow: '#a4fc15cc177c4bee942b7c795ea7d963',
        outflow: '#793bcfaa7bbd47b698ebbc7f20881e8c',
        operationalNSA: '#cf5692e90bf8423682ea6194554563b3',
        msssNsa: '#65a0f27bbc55414ab6ff77ee2eddc4ee',
        ed: '#5e554c84a5c9436aa6cf7c37299211d7',
        infectionControl: '#e2cb9664e7cf494084211246a42f2700',
        rtStatus: '#2e0c9dcfaa044fb7a6c6f64c41e4fbb9',
        edCensus: '#fdbd7ed3cc574f2c8b9bddca1afe3f88',
        operatingRoom: '#102402defa5680e38c06d85940b11c82',
        criticalCare: '#971784e7646c4a16a71dd29c4780fb81',
    },
    ward: {
        ward: '#d41bc1e1235a4d37ad59f9f811e07964',
        wardEd: '#d9a46d1d062e4639a7baedde4ed1684c',
    },
    patient: {
        view: '#86e893a7b00f4e59bec5cf5c592579c2',
    },
    operatingRoom: {
        OrCurrent: '#e902b17787914fb4841b9395143d1a12',
        OrOutflow: '#af8e899bd6814857bb4147d102ae56a7',
        OrTrendOutflow: '#d6bcc8ba92be4da99c619f6bae88a17b',
        OrTrendInflow: '#721208fcc48c487386c4b1e98b67d841',
        OrTrendLosPacu: '#56a382d6d5bc45a38bd1824a9e012c3a',
        OrInflow: '#89b994202ef74ae6bbe0b310f19bf109',
        OrRoomRunning: '#2f289645879948299d5bbd1e9bfe6c07',
        CasesCancelled: '#0f764055ad734d19891fb407c5441590 ',
        RoomRunningLate: '#007a26e533cb45bd9831f0c924d05277 ',
        LateStart: '#007a26e533cb45bd9831f0c924d05277',
        AverageRoomTurnover: '#cae3fc6311a5496d98e24f397da0b0c2',
        TurnoverDelay: '#0ca32c8f1cad464b85c093cfba3c2328',
        OrUtilizationRate: '#edc0a33f4b384f2daaeb0b284a5262c2',
        OpenSurgeryRequest: '#3a001dd9ccc9477da03627f0ade30312',
    },
    criticalCare: {
        inflowResus: '#1c393275d2c149dbbdd988b3a2ac8f90',
        inflowPods: '#ca9fdd28fef24a4d8671853252c2a5e1',
        inflowOperatingRoom: '#041440524b954fb996c98e14fffd7d22',
        inflowPacu: '#299d3748736844c08fdf7ccdd765618c',
        inflowSummary: '#e7242ac34ae24d158b13fa31ea3bbe7b',
        inflowDemographics: '#3bbd93f15f77492bae4a0d574335c1a8',
        outflow: '#1549b7f7d75b40af8502a38825c17d7f',
    },
}

export const createNotionUrl = (
    baseKey: keyof typeof SECTIONS,
    sectionKey?: keyof typeof SECTIONS[keyof typeof SECTIONS] // Optional section key
): string => {
    const baseUrls: Record<keyof typeof SECTIONS, string> = {
        corporate: BASE_CORPORATE_URL,
        ward: BASE_WARD_URL,
        patient: BASE_PATIENT_URL,
        operatingRoom: BASE_OR_URL,
        criticalCare: BASE_CC_URL,
    }

    // If sectionKey is provided, append the section URL to the base URL
    if (sectionKey) {
        const sectionUrl = SECTIONS[baseKey]?.[sectionKey] || ''
        return `${baseUrls[baseKey]}${sectionUrl}`
    }

    // If no sectionKey is provided, return the base URL
    return baseUrls[baseKey]
}
