import React from 'react'
import { IconTypeProps } from '../IconType'

const SurgeryIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 9L6 11V17.5C6 18.3284 5.32843 19 4.5 19V19C3.67157 19 3 18.3284 3 17.5V9Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 6.61659C6.38954 7.11674 6 7.87652 6 8.72727V9.33333L4 8V1.77273C4 1.6221 4.1221 1.5 4.27273 1.5C5.77896 1.5 7 2.72104 7 4.22727V6.61659Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 19C11.1046 19 12 18.1046 12 17C12 15.8954 11.1046 15 10 15C8.89543 15 8 15.8954 8 17C8 18.1046 8.89543 19 10 19ZM10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19ZM17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z"
                fill={fillColor}
            />
            <circle cx="13.5" cy="9.5" r="1.5" fill={fillColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7315 11.8826C12.3845 11.7706 12.0706 11.5848 11.8085 11.3437L11 17.0029H12L12.7315 11.8826ZM14.2685 11.8826C14.6155 11.7706 14.9294 11.5848 15.1915 11.3437L16 17.0029H15L14.2685 11.8826Z"
                fill={fillColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4421 1C12.9114 1 12.4731 1.41461 12.4437 1.94453L12.1406 7.39902C12.5311 7.14656 12.9965 7 13.4961 7C13.9957 7 14.4611 7.14656 14.8516 7.39902L14.5486 1.94453C14.5192 1.41461 14.0809 1 13.5501 1H13.4421Z"
                fill={fillColor}
            />
        </svg>
    )
}

export default SurgeryIcon
