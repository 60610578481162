import { Menu, MenuProps } from '@mantine/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../redux/rtk/user/selectors'

import styles from './MenuPopup.module.scss'

interface MenuPopupProps extends Omit<MenuProps, 'classNames'> {
    // theme: string
    customClassNames?: {
        dropdown?: string
    }
    closeCb: (e: boolean) => void
}

const MenuPopup: React.FC<MenuPopupProps> = ({
    children,
    // theme,
    customClassNames,
    closeCb,
    ...props
}) => {
    const theme = useSelector(selectTheme)
    return (
        <div>
            <Menu
                {...props}
                onClose={() => closeCb(false)}
                position="bottom-start"
                transitionProps={{
                    transition: 'scale-y',
                    duration: 150,
                }}
                classNames={{
                    dropdown: `${styles[theme + '-theme']} ${
                        styles.modal_body
                    }`,
                }}
                closeOnEscape={true}
            >
                {children}
            </Menu>
        </div>
    )
}

export default MenuPopup
