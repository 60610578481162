import React, { useMemo } from 'react'
import ProgressBarWithIcon from 'components/common/ProgressBarWithIcon/ProgressBarWithIcon'
import { convertMinutesToHours } from 'utils/strings/stringToMinutes'

import styles from './ProgressBarWrapper.module.scss'

const TOTAL_NUMBER_MINUTES = 16 * 60

interface ProgressBarWrapperProps {
    time: number | undefined | null
    delayLevel: number | undefined
    total?: number
    isShownDividers?: boolean
    textSymbol?: string
}

const formatTime = (time: number | null | undefined, symbol: string) => {
    if (time == null) return '0'
    return symbol === 'H' ? convertMinutesToHours(time) : time.toString()
}

const ProgressBarWrapper: React.FC<ProgressBarWrapperProps> = ({
    time,
    delayLevel,
    total = TOTAL_NUMBER_MINUTES,
    isShownDividers = false,
    textSymbol = 'H',
}) => {
    const formattedTime = useMemo(
        () => formatTime(time, textSymbol),
        [time, textSymbol]
    )
    const totalNumber = useMemo(
        () => (textSymbol === 'H' ? total * 60 : total),
        [total, textSymbol]
    )

    if (time == null) {
        return <div style={{ textAlign: 'center' }}>--</div>
    }

    return (
        <div className={styles.progressWrapper}>
            <ProgressBarWithIcon
                total={totalNumber}
                time={time}
                delay={`${total}${textSymbol}`}
                delayLevel={delayLevel}
                innerText={formattedTime}
                isShownDividers={isShownDividers}
            />
        </div>
    )
}

export default ProgressBarWrapper
