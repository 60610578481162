import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { labsEndBaseUrl } from '../../../utils/API'
import { EmergencyDepartmentDataMock } from '../../../utils/__Mocks__/EmergencyDepartmentMockData'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { EmergencyDepartmentObject } from './types'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT === 'Development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development'

export const labsEndApi = createApi({
    reducerPath: 'labsEndApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: labsEndBaseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }
            return headers
        },
    }),

    tagTypes: ['EmergencyDepartment'],
    endpoints: (builder) => ({
        getEmergencyDepartment: builder.query<EmergencyDepartmentObject, any>({
            query: () => `v1/EmergencyDepartment`,
            providesTags: [{ type: 'EmergencyDepartment', id: 'LIST_ED' }],
            transformResponse: (response: EmergencyDepartmentObject): EmergencyDepartmentObject => {
                if (isDevelopment) {
                    return EmergencyDepartmentDataMock
                }
                return response
            },
        }),
    }),
})

export const { useGetEmergencyDepartmentQuery } = labsEndApi
