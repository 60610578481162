import React from 'react'
import TitleVsTextBox from '../../TitleVsTextBox/TitleVsTextBox'
import styles from './ScheduleItem.module.scss'

type ScheduleItemProps = {
    id?: any
    procedure: string
    status: string
    doctor: string
    nurse: string
}
const ScheduleItem: React.FC<ScheduleItemProps> = ({
    procedure,
    status,
    doctor,
    nurse,
}) => {
    return (
        <div className={styles.items}>
            <TitleVsTextBox header={procedure} text={status} />
            <TitleVsTextBox header={'lead surgeon'} text={doctor} />
            <TitleVsTextBox header={'lead nurse'} text={nurse} />
        </div>
    )
}

export default ScheduleItem
