import { createAsyncThunk } from '@reduxjs/toolkit'
import { APIDATA } from '../../../utils/API'
import sortByDate from '../../../utils/date/sortByDate'
import {
    saturation_MOCK,
    temperature_MOCK,
    bloodPreasure_MOCK,
    heartRate_MOCK,
} from '../../../utils/__Mocks__/VitalsMockData'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

export const getPatientTableData = createAsyncThunk<any, any>(
    'patientModal/getPatientTableData',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATA.get(`/v1/Visits/${param.id}`, {
                headers: {
                    role: param.role,
                },
            })
            const data = response.data
            return response.data
        } catch (error) {
            console.log('Error fetch dashboard patient popup data: ', error)
            return rejectWithValue(error)
        }
    }
)

//  getPatientTablePopupSaturations Not supported
export const getPatientTablePopupSaturations = createAsyncThunk<any, any>(
    'patientModal/getPatientTablePopupSaturations',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { id, role, token } = param
            const response = await APIDATA.get(`v1/Visits/${id}/saturations`, {
                headers: {
                    role: role,
                },
            })
            let saturations = response.data.saturations.sort(sortByDate)
            if (isDevelopment) {
                return saturation_MOCK
            } else return saturations
        } catch (error) {
            console.log(
                'Error fetch dashboard patient popup saturations: ',
                error
            )
            return rejectWithValue(error)
        }
    }
)

export const getPatientTablePopupTemperatures = createAsyncThunk<any, any>(
    'patientModal/getPatientTablePopupTemperatures',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { id, role, token } = param
            // const response = await APIDATA.get(`api/v1/visits/${id}/temperatures`, {
            const response = await APIDATA.get(`v1/Visits/${id}/temperatures`, {
                headers: {
                    role: role,
                },
            })
            let temperatures = response.data.temperatures.sort(sortByDate)
            if (isDevelopment) {
                return temperature_MOCK
            }
            return temperatures
        } catch (error) {
            console.log(
                'Error fetch dashboard patient popup data temperatures: ',
                error
            )
            return rejectWithValue(error)
        }
    }
)

export const getPatientTablePopupBloodpreasures = createAsyncThunk<any, any>(
    'patientModal/getPatientTablePopupBloodpreasures',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { id, role, token } = param
            const response = await APIDATA.get(
                `v1/Visits/${id}/bloodpreasures`,
                // `api/v1/visits/${id}/blood-pressures`,
                {
                    headers: {
                        role: role,
                    },
                }
            )
            let bloodPreasures = {
                sbPs: response.data.bloodPreasure.sbPs.sort(sortByDate),
                dbPs: response.data.bloodPreasure.dbPs.sort(sortByDate),
            }

            if (isDevelopment) {
                return bloodPreasure_MOCK
            }
            return bloodPreasures
        } catch (error) {
            console.log(
                'Error fetch dashboard patient popup data temperatures: ',
                error
            )
            return rejectWithValue(error)
        }
    }
)

export const getPatientTablePopupHeartrates = createAsyncThunk<any, any>(
    'patientModal/getPatientTablePopupHeartrates',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { id, role, token } = param
            // const response = await APIDATA.get(`api/v1/visits/${id}/heart-rates`, {
            const response = await APIDATA.get(`v1/Visits/${id}/heartrates`, {
                headers: {
                    role: role,
                },
            })
            let heartRates = response.data.heartRates.sort(sortByDate)
            if (isDevelopment) {
                return heartRate_MOCK
            }
            return heartRates
        } catch (error) {
            console.log(
                'Error fetch dashboard patient popup data temperatures: ',
                error
            )
            return rejectWithValue(error)
        }
    }
)

export const updateLenghtOfStay = createAsyncThunk<any, any>(
    'patientModal/updateLenghtOfStay',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const {
                visitId,
                expectedLengthOfStay,
                revisitLengthOfStayPredictionAfter,
                dischargeDisposition,
                token,
                role,
            } = param
            const response = await APIDATA.post(
                'v1/Visits/updatelenghtofstay',
                {
                    visitId,
                    expectedLengthOfStay,
                    revisitLengthOfStayPredictionAfter,
                    dischargeDisposition,
                },
                {
                    headers: {
                        bearer: token,
                        role: role,
                    },
                }
            )
            if (response.status === 200) {
                dispatch(
                    getPatientTableData({
                        id: visitId,
                        token: token,
                        role: role,
                    })
                )
            }
            return response.data
        } catch (error) {
            console.log('Error update lenght of stay: ', error)
            return rejectWithValue(error)
        }
    }
)

export const getDischargeDispositions = createAsyncThunk<any, any>(
    'patientModal/getDischargeDispositions',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATA.get(
                `v1/Visits/dischargedispositions`,
                {
                    headers: {
                        bearer: `${param.token}`,
                        role: param.role,
                    },
                }
            )
            return response.data.dischargeDispositions
        } catch (error) {
            console.log('Error fetch discharge dispositions data: ', error)
            return rejectWithValue(error)
        }
    }
)
