import React, { useMemo } from 'react'
import { OptionConfig } from 'components/CriticalCare/CriticalCareOutflows/tableOptions'
import { useTranslation } from 'react-i18next'

type TableHeadProps = {
    thOptions: OptionConfig[]
}

const TableHead: React.FC<TableHeadProps> = ({ thOptions }) => {
    const { t } = useTranslation()
    const sortedth = useMemo(
        () => thOptions.slice().sort((a, b) => a.order - b.order),
        [thOptions]
    )
    return (
        <thead>
            <tr>
                {sortedth?.map((el, index) => (
                    <th key={index}>
                        {`${t('cc.outflows.' + el.title)}` || ''}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHead
