import React from 'react'
import styles from './Header.module.scss'
import LogoBar from './LogoBar/LogoBar'
import Menu from './Menu/Menu'
import MenuOptions from './MenuOptions'
import OpenAlertsButton from './OpenAlertsButton/OpenAlertsButton'
import UserMenu from './UserMenu/UserMenu'

const Header = (): JSX.Element => {
    return (
        <header className={styles.header}>
            <div className={styles.header_items_left}>
                <Menu menuItems={MenuOptions} />
                {/* <OpenAlertsButton /> */}
            </div>
            <div className={styles.header_items_right}>
                <UserMenu />
                {/* <LogoBar logoType="main" /> */}
                <LogoBar logoType="management_status" />
            </div>
        </header>
    )
}

export default Header
