import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Button, Loader, Modal, ScrollArea, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import TermsAndConditionsFooter from './TermsAndConditionsFooter/TermsAndConditionsFooter'

import {
    useGetShowTermsOfServiceQuery,
    useGetTermsOfServiceForPopupQuery,
} from '../../redux/rtk/terms/termsConditionsSlice'
import { selectToken } from '../../redux/rtk/auth/selectors'
import { selectLocale, selectTheme } from '../../redux/rtk/user/selectors'
import { useKeycloak } from '@react-keycloak/web'
import CenteredWrapper from '../common/CenteredWrapper/CenteredWrapper'
import { Section } from '../../redux/rtk/terms/type'

import styles from './TermsOfService.module.scss'
interface TermsOfServiceProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({
    isOpen,
    setIsOpen,
}) => {
    const { keycloak } = useKeycloak()
    const userToken = useSelector(selectToken)
    const theme = useSelector(selectTheme)
    const locale = useSelector(selectLocale)

    const [activeTab, setActiveTab] = useState<string>('')
    const [activeLang, setActiveLang] = useState<string>(locale)
    const [sectionsData, setSectionsData] = useState<Section[]>([])

    const isLight = theme === 'light'

    const { data: termsAndConditionsData, error } =
        useGetShowTermsOfServiceQuery(undefined, {
            skip: !userToken,
        })

    const { data: termsOfService, isFetching } =
        useGetTermsOfServiceForPopupQuery(undefined, {
            skip:
                !!userToken &&
                termsAndConditionsData &&
                termsAndConditionsData?.sections?.length > 0,
        })

    useEffect(() => {
        if (termsAndConditionsData?.showTermsAndConditions) {
            setSectionsData(termsAndConditionsData?.sections)
        }
        if (termsOfService?.sections) setSectionsData(termsOfService?.sections)
    }, [termsAndConditionsData, termsOfService])

    const getPreventCLoseTermsPopup = useCallback(() => {
        setIsOpen(false)
        if (!keycloak.authenticated) return
        if (termsAndConditionsData?.showTermsAndConditions) {
            const timeoutId = setTimeout(() => {
                setIsOpen(true)
            }, 300)

            return () => clearTimeout(timeoutId)
        }
    }, [userToken, termsAndConditionsData])

    const sectionText = useMemo(
        () =>
            sectionsData?.map((section, index) => {
                const languageData =
                    section.languages.find(
                        (lang) => lang.language === activeLang
                    ) || section.languages[0]
                const isActive = activeTab
                    ? languageData.sectionName === activeTab
                    : setActiveTab(languageData.sectionName)

                return isActive ? (
                    <div
                        key={index}
                        dangerouslySetInnerHTML={{
                            __html: languageData.text,
                        }}
                    />
                ) : null
            }),
        [sectionsData, activeTab, activeLang, locale]
    )

    const sectionButtons = useMemo(
        () =>
            sectionsData.map((section, index) => {
                const languageData =
                    section.languages.find(
                        (lang) => lang.language === activeLang
                    ) || section.languages[0]
                const sectionName = languageData.sectionName

                return (
                    <Fragment key={`${index}_${sectionName}`}>
                        <Button
                            variant="transparent"
                            classNames={{
                                root: `${styles.terms_sidebar_buttons_item}`,
                                label: `${
                                    activeTab === sectionName
                                        ? styles.terms_sidebar_buttons_item_active
                                        : ''
                                }`,
                            }}
                            onClick={() => setActiveTab(sectionName)}
                            styles={{
                                inner: {
                                    justifyContent: 'flex-start',
                                },
                            }}
                        >
                            {sectionName}
                        </Button>
                        {index < sectionsData.length - 1 && (
                            <div className={styles.divider}></div>
                        )}
                    </Fragment>
                )
            }),
        [sectionsData, activeTab, locale]
    )

    return (
        <Modal
            opened={isOpen}
            closeOnClickOutside={false}
            onClose={() => {
                getPreventCLoseTermsPopup()
            }}
            centered
            // withCloseButton={false}
            classNames={{
                overlay: `${styles.terms_overlay}`,
                content: `${styles.terms} ${
                    isLight ? styles.terms_light : styles.terms_dark
                }`,
                root: `${styles[theme + '-theme']}`,
                body: `${styles.terms_body} ${styles[theme + '-theme']}`,
                header: `${styles.terms_header} `,
                close: `${styles.terms_close} `,
            }}
        >
            <div className={styles.terms_content}>
                <div className={styles.terms_wrap}>
                    <article
                        className={`${styles.terms_main} ${
                            isLight
                                ? styles.terms_main_light
                                : styles.terms_main_dark
                        }`}
                    >
                        <p className={`${styles.terms_main_head}`}>
                            {activeTab}
                        </p>
                        <ScrollArea
                            type="hover"
                            offsetScrollbars
                            scrollbarSize={6}
                            classNames={{
                                root: `${styles.scrollarea_root}`,
                                viewport: `${styles.scrollarea_input}`,
                            }}
                        >
                            {sectionText}
                        </ScrollArea>
                    </article>
                    <article className={`${styles.terms_sidebar}`}>
                        {/* <button
                            className={styles.terms_sidebar_close_btn}
                            onClick={() => getPreventCLoseTermsPopup()}
                        /> */}
                        <div className={styles.terms_sidebar_buttons}>
                            <div
                                className={`${styles.menu_label__item} ${styles.lang__block}`}
                            >
                                {sectionsData && sectionsData?.length
                                    ? sectionsData[0]?.languages.map(
                                          (language, index) => (
                                              <Text
                                                  key={index}
                                                  size="xl"
                                                  fw={400}
                                                  variant={
                                                      activeLang ===
                                                      language.language
                                                          ? ''
                                                          : 'gradient'
                                                  }
                                                  tt="uppercase"
                                                  className={`${
                                                      styles.menu_label__button
                                                  } ${
                                                      activeLang ===
                                                      language.language
                                                          ? styles.menu_label__button_active
                                                          : styles.menu_label__button_inactive
                                                  }`}
                                                  onClick={() => {
                                                      setActiveLang(
                                                          language.language
                                                      )
                                                      setActiveTab('')
                                                  }}
                                              >
                                                  {language.language.toUpperCase()}
                                              </Text>
                                          )
                                      )
                                    : null}
                            </div>
                            <>{sectionButtons}</>
                        </div>
                    </article>
                </div>
            </div>
            <TermsAndConditionsFooter setIsOpen={setIsOpen} />
        </Modal>
    )
}

export default TermsOfService
