import React, { useCallback, useMemo } from 'react'
import styles from './AreaChart.module.scss'
import {
    calculateMinMaxMedian,
    findMinAndMax,
    getWeekendDates,
    removeTrailingZ,
    updateMinYMaxYWithAverage,
} from './helper'

import {
    Chart as ChartJS,
    // CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js'

import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'
import { calculateMean } from '../../../utils/arrays/calculateMean'
import { KeyValue, SummaryItem, WaitListNumber } from 'redux/rtk/orStatus/types'
import sortByDate from 'utils/date/sortByDate'
// import { sortByDateKey } from 'utils/date/sortByDateKey'
import { getMonthDayFromDateTime } from 'utils/date/getMonthDayFromDateTime'
import { sortByDateKey } from 'utils/date/sortByDateKey'
import { isTimestampWeekend } from 'utils/date/isTimestampWeekend'

ChartJS.register(
    // CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
)
// WaitListNumber[]
type AreaChartProps = {
    dataObj: SummaryItem[] | WaitListNumber[]
    averageValue?: any
    xAxisKeyName: string
    yAxisKeyName: string
    tooltipLable?: string
}

const modifyLastFourObjects = (data: any, valueKey: string) => {
    // Replace the value to 0
    const modifiedObjects = data?.map((obj: any) => ({
        ...obj,
        [valueKey]: 0,
    }))

    return modifiedObjects
}

const AreaChart: React.FC<AreaChartProps> = ({
    dataObj,
    xAxisKeyName = 'surgeryScheduleDate',
    yAxisKeyName,
    tooltipLable,
}) => {
    let sortedData
    let state
    let updatedData
    let weekendDatesFromSummaries: Array<string>

    if (dataObj && dataObj.length > 0) {
        // Check if dataObj is SummaryItem[]
        if ('surgeryScheduleDate' in dataObj[0]) {
            sortedData = sortByDateKey<SummaryItem>(
                dataObj as SummaryItem[],
                'surgeryScheduleDate'
            )
            updatedData = removeTrailingZ(sortedData, 'surgeryScheduleDate')
            weekendDatesFromSummaries = getWeekendDates(
                sortedData,
                'surgeryScheduleDate'
            )
            state = calculateMinMaxMedian(dataObj as SummaryItem[], 'value')
        } else if ('date' in dataObj[0]) {
            sortedData = sortByDateKey<WaitListNumber>(
                dataObj as WaitListNumber[],
                'date'
            )
            updatedData = removeTrailingZ(sortedData, 'date')
            weekendDatesFromSummaries = getWeekendDates(sortedData, 'date')
            state = calculateMinMaxMedian(
                dataObj as WaitListNumber[],
                'numberWaitingRequests'
            )
        }
    } else {
        sortedData = []
    }
    const data = {
        datasets: [
            {
                label: 'Trends',
                data: updatedData,
                parsing: {
                    xAxisKey: xAxisKeyName || 'time',
                    yAxisKey: yAxisKeyName || 'value',
                },
                pointBorderColor: '#FF7783',
                borderColor: '#FF7783',
                backgroundColor: '#FF7783',
                borderWidth: 2,
                fill: 0,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 3,
                // spanGaps: true,
            },
            {
                label: 'spetials',
                data: modifyLastFourObjects(sortedData, yAxisKeyName),
                pointBorderColor: 'rgba(191, 220, 233, 1)',

                backgroundColor: (context: any) => {
                    const chart = context.chart
                    const { ctx, chartArea, scales } = chart
                    if (!chartArea) {
                        return null
                    }
                    return getGradient(ctx, chartArea, scales)
                },
                borderColor: [
                    'rgba(255, 26, 104, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(0, 0, 0, 1)',
                ],
                borderWidth: 1,
                fill: 0,
                radius: 0,
                parsing: {
                    xAxisKey: xAxisKeyName || 'time',
                    yAxisKey: yAxisKeyName || 'value',
                },
            },
        ],
    }

    function getGradient(ctx: any, chartArea: any, scales: any) {
        const gradientBg = ctx.createLinearGradient(
            chartArea.left,
            0,
            chartArea.right,
            0
        )
        gradientBg.addColorStop(0.2, 'rgba(46, 226, 249,0)')
        gradientBg.addColorStop(0.6, 'rgba(46, 226, 249, 0.0391479)')
        gradientBg.addColorStop(1, 'rgba(46, 226, 249, 0.08)')
        return gradientBg
    }

    const yScaleText = {
        id: 'yScaleText',
        afterDatasetsDraw(chart: any, args: any, options: any) {
            const {
                ctx,
                chartArea: { top },
                scales: { x, y },
            } = chart
            ctx.save()
            ctx.font = `${options.fontWeight} ${options.fontSize}px Kumbh Sans`
            ctx.fillStyle = options.fontColor
            ctx.fillText(options.yTitle, 0, top)
            ctx.restore()
        },
    }

    const dateHighlighter = {
        id: 'dateHighlighter',
        beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
            const {
                ctx,
                chartArea: { top, bottom, left, right, width, height },
                scales: { x, y },
            } = chart
            const weekendsArray = chart.boxes[2].ticks
                .map((item: any) =>
                    isTimestampWeekend(item.value) ? item.value : null
                )
                .filter((value: any) => value !== null)

            const startDate = new Date(weekendsArray[0]).setHours(-12, 0, 0, 0)
            const startDate2 = new Date(weekendsArray[0]).setHours(12, 0, 0, 0)
            const endDate = new Date(weekendsArray[1]).setHours(-12, 0, 0, 0)
            const endDate2 = new Date(weekendsArray[1]).setHours(12, 0, 0, 0)

            ctx.save()
            ctx.fillStyle = 'rgba(142, 149, 149, 0.16)'
            ctx.fillRect(
                x.getPixelForValue(startDate),
                top,
                x.getPixelForValue(startDate2) -
                    x.getPixelForValue(startDate) -
                    1,
                height + 35
            )
            ctx.fillRect(
                x.getPixelForValue(endDate) + 1,
                top,
                x.getPixelForValue(endDate2) - x.getPixelForValue(endDate),
                height + 35
            )
            ctx.restore()
        },
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 2,
            },
        },
        layout: {
            padding: {
                bottom: 15, //set that fits the best
            },
        },

        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: '#091931',
                displayColors: false,
                callbacks: {
                    title: (ctx: any) => {
                        const formatedTitle = getMonthDayFromDateTime(
                            ctx[0].label
                        )
                        return formatedTitle
                    },
                    label: (ctx: any) => {
                        if (ctx.dataset.label === 'spetials') return null
                        if (ctx.raw.keys) {
                            return ctx.raw.keys.map(
                                (item: KeyValue) =>
                                    `${item.key || tooltipLable}: ${item.value}`
                            )
                        } else {
                            return `${tooltipLable}: ${ctx.raw.numberWaitingRequests}`
                        }
                    },
                    labelColor: (context: any) => {
                        return {
                            borderColor: 'rgb(0, 0, 255)',
                            backgroundColor: 'rgb(255, 0, 0)',
                            borderWidth: 2,
                            borderDash: [2, 2],
                            borderRadius: 2,
                        }
                    },
                    // labelTextColor: (context: any) => {
                    //     return '#543453'
                    // },
                },
            },
            //yScaleText need to be included in the  plugins={[noData, yScaleText]}
            // yScaleText: {
            //     fontColor: '#B0D3E7',
            //     fontSize: 6,
            //     yTitle: 'Hours',
            //     fontWeight: 700
            // },
            dateHighlighter: {},
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                grid: {
                    drawOnChartArea: false,
                    borderWidth: 2,
                    borderColor: 'rgba(176, 211, 231, 1)',
                    color: 'rgba(176, 211, 231, 1)',
                    tickWidth: 2,
                    tickLength: 5,
                },
                ticks: {
                    padding: 14,
                    font: (context: any, index: number) => {
                        const isWeekend = isTimestampWeekend(context.tick.value)
                        return {
                            size: 9,
                            weight: isWeekend ? 'bold' : 'normal',
                        }
                    },
                    color: (context: any, index: number) => {
                        const isWeekend = isTimestampWeekend(context.tick.value)
                        return isWeekend ? '#F1F4F7' : 'rgba(176, 211, 231, 1)'
                    },
                    background: 'black',
                },
            },
            y: {
                min: state?.min
                    ? Math.round(state?.min - state?.min / 30)
                    : null,
                // max: state?.max
                //     ? Math.round(state?.max + state?.max * 0.1)
                //     : null,
                beginAtZero: false,
                grid: {
                    // drawTicks: false,
                    drawOnChartArea: false,
                    borderWidth: 2,
                    borderColor: 'rgba(176, 211, 231, 1)',
                    // color: 'rgba(157, 17, 29, 1)',
                    color: 'rgba(176, 211, 231, 1)',
                    tickWidth: 1,
                    tickLength: 4,
                },
                ticks: {
                    // display: false,
                    callback: (value: any, index: number, values: any) => {
                        return value
                    },
                    padding: 2,
                    color: 'rgba(136, 155, 186, 1)',
                    font: {
                        size: 9,
                        weight: 'bold',
                    },
                },
            },
        },
    }

    const noData = {
        id: 'noData',
        afterDatasetsDraw: (chart: any, args: any, plugins: any) => {
            const {
                ctx,
                data,
                chartArea: { top, right, bottom, left, width, height },
            } = chart
            ctx.save()

            if (data.datasets[0].data.length === 0) {
                ctx.fillStyle = 'rgba(134, 180, 217, 0.5)'
                ctx.fillRect(left, top, width, height)

                ctx.font = 'bold 10px sans-serif'
                ctx.fillStyle = '#ffffff'
                ctx.textAlign = 'center'
                ctx.fillText(
                    'No Data Available',
                    left + width / 2,
                    top + height / 2
                )
            }
        },
    }

    // const horizontalDottedLine = {
    //     id: 'horizontalDottedLine',
    //     afterDatasetsDraw(chart: any, args: any, options: object) {
    //         const {
    //             ctx,
    //             chartArea: { top, right, bottom, left, width, height },
    //             scales: { x, y },
    //         } = chart
    //         ctx.save()
    //         if (averageValue && averageValue > 0) {
    //             ctx.strokeStyle = 'rgba(255, 119, 131, 0.5)'
    //             ctx.setLineDash([3, 7])
    //             ctx.lineWidth = 3
    //             ctx.strokeRect(left, y.getPixelForValue(averageValue), width, 0)
    //             ctx.restore()
    //         }
    //     },
    // }

    return (
        // <div className={styles.component}>
        <Line
            //@ts-ignore
            data={data}
            //@ts-ignore
            options={options}
            // className={styles.chart}
            plugins={[noData, dateHighlighter]}
            // plugins={[noData, yScaleText]}
        ></Line>
        // </div>
    )
}

export default AreaChart
