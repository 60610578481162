export const getTimestampForHourAndMinute = (hour: number, minute: number): number => {
    const now = new Date();
    now.setHours(hour, minute, 0, 0); // Set the specified hour and minute, and reset seconds and milliseconds to 0
    return now.getTime(); // Get the timestamp
}

// const timestampFor9AM = getTimestampForHourAndMinute(9, 0);
// const timestampFor9PM30 = getTimestampForHourAndMinute(21, 30);

// console.log(new Date(timestampFor9AM)); // Prints the date for 9:00 AM
// console.log(new Date(timestampFor9PM30)); // Prints the date for 9:30 PM