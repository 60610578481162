import { skipToken } from '@reduxjs/toolkit/query/react';

export default function useConditionalQuery<ResultType, QueryArg>(
    query: (arg: QueryArg | typeof skipToken) => {
        data?: ResultType;
        error?: unknown;
        isLoading: boolean;
        isFetching: boolean;
        isSuccess: boolean;
    },
    arg: QueryArg,
    conditions: boolean[]
) {
    const shouldFetch = conditions.every(condition => condition);
    return query(shouldFetch ? arg : skipToken);
}