import React, { useMemo } from 'react'
import { useGetResusPodsInflowDataQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CustomAccordion from 'components/common/CustomAccordion/CustomAccordion'
import CcListItem from '../CcListItem/CcListItem'

import { icons } from '../../../../utils/iconsMap'
import styles from './ResusContainer.module.scss'
import { useTranslation } from 'react-i18next'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const ResusContainer: React.FC = () => {
    const { data, error, isFetching, isSuccess } =
        useGetResusPodsInflowDataQuery('RESUS', {
            pollingInterval: 300000,
        })
    const { t } = useTranslation()

    const resusItems = useMemo(() => {
        if (!isSuccess || !data?.resusList) return []

        return data?.resusList?.map((item) => ({
            ...item,
        }))
    }, [isSuccess, data])

    const renderItems = () => (
        <CustomAccordion.Item key="resus" value="resus">
            <CustomAccordion.Control>
                <CcTitle>
                    {t('cc.inflow.RESUS')} ({resusItems?.length || 0}){' '}
                    <CorporateDocLink
                        baseKey="criticalCare"
                        sectionKey="inflowResus"
                    />
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {resusItems?.length > 0 ? (
                        resusItems?.map((item, index) => (
                            <CcListItem {...item} key={index} />
                        ))
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <div className={styles.container}>
            <CustomAccordion
                chevronPosition="left"
                chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
                defaultValue="resus"
            >
                {renderItems()}
            </CustomAccordion>
        </div>
    )
}

export default ResusContainer
