import React from 'react'
import ScheduleItem from 'components/OrStatus/OrList/ListItem/ScheduleItem/ScheduleItem'
import ProgressBarWithIcon from 'components/common/ProgressBarWithIcon/ProgressBarWithIcon'
import { OperatingRoomCurrentInflow } from 'redux/rtk/criticalCare/types'
import PopoverItem from 'components/OrStatus/PopoverItem/PopoverItem'
import IconItem from '../../CcListItem/IconItem/IconItem'

import styles from './OrListItem.module.scss'
import { convertMinutesToHours } from 'utils/strings/stringToMinutes'
import ProgressBarWrapper from 'components/common/ProgressBarWrapper/ProgressBarWrapper'

const TOTAL_NUMBER_MINUTES = 30

const OrListItem: React.FC<OperatingRoomCurrentInflow> = ({
    patientRecord,
    patientFirstName,
    patientLastName,
    surgeryRoom,
    admittingService,
    timeDelayLevel,
    surgeryScheduleDateTime,
    waitingTimePostOperationMinutes,
    expectedSurgeryScheduleEndDateTime,
    timeDelaySinceAdmissionLevel,
}) => {
    return (
        <div className={styles.item}>
            <PopoverItem
                target={patientRecord}
                items={[
                    {
                        title: 'patient name',
                        data: `${patientFirstName ?? '--'} ${
                            patientLastName ?? '--'
                        }`,
                    },
                ]}
            />
            <div>{surgeryRoom ?? '--'}</div>

            {admittingService ? (
                <IconItem text={admittingService} />
            ) : (
                <div>--</div>
            )}
            <ScheduleItem
                surgerySchedule={surgeryScheduleDateTime}
                expectedSurgeryEndDate={expectedSurgeryScheduleEndDateTime}
                timeDelayLevel={timeDelayLevel}
            />
            <ProgressBarWrapper
                time={waitingTimePostOperationMinutes}
                delayLevel={timeDelaySinceAdmissionLevel}
                total={TOTAL_NUMBER_MINUTES}
                textSymbol="M"
            />
        </div>
    )
}

export default OrListItem
