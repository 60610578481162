import React, { ReactNode, ReactElement } from 'react'

import styles from './LastAdmissionColumn.module.scss'

type LastAdmissionColumnProps = {
    renderedCellValue: string | ReactNode
    reAdm: string
}

const LastAdmissionColumn: React.FC<LastAdmissionColumnProps> = ({
    renderedCellValue,
    reAdm,
}): ReactElement | null => {
    if (renderedCellValue && typeof renderedCellValue === 'string') {
        const cellTextStyle =
            reAdm === 'True' || reAdm === 'true' ? { color: '#FF7783' } : {}

        return (
            <span
                className="cellText"
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...cellTextStyle,
                }}
            >
                {renderedCellValue}
            </span>
        )
    } else if (renderedCellValue) {
        return renderedCellValue as ReactElement
    } else {
        return null
    }
}

export default LastAdmissionColumn
