import React, { useMemo } from 'react'
import { useGetEdPatientsQuery } from '../../redux/rtk/edLive/edLiveApi'
import {
    buttonsFilter,
    consultationFilter,
    originFilters,
    ButtonDataWithVariant,
} from './AdmissionsFilterOptions'
import FilterButtons from './FilterButtons/FilterButtons'
import OriginFilters from './OriginFilters/OriginFilters'
import { countItemsForFilters } from './helpers'

import { icons } from '../../utils/iconsMap'
import styles from './AdmissionsFilter.module.scss'

const AdmissionsFilter: React.FC = () => {
    const {
        data: edLiveData,
        error,
        isFetching,
        isLoading,
        isSuccess,
    } = useGetEdPatientsQuery(
        {},
        {
            // skip: !userToken && keycloak.isTokenExpired(),
            pollingInterval: 300000,
        }
    )
    const result = useMemo(
        () =>
            edLiveData?.predictions
                ? countItemsForFilters(buttonsFilter, edLiveData?.predictions)
                : {},
        [edLiveData]
    )

    return (
        <div className={styles.container}>
            {/* <FilterButtons filterId="origin" buttonsData={originFilters} /> */}
            <OriginFilters filterId="origin" />
            <img
                src={icons.arrow}
                className={styles.container__img}
                alt="sort icons"
            />
            <div className={styles.container__filters}>
                <FilterButtons
                    filterId="filter"
                    buttonsData={buttonsFilter}
                    counters={result}
                />
            </div>
            <img
                src={icons.arrow}
                className={styles.container__img}
                alt="sort icons"
            />
            <div className={styles.container__filters}>
                <FilterButtons
                    filterId="consultation"
                    buttonsData={consultationFilter}
                />
            </div>
        </div>
    )
}

export default AdmissionsFilter
