import { createAsyncThunk } from '@reduxjs/toolkit';
import { APIUSERS } from '../../../utils/API';
import { UserInfo } from './types';

export const getUserDetails = createAsyncThunk<UserInfo, undefined>(
    'userData/getUserDetails',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            const response = await APIUSERS.get('/v1/Users/details')
            // if (response.status !== 200) {
            //     throw new Error("Can't get user details. Server Error");
            // }
            const data = await response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setUpdatePreferences = createAsyncThunk(
    'userData/setUpdatePreferences',
    async (preferences: any, { rejectWithValue }) => {
        try {
            const response = await APIUSERS.post('/v1/Users/updatepreferences', preferences)
            // if (response.status !== 200) {
            //     throw new Error("Can't add User Preferences. Server Error");
            // }
            const data = await response.data
            return data
        } catch (error) {
            console.log("🚀 ~ file: asyncAction.ts:40 ~ error:", error)
            console.log('Error fetch to updated user preference')
            return rejectWithValue(error);
        }
    }
);