import { RootState } from '../index'

export const selectPatientsDataAPI = (state: RootState) =>
    state.patientsTable.patientsDataAPI
export const selectModifiedPatientsDataAPI = (state: RootState) =>
    state.patientsTable.modifiedPatientsDataAPI

export const selectPredefinedFilterCounts = (state: RootState) =>
    state.patientsTable.PredefinedFilterCounts

export const selectLengthOfStay = (state: RootState) =>
    state.patientsTable.lengthOfStay
export const selectDashboardPatientsLocations = (state: RootState) =>
    state.patientsTable.dashboardPatientsLocations

export const selectDashboardSelectedPatient = (state: RootState) =>
    state.patientsTable.dashboardSelectedPatient
// export const selectDashboardPopupPatient = (state: RootState) => state.patientsTable.dashboardPopupPatient
export const selectDashboardPopupPatientId = (state: RootState) =>
    state.patientsTable.dashboardPopupPatientId
export const selectDashboardPopupVisitId = (state: RootState) =>
    state.patientsTable.dashboardPopupVisitId
export const selectDashboardPopupPatientHazards = (state: RootState) =>
    state.patientsTable.dashboardPopupPatientHazards
export const selectPatientSUpdateDataFetchDate = (state: RootState) =>
    state.patientsTable.patientSUpdateDataFetchDate

export const selectUpdatesForPatients = (state: RootState) =>
    state.patientsTable.updatesForPatients
export const selectUpdatesForPatient = (state: RootState) =>
    state.patientsTable.updatesForPatient
export const selectIsLoadingPatientsData = (state: RootState) =>
    state.patientsTable.IsLoadingPatientsData
export const selectDownloadingCsvStatus = (state: RootState) =>
    state.patientsTable.downloadingCsvStatus
export const selectLengthOfStayStatus = (state: RootState) =>
    state.patientsTable.lengthOfStayStatus

export const selectLabsDataperDay = (state: RootState) =>
    state.patientsTable.labsDataperDay
export const selectLabsDataperDayStatus = (state: RootState) =>
    state.patientsTable.labsDataperDayStatus
export const selectDischargeStatuses = (state: RootState) =>
    state.patientsTable.dischargeStatuses
export const selectUpdateDischargeStatus = (state: RootState) =>
    state.patientsTable.updateDischargeStatus
export const selectMarkPendingLabsAsSeenStatus = (state: RootState) =>
    state.patientsTable.markPendingLabsAsSeenStatus
