export const calculateStringToMinutes = (time: string | number) => {
    if (typeof time === 'number') {
        return calculateUnitToMinutes(time, 'd')
    }
    // const regex = /^(\d+)(d|h|m)$/
    const regex = /^([<>])?(\d+)(-\d+)?(d|h|m)$/
    const match = time.match(regex)

    if (!match) {
        return 0
    }

    const modifier = match[1] // "<" or ">" or undefined
    const firstQuantity = parseInt(match[2], 10)
    const rangePart = match[3] // "-number" or undefined
    const unit = match[4] // "d", "h", or "m"

    let totalMinutes = 0

    if (rangePart) {
        const secondQuantity = parseInt(rangePart.slice(1), 10)
        // Calculate average if a range is given
        const averageQuantity = (firstQuantity + secondQuantity) / 2
        totalMinutes = calculateUnitToMinutes(averageQuantity, unit)
    } else {
        totalMinutes = calculateUnitToMinutes(firstQuantity, unit)
    }

    // if (modifier === '<') {
    //     return `Less than ${totalMinutes} minutes`;
    // } else if (modifier === '>') {
    //     return `More than ${totalMinutes} minutes`;
    // }

    return totalMinutes
}

const calculateUnitToMinutes = (quantity: number, unit: string) => {
    switch (unit) {
        case 'd':
            return quantity * 1440 // 1 day = 1440 minutes
        case 'h':
            return quantity * 60 // 1 hour = 60 minutes
        case 'm':
            return quantity // 1 minute = 1 minute
        default:
            return 0 // Return 0 for unknown units
    }
}

export const convertMinutesToHours = (minutes: number): string => {
    const hours = Math.floor(minutes / 60) // Get the whole number of hours
    const remainingMinutes = minutes % 60 // Get the remaining minutes

    // return `${hours}h ${remainingMinutes}m`
    return `${hours}`
}
