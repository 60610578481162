import { createAsyncThunk } from '@reduxjs/toolkit';
import { TASKSAPIDATA } from '../../../utils/API';

export const getTasksCount = createAsyncThunk<any, any>(
    'tasks/getTasksCount',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { PatientIds, CreatedAfter } = param
            const patients = PatientIds.map(
                (PatientId: string) => `PatientIds=${PatientId}`
            )
            const response = await TASKSAPIDATA.get(
                `/v1/Tasks/taskscount?${patients.join('&')}`,
                {
                    // headers: {
                    //     bearer: token,
                    // },
                    params: { CreatedAfter },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get tasks count from server: ', error)
            return rejectWithValue(error);
        }
    }
);

export const getTasksForHuddlePage = createAsyncThunk<any, any>(
    'tasks/getTasksForHuddlePage',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, PatientIds, CreatedAfter, OnlyOpen, visitIds } = param
            //Outdated, maybe will be deleted (patients)
            const visits = visitIds.map(
                (visitId: string) => `VisitIds=${visitId}`
            )
            // @ts-ignore
            const response = await TASKSAPIDATA.get(
                `/v1/Tasks?${visits.join('&')}`,
                {
                    headers: {
                        bearer: token,
                    },
                    params: {
                        CreatedAfter, OnlyOpen
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get tasks count from server: ', error)
            return rejectWithValue(error);
        }
    }
);


export const getActionItems = createAsyncThunk<any, any>(
    'tasks/getActionItems',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, AsigneeId, CcId, PatientIds, CreatedAfter } = param
            const patients =
                PatientIds &&
                PatientIds.length > 0 &&
                PatientIds.map((PatientId: string) => `PatientIds=${PatientId}`)
            const patientIds = patients && patients.length > 0 && patients.join('&')

            const response = await TASKSAPIDATA.get(
                `/v1/ActionItems?${patientIds}`,
                {
                    headers: {
                        bearer: token,
                    },
                    params: { AsigneeId, CcId, CreatedAfter },
                }
            )
            return response.data
        } catch (error) {
            console.log('Error can not get action items from server: ', error)
            return rejectWithValue(error);
        }
    }
);

export const updateActionItems = createAsyncThunk<any, any>(
    'tasks/updateActionItems',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const {
                ccId,
                name,
                text,
                token,
                dueDate,
                asigneeId,
                patientId,
                patientIds,
            } = param
            const response = await TASKSAPIDATA.post(
                '/v1/ActionItems',
                { ccId, name, text, dueDate, asigneeId, patientId, patientIds },
                {
                    headers: {
                        bearer: token,
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not SET action items TO server: ', error)
            return rejectWithValue(error);
        }
    }
);

export const updateNotes = createAsyncThunk<any, any>(
    'tasks/updateNotes',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, patientId, note, state } = param
            const response = await TASKSAPIDATA.post(
                '/v1/Notes',
                { patientId, note, state },
                {
                    headers: {
                        bearer: token,
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not SET action items TO server: ', error)
            return rejectWithValue(error);
        }
    }
);


export const updateHuddleTasks = createAsyncThunk<any, any>(
    'tasks/updateHuddleTasks',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, patientId, visitId, text, role } = param
            const response = await TASKSAPIDATA.post(
                '/v1/Tasks',
                { patientId, visitId, text },
                {
                    headers: {
                        bearer: token,
                        role: role
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not update tasks in server: ', error)
            return rejectWithValue(error);
        }
    }
);


export const setTasksMarkAsDone = createAsyncThunk<any, any>(
    'tasks/setTasksMarkAsDone',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, id, done } = param
            const response = await TASKSAPIDATA.post(
                '/v1/Tasks/markasdone',
                { id, done },
                {
                    headers: {
                        bearer: token,
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not set tasks is done in server: ', error)
            return rejectWithValue(error);
        }
    }
);

export const getTasksForUpdate = createAsyncThunk<any, any>(
    'tasks/getTasksForUpdate',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, Id, CreatedAfter } = param
            const response = await TASKSAPIDATA.get(
                '/v1/Tasks/getforupdate',
                {
                    headers: {
                        bearer: token,
                    },
                    params: { Id, CreatedAfter },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get tasks for update from server: ', error)
            return rejectWithValue(error);
        }
    }
);
