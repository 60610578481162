import React from 'react'
import { IconTypeProps } from '../IconType'

const PendingTransfer: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5907 8.2516V9.87805H12.5246C12.3089 9.87805 12.1021 9.96373 11.9496 10.1162C11.7971 10.2687 11.7114 10.4756 11.7114 10.6913C11.7114 10.907 11.7971 11.1138 11.9496 11.2663C12.1021 11.4188 12.3089 11.5045 12.5246 11.5045H17.404C17.6196 11.5045 17.8265 11.4188 17.979 11.2663C18.1315 11.1138 18.2172 10.907 18.2172 10.6913V9.93497L21.0147 12.1551L18.2172 14.5947V13.9442C18.2172 13.7285 18.1315 13.5216 17.979 13.3691C17.8265 13.2166 17.6196 13.1309 17.404 13.1309H10.085C9.86927 13.1309 9.66243 13.2166 9.50992 13.3691C9.35741 13.5216 9.27173 13.7285 9.27173 13.9442C9.27173 14.1598 9.35741 14.3667 9.50992 14.5192C9.66243 14.6717 9.86927 14.7574 10.085 14.7574H16.5907V16.3838C16.5909 16.5401 16.6362 16.693 16.721 16.8243C16.8059 16.9555 16.9267 17.0596 17.0691 17.1239C17.2116 17.1883 17.3695 17.2102 17.5241 17.1871C17.6786 17.164 17.8233 17.0969 17.9407 16.9938L22.82 12.7406C22.9068 12.6644 22.9763 12.5706 23.024 12.4654C23.0717 12.3603 23.0964 12.2461 23.0965 12.1307C23.0936 12.0113 23.0643 11.894 23.0109 11.7872C22.9575 11.6804 22.8812 11.5866 22.7875 11.5126L17.9082 7.63355C17.7896 7.53981 17.6473 7.48096 17.4972 7.46358C17.3471 7.44621 17.1951 7.471 17.0583 7.53518C16.9215 7.59936 16.8053 7.70039 16.7227 7.82694C16.6401 7.95348 16.5944 8.10053 16.5907 8.2516Z"
                fill="url(#paint0_linear_11989_12576)"
            />
            <path
                d="M9.25822 16.5736C9.04132 14.6654 8.17052 13.8746 7.53448 13.2978C7.09474 12.8975 6.88331 12.6893 6.88331 12.3268C6.88331 11.9692 7.094 11.7649 7.5325 11.3728C8.17598 10.7978 9.0572 10.0102 9.25871 8.07626C9.27482 7.91378 9.25664 7.74973 9.20534 7.59473C9.15404 7.43972 9.07077 7.29721 8.96092 7.17642C8.8436 7.04741 8.70055 6.9444 8.541 6.87405C8.38144 6.80369 8.20891 6.76755 8.03453 6.76795H2.55561C2.38099 6.7674 2.2082 6.80347 2.04838 6.87383C1.88856 6.94419 1.74526 7.04727 1.62774 7.17642C1.51821 7.29738 1.43526 7.43995 1.38422 7.59494C1.33318 7.74993 1.3152 7.91389 1.33143 8.07626C1.53219 10.004 2.41019 10.7859 3.05119 11.3567C3.49391 11.751 3.70684 11.9568 3.70684 12.3268C3.70684 12.7015 3.49342 12.9114 3.04921 13.3132C2.41639 13.8865 1.54783 14.6714 1.33193 16.5736C1.3144 16.7353 1.3312 16.8989 1.38123 17.0537C1.43126 17.2085 1.5134 17.351 1.62228 17.4719C1.74005 17.6026 1.88404 17.707 2.04485 17.7782C2.20567 17.8495 2.37971 17.8861 2.55561 17.8856H8.03453C8.21044 17.8861 8.38447 17.8495 8.54529 17.7782C8.70611 17.707 8.8501 17.6026 8.96787 17.4719C9.07674 17.351 9.15888 17.2085 9.20891 17.0537C9.25895 16.8989 9.27575 16.7353 9.25822 16.5736ZM7.46152 16.6944H3.13929C2.75216 16.6944 2.64297 16.2477 2.91446 15.9708C3.57159 15.3047 4.89801 14.8277 4.89801 14.0639V11.5327C4.89801 11.0401 3.955 10.6641 3.37157 9.86501C3.27528 9.73323 3.28496 9.54736 3.52965 9.54736H7.07166C7.28037 9.54736 7.32528 9.73175 7.23048 9.86377C6.65549 10.6641 5.69213 11.0376 5.69213 11.5327V14.0639C5.69213 14.8215 7.07464 15.2303 7.68735 15.9715C7.93427 16.2703 7.84791 16.6944 7.46152 16.6944Z"
                fill="url(#paint1_linear_11989_12576)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12576"
                    x1="9.27173"
                    y1="12.3637"
                    x2="21.9713"
                    y2="16.144"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12576"
                    x1="1.3252"
                    y1="12.3686"
                    x2="9.09083"
                    y2="13.5314"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default PendingTransfer
