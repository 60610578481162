import { generateDate } from '../date/generateDate'

export const INFLOW_PREDICTION_MOCK = {
    departments: [
        {
            departmentName: 'Psychiatry',
            predictions: [
                {
                    predictions: [
                        65.21428571428571,
                        65.84523809523809,
                        66.47619047619047
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Surgery',
            predictions: [
                {
                    predictions: [
                        159.25,
                        163.75,
                        168.25
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'SSU',
            predictions: [
                {
                    predictions: [
                        22.392857142857142,
                        22.369047619047617,
                        22.345238095238095
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Palliative',
            predictions: [
                {
                    predictions: [
                        17.92857142857143,
                        18.023809523809526,
                        18.11904761904762
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Cardiology',
            predictions: [
                {
                    predictions: [
                        57.714285714285715,
                        57.42857142857143,
                        57.142857142857146
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Geriatrics',
            predictions: [
                {
                    predictions: [
                        34.42857142857143,
                        34.44047619047619,
                        34.452380952380956
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'IM non Teach',
            predictions: [
                {
                    predictions: [
                        34.964285714285715,
                        34.92857142857143,
                        34.892857142857146
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Family Med',
            predictions: [
                {
                    predictions: [
                        27.857142857142858,
                        27.964285714285715,
                        28.071428571428573
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Neurology',
            predictions: [
                {
                    predictions: [
                        28.75,
                        28.416666666666668,
                        28.083333333333336
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Critical Care',
            predictions: [
                {
                    predictions: [
                        31.10714285714286,
                        30.54761904761905,
                        29.98809523809524
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'HAD',
            predictions: [
                {
                    predictions: [
                        8.464285714285714,
                        8.428571428571429,
                        8.392857142857142
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Oncology',
            predictions: [
                {
                    predictions: [
                        34.964285714285715,
                        35.42857142857143,
                        35.892857142857146
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'Special Units',
            predictions: [
                {
                    predictions: [
                        53.142857142857146,
                        47.702380952380956,
                        42.261904761904766
                    ],
                    date: generateDate(0),
                },
            ],
        },
        {
            departmentName: 'IM Teach',
            predictions: [
                {
                    predictions: [
                        74.14285714285714,
                        74.53571428571429,
                        74.92857142857143
                    ],
                    date: generateDate(0),
                },
            ],
        },
    ],
}
