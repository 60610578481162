import React, { CSSProperties, ReactNode } from 'react'
import styles from './WidgetTitle.module.scss'

interface WidgetTitleProps {
    children: ReactNode
    color?: string
    isLight?: boolean
    style?: CSSProperties
}

const WidgetTitle = ({ children, color, isLight, style }: WidgetTitleProps) => {
    return (
        <div
            className={`${styles.title} ${
                isLight ? styles.title_light : styles.title_dark
            }`}
            style={style}
        >
            {children}
        </div>
    )
}

export default WidgetTitle
