import React, { ReactNode } from 'react'
import styles from './CenteredWrapper.module.scss'

interface CenteredWrapperProps {
    children: ReactNode
}

const CenteredWrapper: React.FC<CenteredWrapperProps> = ({ children }) => {
    return <div className={styles.centered_wrapper}>{children}</div>
}

export default CenteredWrapper
