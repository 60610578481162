import * as React from 'react'
import LogoBar from '../../Header/LogoBar/LogoBar'
import OpenAlertsButton from '../../Header/OpenAlertsButton/OpenAlertsButton'
import UserMenu from '../../Header/UserMenu/UserMenu'

import styles from './ManagementHeader.module.scss'
import ManagementMenu from './ManagementMenu/ManagementMenu'

const ManagementHeader = () => {
    return (
        <header className={styles.header}>
            <div className={styles.header_items_left}>
                <ManagementMenu />
            </div>
            <div className={styles.header_items_right}>
                {/* <OpenAlertsButton /> */}
                <UserMenu type="jgh" />
                <LogoBar logoType="management_status" />
            </div>
        </header>
    )
}

export default ManagementHeader
