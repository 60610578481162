import React, { useState, ChangeEvent } from 'react'
import { icons } from '../../../utils/iconsMap'

import styles from './CustomCheckbox.module.scss'
import DefaultCheckbox from './DefaultCheckbox'

interface CustomCheckboxProps {
    id: string | number
    label: string
    isChecked: boolean
    onChange: (itemId: string | number, isChecked: boolean) => void
    variant?: string
    colour?: string
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
    id,
    label,
    isChecked,
    onChange,
    variant,
    colour,
}) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(id, !isChecked)
    }

    const classString = `checkbox_item__${variant}`
    const defaultColour = '#7EC6DF'
    const svgColour = colour || defaultColour

    return (
        <div className={styles.checkbox}>
            <label
                className={`${styles.checkbox_item} ${
                    variant ? `${styles[classString]}` : ''
                }`}
            >
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ display: 'none' }}
                />
                {variant ? (
                    <div className={styles.checkbox_item_icon}>
                        <DefaultCheckbox
                            svgColour={svgColour}
                            isChecked={isChecked}
                        />
                    </div>
                ) : (
                    <img
                        src={isChecked ? icons.checked : icons.checkbox}
                        alt={`checkbox ${label}`}
                        className={styles.checkbox_item_icon}
                    />
                )}
                <span className={`${styles.checkbox_item__lable}`}>
                    {label}
                </span>
            </label>
        </div>
    )
}

export default CustomCheckbox
