import React from 'react'
import TitleVsTextBox from '../TitleVsTextBox/TitleVsTextBox'
import styles from './PatientSchedule.module.scss'
import ScheduleItem from './ScheduleItem/ScheduleItem'

type Item = {
    id: any
    [key: string]: string
}

type PatientScheduleProps = {
    data: any
}

const PatientSchedule: React.FC<PatientScheduleProps> = ({ data }) => {
    console.log('🚀 ~ file: PatientSchedule.tsx:15 ~ data:', data)
    return (
        <div className={styles.schedule}>
            {data?.map((item: any) => (
                // <div className={styles.schedule__item}>
                // </div>
                <ScheduleItem
                    procedure={item.procedure}
                    status={item.procedureName}
                    doctor={item.doctor}
                    nurse={item.nurse}
                />
            ))}
        </div>
    )
}

export default PatientSchedule
