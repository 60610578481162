import React from 'react'
import { icons } from '../../../../utils/iconsMap'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CustomAccordion from '../../../common/CustomAccordion/CustomAccordion'

import styles from './OrOutflowList.module.scss'
import OutflowListItem from './OutflowListItem/OutflowListItem'
import {
    DestinationAfterSurgeryType,
    OrOutflowResponce,
} from 'redux/rtk/orStatus/types'
import { useFetchStatus } from 'hooks'
import { useGetOrOutflowDataQuery } from 'redux/rtk/orStatus/OrStatusApi'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'

type OrOutflowListProps = {
    title: string
    destinationAfterSurgeryType: DestinationAfterSurgeryType
}

const OrOutflowList: React.FC<OrOutflowListProps> = ({
    title,
    destinationAfterSurgeryType,
}) => {
    const { data, error, isFetching, isSuccess } = useGetOrOutflowDataQuery(
        destinationAfterSurgeryType,
        { pollingInterval: 300000 }
    )

    const fetchStatus = useFetchStatus({
        data,
        error,
        isFetching,
        isSuccess,
    })

    const renderItems = (data: OrOutflowResponce) => (
        <CustomAccordion.Item key="ed" value="ed">
            <CustomAccordion.Control>
                <CcTitle>
                    {title} ({data?.outflowList?.length || 0})
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {data?.outflowList?.length > 0 ? (
                        <div className={styles.container}>
                            {data?.outflowList?.map((item, index: number) => (
                                <OutflowListItem {...item} />
                            ))}
                        </div>
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <>
            {/* {fetchStatus ? (
                fetchStatus
            ) : ( */}
            <CustomAccordion
                chevronPosition="left"
                chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
            >
                {isSuccess && data && renderItems(data)}
            </CustomAccordion>
            {/* )} */}
        </>
    )
}
export default OrOutflowList
