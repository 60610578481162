import styles from './Loader.module.scss'
import { Loader as Load } from '@mantine/core'

const Loader = () => {
    return (
        <>
            <div className={styles.loader_wrap}>
                <Load size={'xl'} />
            </div>
        </>
    )
}

export default Loader
