import React from 'react'
import { useSelectedButtons } from '../../../contexts/SelectedButtonsContext'
import FiltersButton from '../../common/FiltersButton/FiltersButton'
import { Counts } from '../helpers'

import styles from './FilterButtons.module.scss'
import { useTranslation } from 'react-i18next'

type ButtonData = {
    label: string
    name: string
    variant?: string
}

type FilterButtonsProps = {
    filterId: string
    buttonsData: ButtonData[]
    withReset?: boolean
    counters?: Counts
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
    filterId,
    buttonsData,
    counters,
}) => {
    const { t } = useTranslation()
    const { selectedButtons, handleButtonClick } = useSelectedButtons()
    const currentFilterValue =
        selectedButtons.find((filter) => filter.id === filterId)?.value || []

    const excludedButtonNames = new Set(['all', 'reset all filters'])
    const allNames = buttonsData
        .filter((button) => !excludedButtonNames.has(button.name))
        .map((button) => button.name)

    const handleAllClick = () => {
        const newValues =
            currentFilterValue.length === allNames.length
                ? ['reset all filters']
                : allNames
        handleButtonClick(filterId, newValues)
    }

    return (
        <div className={styles.buttons}>
            {buttonsData.map((button: ButtonData, index) => (
                <FiltersButton
                    key={`${filterId}_${index}`}
                    radius="xl"
                    size="lg"
                    variant={button?.variant || 'outline'}
                    isActive={
                        currentFilterValue.includes(button.name) &&
                        button.name !== 'all'
                            ? true
                            : false
                    }
                    onClick={() =>
                        button.name === 'all'
                            ? handleAllClick()
                            : handleButtonClick(filterId, button.name)
                    }
                >
                    {t(button.label)}
                    {counters && counters[button.name]
                        ? ` (${counters[button.name]})`
                        : null}
                </FiltersButton>
            ))}
        </div>
    )
}

export default FilterButtons
