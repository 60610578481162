import React from 'react'
import { IconTypeProps } from '../IconType'

const IcuIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor

    return (
        <svg
            width="18"
            height="11"
            viewBox="0 0 18 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.80078 7.99844C9.77503 7.99844 9.74928 7.99844 9.72352 7.99367C9.47406 7.9651 9.26483 7.80161 9.17953 7.56827L9.16665 7.52383L8.23155 3.56345L7.37854 5.89682C7.26427 6.11111 7.04055 6.24444 6.79591 6.24444H1.16018C0.938072 6.24444 0.757812 6.06666 0.757812 5.84761C0.757812 5.62856 0.938072 5.45078 1.16018 5.45078H6.6913L7.67951 2.74597C7.80183 2.51898 8.04808 2.38247 8.30881 2.39993C8.56954 2.41739 8.79487 2.58565 8.88339 2.82851L8.89626 2.87296L9.82814 6.82223L10.2015 5.78412L10.2273 5.74443C10.3496 5.5603 10.5556 5.44919 10.7793 5.44919H16.6699C16.892 5.44919 17.0722 5.62697 17.0722 5.84602C17.0722 6.06507 16.892 6.24285 16.6699 6.24285H10.8888L10.3786 7.66351L10.3528 7.7032C10.2305 7.88891 10.0229 7.99844 9.80239 7.99844H9.80078Z"
                fill="url(#paint0_linear_9454_9710)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.20703 1H11.8114C12.3637 1 12.8114 1.44771 12.8114 2V4H13.8114V2C13.8114 0.895431 12.916 0 11.8114 0H5.20703C4.10246 0 3.20703 0.895432 3.20703 2V4H4.20703V2C4.20703 1.44772 4.65475 1 5.20703 1ZM4.20703 7.2H3.20703V8.4C3.20703 9.50457 4.10246 10.4 5.20703 10.4H11.8114C12.916 10.4 13.8114 9.50457 13.8114 8.4V7.2H12.8114V8.4C12.8114 8.95228 12.3637 9.4 11.8114 9.4H5.20703C4.65475 9.4 4.20703 8.95228 4.20703 8.4V7.2Z"
                fill="url(#paint1_linear_9454_9710)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9454_9710"
                    x1="0.757813"
                    y1="5.21949"
                    x2="12.6392"
                    y2="12.4769"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={defaultColor1} />
                    <stop offset="1" stopColor={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_9454_9710"
                    x1="3.20703"
                    y1="5.2391"
                    x2="13.3479"
                    y2="7.4071"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={defaultColor1} />
                    <stop offset="1" stopColor={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default IcuIcon
