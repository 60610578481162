import React from 'react'
import { icons } from '../../../../utils/iconsMap'
import CcTitle from '../../../common/CcTitle/CcTitle'

import styles from './CapacityPercentage.module.scss'
import { useTranslation } from 'react-i18next'

type CapacityPercentageProps = {
    totalOccupied: number
    totalCapacity: number
}

const CapacityPercentage: React.FC<CapacityPercentageProps> = ({
    totalOccupied,
    totalCapacity,
}) => {
    const percentage = Math.round((totalOccupied / totalCapacity) * 100)
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.container__capacity}>
                <div className={styles.container__capacity__percentage}>
                    {percentage}
                    <span>%</span>
                </div>
                <div className={styles.container__capacity__divider}></div>
                <div className={styles.container__capacity__total}>
                    <img
                        src={icons.bed}
                        className={styles.container__capacity__total__img}
                        alt="bed icon"
                    />
                    <div>{totalCapacity}</div>
                </div>
            </div>

            <CcTitle>
                <div className={styles.container__footer}>
                    {t('cc.occupancy.of capacity', { simbol: '%' })} /{' '}
                    {t('cc.occupancy.number ob beds', { simbol: '#' })}
                </div>
            </CcTitle>
        </div>
    )
}

export default CapacityPercentage
