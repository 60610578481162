import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { InfectionResponce } from './types'
import { INFECTION_MOCK_DATA } from 'utils/__Mocks__/InvectionMock'
import { prepareHeaders } from '../helpers/prepareHeaders'
import { createQueryFn } from '../helpers/createQueryFn'

const unitsBaseURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}/api/v1/units/`

export const unitsApi = createApi({
    reducerPath: 'unitsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: unitsBaseURL,
        prepareHeaders,
    }),

    tagTypes: ['Infections'],

    endpoints: (builder) => ({
        getInfectionControl: builder.query<InfectionResponce, void>({
            // query: () => `infection-control`,
            // providesTags: [{ type: 'Infections', id: 'LIST_INFECTION' }],
            // This is for mocking data
            //@ts-ignore
            queryFn: createQueryFn(
                () => `${unitsBaseURL}infection-control`,
                () => INFECTION_MOCK_DATA
            ),
        }),
    }),
})

export const { useGetInfectionControlQuery } = unitsApi
