import {
    FlowData,
    OrInflowResponce,
    OrOutflowResponce,
} from 'redux/rtk/orStatus/types'
import { generateDate } from '../date/generateDate'

//temporary
export const OR_OUTFLOWS_MOCK = {
    icu: [
        {
            name: 'Gavin Tremblay',
            time: 68,
            delay: 60,
            total: 120,
        },
        {
            name: 'Florence Bergeron',
            time: 30,
            delay: 60,
            total: 120,
        },
        {
            name: 'Isla Taylor',
            time: 97,
            delay: 60,
            total: 120,
        },
    ],
}
//temporary
export const OR_INFLOW_MOCK = [
    {
        name: 'Test_1',
        value: 20,
        total: 100,
        color: 'green',
    },
    {
        name: 'ED',
        value: 45,
        total: 100,
        color: 'blue',
    },
    {
        name: 'Units',
        value: 70,
        total: 100,
        color: 'orange',
    },
    {
        name: 'Home',
        value: 100,
        total: 100,
        color: 'red',
    },
]

export const TREND_OVER_MOCK = [
    { value: 20, time: generateDate(8) },
    { value: 35, time: generateDate(7) },
    { value: 40, time: generateDate(6) },
    { value: 30, time: generateDate(5) },
    { value: 50, time: generateDate(4) },
    { value: 60, time: generateDate(3) },
    { value: 75, time: generateDate(2) },
    { value: 90, time: generateDate(1) },
    { value: 95, time: generateDate(0) },
]

export const TREND_OVER_MOCK_LOS = [
    { value: 15, time: generateDate(8) },
    { value: 25, time: generateDate(7) },
    { value: 40, time: generateDate(6) },
    { value: 30, time: generateDate(5) },
    { value: 45, time: generateDate(4) },
    { value: 35, time: generateDate(3) },
    { value: 50, time: generateDate(2) },
    { value: 70, time: generateDate(1) },
    { value: 70, time: generateDate(0) },
]

export const TREND_OVER_MOCK_NB = [
    { value: 20, time: generateDate(8) },
    { value: 15, time: generateDate(7) },
    { value: 35, time: generateDate(6) },
    { value: 30, time: generateDate(5) },
    { value: 40, time: generateDate(4) },
    { value: 50, time: generateDate(3) },
    { value: 55, time: generateDate(2) },
    { value: 70, time: generateDate(1) },
    { value: 75, time: generateDate(0) },
]

export const TREND_UNITS_MOCK_GAUGE = {
    capacity: 15,
    currentValue: 2,
}

export const TREND_PERIOD_MOCK_GAUGE = {
    capacity: 15,
    currentValue: 6,
}

export const INFLOW_ER_MOCK: OrInflowResponce = {
    inflowList: [
        {
            mrn: '123456',
            patientName: 'John',
            patientLastName: 'Doe',
            surgerySchedule: '2024-06-21T08:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T08:50:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 101,
            location: 'Room A',
            admissionType: 'ER',
        },
        {
            mrn: '654321',
            patientName: 'Jane',
            patientLastName: 'Smith',
            surgerySchedule: '2024-06-21T09:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T10:00:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 102,
            location: 'Room B',
            admissionType: 'ER',
        },
        {
            mrn: '456321',
            patientName: 'Rob',
            patientLastName: 'Stark',
            surgerySchedule: '2024-06-21T09:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T09:45:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 103,
            location: 'Room C',
            admissionType: 'ER',
        },
    ],
}

export const INFLOW_IP_ID_MOCK: OrInflowResponce = {
    inflowList: [
        {
            mrn: '789012',
            patientName: 'Alice',
            patientLastName: 'Johnson',
            surgerySchedule: '2024-06-21T10:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T10:45:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 104,
            location: 'Room D',
            admissionType: 'IP-ID',
        },
        {
            mrn: '890123',
            patientName: 'Bob',
            patientLastName: 'Brown',
            surgerySchedule: '2024-06-21T11:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T12:30:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 105,
            location: 'Room E',
            admissionType: 'IP-ID',
        },
        {
            mrn: '901234',
            patientName: 'Charlie',
            patientLastName: 'Davis',
            surgerySchedule: '2024-06-21T12:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T13:45:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 106,
            location: 'Room F',
            admissionType: 'IP-ID',
        },
    ],
}

export const INFLOW_IP_UNITS_MOCK: OrInflowResponce = {
    inflowList: [
        {
            mrn: '1756621',
            patientName: 'AMINATA',
            patientLastName: 'TRAORE',
            surgerySchedule: '2024-08-16T09:51:00',
            expectedSurgeryEndDate: '2024-08-16T12:54:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1474663',
            patientName: 'CHRISTIANE',
            patientLastName: 'LAPOINTE',
            surgerySchedule: '2024-08-16T13:09:00',
            expectedSurgeryEndDate: '2024-08-16T14:49:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '292612',
            patientName: 'STANLEY',
            patientLastName: 'SCHEIGETZ',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T09:36:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1774794',
            patientName: 'PHILIPPE',
            patientLastName: 'FINNERTY',
            surgerySchedule: '2024-08-16T08:30:00',
            expectedSurgeryEndDate: '2024-08-16T12:12:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1781392',
            patientName: 'DOMINIK',
            patientLastName: 'GEARGEOURA',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T12:44:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1374115',
            patientName: 'SIMON',
            patientLastName: 'PAPINEAU',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T09:08:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1675349',
            patientName: 'NOHA',
            patientLastName: 'AHMED',
            surgerySchedule: '2024-08-16T13:07:00',
            expectedSurgeryEndDate: '2024-08-16T17:01:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1686127',
            patientName: 'MICHAEL',
            patientLastName: 'FOX',
            surgerySchedule: '2024-08-16T13:42:00',
            expectedSurgeryEndDate: '2024-08-16T15:20:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '823078',
            patientName: 'KENGATHARAN',
            patientLastName: 'SINNATHURAI',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T16:15:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1674662',
            patientName: 'LEANG ENG',
            patientLastName: 'CHHAY',
            surgerySchedule: '2024-08-16T09:23:00',
            expectedSurgeryEndDate: '2024-08-16T10:46:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1212791',
            patientName: 'PILAR',
            patientLastName: 'CORTEZ SANTOS',
            surgerySchedule: '2024-08-16T15:12:00',
            expectedSurgeryEndDate: '2024-08-16T16:15:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1185245',
            patientName: 'MONA',
            patientLastName: 'CHEHADE',
            surgerySchedule: '2024-08-16T09:55:00',
            expectedSurgeryEndDate: '2024-08-16T11:49:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '5193',
            patientName: 'EVELYNE',
            patientLastName: 'ALLEN',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T09:40:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1799876',
            patientName: 'CLAUDINE',
            patientLastName: 'CARBONNEAU',
            surgerySchedule: '2024-08-16T11:01:00',
            expectedSurgeryEndDate: '2024-08-16T12:24:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '816427',
            patientName: 'RICHARD STANLEY',
            patientLastName: 'MEAWASIGE',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T12:52:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PS    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '306992',
            patientName: 'SUZANNE',
            patientLastName: 'MEUNIER',
            surgerySchedule: '2024-08-16T13:24:00',
            expectedSurgeryEndDate: '2024-08-16T14:57:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '353464',
            patientName: 'JUDY',
            patientLastName: 'FINESTONE',
            surgerySchedule: '2024-08-16T12:04:00',
            expectedSurgeryEndDate: '2024-08-16T13:09:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1393643',
            patientName: 'STEPHANE',
            patientLastName: 'RASHOTTE',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T14:25:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: '5503     ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1177778',
            patientName: 'RAHEL YARDENAH',
            patientLastName: 'LAHIANY',
            surgerySchedule: '2024-08-16T12:39:00',
            expectedSurgeryEndDate: '2024-08-16T13:27:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1802181',
            patientName: 'JEAN-MARIE',
            patientLastName: 'THIBEAULT',
            surgerySchedule: '2024-08-16T13:20:00',
            expectedSurgeryEndDate: '2024-08-16T18:27:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K0222    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1071147',
            patientName: 'JOHN D',
            patientLastName: 'MASON',
            surgerySchedule: '2024-08-16T14:22:00',
            expectedSurgeryEndDate: '2024-08-16T15:16:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K0805    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1800457',
            patientName: 'RAYMOND',
            patientLastName: 'BEAUDRY',
            surgerySchedule: '2024-08-16T12:30:00',
            expectedSurgeryEndDate: '2024-08-16T14:07:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'C372     ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1152120',
            patientName: 'JACQUES',
            patientLastName: 'LEMIEUX',
            surgerySchedule: '2024-08-16T07:45:00',
            expectedSurgeryEndDate: '2024-08-16T13:05:00',
            timeDelayLevel: 0,
            surgeryRoom: '',
            location: 'K0276    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '998774',
            patientName: 'DONIA',
            patientLastName: 'SAAD ELDINE',
            surgerySchedule: '2024-08-16T00:00:00',
            expectedSurgeryEndDate: '2024-08-16T02:33:00',
            timeDelayLevel: 2,
            surgeryRoom: '06',
            location: 'K01PD    ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '400629',
            patientName: 'WILFRED',
            patientLastName: 'CHEVRIER',
            surgerySchedule: '2024-08-16T00:00:00',
            expectedSurgeryEndDate: '2024-08-16T01:30:00',
            timeDelayLevel: 2,
            surgeryRoom: '06',
            location: 'C472     ',
            admissionType: 'IPUnits',
        },
        {
            mrn: '1011504',
            patientName: 'SHIRLEY',
            patientLastName: 'HEAD',
            surgerySchedule: '2024-08-16T00:00:00',
            expectedSurgeryEndDate: '2024-08-16T01:30:00',
            timeDelayLevel: 2,
            surgeryRoom: '06',
            location: 'C472     ',
            admissionType: 'IPUnits',
        },
    ],
}
export const INFLOW_ODS_MOCK: OrInflowResponce = {
    inflowList: [
        {
            mrn: '456789',
            patientName: 'George',
            patientLastName: 'Harris',
            surgerySchedule: '2024-06-21T16:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T16:30:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 110,
            location: 'Room J',
            admissionType: 'ODS',
        },
        {
            mrn: '567890',
            patientName: 'Hannah',
            patientLastName: 'Iverson',
            surgerySchedule: '2024-06-21T17:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T17:45:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 111,
            location: 'Room K',
            admissionType: 'ODS',
        },
        {
            mrn: '890123',
            patientName: 'Bob',
            patientLastName: 'Brown',
            surgerySchedule: '2024-06-21T11:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T11:30:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 112,
            location: 'Room Z',
            admissionType: 'ODS',
        },
    ],
}

export const INFLOW_DATA_RESPONSE_MOCK = {
    inflowList: [
        {
            mrn: '456789',
            patientName: 'George',
            patientLastName: 'Harris',
            surgerySchedule: '2024-06-21T16:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T16:30:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 110,
            location: 'Room J',
            admissionType: 'ODS',
        },
        {
            mrn: '567890',
            patientName: 'Hannah',
            patientLastName: 'Iverson',
            surgerySchedule: '2024-06-21T17:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T17:45:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 111,
            location: 'Room K',
            admissionType: 'ODS',
        },
        {
            mrn: '890123',
            patientName: 'Bob',
            patientLastName: 'Brown',
            surgerySchedule: '2024-06-21T11:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T11:30:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 112,
            location: 'Room Z',
            admissionType: 'ODS',
        },
        {
            mrn: '012345',
            patientName: 'Diana',
            patientLastName: 'Evans',
            surgerySchedule: '2024-06-21T13:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T13:30:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 107,
            location: 'Room G',
            admissionType: 'IP-Units',
        },
        {
            mrn: '234567',
            patientName: 'Edward',
            patientLastName: 'Franklin',
            surgerySchedule: '2024-06-21T14:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T15:00:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 108,
            location: 'Room H',
            admissionType: 'IP-Units',
        },
        {
            mrn: '345678',
            patientName: 'Fiona',
            patientLastName: 'Garcia',
            surgerySchedule: '2024-06-21T15:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T15:45:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 109,
            location: 'Room I',
            admissionType: 'IP-Units',
        },
        {
            mrn: '789012',
            patientName: 'Alice',
            patientLastName: 'Johnson',
            surgerySchedule: '2024-06-21T10:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T10:45:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 104,
            location: 'Room D',
            admissionType: 'IP-ID',
        },
        {
            mrn: '890123',
            patientName: 'Bob',
            patientLastName: 'Brown',
            surgerySchedule: '2024-06-21T11:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T12:30:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 105,
            location: 'Room E',
            admissionType: 'IP-ID',
        },
        {
            mrn: '901234',
            patientName: 'Charlie',
            patientLastName: 'Davis',
            surgerySchedule: '2024-06-21T12:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T13:45:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 106,
            location: 'Room F',
            admissionType: 'IP-ID',
        },
        {
            mrn: '123456',
            patientName: 'John',
            patientLastName: 'Doe',
            surgerySchedule: '2024-06-21T08:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T08:50:00Z',
            timeDelayLevel: 1,
            surgeryRoom: 101,
            location: 'Room A',
            admissionType: 'ER',
        },
        {
            mrn: '654321',
            patientName: 'Jane',
            patientLastName: 'Smith',
            surgerySchedule: '2024-06-21T09:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T10:00:00Z',
            timeDelayLevel: 2,
            surgeryRoom: 102,
            location: 'Room B',
            admissionType: 'ER',
        },
        {
            mrn: '456321',
            patientName: 'Rob',
            patientLastName: 'Stark',
            surgerySchedule: '2024-06-21T09:00:00Z',
            expectedSurgeryEndDate: '2024-06-21T09:45:00Z',
            timeDelayLevel: 0,
            surgeryRoom: 103,
            location: 'Room C',
            admissionType: 'ER',
        },
    ],
}

// type OutflowRecord = {
//     mrn: string
//     patientName: string
//     patientLastName: string
//     TransferDelay: number
//     location: string
// }

export const OUTFLOW_MSICU_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '345678',
            patientName: 'Alice',
            patientLastName: 'Johnson',
            transferDelay: 60,
            timeDelayLevel: 2,
            location: null,
            destinationAfterSurgery: 'MSICU',
        },
        {
            mrn: '990011',
            patientName: 'Quincy',
            patientLastName: 'Martin',
            transferDelay: 15,
            timeDelayLevel: 0,
            location: null,
            destinationAfterSurgery: 'MSICU',
        },
        {
            mrn: '112233',
            patientName: 'Isabel',
            patientLastName: 'Lopez',
            transferDelay: null,
            timeDelayLevel: 1,
            location: null,
            destinationAfterSurgery: 'MSICU',
        },
    ],
}

export const OUTFLOW_PACU_MOCK: OrOutflowResponce = {
    outflowList: [
        // {
        //     mrn: '901234',
        //     patientName: 'Grace',
        //     patientLastName: 'Martinez',
        //     transferDelay: 20,
        //     timeDelayLevel: 1,
        //     location: 'Room I',
        //     destinationAfterSurgery: 'PACU',
        // },
        // {
        //     mrn: '778899',
        //     patientName: 'Olivia',
        //     patientLastName: 'Moore',
        //     transferDelay: 30,
        //     timeDelayLevel: 2,
        //     location: 'Room Q',
        //     destinationAfterSurgery: 'PACU',
        // },
        // {
        //     mrn: '171718',
        //     patientName: 'Wendy',
        //     patientLastName: 'Robinson',
        //     transferDelay: 5,
        //     timeDelayLevel: 0,
        //     location: 'Room Y',
        //     destinationAfterSurgery: 'PACU',
        // },
    ],
}

export const OUTFLOW_PERI_OP_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '171819',
            patientName: 'Xander',
            patientLastName: 'Walker',
            transferDelay: 20,
            timeDelayLevel: 1,
            location: 'Room Z',
            destinationAfterSurgery: 'PERIOP',
        },
        {
            mrn: '445566',
            patientName: 'Leo',
            patientLastName: 'Anderson',
            transferDelay: 15,
            timeDelayLevel: 0,
            location: 'Room N',
            destinationAfterSurgery: 'PERIOP',
        },
        {
            mrn: '334455',
            patientName: 'Karen',
            patientLastName: 'Wilson',
            transferDelay: 40,
            timeDelayLevel: 2,
            location: 'Room M',
            destinationAfterSurgery: 'PERIOP',
        },
        {
            mrn: '123456',
            patientName: 'Oscar',
            patientLastName: 'Foster',
            transferDelay: 30,
            timeDelayLevel: 2,
            location: 'Room Y',
            destinationAfterSurgery: 'PERIOP',
        },
        {
            mrn: '234567',
            patientName: 'Paula',
            patientLastName: 'Ward',
            transferDelay: 15,
            timeDelayLevel: 0,
            location: 'Room Z',
            destinationAfterSurgery: 'PERIOP',
        },
    ],
}

export const OUTFLOW_CVICU_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '202122',
            patientName: 'Amy',
            patientLastName: 'Scott',
            transferDelay: 30,
            timeDelayLevel: 2,
            location: 'Room CC',
            destinationAfterSurgery: 'CVICU',
        },
        {
            mrn: '567890',
            patientName: 'Chris',
            patientLastName: 'Brown',
            transferDelay: 5,
            timeDelayLevel: 0,
            location: 'Room E',
            destinationAfterSurgery: 'CVICU',
        },
        {
            mrn: '121314',
            patientName: 'Sam',
            patientLastName: 'Perez',
            transferDelay: 20,
            timeDelayLevel: 1,
            location: 'Room U',
            destinationAfterSurgery: 'CVICU',
        },
    ],
}

export const OUTFLOW_FBC_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '223344',
            patientName: 'Jack',
            patientLastName: 'Gonzalez',
            transferDelay: 30,
            timeDelayLevel: 1,
            location: 'Room L',
            destinationAfterSurgery: 'FBC',
        },
        {
            mrn: '101112',
            patientName: 'Rachel',
            patientLastName: 'Lee',
            transferDelay: 20,
            timeDelayLevel: 0,
            location: 'Room T',
            destinationAfterSurgery: 'FBC',
        },
        {
            mrn: '456789',
            patientName: 'Bob',
            patientLastName: 'Williams',
            transferDelay: 50,
            timeDelayLevel: 2,
            location: 'Room D',
            destinationAfterSurgery: 'FBC',
        },
        {
            mrn: '192021',
            patientName: 'Zach',
            patientLastName: 'King',
            transferDelay: 25,
            timeDelayLevel: 1,
            location: 'Room BB',
            destinationAfterSurgery: 'FBC',
        },
    ],
}
export const OUTFLOW_UNK_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '890123',
            patientName: 'Frank',
            patientLastName: 'Garcia',
            transferDelay: 30,
            timeDelayLevel: 1,
            location: 'Room H',
            destinationAfterSurgery: 'UNK',
        },
        {
            mrn: '789012',
            patientName: 'Linda',
            patientLastName: 'Jenkins',
            transferDelay: 10,
            timeDelayLevel: 0,
            location: 'Room X',
            destinationAfterSurgery: 'UNK',
        },
        {
            mrn: '890123',
            patientName: 'Mike',
            patientLastName: 'Ramirez',
            transferDelay: 60,
            timeDelayLevel: 2,
            location: 'Room G',
            destinationAfterSurgery: 'UNK',
        },
        {
            mrn: '901234',
            patientName: 'Nina',
            patientLastName: 'Cooper',
            transferDelay: 10,
            timeDelayLevel: 0,
            location: 'Room H',
            destinationAfterSurgery: 'UNK',
        },
    ],
}
export const OUTFLOW_ROOM_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '678901',
            patientName: 'Diana',
            patientLastName: 'Davis',
            transferDelay: 15,
            timeDelayLevel: 0,
            location: 'Room F',
            destinationAfterSurgery: 'ROOM',
        },
        {
            mrn: '131415',
            patientName: 'Tina',
            patientLastName: 'White',
            transferDelay: 60,
            timeDelayLevel: 2,
            location: 'Room V',
            destinationAfterSurgery: 'ROOM',
        },
        {
            mrn: '212223',
            patientName: 'Ben',
            patientLastName: 'Green',
            transferDelay: 45,
            timeDelayLevel: 1,
            location: 'Room DD',
            destinationAfterSurgery: 'ROOM',
        },
    ],
}

export const OUTFLOW_HOME_MOCK: OrOutflowResponce = {
    outflowList: [
        {
            mrn: '789012',
            patientName: 'Hank',
            patientLastName: 'Miller',
            transferDelay: 10,
            timeDelayLevel: 0,
            location: 'Room G',
            destinationAfterSurgery: 'HOME',
        },
        {
            mrn: '345678',
            patientName: 'Olga',
            patientLastName: 'Brown',
            transferDelay: 40,
            timeDelayLevel: 1,
            location: 'Room B',
            destinationAfterSurgery: 'HOME',
        },
        {
            mrn: '456789',
            patientName: 'Ian',
            patientLastName: 'Parker',
            transferDelay: 25,
            timeDelayLevel: 0,
            location: 'Room S',
            destinationAfterSurgery: 'HOME',
        },
        {
            mrn: '567890',
            patientName: 'Janet',
            patientLastName: 'Collins',
            transferDelay: 60,
            timeDelayLevel: 2,
            location: 'Room W',
            destinationAfterSurgery: 'HOME',
        },
        {
            mrn: '678901',
            patientName: 'Kevin',
            patientLastName: 'Bailey',
            transferDelay: 35,
            timeDelayLevel: 1,
            location: 'Room R',
            destinationAfterSurgery: 'HOME',
        },
    ],
}

export const NUMBER_OF_ROOMS_RUNNING_MOCK = {
    items: [
        {
            value: 1,
            time: generateDate(7),
        },
        {
            value: 2,
            time: generateDate(6),
        },
        {
            value: 3,
            time: generateDate(5),
        },
        {
            value: 4,
            time: generateDate(4),
        },
        {
            value: 2,
            time: generateDate(3),
        },
        {
            value: 3,
            time: generateDate(2),
        },
        {
            value: 4,
            time: generateDate(1),
        },
        {
            value: 3,
            time: generateDate(0),
        },
    ],
}

export const NUMBER_OF_ROOMS_RUNNING_LATE_MOCK = {
    items: [
        {
            value: 1,
            time: generateDate(7),
        },
        {
            value: 2,
            time: generateDate(6),
        },
        {
            value: 3,
            time: generateDate(5),
        },
        {
            value: 4,
            time: generateDate(4),
        },
        {
            value: 4,
            time: generateDate(3),
        },
        {
            value: 3,
            time: generateDate(2),
        },
        {
            value: 3,
            time: generateDate(1),
        },
        {
            value: 1,
            time: generateDate(0),
        },
    ],
}

export const ON_TIME_START_MOCK = {
    items: [
        {
            value: 0,
            time: generateDate(7),
        },
        {
            value: 1,
            time: generateDate(6),
        },
        {
            value: 0,
            time: generateDate(5),
        },
        {
            value: 0,
            time: generateDate(4),
        },
        {
            value: 0,
            time: generateDate(3),
        },
        {
            value: 0,
            time: generateDate(2),
        },
        {
            value: 3,
            time: generateDate(1),
        },
        {
            value: 1,
            time: generateDate(0),
        },
    ],
}

export const ON_TIME__MOCK = {
    items: [
        {
            value: 0,
            time: generateDate(7),
        },
        {
            value: 1,
            time: generateDate(6),
        },
        {
            value: 0,
            time: generateDate(5),
        },
        {
            value: 0,
            time: generateDate(4),
        },
        {
            value: 0,
            time: generateDate(3),
        },
        {
            value: 0,
            time: generateDate(2),
        },
        {
            value: 3,
            time: generateDate(1),
        },
        {
            value: 1,
            time: generateDate(0),
        },
    ],
}

export const SUMMARIES_CASE_CANSELED_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: '2024-08-14T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'Double requete',
                    value: 1,
                },
                {
                    key: 'Medical condition',
                    value: 1,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: '2024-08-16T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'Previous case unanticipated difficulty',
                    value: 1,
                },
                {
                    key: 'Medical condition',
                    value: 1,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: '2024-08-19T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'Patient refused surgery',
                    value: 1,
                },
                {
                    key: 'No I.C.U. beds',
                    value: 1,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: '2024-08-18T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: '',
                    value: 0,
                },
            ],
            value: 0,
        },
        {
            surgeryScheduleDate: '2024-08-17T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: '',
                    value: 0,
                },
            ],
            value: 0,
        },
        {
            surgeryScheduleDate: '2024-08-20T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'Medical condition',
                    value: 1,
                },
            ],
            value: 1,
        },
        {
            surgeryScheduleDate: '2024-08-15T00:00:00Z',
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: '',
                    value: 0,
                },
            ],
            value: 0,
        },
    ],
}

export const SUMMARIES_ON_TIME_START_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'no delay',
                    value: 0,
                },
                {
                    key: 'Patient late arrival from Perio',
                    value: 1,
                },
                {
                    key: 'Surgeon late',
                    value: 3,
                },
                {
                    key: 'Patient went to washroom',
                    value: 1,
                },
                {
                    key: 'Patient late arrival from floor',
                    value: 1,
                },
                {
                    key: 'ER room-waiting for cases',
                    value: 1,
                },
                {
                    key: 'Change of theater',
                    value: 1,
                },
            ],
            value: 8,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'CaseCancelled',
            keys: [
                {
                    key: 'Done in Cath Lab',
                    value: 1,
                },
                {
                    key: 'Human ressource non available',
                    value: 1,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'Anesthesia late',
                    value: 1,
                },
                {
                    key: 'Human ressource non available',
                    value: 1,
                },
                {
                    key: 'Anesthesia did ECT',
                    value: 1,
                },
                {
                    key: 'Assesment patient out of room',
                    value: 1,
                },
            ],
            value: 4,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'Anesthesia late',
                    value: 1,
                },
                {
                    key: 'Human ressource non available',
                    value: 1,
                },
                {
                    key: 'Assesment patient out of room',
                    value: 1,
                },
            ],
            value: 3,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'First patient cancelled-PT order changed',
                    value: 1,
                },
                {
                    key: 'Surgeon late',
                    value: 1,
                },
                {
                    key: 'Assesment patient out of room',
                    value: 1,
                },
                {
                    key: 'Transfer requires multidisciplinary team',
                    value: 1,
                },
            ],
            value: 4,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'Improperly set-up of room',
                    value: 1,
                },
                {
                    key: 'Surgeon late',
                    value: 2,
                },
                {
                    key: 'Anesthesia late',
                    value: 1,
                },
                {
                    key: 'Change of theater',
                    value: 1,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'Incomplet chart',
                    value: 1,
                },
            ],
            value: 1,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'OnTimeStart',
            keys: [
                {
                    key: 'Done in Cath Lab',
                    value: 1,
                },
                {
                    key: 'Human ressource non available',
                    value: 1,
                },
            ],
            value: 2,
        },
    ],
}

export const SUMMARIES_ON_TIME_FINMISH_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Room air exchange protocol (latex)',
                    value: 1,
                },
                {
                    key: 'Anesthesia assesmment outside room',
                    value: 1,
                },
                {
                    key: 'Patient late arrival Periop',
                    value: 1,
                },
                {
                    key: 'Anesthesia late',
                    value: 1,
                },
                {
                    key: 'Equipment/bed reorganization',
                    value: 1,
                },
                {
                    key: 'Equipment reorganization-robot',
                    value: 1,
                },
                {
                    key: 'Anesthesia assement / trauma day',
                    value: 2,
                },
            ],
            value: 8,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Patient went to bathroom',
                    value: 1,
                },
                {
                    key: 'Surgeon patient preparation',
                    value: 2,
                },
                {
                    key: 'Anesthesia assement / trauma day',
                    value: 3,
                },
            ],
            value: 6,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Lack of orderlies sick call',
                    value: 2,
                },
                {
                    key: 'Surgeon patient preparation',
                    value: 1,
                },
                {
                    key: 'Complexe case-longer setup',
                    value: 1,
                },
                {
                    key: 'Anesthesia assement / trauma day',
                    value: 1,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: '',
                    value: 1,
                },
                {
                    key: 'Equipment/bed reorganization',
                    value: 1,
                },
                {
                    key: 'Surgeon late',
                    value: 2,
                },
                {
                    key: 'Patient late arrival Periop',
                    value: 1,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Equipment/bed reorganization',
                    value: 1,
                },
                {
                    key: 'Equipment reorganization-robot',
                    value: 1,
                },
                {
                    key: 'Anesthesia assement / trauma day',
                    value: 2,
                },
            ],
            value: 4,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Room air exchange protocol (latex)',
                    value: 1,
                },
                {
                    key: 'Anesthesia assesmment outside room',
                    value: 1,
                },
                {
                    key: 'Patient late arrival Periop',
                    value: 1,
                },
                {
                    key: 'Anesthesia late',
                    value: 1,
                },
                {
                    key: 'Equipment/bed reorganization',
                    value: 1,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: '',
                    value: 1,
                },
                {
                    key: 'Equipment/bed reorganization',
                    value: 1,
                },
                {
                    key: 'Surgeon late',
                    value: 2,
                },
                {
                    key: 'Patient late arrival Periop',
                    value: 1,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'OnTimeFinish',
            keys: [
                {
                    key: 'Surgeon late',
                    value: 2,
                },
                {
                    key: 'Patient late arrival Periop',
                    value: 1,
                },
            ],
            value: 3,
        },
    ],
}

export const SUMMARIES_NUMBER_ROOM_RUNNING_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 11,
                },
            ],
            value: 11,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 13,
                },
            ],
            value: 13,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 11,
                },
            ],
            value: 11,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 12,
                },
            ],
            value: 12,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 2,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 2,
                },
            ],
            value: 2,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 10,
                },
            ],
            value: 10,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'NumberRoomRunning',
            keys: [
                {
                    key: '',
                    value: 7,
                },
            ],
            value: 7,
        },
    ],
}

export const SUMMARIES_ROOMS_RUNNING_LATE_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 7,
                },
            ],
            value: 7,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 6,
                },
            ],
            value: 6,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 6,
                },
            ],
            value: 6,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 5,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 6,
                },
            ],
            value: 6,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 7,
                },
            ],
            value: 7,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 5,
                },
            ],
            value: 5,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 6,
                },
            ],
            value: 6,
        },
    ],
}

export const SUMMARIES_OR_UTILIZATION_RATE_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 91,
                },
            ],
            value: 91,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'RoomsRunningLate',
            keys: [
                {
                    key: '',
                    value: 83,
                },
            ],
            value: 83,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 82,
                },
            ],
            value: 82,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 90,
                },
            ],
            value: 90,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 77,
                },
            ],
            value: 77,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 85,
                },
            ],
            value: 85,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 80,
                },
            ],
            value: 80,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'OrUtilizationRate',
            keys: [
                {
                    key: '',
                    value: 89,
                },
            ],
            value: 89,
        },
    ],
}

export const SUMMARIES_AVERAGE_ROOM_TURNOVER_MOCK = {
    summaries: [
        {
            surgeryScheduleDate: generateDate(7),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 46,
                },
            ],
            value: 46,
        },
        {
            surgeryScheduleDate: generateDate(6),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 26,
                },
            ],
            value: 26,
        },
        {
            surgeryScheduleDate: generateDate(5),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 157,
                },
            ],
            value: 157,
        },
        {
            surgeryScheduleDate: generateDate(4),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 30,
                },
            ],
            value: 30,
        },
        {
            surgeryScheduleDate: generateDate(3),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 145,
                },
            ],
            value: 145,
        },
        {
            surgeryScheduleDate: generateDate(2),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 34,
                },
            ],
            value: 34,
        },
        {
            surgeryScheduleDate: generateDate(1),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 50,
                },
            ],
            value: 50,
        },
        {
            surgeryScheduleDate: generateDate(0),
            summaryType: 'AverageRoomTurnover',
            keys: [
                {
                    key: '',
                    value: 70,
                },
            ],
            value: 70,
        },
    ],
}

export const WAIT_LIST_NUMBERS_MOCK = {
    waitListNumbers: [
        {
            id: '00bc9301-a3b3-460a-978f-b602138abaa1',
            date: generateDate(7),
            numberWaitingRequests: 2174,
        },
        {
            id: 'af339c71-f55b-4b57-8811-83b4ad67c281',
            date: generateDate(6),
            numberWaitingRequests: 2167,
        },
        {
            id: '8f775bf5-0a8f-4f69-b36e-d7f61563abe5',
            date: generateDate(5),
            numberWaitingRequests: 2176,
        },
        {
            id: '10462189-9af0-4bd5-85c6-7ca9430efb20',
            date: generateDate(4),
            numberWaitingRequests: 2176,
        },
        {
            id: '9f70c199-0b4c-4154-8bdc-b228dd2ea9e6',
            date: generateDate(3),
            numberWaitingRequests: 2171,
        },
        {
            id: '849dd564-6dca-422f-b06c-90ce7f079c24',
            date: generateDate(2),
            numberWaitingRequests: 2173,
        },
        {
            id: '65345d1a-d007-47b6-b265-a40f910bd8e4',
            date: generateDate(1),
            numberWaitingRequests: 2161,
        },
        {
            id: '63994fbb-94a4-4e2a-8bf1-cffa5309d2b9',
            date: generateDate(0),
            numberWaitingRequests: 2181,
        },
    ],
}

export const OR_TRENDS_OUTFLOW_OR_MOCK = {
    outflows: [
        {
            destinationAfterSurgery: 'MSICU',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 2,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 5,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    destinationAfterSurgery: 'MSICU',
                    numberOfDischargedPatients: 2,
                },
            ],
        },
        {
            destinationAfterSurgery: 'OTHER',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    destinationAfterSurgery: 'OTHER',
                    numberOfDischargedPatients: 0,
                },
            ],
        },
        {
            destinationAfterSurgery: 'PERIOP',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 3,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    destinationAfterSurgery: 'PERIOP',
                    numberOfDischargedPatients: 5,
                },
            ],
        },
        {
            destinationAfterSurgery: 'PACU',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 3,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 19,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 27,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 21,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    destinationAfterSurgery: 'PACU',
                    numberOfDischargedPatients: 25,
                },
            ],
        },
    ],
}

export const OR_TRENDS_INFLOW_OR_MOCK = {
    inflows: [
        {
            admissionType: 'ODS',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 22,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 9,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 20,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 15,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'ODS',
                    numberOfAcceptedPatients: 17,
                },
            ],
        },
        {
            admissionType: 'SDS',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 9,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 7,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 7,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'SDS',
                    numberOfAcceptedPatients: 7,
                },
            ],
        },
        {
            admissionType: 'IPUnits',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 2,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 0,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 3,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 1,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'IPUnits',
                    numberOfAcceptedPatients: 0,
                },
            ],
        },
        {
            admissionType: 'IpEd',
            items: [
                {
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 4,
                },
                {
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 4,
                },
                {
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 6,
                },
                {
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 5,
                },
                {
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 10,
                },
                {
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'IpEd',
                    numberOfAcceptedPatients: 8,
                },
            ],
        },
    ],
}

export const OR_TRENDS_LOS_OR_MOCK = {
    losData: [
        {
            admissionType: 'SDS',
            items: [
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '96564b1d-b34e-43b2-af8e-8c5d4d6f2c26',
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 224,
                },
                {
                    id: '4867bbef-9aa1-42df-84a1-ef72799d2544',
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 223,
                },
                {
                    id: 'ea2a05cf-5edf-4592-a81a-734e8f964bd6',
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'SDS',
                    averageWaitingTimeHours: 488,
                },
            ],
        },
        {
            admissionType: 'IpEd',
            items: [
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '054e8b51-89a2-4f76-adcf-dbb5af207a03',
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 99,
                },
                {
                    id: 'b7fadd66-40d8-41e8-a54b-7d6c74408d8c',
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 189,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'IpEd',
                    averageWaitingTimeHours: 0,
                },
            ],
        },
        {
            admissionType: 'IPUnits',
            items: [
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: 'ce1b4c3f-4be4-4d48-a46b-35bf016a02e6',
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'IPUnits',
                    averageWaitingTimeHours: 213,
                },
            ],
        },
        {
            admissionType: 'ODS',
            items: [
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-26T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-25T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-24T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    surgeryScheduleDate: '2024-08-23T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 0,
                },
                {
                    id: 'a3de37a4-eeeb-4209-b478-ec33839ec795',
                    surgeryScheduleDate: '2024-08-22T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 109,
                },
                {
                    id: 'b6723e0e-dd97-4c09-b74c-213a7da08ab1',
                    surgeryScheduleDate: '2024-08-21T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 131,
                },
                {
                    id: '3e32dade-257e-4b52-bd12-0909aa9abf5a',
                    surgeryScheduleDate: '2024-08-20T00:00:00Z',
                    admissionType: 'ODS',
                    averageWaitingTimeHours: 240,
                },
            ],
        },
    ],
}

export const CURRENT_FLOW_MOCK: FlowData = {
    currentFlowList: {
        // IpEd: [
        //     {
        //         patientRecord: 'IP00001',
        //         patientFirstName: 'Eleanor',
        //         patientLastName: 'Rigby',
        //         admissionType: 'IpEd',
        //         surgeryProcedure: 'Hip Replacement with some other stuff',
        //         surgeryScheduleStartDateTime: '2024-09-01T09:00:00',
        //         surgeryActualizedStartDateTime: '2024-09-01T09:30:00',
        //         surgeryActualizedEndDateTime: '2024-09-01T10:30:00',
        //         timeDelayLevel: 1,
        //         timeDelayMinutes: 10,
        //         surgeryRoom: 'Room 7',
        //         nextPatientLocation: 'Room 2',
        //     },
        //     {
        //         patientRecord: 'IP00002',
        //         patientFirstName: 'Paul',
        //         patientLastName: 'McCartney',
        //         admissionType: 'IpEd',
        //         surgeryProcedure: 'Appendectomy',
        //         surgeryScheduleStartDateTime: '2024-09-02T11:00:00',
        //         surgeryActualizedStartDateTime: '2024-09-02T11:30:00',
        //         surgeryActualizedEndDateTime: '2024-09-02T12:00:00',
        //         timeDelayLevel: 2,
        //         timeDelayMinutes: 15,
        //         surgeryRoom: 'Room 5',
        //         nextPatientLocation: 'Room A',
        //     },
        //     {
        //         patientRecord: 'IP00003',
        //         patientFirstName: 'George',
        //         patientLastName: 'Harrison',
        //         admissionType: 'IpEd',
        //         surgeryProcedure: 'Cataract Surgery',
        //         surgeryScheduleStartDateTime: '2024-09-03T14:00:00',
        //         surgeryActualizedStartDateTime: '2024-09-03T14:20:00',
        //         surgeryActualizedEndDateTime: '2024-09-03T15:00:00',
        //         timeDelayLevel: 0,
        //         timeDelayMinutes: 10,
        //         surgeryRoom: 'Room 3',
        //         nextPatientLocation: 'Room B',
        //     },
        //     {
        //         patientRecord: 'IP00004',
        //         patientFirstName: 'Ringo',
        //         patientLastName: 'Starr',
        //         admissionType: 'IpEd',
        //         surgeryProcedure: 'Tonsillectomy',
        //         surgeryScheduleStartDateTime: '2024-09-04T10:00:00',
        //         surgeryActualizedStartDateTime: '2024-09-04T10:30:00',
        //         surgeryActualizedEndDateTime: '2024-09-04T11:00:00',
        //         timeDelayLevel: 3,
        //         timeDelayMinutes: 10,
        //         surgeryRoom: 'Room 4',
        //         nextPatientLocation: 'Room C',
        //     },
        // ],
        IPUnits: [
            {
                patientRecord: 'IP00005',
                patientFirstName: 'James',
                patientLastName: 'Bond',
                admissionType: 'IPUnits',
                surgeryProcedure: 'Knee Surgery',
                surgeryScheduleStartDateTime: '2024-09-01T11:00:00',
                surgeryActualizedStartDateTime: '2024-09-01T11:15:00',
                surgeryActualizedEndDateTime: '2024-09-01T12:00:00',
                timeDelayLevel: 2,
                timeDelayMinutes: 15,
                surgeryRoom: 'Room 5',
                nextPatientLocation: 'Room 1',
            },
            {
                patientRecord: 'IP00006',
                patientFirstName: 'Bruce',
                patientLastName: 'Wayne',
                admissionType: 'IPUnits',
                surgeryProcedure: 'Shoulder Surgery',
                surgeryScheduleStartDateTime: '2024-09-02T13:00:00',
                surgeryActualizedStartDateTime: '2024-09-02T13:20:00',
                surgeryActualizedEndDateTime: '2024-09-02T14:00:00',
                timeDelayLevel: 1,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 8',
                nextPatientLocation: 'Room 2',
            },
            {
                patientRecord: 'IP00007',
                patientFirstName: 'Clark',
                patientLastName: 'Kent',
                admissionType: 'IPUnits',
                surgeryProcedure: 'Spinal Surgery',
                surgeryScheduleStartDateTime: '2024-09-03T15:00:00',
                surgeryActualizedStartDateTime: '2024-09-03T15:30:00',
                surgeryActualizedEndDateTime: '2024-09-03T16:30:00',
                timeDelayLevel: 0,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 2',
                nextPatientLocation: 'Room D',
            },
            {
                patientRecord: 'IP00008',
                patientFirstName: 'Diana',
                patientLastName: 'Prince',
                admissionType: 'IPUnits',
                surgeryProcedure: 'Gallbladder Removal',
                surgeryScheduleStartDateTime: '2024-09-04T09:00:00',
                surgeryActualizedStartDateTime: '2024-09-04T09:30:00',
                surgeryActualizedEndDateTime: '2024-09-04T10:30:00',
                timeDelayLevel: 3,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 3',
                nextPatientLocation: 'Room C',
            },
        ],
        ODS: [
            {
                patientRecord: 'ODS00001',
                patientFirstName: 'John',
                patientLastName: 'Wick',
                admissionType: 'ODS',
                surgeryProcedure: 'Appendectomy',
                surgeryScheduleStartDateTime: '2024-09-02T10:00:00',
                surgeryActualizedStartDateTime: '2024-09-02T10:15:00',
                surgeryActualizedEndDateTime: '2024-09-02T10:45:00',
                timeDelayLevel: 0,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 3',
                nextPatientLocation: 'Room A',
            },
            {
                patientRecord: 'ODS00002',
                patientFirstName: 'Michael',
                patientLastName: 'Jordan',
                admissionType: 'ODS',
                surgeryProcedure: 'Hernia Repair',
                surgeryScheduleStartDateTime: '2024-09-02T11:00:00',
                surgeryActualizedStartDateTime: '2024-09-02T11:30:00',
                surgeryActualizedEndDateTime: '2024-09-02T12:00:00',
                timeDelayLevel: 1,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 4',
                nextPatientLocation: 'Room B',
            },
            {
                patientRecord: 'ODS00003',
                patientFirstName: 'Peter',
                patientLastName: 'Parker',
                admissionType: 'ODS',
                surgeryProcedure: 'Tonsillectomy',
                surgeryScheduleStartDateTime: '2024-09-03T09:00:00',
                surgeryActualizedStartDateTime: '2024-09-03T09:30:00',
                surgeryActualizedEndDateTime: '2024-09-03T10:00:00',
                timeDelayLevel: 0,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 1',
                nextPatientLocation: 'Room C',
            },
            {
                patientRecord: 'ODS00004',
                patientFirstName: 'Tony',
                patientLastName: 'Stark',
                admissionType: 'ODS',
                surgeryProcedure: 'Gallbladder Removal',
                surgeryScheduleStartDateTime: '2024-09-03T10:00:00',
                surgeryActualizedStartDateTime: '2024-09-03T10:20:00',
                surgeryActualizedEndDateTime: '2024-09-03T11:00:00',
                timeDelayLevel: 3,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 5',
                nextPatientLocation: 'Room D',
            },
        ],
        SDS: [
            {
                patientRecord: 'SDS00001',
                patientFirstName: 'Diana',
                patientLastName: 'Prince',
                admissionType: 'SDS',
                surgeryProcedure: 'Gallbladder Removal',
                surgeryScheduleStartDateTime: '2024-09-03T08:00:00',
                surgeryActualizedStartDateTime: '2024-09-03T08:20:00',
                surgeryActualizedEndDateTime: '2024-09-03T09:00:00',
                timeDelayLevel: 3,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 2',
                nextPatientLocation: 'Room B',
            },
            {
                patientRecord: 'SDS00002',
                patientFirstName: 'Bruce',
                patientLastName: 'Wayne',
                admissionType: 'SDS',
                surgeryProcedure: 'Tonsillectomy',
                surgeryScheduleStartDateTime: '2024-09-04T11:00:00',
                surgeryActualizedStartDateTime: '2024-09-04T11:30:00',
                surgeryActualizedEndDateTime: '2024-09-04T12:00:00',
                timeDelayLevel: 1,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 5',
                nextPatientLocation: 'Room D',
            },
            {
                patientRecord: 'SDS00003',
                patientFirstName: 'Clark',
                patientLastName: 'Kent',
                admissionType: 'SDS',
                surgeryProcedure: 'Cataract Surgery',
                surgeryScheduleStartDateTime: '2024-09-02T14:00:00',
                surgeryActualizedStartDateTime: '2024-09-02T14:20:00',
                surgeryActualizedEndDateTime: '2024-09-02T15:00:00',
                timeDelayLevel: 0,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 3',
                nextPatientLocation: 'Room C',
            },
            {
                patientRecord: 'SDS00004',
                patientFirstName: 'Peter',
                patientLastName: 'Parker',
                admissionType: 'SDS',
                surgeryProcedure: 'Hernia Repair',
                surgeryScheduleStartDateTime: '2024-09-01T09:00:00',
                surgeryActualizedStartDateTime: '2024-09-01T09:20:00',
                surgeryActualizedEndDateTime: '2024-09-01T10:00:00',
                timeDelayLevel: 2,
                timeDelayMinutes: 10,
                surgeryRoom: 'Room 1',
                nextPatientLocation: 'Room A',
            },
        ],
    },
}
