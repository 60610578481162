import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ALERTSAPIDATA } from '../../../utils/API'
import { selectActiveUserRole } from '../user/selectors'
import { AlertsDataType, GeneralAlertsData } from './types'
import qs from 'qs'

export const getPatientsAlertsData = createAsyncThunk<AlertsDataType, any>(
    'alerts/getPatientsAlertsData',
    async function (param, { rejectWithValue, dispatch, getState }) {
        try {
            const { token, PatientIds } = param
            const patients = PatientIds.map(
                (PatientId: string) => `PatientIds=${PatientId}`
            )
            const userRole = selectActiveUserRole(getState() as RootState)
            const response = await ALERTSAPIDATA.get(
                `/v1/Alerts?${patients.join('&')}`,
                {
                    headers: {
                        bearer: token,
                        role: userRole,
                    },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get patient alerts from server: ', error)
            return rejectWithValue(error)
        }
    }
)

export const getGeneralAlertsData = createAsyncThunk<GeneralAlertsData, any>(
    'alerts/general-alerts',
    async function (param, { rejectWithValue, dispatch, getState }) {
        try {
            const { token, PageSize, Page, DepartmentGroup, LastFetchDateInUtc } = param
            const queryData = {
                PageSize: PageSize || undefined,
                Page: Page || undefined,
                DepartmentGroup: DepartmentGroup || undefined,
                // LastFetchDateInUtc: LastFetchDateInUtc || undefined,
            }

            const queryString = qs.stringify(queryData, {
                encode: false,
                indices: false,
            })

            const userRole = selectActiveUserRole(getState() as RootState)
            const response = await ALERTSAPIDATA.get(`/v1/Alerts/general-alerts${queryString ? '?' + queryString : ''}`, {
                headers: {
                    // bearer: token,
                    role: userRole,
                },
            })
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get general-alerts from server: ', error)
            return rejectWithValue(error)
        }
    }
)
