import { useKeycloak } from '@react-keycloak/web'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PatientsDataTypeAPI } from '../types'
import { useParams } from 'react-router-dom'
// import useQueryBuilder from './useQueryBuilder'
import qs from 'qs'
import { getPatientsdata, getUpdatePatientsData } from '../redux/rtk/patientsTable/asyncAction'
import {
    selectIsLoadingPatientsData,
    selectPatientsDataAPI,
} from '../redux/rtk/patientsTable/selectors'
import {
    selectActiveDepartment,
    selectCustomFilter,
    selectDepartmenSearch,
    selectGenderFilter,
    selectHasNextPage,
    selectHospitalIdSearch,
    selectIsLoading,
    selectPageNumber,
    selectPatientNameSearch,
    selectSortBy,
    selectSortOrder,
} from '../redux/rtk/filter/selectors'
import { selectActiveUserRole } from '../redux/rtk/user/selectors'

const usePatients = (
    userToken: string | undefined
    // query: string
) => {
    const dispatch = useDispatch()
    const { department } = useParams()
    const { keycloak } = useKeycloak()
    // const { queryString } = useQueryBuilder()
    const [skip, setSkip] = useState(true)
    const patientsDataAPI = useSelector(selectPatientsDataAPI)
    const hasNextPage = useSelector(selectHasNextPage)
    const customFilter = useSelector(selectCustomFilter)
    const activeUserRole = useSelector(selectActiveUserRole)
    const departmenSearch = useSelector(selectDepartmenSearch)
    const patientNameSearch = useSelector(selectPatientNameSearch)
    const hospitalIdSearch = useSelector(selectHospitalIdSearch)
    const SortBy = useSelector(selectSortBy)
    const SortOrder = useSelector(selectSortOrder)
    const pageNumber = useSelector(selectPageNumber)
    const isAuthorized = keycloak.authenticated
    const isLoading = useSelector(selectIsLoadingPatientsData)
    const activeDepartment = useSelector(selectActiveDepartment)
    const genderFilter = useSelector(selectGenderFilter)

    useEffect(() => {
        setSkip(keycloak.token?.length === 0 && activeUserRole.length === 0)
    }, [userToken, activeUserRole])

    let parsedFilter = JSON.parse(JSON.stringify(customFilter))
    let discharge: any
    if (parsedFilter.hasOwnProperty('disch_today')) {
        discharge = parsedFilter.disch_today.status
    }
    if (parsedFilter.hasOwnProperty('disch_within')) {
        discharge = parsedFilter.disch_within.status
    }
    if (parsedFilter.hasOwnProperty('disch_over')) {
        discharge = parsedFilter.disch_over.status
    }

    let departmenSearchParsed = JSON.parse(JSON.stringify(departmenSearch))
    let data = {
        // PageSize: 25,
        // Page: pageNumber,
        Name: patientNameSearch || undefined,
        HospitalId: hospitalIdSearch || undefined,
        Departments: departmenSearchParsed || undefined,
        HasPendingLabResults: parsedFilter?.pendingLabs?.status || undefined,
        HasPendingConsultations:
            parsedFilter?.pendingConsultations?.status || undefined,
        HasAlerts: parsedFilter?.alerts?.status || undefined,
        HasOpenTasks: parsedFilter?.tasks?.status || undefined,
        HasHazards: parsedFilter?.hazards?.status || undefined,
        DischargeGroup: discharge || undefined,
        DischargeInDays: undefined,
        Department: department,
        SortBy: SortBy,
        SortOrder: SortOrder,
        genders: genderFilter || undefined
    }


    const getVisits = () => {
        const queryString = qs.stringify(data, {
            encode: false,
            indices: false,
        })

        if (!isAuthorized) return
        if (!hasNextPage) return
        if (!userToken) return

        if (keycloak.isTokenExpired()) return

        // if (activeUserRole) {
        dispatch(
            getPatientsdata({
                // @ts-ignore
                token: userToken || keycloak.token,
                role: activeUserRole,
                page: pageNumber,
                // PageSize: 25,
                // patients: patientsDataAPI,
                queryString: queryString,
            })
        )
        // }
    }

    useEffect(() => {
        getVisits()
    }, [
        pageNumber,
        customFilter,
        departmenSearch,
        SortBy,
        SortOrder,
        patientNameSearch,
        hospitalIdSearch,
        // isLoading,
        isAuthorized,
        activeUserRole,
        department,
        activeDepartment,
        // hasNextPage,
        genderFilter
    ])

    return { isLoading, patientsDataAPI, hasNextPage, getVisits }
}

export default usePatients
