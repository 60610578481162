import React, { createContext, useContext, ReactNode, useState } from 'react'
import { useSelectedButtonsLogic } from '../hooks'

type SelectedButton = {
    id: string
    value: string[]
}

interface SelectedButtonsContextType {
    selectedButtons: SelectedButton[]
    handleButtonClick: (filterId: string, name: string | string[]) => void
    setSelectedButtons: (e: any) => void
    filteredDataRow: any[]
    setFilteredDataRow: (e: any) => void
}

const SelectedButtonsContext = createContext<
    SelectedButtonsContextType | undefined
>(undefined)

interface SelectedButtonsProviderProps {
    children: ReactNode
}

export const SelectedButtonsProvider: React.FC<
    SelectedButtonsProviderProps
> = ({ children }) => {
    const { selectedButtons, handleButtonClick, setSelectedButtons } =
        useSelectedButtonsLogic([
            // { id: 'filter', value: [] },
            // { id: 'consultation', value: [] },
        ])

    const [filteredDataRow, setFilteredDataRow] = useState<any>([])
    return (
        <SelectedButtonsContext.Provider
            value={{
                selectedButtons,
                handleButtonClick,
                setSelectedButtons,
                //@ts-ignore
                filteredDataRow,
                setFilteredDataRow,
            }}
        >
            {children}
        </SelectedButtonsContext.Provider>
    )
}

export const useSelectedButtons = () => {
    const context = useContext(SelectedButtonsContext)
    if (context === undefined) {
        throw new Error(
            'useSelectedButtons must be used within a SelectedButtonsProvider'
        )
    }
    return context
}
