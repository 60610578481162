import { icons } from '../../../utils/iconsMap'

import styles from './TableIcons.module.scss'

export const TableIcons: Partial<any> = {
    //change sort icon, connect internal props so that it gets styled correctly
    IconSortAscending: () => (
        <img
            src={icons.sort_arrow_up}
            className={styles.img}
            alt="sort icons"
        />
    ),
    IconSortDescending: () => (
        <img
            src={icons.sort_arrow_down}
            className={styles.img}
            alt="sort icons"
        />
    ),
    IconArrowsSort: (props: any) => (
        <img
            src={icons.filter_arrows}
            className={styles.img}
            alt="sort icons"
        />
    ),
    IconChevronDown: () => (
        <img
            src={icons.sort_arrow_down}
            className={styles.img}
            alt="sort icons"
        />
    ),
    // IconArrowsSort: () => <TopArrow />,
}
