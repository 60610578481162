import React from 'react'
import { IconTypeProps } from '../IconType'

const PlannedTransfer: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9429 2.83551V4.15746M4.01123 2.83551V4.15746"
                stroke="url(#paint0_linear_11989_12578)"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.30748 16.0551H7.34907C4.68501 16.0551 3.35299 16.0551 2.52537 15.1603C1.69775 14.2656 1.69775 12.8256 1.69775 9.94553V9.60611C1.69775 6.72605 1.69775 5.28602 2.52537 4.3913C3.35299 3.49658 4.68501 3.49658 7.34907 3.49658H8.60492C11.269 3.49658 12.601 3.49658 13.4286 4.3913C14.2078 5.23363 14.2534 6.55929 14.2561 9.11485"
                stroke="url(#paint1_linear_11989_12578)"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.02832 6.80127H13.9258"
                stroke="url(#paint2_linear_11989_12578)"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.553 10.8754V12.0627H11.5849C11.4275 12.0627 11.2765 12.1252 11.1652 12.2365C11.0538 12.3478 10.9913 12.4988 10.9913 12.6563C10.9913 12.8137 11.0538 12.9647 11.1652 13.076C11.2765 13.1873 11.4275 13.2499 11.5849 13.2499H15.1466C15.3041 13.2499 15.4551 13.1873 15.5664 13.076C15.6777 12.9647 15.7402 12.8137 15.7402 12.6563V12.1042L17.7823 13.7248L15.7402 15.5056V15.0307C15.7402 14.8733 15.6777 14.7223 15.5664 14.611C15.4551 14.4997 15.3041 14.4371 15.1466 14.4371H9.80407C9.64663 14.4371 9.49564 14.4997 9.38432 14.611C9.27299 14.7223 9.21045 14.8733 9.21045 15.0307C9.21045 15.1882 9.27299 15.3392 9.38432 15.4505C9.49564 15.5618 9.64663 15.6244 9.80407 15.6244H14.553V16.8116C14.5532 16.9257 14.5862 17.0373 14.6481 17.1331C14.71 17.2289 14.7983 17.3048 14.9022 17.3518C15.0062 17.3988 15.1215 17.4148 15.2343 17.398C15.3471 17.3811 15.4527 17.3321 15.5384 17.2568L19.1001 14.1522C19.1634 14.0966 19.2142 14.0281 19.249 13.9513C19.2838 13.8746 19.3018 13.7913 19.302 13.707C19.2998 13.6198 19.2785 13.5342 19.2395 13.4562C19.2005 13.3783 19.1448 13.3098 19.0764 13.2558L15.5147 10.4243C15.4282 10.3558 15.3243 10.3129 15.2147 10.3002C15.1051 10.2875 14.9942 10.3056 14.8943 10.3525C14.7945 10.3993 14.7096 10.4731 14.6493 10.5654C14.5891 10.6578 14.5557 10.7651 14.553 10.8754Z"
                fill="url(#paint3_linear_11989_12578)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12578"
                    x1="4.01123"
                    y1="3.50145"
                    x2="7.08243"
                    y2="7.36506"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12578"
                    x1="1.69775"
                    y1="9.82303"
                    x2="13.7273"
                    y2="12.3452"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11989_12578"
                    x1="2.02832"
                    y1="7.30503"
                    x2="3.67556"
                    y2="11.4141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11989_12578"
                    x1="9.21045"
                    y1="13.8771"
                    x2="18.4806"
                    y2="16.6365"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default PlannedTransfer
