import '@mantine/core/styles.css'
import '@mantine/dates/styles.css' //if using mantine date picker features
import 'mantine-react-table/styles.css' //make sure MRT styles were imported in your app root (once)
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef,
    type MRT_SortingState,
    type MRT_RowVirtualizer,
    type MRT_Icons,
    type MRT_Row,
} from 'mantine-react-table'
import {
    useGetEdPatientsQuery,
    useLazyGetEdPatientsQuery,
} from '../../redux/rtk/edLive/edLiveApi'
import {
    type AdmissionColumnType,
    type ConsultationType,
    type EdPatient,
} from '../../redux/rtk/edLive/types'
import {
    AdmissionColumn,
    ConsultationsColumn,
    GenderColumn,
    IdColumn,
    LastAdmissionColumn,
    TriageColumn,
    WaitingColumn,
} from './AdmissionsCells'
import { TableIcons } from '../Tables/TableIcons/TableIcons'

import styles from './AdmissionsTable.module.scss'
import { useSelectedButtons } from '../../contexts/SelectedButtonsContext'
import {
    buttonsFilter,
    consultationFilter,
    originFilters,
} from '../AdmissionsFilter/AdmissionsFilterOptions'
import {
    calculateTime,
    filterConsultations,
    multiSelectOptions,
} from './helpers'
import { useKeycloak } from '@react-keycloak/web'
import { useSelector } from 'react-redux'
import { selectToken } from '../../redux/rtk/auth/selectors'
type AdmissionsTableProps = {
    columnFilters: { id: string; value: string[] }[]
    setColumnFilters: any
}

interface FilterItem {
    id: string
    value: string
}

const convertFilterItemsToValues = (items: FilterItem[]): string[] => {
    return items.map((item) => item.value)
}

export type SortingFn<TData extends EdPatient> = {
    (rowA: MRT_Row<TData>, rowB: MRT_Row<TData>, columnId: string): number
}

const customAlphanumericSort = (rowA: any, rowB: any, columnId: string) => {
    const locales = ['en', 'fr']
    const a = rowA.getValue(columnId).trim().toLowerCase()
    const b = rowB.getValue(columnId).trim().toLowerCase()
    return a.localeCompare(b, locales, {
        numeric: true,
        ignorePunctuation: true,
    })
}

const admissionSortingFn = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.original[columnId].admService.toLowerCase()
    const valueB = rowB.original[columnId].admService.toLowerCase()

    if (valueA > valueB) return 1
    if (valueA < valueB) return -1
    return 0
}

const AdmissionsTable: React.FC = () => {
    const { keycloak } = useKeycloak()
    const userToken = useSelector(selectToken)
    const {
        selectedButtons: columnFilters,
        handleButtonClick,
        setSelectedButtons: setColumnFilters,
        setFilteredDataRow,
    } = useSelectedButtons()
    const [columnFilter, setColumnFilter] = useState<any>()
    const {
        data: edLiveData,
        error,
        isFetching,
        isLoading,
        isSuccess,
    } = useGetEdPatientsQuery(
        {},
        {
            skip: !userToken && !keycloak.authenticated,
            pollingInterval: 300000,
        }
    )

    const columns = useMemo<MRT_ColumnDef<EdPatient>[]>(
        () => [
            {
                accessorKey: 'number',
                header: 'ID',
                size: 100,
                Cell: ({ row, renderedCellValue }) => {
                    const triage: string = row.getValue('triage')
                    return (
                        <IdColumn triage={triage} number={renderedCellValue} />
                    )
                },
            },
            {
                accessorKey: 'name',
                header: 'Patient',
                size: 150,
            },
            {
                accessorKey: 'origin',
                header: 'Origin',
                size: 100,
                filterVariant: 'multi-select',
                // columnFilterModeOptions: ['includes'],
                mantineFilterMultiSelectProps: {
                    data: multiSelectOptions(originFilters),
                },
            },
            {
                accessorKey: 'gender',
                header: 'Gender',
                size: 100,
                Cell: ({ row }) => {
                    const data: string = row.getValue('gender')
                    return <GenderColumn gender={data} />
                },
                filterVariant: 'multi-select',
                mantineFilterMultiSelectProps: {
                    data: [
                        { value: 'F', label: 'Female' },
                        { value: 'M', label: 'Male' },
                    ],
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'age',
                header: 'Age',
                size: 80,
                filterFn: 'between',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'waiting',
                header: 'Waiting',
                size: 80,
                // filterFn: 'between',
                Cell: ({ row, renderedCellValue }) => {
                    return <WaitingColumn value={renderedCellValue} />
                    // }
                },
                // enableSorting: false,
                // enableColumnFilter: false,
            },
            {
                accessorKey: 'triage',
                header: 'Triage',
                size: 80,
                Cell: ({ row, renderedCellValue }) => {
                    const data: string = row.getValue('triage')
                    return <TriageColumn triage={data} />
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'chiefComplaint',
                header: 'Chief Complaint',
                //   size: 300,
                sortingFn: customAlphanumericSort,
            },
            {
                accessorKey: 'stretcher',
                header: 'Stretcher',
                size: 100,
            },
            {
                accessorKey: 'covid',
                header: 'Covid',
                size: 140,
            },
            {
                accessorKey: 'pastAdmitsDate',
                header: 'Last Admission',
                size: 150,
                columnFilterModeOptions: ['contains', 'startsWith'],
                Cell: ({ row, renderedCellValue }) => {
                    const reAdm: string = row.getValue('readmission')

                    return (
                        <>
                            {typeof renderedCellValue === 'string' ? (
                                <LastAdmissionColumn
                                    reAdm={reAdm}
                                    renderedCellValue={renderedCellValue}
                                />
                            ) : (
                                renderedCellValue
                            )}
                        </>
                    )
                    // }
                },
            },
            {
                accessorKey: 'admissionColumn',
                header: 'Admission',
                size: 140,
                filterVariant: 'text',
                Cell: ({ cell, row, renderedCellValue }) => {
                    const filterValue: string = row.getValue('filter')
                    const data = cell.getValue<AdmissionColumnType | string>()

                    if (data === null || data === undefined) {
                        return null
                    }

                    return (
                        <AdmissionColumn
                            cellValue={data}
                            //@ts-ignore
                            filterValue={filterValue}
                        />
                    )
                },
                sortingFn: admissionSortingFn,
            },
            {
                accessorKey: 'consultation',
                header: 'Consultations',
                Cell: ({ row, renderedCellValue }) => {
                    const data = row.getValue<ConsultationType[] | string>(
                        'consultation'
                    )
                    if (typeof data === 'string') return data
                    return <ConsultationsColumn consultations={data} />
                },
                filterFn: (row, id, filterValue) => {
                    const data: {
                        consultationDelayFlag: string
                        consultationTime: string
                        consultationTitle: string
                    }[] = row.getValue(id)

                    if (data && data?.length > 0) {
                        const filteredConsultations = filterConsultations(
                            data,
                            filterValue
                        )
                        return filteredConsultations.length > 0
                    }
                    if (filterValue.length === 0) return true

                    return false
                },
                columnFilterModeOptions: ['includes'],
                filterVariant: 'multi-select',
                mantineFilterMultiSelectProps: {
                    data: multiSelectOptions(consultationFilter),
                },
            },
            {
                accessorKey: 'readmission',
                header: 'Re-admission',
            },
            {
                accessorKey: 'highRisk',
                header: 'High-risk',
                size: 100,
            },
            {
                accessorKey: 'filter',
                header: 'Filter',
                size: 100,
                enableHiding: true,
                filterVariant: 'multi-select',
                columnFilterModeOptions: ['includes'],
                mantineFilterMultiSelectProps: {
                    data: buttonsFilter.map((filter) => filter.name),
                },
                // enableColumnFilterModes: false,
            },

            // {
            //   accessorKey: 'dateOfJoining',
            //   header: 'Date of Joining',
            // },
            // {
            //   accessorKey: 'isActive',
            //   header: 'Is Active',
        ],
        []
    )

    //   /optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null)

    const [data, setData] = useState<any>([])
    // const [isLoading, setIsLoading] = useState(true)
    const [sorting, setSorting] = useState<MRT_SortingState>([])

    useEffect(() => {
        if (edLiveData && edLiveData?.predictions?.length > 0) {
            setData(edLiveData?.predictions)
            // setIsLoading(false)
        }
    }, [edLiveData])

    useEffect(() => {
        try {
            //scroll to the top of the table when the sorting changes
            rowVirtualizerInstanceRef.current?.scrollToIndex(0)
        } catch (e) {
            console.log(e)
        }
    }, [sorting])

    const table = useMantineReactTable({
        columns,
        data, //10,000 rows
        enableStickyHeader: true,
        // enableFilterMatchHighlighting: false,
        maxLeafRowFilterDepth: 1000,
        filterFromLeafRows: true,
        initialState: {
            columnVisibility: {
                //hide columns by default
                filter: false,
                readmission: false,
                highRisk: false,
                covid: false,
                'mrt-row-expand': false, //hide row expand column by default
            },
        },
        enableColumnFilterModes: true,
        enableColumnFilters: true,
        enableBottomToolbar: false,
        // enableColumnResizing: true,
        enableColumnVirtualization: true,
        enableGlobalFilterModes: true,
        enablePagination: false,
        enableColumnPinning: true,
        // enableRowNumbers: true,
        enableRowVirtualization: true,
        mantineTableContainerProps: {
            style: {
                maxHeight: '65vh',
                // backgroundColor: '#455671',
                borderRadius: '10px',
                border: '0px solid',
                fontSize: '16px',
            },
        },
        mantineTableHeadCellProps: {},
        mantineTableHeadProps: {
            style: {
                backgroundColor: 'inherit',
                '--ai-hover': 'transparent',
                textTransform: 'uppercase',
                color: '#889BBA',
                fontSize: '10px',
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        //@ts-ignore
        state: { isLoading, sorting, columnFilters },
        rowVirtualizerInstanceRef, //optional
        rowVirtualizerOptions: { overscan: 5 }, //optionally customize the row virtualizer
        columnVirtualizerOptions: { overscan: 2 }, //optionally customize the column virtualizer
        // icons: faIcons,
        icons: TableIcons,
        enableColumnActions: false,
        mantineTopToolbarProps: {},
        mantinePaperProps: {
            style: {
                // '--mrt-base-background-color': 'rgb(33, 24, 44)',
                '--mrt-base-background-color': 'transparent',
                '--mantine-font-size-sm': '14px',
                '--mantine-font-size-md': '14px',
                // '--mrt-row-hover-background-color':
                //     'linear-gradient(90deg, rgba(24,60,93,1) 100%, rgba(42,71,110,1) 100%)',
                // '--mrt-row-hover-background-color': 'rgba(42,71,110,1)',
                '--ai-size-xs': '5rem',
                border: '0px',
                borderRadius: '10px',
            },
        },
        mantineFilterMultiSelectProps: {},
        mantineSearchTextInputProps: {
            placeholder: 'Search',
            // sx: { minWidth: '300px' },
            variant: 'outlined',
        },
    })

    return (
        <div className={styles.section}>
            <MantineReactTable table={table} />
        </div>
    )
}

export default AdmissionsTable
