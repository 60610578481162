import { useState, useCallback } from 'react';

type SelectedButton = {
    id: string;
    value: string[];
};

const isSelectedButtonsEmpty = (buttons: SelectedButton[]): boolean => {
    return buttons.every(button => button.value.length === 0);
};

const useSelectedButtonsLogic = (initialState: SelectedButton[] = []) => {
    const [selectedButtons, setSelectedButtons] = useState<SelectedButton[]>(initialState);

    const handleButtonClick = useCallback((filterId: string, names: string | string[]) => {

        setSelectedButtons((prev) => {
            const currentFilterIndex = prev.findIndex((filter) => filter.id === filterId);


            // Convert names to an array if it's not already one
            const namesArray = Array.isArray(names) ? names : [names];

            if (namesArray.includes('reset all filters')) {
                const newFilter = { id: filterId, value: [] };
                return [
                    ...prev.slice(0, currentFilterIndex),
                    ...prev.slice(currentFilterIndex + 1),
                ];
            }

            // If the filterId  === origin
            if (filterId === 'origin') {
                return [...prev, { id: filterId, value: namesArray }];
            }

            // If the filter does not exist, create a new one
            if (currentFilterIndex === -1) {
                return [...prev, { id: filterId, value: namesArray }];
            }

            // If the filter exists, update it
            let newValue = [...prev[currentFilterIndex].value];
            namesArray.forEach(name => {
                const currentIndex = newValue.indexOf(name);
                if (currentIndex === -1) {
                    newValue.push(name);
                } else {
                    newValue.splice(currentIndex, 1);
                }
            });

            const newFilter = { id: filterId, value: newValue };

            const combinedFilter = [
                ...prev.slice(0, currentFilterIndex),
                newFilter,
                ...prev.slice(currentFilterIndex + 1),
            ];

            if (isSelectedButtonsEmpty(combinedFilter)) {
                return []
            }

            return combinedFilter;

        });
    }, []);

    return { selectedButtons, handleButtonClick, setSelectedButtons };
};

export default useSelectedButtonsLogic;
