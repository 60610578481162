import { getTimestampForHourAndMinute } from '../date/getTimestampForHourAndMinutes'

export const OperatiningRoomsMock = [
    {
        groupId: 1,
        name: 'OR01',
        orType: 'Orthopedic Surgeries',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 1,
            name: 'MRI Scan',
            procedure: 'Diagnostic Imaging',
            procedureName: '0FSC4ZZ RAV',
            doctor: 'Dr. Michael Johnson',
            nurse: 'Emily Thompson'
        },
        {
            id: 2,
            name: 'X-ray Examination',
            procedure: 'Radiology',
            procedureName: '0FSC4ZZ RAV',
            doctor: 'Dr. Sarah Parker',
            nurse: 'Ryan Collins'
        },
        {
            id: 3,
            name: 'Blood Test',
            procedure: 'Laboratory Test',
            procedureName: '820-822 LLMP',
            doctor: 'Dr. James Peterson',
            nurse: 'Jessica Carter'
        },]
    },
    {
        groupId: 2,
        name: 'OR02',
        orType: 'General Surgery Theater',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: false
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 4,
            name: 'Ultrasound',
            procedure: 'Imaging Procedure',
            procedureName: '920-924 MMG',
            doctor: 'Dr. Michelle Adams',
            nurse: 'David Anderson'
        },
        {
            id: 5,
            name: 'Electrocardiogram (ECG)',
            procedure: 'Cardiac Test',
            procedureName: '123-456 ECG',
            doctor: 'Dr. Emily Ross',
            nurse: 'Oliver Matthews'
        },
        {
            id: 6,
            name: 'Colonoscopy',
            procedure: 'Gastrointestinal Procedure',
            procedureName: '789-123 CLO',
            doctor: 'Dr. Rachel Garcia',
            nurse: 'Nathan Perez'
        },]
    },
    {
        groupId: 3,
        name: 'OR03',
        orType: 'Neurosurgery Suite',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 7,
            name: 'Dental Extraction',
            procedure: 'Dental Surgery',
            procedureName: '567-890 DEX',
            doctor: 'Dr. Sophia Lee',
            nurse: 'Ethan Wright'
        },
        {
            id: 8,
            name: 'CT Scan',
            procedure: 'Computed Tomography',
            procedureName: '456-789 CTS',
            doctor: 'Dr. Andrew Scott',
            nurse: 'Mia Turner'
        },
        {
            id: 9,
            name: 'Vaccination',
            procedure: 'Immunization',
            procedureName: '111-222 VAC',
            doctor: 'Dr. Eric Baker',
            nurse: 'Ashley King'
        },]
    },
    {
        groupId: 4,
        name: 'OR04',
        orType: 'Cardiac Surgery Theater',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 10,
            name: 'Appendectomy',
            procedure: 'Surgical Procedure',
            procedureName: '333-444 APP',
            doctor: 'Dr. Olivia White',
            nurse: 'Daniel Evans'
        },
        {
            id: 11,
            name: 'Chemotherapy',
            procedure: 'Cancer Treatment',
            procedureName: '555-666 CHE',
            doctor: 'Dr. Benjamin Wright',
            nurse: 'Ava Martinez'
        },
        {
            id: 12,
            name: 'Physical Therapy Session',
            procedure: 'Rehabilitation',
            procedureName: '777-888 PHY',
            doctor: 'Dr. Daniel Harris',
            nurse: 'Sophie Young'
        },]
    },
    {
        groupId: 5,
        name: 'OR05',
        orType: 'Gynecological Operating',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 13,
            name: 'Endoscopy',
            procedure: 'Gastrointestinal Procedure',
            procedureName: '999-000 END',
            doctor: 'Dr. Laura Turner',
            nurse: 'Tyler Rodriguez'
        },
        {
            id: 14,
            name: 'Prenatal Check-up',
            procedure: 'Maternity Care',
            procedureName: '101-202 PRE',
            doctor: 'Dr. Jessica Miller',
            nurse: 'Grace Collins'
        },
        {
            id: 12,
            name: 'Physical Therapy Session',
            procedure: 'Rehabilitation',
            procedureName: '777-888 PHY',
            doctor: 'Dr. Daniel Harris',
            nurse: 'Sophie Young'
        },]
    },
    {
        groupId: 6,
        name: 'OR06',
        orType: 'Ophthalmology Surgical Suite',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 15,
            name: 'Colonoscopy',
            procedure: 'Gastrointestinal Procedure',
            procedureName: '203-305 CLN',
            doctor: 'Dr. Maria Lopez',
            nurse: 'Jack Roberts'
        },
        {
            id: 16,
            name: 'Knee Replacement Surgery',
            procedure: 'Orthopedic Procedure',
            procedureName: '405-507 KRS',
            doctor: 'Dr. William Anderson',
            nurse: 'Hannah White'
        },
        {
            id: 17,
            name: 'Eye Examination',
            procedure: 'Ophthalmology Check-up',
            procedureName: '607-709 EYE',
            doctor: 'Dr. Sophia Clark',
            nurse: 'Matthew Harris'
        },]
    },
    {
        groupId: 7,
        name: 'OR07',
        orType: 'Urology Procedure Room',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 18,
            name: 'MRI Scan',
            procedure: 'Neurological Imaging',
            procedureName: '809-911 MRI',
            doctor: 'Dr. Daniel Foster',
            nurse: 'Chloe Baker'
        },
        {
            id: 19,
            name: 'Chemotherapy Session',
            procedure: 'Cancer Treatment',
            procedureName: '101-213 CHE',
            doctor: 'Dr. Olivia Turner',
            nurse: 'Logan Murphy'
        },
        {
            id: 20,
            name: 'Blood Transfusion',
            procedure: 'Transfusion Therapy',
            procedureName: '313-425 BTR',
            doctor: 'Dr. Ethan Roberts',
            nurse: 'Sophia Evans'
        },]
    },
    {
        groupId: 8,
        name: 'OR08',
        orType: 'ENT (Ear, Nose, and Throat)',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 21,
            name: 'CT Angiography',
            procedure: 'Vascular Imaging',
            procedureName: '001-113 CTA',
            doctor: 'Dr. Lily Johnson',
            nurse: 'Noah Turner'
        },
        {
            id: 22,
            name: 'Dialysis Treatment',
            procedure: 'Renal Therapy',
            procedureName: '707-819 DIA',
            doctor: 'Dr. Isaac Parker',
            nurse: 'Aria Wilson'
        },
        {
            id: 23,
            name: 'Cardiac Stress Test',
            procedure: 'Cardiac Evaluation',
            procedureName: '505-617 CST',
            doctor: 'Dr. Harper Reed',
            nurse: 'Leo Rodriguez'
        },]
    },
    {
        groupId: 9,
        name: 'OR09',
        orType: 'Pediatric Surgery Room',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: false
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 24,
            name: 'Endoscopic Retrograde Cholangiopancreatography (ERCP)',
            procedure: 'Gastrointestinal Imaging',
            procedureName: '315-427 ERCP',
            doctor: 'Dr. Madison White',
            nurse: 'Elijah Martinez'
        },
        {
            id: 25,
            name: 'Laboratory Blood Test',
            procedure: 'Diagnostic Test',
            procedureName: '129-241 LBT',
            doctor: 'Dr. Cameron Stewart',
            nurse: 'Ava Adams'
        },
        {
            id: 26,
            name: 'Hysterectomy Surgery',
            procedure: 'Gynecological Procedure',
            procedureName: '229-341 HYS',
            doctor: 'Dr. Penelope Harris',
            nurse: 'Mason Turner'
        },]
    },
    {
        groupId: 10,
        name: 'OR10',
        orType: 'Plastic Surgery Suite',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: false
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 24,
            name: 'Endoscopic Retrograde Cholangiopancreatography (ERCP)',
            procedure: 'Gastrointestinal Imaging',
            procedureName: '315-427 ERCP',
            doctor: 'Dr. Madison White',
            nurse: 'Elijah Martinez'
        },
        {
            id: 27,
            name: 'Lumbar Puncture',
            procedure: 'Neurological Procedure',
            procedureName: '429-541 LMP',
            doctor: 'Dr. Felix Adams',
            nurse: 'Zoe Carter'
        },
        {
            id: 28,
            name: 'Orthodontic Procedure',
            procedure: 'Dental Treatment',
            procedureName: '529-641 ORT',
            doctor: 'Dr. Gabriella Flores',
            nurse: 'Xavier Smith'
        },]
    },
    {
        groupId: 11,
        name: 'OR11',
        orType: 'Vascular Surgery Operating',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 29,
            name: 'Mammogram',
            procedure: 'Breast Examination',
            procedureName: '741-853 MAM',
            doctor: 'Dr. Isabella Ward',
            nurse: 'Olivia Wright'
        },
        {
            id: 30,
            name: 'Gastric Bypass Surgery',
            procedure: 'Bariatric Procedure',
            procedureName: '853-965 GBP',
            doctor: 'Dr. Alexander Taylor',
            nurse: 'Natalie Miller'
        },
        {
            id: 31,
            name: 'Echocardiogram',
            procedure: 'Cardiac Imaging',
            procedureName: '965-077 ECH',
            doctor: 'Dr. Claire Mitchell',
            nurse: 'Jacob Turner'
        },]
    },
    {
        groupId: 12,
        name: 'OR12',
        orType: 'Thoracic Surgery Theater',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: false
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: false
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: false
        },
        ],
        patientsShedule: [{
            id: 32,
            name: 'Pulmonary Function Test',
            procedure: 'Respiratory Evaluation',
            procedureName: '077-189 PFT',
            doctor: 'Dr. Christopher Baker',
            nurse: 'Maya Garcia'
        },
        {
            id: 33,
            name: 'Angioplasty',
            procedure: 'Vascular Procedure',
            procedureName: '189-291 ANG',
            doctor: 'Dr. Victoria Young',
            nurse: 'Ethan Parker'
        },
        {
            id: 34,
            name: 'Hematology Panel',
            procedure: 'Blood Test',
            procedureName: '291-403 HEM',
            doctor: 'Dr. Lucas Hill',
            nurse: 'Hannah Adams'
        },]
    },
    {
        groupId: 13,
        name: 'OR13',
        orType: 'Endoscopy Suite',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 35,
            name: 'Tonsillectomy',
            procedure: 'Ear, Nose, and Throat Surgery',
            procedureName: '403-515 TON',
            doctor: 'Dr. Stella Turner',
            nurse: 'David Foster'
        },
        {
            id: 36,
            name: 'Skin Biopsy',
            procedure: 'Dermatological Test',
            procedureName: '515-627 SKB',
            doctor: 'Dr. Gabriel Rodriguez',
            nurse: 'Sophie Clark'
        }, {
            id: 37,
            name: 'Colon Resection Surgery',
            procedure: 'Colorectal Procedure',
            procedureName: '627-739 CRS',
            doctor: 'Dr. Naomi Bennett',
            nurse: 'Nathan Young'
        },]
    },
    {
        groupId: 14,
        name: 'OR14',
        orType: 'Laparoscopic Surgery Room',
        airSystem: 'Medium',
        equipments: [{
            id: 1,
            name: 'Equipment 1',
            isAvailable: true
        },
        {
            id: 2,
            name: 'Equipment 2',
            isAvailable: true
        },
        {
            id: 3,
            name: 'Equipment 3',
            isAvailable: true
        },
        ],
        patientsShedule: [{
            id: 38,
            name: 'Laparoscopy',
            procedure: 'Minimally Invasive Surgery',
            procedureName: '739-851 LAP',
            doctor: 'Dr. Jason Reed',
            nurse: 'Ava Brooks'
        },
        {
            id: 39,
            name: 'Intravenous Therapy',
            procedure: 'IV Infusion',
            procedureName: '851-963 IVT',
            doctor: 'Dr. Sophia Turner',
            nurse: 'William Harris'
        },
        {
            id: 40,
            name: 'Electroencephalogram (EEG)',
            procedure: 'Brain Activity Test',
            procedureName: '963-075 EEG',
            doctor: 'Dr. Isaac Stewart',
            nurse: 'Emma Davis'
        },]
    },
]


export const OrItems = [
    {
        id: 1,
        groupId: 1,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 30),
        patientName: 'John Smith',
        surgeonName: 'Lidia Sachs',
        procedure: '0FSC4ZZ RAV',
    },
    {
        id: 2,
        groupId: 2,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 30),
        patientName: 'Emma Johnson',
        surgeonName: 'Olivia Turner',
        procedure: '820-822 LLMP',
    },
    {
        id: 3,
        groupId: 1,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 30),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Sophia Davis',
        surgeonName: 'Benjamin Wright',
        procedure: '707-819 DIA',
    },

    {
        id: 4,
        groupId: 1,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'William Brown',
        surgeonName: 'Ava Martinez',
        procedure: '203-305 CLN',
    },
    {
        id: 5,
        groupId: 1,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Oliver Garcia',
        surgeonName: 'Gabriella Flores',
        procedure: '965-077 ECH',
    },
    {
        id: 6,
        groupId: 1,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Sophie Clark',
        surgeonName: 'Isabella Ward',
        procedure: '101-213 CHE',
    },
    {
        id: 7,
        groupId: 1,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Chloe Baker',
        surgeonName: 'Christopher Baker',
        procedure: '315-427 ERCP',
    },
    {
        id: 8,
        groupId: 2,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 30),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'Liam Turner',
        surgeonName: 'Victoria Young',
        procedure: '123-456 ECG',
    },
    {
        id: 9,
        groupId: 2,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(11, 40),
        patientName: 'Ava Rodriguez',
        surgeonName: 'Naomi Bennett',
        procedure: '777-888 PHY',
    },
    {
        id: 10,
        groupId: 2,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 40),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Ethan Adams',
        surgeonName: 'Maria Lopez',
        procedure: '001-113 CTA',
    },
    {
        id: 11,
        groupId: 3,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 30),
        patientName: 'Mia Foster',
        surgeonName: 'Nora Martinez',
        procedure: '203-305 CLN',
    },
    {
        id: 12,
        groupId: 1,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 35),
        patientName: 'Jacob Young',
        surgeonName: 'Felix Adams',
        procedure: '965-077 ECH',
    },

    {
        id: 13,
        groupId: 1,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 35),
        end: getTimestampForHourAndMinute(13, 0),
        patientName: 'Isaac Harris',
        surgeonName: 'Penelope Harris',
        procedure: '739-851 LAP',
    },
    {
        id: 14,
        groupId: 1,
        title: 'emergency procedure',
        status: 'emergency procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 0),
        end: getTimestampForHourAndMinute(14, 0),
        patientName: 'Sophia Ward',
        surgeonName: 'Ethan Roberts',
        procedure: '963-075 EEG',
    },
    {
        id: 15,
        groupId: 1,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 0),
        end: getTimestampForHourAndMinute(14, 0),
        patientName: 'Lily Adams',
        surgeonName: 'Carter Rodriguez',
        procedure: '741-853 MAM',
    },
    {
        id: 16,
        groupId: 1,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(14, 0),
        end: getTimestampForHourAndMinute(15, 0),
        patientName: 'Noah Turner',
        surgeonName: 'Madison White',
        procedure: '315-427 ERCP',
    },
    {
        id: 17,
        groupId: 1,
        title: 'actual delay 1',
        status: 'actual delay 1',
        delay: 0,
        start: getTimestampForHourAndMinute(15, 0),
        end: getTimestampForHourAndMinute(15, 30),
        patientName: 'Aria Garcia',
        surgeonName: 'Alexander Taylor',
        procedure: '607-709 EYE',
    },
    {
        id: 18,
        groupId: 1,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(15, 30),
        end: getTimestampForHourAndMinute(16, 0),
        patientName: 'Xavier Martinez',
        surgeonName: 'Claire Mitchell',
        procedure: '187-299 SEC',
    },
    {
        id: 19,
        groupId: 1,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(16, 0),
        end: getTimestampForHourAndMinute(16, 15),
        patientName: 'Natalie Parker',
        surgeonName: 'Jason Reed',
        procedure: '203-305 CLN',
    },
    {
        id: 20,
        groupId: 1,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(16, 15),
        end: getTimestampForHourAndMinute(16, 45),
        patientName: 'Olivia White',
        surgeonName: 'Isabella Ward',
        procedure: '777-888 PHY',
    },
    // groupId4
    {
        id: 21,
        groupId: 4,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Grace Foster',
        surgeonName: 'Victoria Young',
        procedure: '203-305 CLN',
    },

    {
        id: 22,
        groupId: 4,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'David Turner',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 23,
        groupId: 4,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Emily Rodriguez',
        surgeonName: 'Ethan Roberts',
        procedure: '739-851 LAP',
    },
    {
        id: 24,
        groupId: 4,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Logan Harris',
        surgeonName: 'Carter Rodriguez',
        procedure: '963-075 EEG',
    },
    {
        id: 25,
        groupId: 4,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Hannah Adams',
        surgeonName: 'Lidia Sachs',
        procedure: '741-853 MAM',
    },
    {
        id: 26,
        groupId: 4,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Mason Turner',
        surgeonName: 'Olivia Turner',
        procedure: '315-427 ERCP',
    },

    {
        id: 27,
        groupId: 4,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 0),
        end: getTimestampForHourAndMinute(12, 45),
        patientName: 'Eva Martinez',
        surgeonName: 'Isabella Ward',
        procedure: '607-709 EYE',
    },
    {
        id: 28,
        groupId: 4,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 45),
        end: getTimestampForHourAndMinute(13, 15),
        patientName: 'Luke Young',
        surgeonName: 'Claire Mitchell',
        procedure: '187-299 SEC',
    },
    {
        id: 29,
        groupId: 4,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 15),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Nora Parker',
        surgeonName: 'Jason Reed',
        procedure: '203-305 CLN',
    },
    {
        id: 30,
        groupId: 4,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(14, 45),
        patientName: 'Ella White',
        surgeonName: 'Isabella Ward',
        procedure: '777-888 PHY',
    },

    {
        id: 31,
        groupId: 5,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Levi Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 32,
        groupId: 5,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Bella Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 33,
        groupId: 5,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(15, 45),
        patientName: 'Xander Baker',
        surgeonName: 'Ethan Roberts',
        procedure: '739-851 LAP',
    },
    ///

    {
        id: 34,
        groupId: 6,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Luna Adams',
        surgeonName: 'Carter Rodriguez',
        procedure: '963-075 EEG',
    },

    {
        id: 35,
        groupId: 6,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'Milo Harris',
        surgeonName: 'Lidia Sachs',
        procedure: '741-853 MAM',
    },
    {
        id: 36,
        groupId: 6,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Zara Turner',
        surgeonName: 'Olivia Turner',
        procedure: '315-427 ERCP',
    },
    {
        id: 37,
        groupId: 6,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Axel Martinez',
        surgeonName: 'Isabella Ward',
        procedure: '607-709 EYE',
    },
    {
        id: 38,
        groupId: 6,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Violet Young',
        surgeonName: 'Claire Mitchell',
        procedure: '187-299 SEC',
    },
    {
        id: 39,
        groupId: 6,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Owen Parker',
        surgeonName: 'Jason Reed',
        procedure: '203-305 CLN',
    },

    {
        id: 40,
        groupId: 6,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 0),
        end: getTimestampForHourAndMinute(12, 45),
        patientName: 'Stella White',
        surgeonName: 'Isabella Ward',
        procedure: '777-888 PHY',
    },
    {
        id: 41,
        groupId: 6,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 45),
        end: getTimestampForHourAndMinute(13, 15),
        patientName: 'Leo Garcia',
        surgeonName: 'Victoria Young',
        procedure: '203-305 CLN',
    },
    {
        id: 42,
        groupId: 6,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 15),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Aurora Turner',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 43,
        groupId: 6,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(14, 45),
        patientName: 'Hazel Martinez',
        surgeonName: 'Ethan Roberts',
        procedure: '739-851 LAP',
    },
    // 7

    {
        id: 44,
        groupId: 7,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Finn Rodriguez',
        surgeonName: 'Carter Rodriguez',
        procedure: '963-075 EEG',
    },

    {
        id: 45,
        groupId: 7,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'Nova Adams',
        surgeonName: 'Lidia Sachs',
        procedure: '741-853 MAM',
    },
    {
        id: 46,
        groupId: 7,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Kai Turner',
        surgeonName: 'Olivia Turner',
        procedure: '315-427 ERCP',
    },
    {
        id: 47,
        groupId: 7,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Kai Turner',
        surgeonName: 'Olivia Turner',
        procedure: '315-427 ERCP',
    },
    {
        id: 48,
        groupId: 7,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Sienna Martinez',
        surgeonName: 'Isabella Ward',
        procedure: '607-709 EYE',
    },
    {
        id: 49,
        groupId: 7,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Ivy Parker',
        surgeonName: 'Jason Reed',
        procedure: '203-305 CLN',
    },

    {
        id: 50,
        groupId: 7,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 0),
        end: getTimestampForHourAndMinute(12, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 51,
        groupId: 7,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 45),
        end: getTimestampForHourAndMinute(13, 15),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 52,
        groupId: 7,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 15),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 77,
        groupId: 7,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(14, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },

    //

    {
        id: 53,
        groupId: 10,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },

    {
        id: 54,
        groupId: 10,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 55,
        groupId: 10,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 56,
        groupId: 10,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 57,
        groupId: 10,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 58,
        groupId: 10,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },

    {
        id: 59,
        groupId: 10,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 0),
        end: getTimestampForHourAndMinute(12, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 60,
        groupId: 10,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 45),
        end: getTimestampForHourAndMinute(13, 15),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 61,
        groupId: 10,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 15),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 62,
        groupId: 10,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(14, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },

    //

    {
        id: 63,
        groupId: 14,
        title: 'setup',
        status: 'setup',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 0),
        end: getTimestampForHourAndMinute(9, 45),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },

    {
        id: 64,
        groupId: 14,
        title: 'procedure',
        status: 'procedure',
        delay: 0,
        start: getTimestampForHourAndMinute(9, 45),
        end: getTimestampForHourAndMinute(10, 30),
        patientName: 'Aria Garcia',
        surgeonName: 'Felix Adams',
        procedure: '203-305 CLN',
    },
    {
        id: 65,
        groupId: 14,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 30),
        end: getTimestampForHourAndMinute(10, 45),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 66,
        groupId: 14,
        title: 'predirect delay',
        status: 'predirect delay',
        delay: 0,
        start: getTimestampForHourAndMinute(10, 45),
        end: getTimestampForHourAndMinute(11, 0),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 67,
        groupId: 14,
        title: 'free',
        status: 'free',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 0),
        end: getTimestampForHourAndMinute(11, 45),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 68,
        groupId: 14,
        title: 'reserved for emergency',
        status: 'reserved for emergency',
        delay: 0,
        start: getTimestampForHourAndMinute(11, 45),
        end: getTimestampForHourAndMinute(12, 0),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },

    {
        id: 69,
        groupId: 14,
        title: 'clearance',
        status: 'clearance',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 0),
        end: getTimestampForHourAndMinute(12, 45),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 70,
        groupId: 14,
        title: 'slack',
        status: 'slack',
        delay: 0,
        start: getTimestampForHourAndMinute(12, 45),
        end: getTimestampForHourAndMinute(13, 15),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 71,
        groupId: 14,
        title: 'risk for delay',
        status: 'risk for delay',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 15),
        end: getTimestampForHourAndMinute(13, 45),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
    {
        id: 72,
        groupId: 14,
        title: 'actual delay 2',
        status: 'actual delay 2',
        delay: 0,
        start: getTimestampForHourAndMinute(13, 45),
        end: getTimestampForHourAndMinute(14, 45),
        patientName: 'Noah Rodriguez',
        surgeonName: 'Madison White',
        procedure: '965-077 ECH',
    },
]