import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { APIDATA, APIDATABLOB } from '../../../utils/API';
import { OcpApimSubscriptionKey } from '../../../utils/axiosInstance';
import { selectToken } from '../auth/selectors';
import { resetFilter, setHasNextPage, setIsLoading } from '../filter/filterSlice';
import { selectIsLoading } from '../filter/selectors';
import { setDashboardPatientPopupFetchDate } from '../patientModal/patientModalSlice';
import FileSaver from 'file-saver'
import { DischargeData, PatientsDataType, VisitsResponce } from './types';
import { setPatientsDataAPI } from './patientsTableSlice';
// { rejectValue: string }

const isDevelopment = process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development'

export const getPatientsdata = createAsyncThunk<VisitsResponce, any>(
    'visits/getPatientsdata',
    async function (param, { rejectWithValue, dispatch }) {
        // const urlSection = isDevelopment ? '/v1/Visits' : '/v1/Visits/list'
        const urlSection = '/v1/Visits/list'
        try {
            dispatch(setIsLoading(true))
            // const response = await APIDATA.get(`${urlSection}?PageSize=${500}&Page=${param.page}&${param.queryString}`, {
            const response = await APIDATA.get(`${urlSection}?PageSize=${500}&${param.queryString}`, {
                headers: {
                    role: param.role,
                },
            })
            if (response.status === 200) {
                dispatch(setIsLoading(false))
                // dispatch(setHasNextPage(!Boolean(response?.data?.visits?.length < 25)))
                dispatch(setHasNextPage(false))
            }

            const data = await response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// getUpdatePatientsData temporary solution for updating.Should be replaced after rebuilding the table to new approach
export const getUpdatePatientsData = createAsyncThunk<VisitsResponce, any>(
    'visits/getPatientsdata',
    async function (param, { rejectWithValue, dispatch }) {
        // const urlSection = isDevelopment ? '/v1/Visits' : '/v1/Visits/list'
        const urlSection = '/v1/Visits/list'
        try {
            const response = await APIDATA.get(`${urlSection}?PageSize=${500}&${param.queryString}`, {
                headers: {
                    role: param.role,
                },
            })
            if (response.status === 200) {
                dispatch(setPatientsDataAPI(response?.data?.visits))
            }

            const data = await response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getLengthOfStays = createAsyncThunk<any, any>(
    'visits/getLengthOfStays',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATA.get(`/v1/Visits/lengthofstays${param.queryString}`, {
                headers: {
                    role: param.role,
                },
            })

            const data = await response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const getUpdatesForPatients = createAsyncThunk<any, any>(
    'visits/getUpdatesForPatients',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            dispatch(setIsLoading(true))
            const response = await APIDATA.get(
                `/v1/Visits/updatesforpatients?After=${param.after}`,
                {
                    headers: {
                        role: param.role,
                    },
                }
            )
            const eventDatas = response.data.events.map(
                (eventData: {
                    patientId: string
                    type: number
                    eventDataJson: string
                    occuredOn: Date | string
                }) => {
                    return {
                        patientId: eventData.patientId,
                        type: eventData.type,
                        eventDataJson: JSON.parse(eventData.eventDataJson),
                        occuredOn: eventData.occuredOn,
                    }
                }
            )
            return { events: { events: eventDatas }, fetchDate: response.data.fetchDate }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getUpdatesForPatient = createAsyncThunk<any, any>(
    'visits/getUpdatesForPatient',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            // dispatch(setIsLoading(true))
            const response = await APIDATA.get(
                `/v1/Visits/updatesforpatient?After=${param.after}&PatientId=${param.patientId}`,
                {
                    headers: {
                        role: param.role,
                    },
                }
            )
            const eventDatas = response.data.events.map(
                (eventData: {
                    patientId: string
                    type: number
                    eventDataJson: string
                    occuredOn: Date | string
                }) => {
                    return {
                        patientId: eventData.patientId,
                        type: eventData.type,
                        eventDataJson: JSON.parse(eventData.eventDataJson),
                        occuredOn: eventData.occuredOn,
                    }
                }
            )
            dispatch(setDashboardPatientPopupFetchDate({
                fetchDate: response.data.fetchDate,
            }))
            return { events: { events: eventDatas }, fetchDate: response.data.fetchDate }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDownloadPatientsOnCsv = createAsyncThunk<any, any>(
    'visits/getDownloadPatientsOnCsv',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATABLOB.get(`/v1/Visits/export-as-csv?${param.query}`, {
                headers: {
                    role: param.role,
                },
            })
            // FileSaver.saveAs(response.data, 'patients.csv')
            // Trigger file download in the client browser xlsx
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', param.filename || 'patients_data.xlsx'); // Provide a default filename
            // document.body.appendChild(link);
            // link.click();
            // window.URL.revokeObjectURL(url);
            // if (link.parentNode) {
            //     link.parentNode.removeChild(link);
            // }

            return response.data;
            // const blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
            // FileSaver.saveAs(blob, "patients_ward.xlsx");
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//JGH management page
export const getLabsDataperDay = createAsyncThunk(
    'visits/getLabsDataperDay',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATA.get(`/v1/Visits/labsdataperday`)

            const data = await response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDischargeStatuses = createAsyncThunk<DischargeData>('DischargeStatuses',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            const response = await APIDATA.get(`/v1/DischargeStatuses`)

            const data = response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const updateDischargeStatuses = createAsyncThunk<any, any>('updateDischargeStatuses',
    async function (params, { rejectWithValue, dispatch }) {
        const { id, body } = params
        try {
            const response = await APIDATA.post(`/v1/Visits/${id}/updatedischargestatus`,
                body,
            )

            if (response.status === 200) {
                dispatch(resetFilter(['pageNumber', 'hasNextPage', 'isLoading']))
                dispatch(setPatientsDataAPI([]))
            }

            const data = response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const markPendingLabsAsSeen = createAsyncThunk<any, any>('markPendingLabsAsSeen',
    async function (params, { rejectWithValue, dispatch }) {
        const { body, role } = params
        try {

            const customHeaders = {
                'role': role,
            };

            const response = await APIDATA.post(`/v1/Visits/mark-pending-labs-as-seen`, body, {
                headers: customHeaders
            });

            const data = response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);