import React from 'react'
import { InflowOrItem } from 'redux/rtk/orStatus/types'

import styles from './ScheduleItem.module.scss'
import { formatTimeFromDatetime } from './helper'

// type ScheduleItemProps = Pick<
//     InflowOrItem,
//     'surgerySchedule' | 'expectedSurgeryEndDate' | 'timeDelayLevel'
// >

type ScheduleItemProps = {
    surgerySchedule: string | null
    expectedSurgeryEndDate: string | null
    timeDelayLevel: number | null
}

const TextColor = {
    0: '#7ABD83',
    1: '#FFB979',
    2: '#FF7783',
} as const

const ScheduleItem: React.FC<ScheduleItemProps> = ({
    surgerySchedule,
    expectedSurgeryEndDate,
    timeDelayLevel = 0,
}) => {
    // Provide default values or checks for null before passing to format function
    const formattedSurgerySchedule = surgerySchedule
        ? formatTimeFromDatetime(surgerySchedule)
        : '--'
    const formattedExpectedSurgeryEndDate = expectedSurgeryEndDate
        ? formatTimeFromDatetime(expectedSurgeryEndDate)
        : '--'

    const validTimeDelayLevel = timeDelayLevel ?? 0
    const textColor =
        TextColor[validTimeDelayLevel as keyof typeof TextColor] || '#000' // Default color if not found

    return (
        <div className={styles.container} style={{ color: textColor }}>
            <span>{formattedSurgerySchedule}</span>
            <span>&#8211;</span>
            <span>{formattedExpectedSurgeryEndDate}</span>
        </div>
    )
}

export default ScheduleItem
