import React, { useEffect, useRef, useState, ReactNode } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { calculateTime } from '../../helpers'
dayjs.extend(duration)

type WaitingColumnProps = {
    value: string | ReactNode
}
const WaitingColumn: React.FC<WaitingColumnProps> = ({ value }) => {
    const [timerDisplay, setTimerDisplay] = useState<string>('')
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (typeof value === 'string') {
            const waitingStart = new Date(value)

            setTimerDisplay(calculateTime(waitingStart)) // Initialize timer display
            intervalRef.current = setInterval(() => {
                setTimerDisplay(calculateTime(waitingStart))
            }, 60000) // Update every minute

            return () => {
                if (intervalRef.current) clearInterval(intervalRef.current)
            }
        }
    }, [value])

    return <div>{timerDisplay}</div>
}

export default WaitingColumn
