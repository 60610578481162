import React from 'react'
import { IconTypeProps } from '../IconType'

const FtuIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M12 5L15.2556 8.95167C16.2432 10.1504 16.1526 11.9047 15.0468 12.9953L12 16"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M8 5L10.7909 9.23443C11.5107 10.3267 11.4399 11.7594 10.6158 12.7753L8 16"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M3 5L5.79087 9.23443C6.51075 10.3267 6.43992 11.7594 5.61584 12.7753L3 16"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default FtuIcon
