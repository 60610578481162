import React from 'react'

type DefaultCheckboxProps = {
    isChecked: boolean
    svgColour: string
}

const DefaultCheckbox: React.FC<DefaultCheckboxProps> = ({
    isChecked,
    svgColour,
}) => {
    return (
        <>
            {' '}
            {isChecked ? (
                <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.7002 4H3C1.89543 4 1 4.89543 1 6V7.46116C1.7146 7.46107 2.43017 7.71473 3 8.22505V6H12.6637L14.7002 4ZM3 16.1107V18H15V12.1152L17 10.151V18C17 19.1046 16.1046 20 15 20H3C1.89543 20 1 19.1046 1 18V14.6222L1.18152 14.7967C1.73694 15.3305 2.35 15.7685 3 16.1107Z"
                        fill={svgColour}
                    />
                    <path
                        d="M1 10.4612L6.06227 15.3267C6.45236 15.7017 7.06989 15.6984 7.45592 15.3192L20 3"
                        stroke={svgColour}
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
            ) : (
                <svg
                    width="22"
                    height="22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1"
                        y="1"
                        width="14"
                        height="14"
                        rx="1"
                        stroke={svgColour}
                        strokeWidth="2"
                    />
                </svg>
            )}
        </>
    )
}

export default DefaultCheckbox
