import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const authDataSlice = createSlice({
    name: 'authData',
    initialState: {
        authData: {
            userName: '',
            userRole: [],
            authorized: false,
            userToken: '',
        },
        userToken: ''
    },
    reducers: {
        getAuthData(state, action) {
            state.authData = action.payload
        },
        setKeycloakToken(state, action) {
            state.userToken = action.payload
        },
    },
});

export const { getAuthData, setKeycloakToken } = authDataSlice.actions;

export default authDataSlice.reducer;