import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    setActiveEventBox,
    setActiveRoom,
} from '../../redux/rtk/operatingRoom/operatingRoomSlice'
import { getTimestampForHourAndMinute } from '../../utils/date/getTimestampForHourAndMinutes'
import { OperatiningRoomsMock, OrItems } from '../../utils/__Mocks__/OrStatus'
import EventBox from './EventBox/EventBox'
import { calculatePosition } from './helpers'

import styles from './TimeLine.module.scss'

enum Statuses {
    'free' = 'free',
    'setup' = 'setup',
    'procedure' = 'procedure',
    'clearance' = 'clearance',
    'predirect delay' = 'predirect_delay',
    'reserved for emergency' = 'reserved_for_emergency',
    'emergency procedure' = 'emergency_procedure',
    'slack' = 'slack',
    'risk for delay' = 'risk_for_delay',
    'actual delay 1' = 'actual_delay_1',
    'actual delay 2' = 'actual_delay_2',
}

const CalendarTimeline: React.FC = () => {
    const dispatch = useDispatch()
    const [selectedRoom, setSelectedRoom] = useState<null | number>(null)
    const timelineStartTime = getTimestampForHourAndMinute(9, 0)
    const timelineEndTime = getTimestampForHourAndMinute(20, 0)
    const [selectedBox, setSelectedBox] = useState<null | number>(null)

    const handleSelectedBox = (item: any) => {
        dispatch(setActiveEventBox(item))
        setSelectedBox(item.id)
    }
    const handleSelectedRoom = (item: any) => {
        dispatch(setActiveRoom(item))
        setSelectedRoom(item.groupId)
    }

    useEffect(() => {
        const currentTimeLine = document.getElementById('current-time-line')
        if (currentTimeLine) {
            const updateCurrentTimeLine = () => {
                const now = new Date()
                const hours = now.getHours()
                const minutes = now.getMinutes()
                const currentTime = getTimestampForHourAndMinute(hours, minutes)
                const position = calculatePosition(
                    currentTime,
                    timelineStartTime,
                    timelineEndTime
                )
                currentTimeLine.style.left = `${position}%`
            }

            // Update the current time line initially and every minute
            updateCurrentTimeLine()
            setInterval(updateCurrentTimeLine, 60000)
        }
    }, [timelineStartTime, timelineEndTime])

    const groupedOrItems = OperatiningRoomsMock.map((room) => ({
        ...room,
        items: OrItems.filter((item) => item.groupId === room.groupId),
    }))

    return (
        <div className={styles.calendar_timeline}>
            <div className={styles.timeline_header}>
                <div className={styles.timeline_header_title}>Rooms</div>
                <div className={styles.timeline_groups}>
                    {OperatiningRoomsMock.map((room, index) => (
                        <div
                            key={room.groupId}
                            className={`${styles.group} ${
                                selectedRoom === room.groupId
                                    ? styles.group__active
                                    : null
                            }`}
                            onClick={() => handleSelectedRoom(room)}
                        >
                            {room.name}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.timeline_body}>
                <div className={styles.timeline_hours}>
                    {renderTimelineHours(timelineStartTime, timelineEndTime)}
                </div>
                <div className={styles.timeline_rows}>
                    {groupedOrItems.map((room, index) => (
                        <div
                            key={'row' + room.groupId}
                            className={`${styles.timeline_row} ${
                                selectedRoom === room.groupId
                                    ? styles.timeline_row__active
                                    : null
                            }`}
                        >
                            {room.items.map((item) => (
                                <EventBox
                                    key={item.id}
                                    item={item}
                                    startTime={timelineStartTime}
                                    endTime={timelineEndTime}
                                    handleSelectedBox={handleSelectedBox}
                                    selectedBox={selectedBox}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                <div
                    className={styles.current_time_line}
                    id="current-time-line"
                ></div>
            </div>
        </div>
    )
}

const renderTimelineHours = (startTime: number, endTime: number) => {
    const hours = []
    for (let i = startTime; i <= endTime; i += 60 * 60 * 1000) {
        const time = new Date(i)
        const formattedTime = time.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        })
        hours.push(
            <div key={i} className={styles.hour}>
                {formattedTime}
            </div>
        )
    }
    return hours
}

// Implement getTimestampForHourAndMinute function as previously shown

export default CalendarTimeline
