export const generateDate = (offset: number) => {
    const today = new Date()
    today.setDate(today.getDate() - offset)

    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}T00:00:00`
    return formattedDate
}

export const generateFurureDate = (offset: number) => {
    const today = new Date()
    today.setDate(today.getDate() + offset)

    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}T00:00:00Z`
    return formattedDate
}
