export type OptionConfig = {
    name: string
    title: string
    order: number
    type: 'string' | 'icon' | 'iconString' | 'progress'
}

export const ccOutflowOptions: OptionConfig[] = [
    { name: 'patientRecord', title: 'MRN', order: 1, type: 'string' },
    {
        name: 'bedName',
        title: 'BED',
        order: 2,
        type: 'string',
    },
    { name: 'transferType', title: 'Transfer type', order: 2, type: 'icon' },
    {
        name: 'transferService',
        title: 'Service',
        order: 3,
        type: 'iconString',
    },
    {
        name: 'waitingTimeMinutes',
        title: 'Transfer delay',
        order: 5,
        type: 'progress',
    },
]
