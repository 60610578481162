import React, { useState, useEffect } from 'react'

const breakpoints = {
    md: 1680,
    lg: 1820,
    xl: 2100,
    xxl: 2400,
}

const useMaxVisibleDepartments = () => {
    const [maxVisibleDepartment, setMaxVisibleDepartment] = useState<number>(4)

    useEffect(() => {
        const updateMaxWidth = () => {
            if (window.innerWidth < breakpoints.md) {
                setMaxVisibleDepartment(1) // Mobile devices
            } else if (
                window.innerWidth >= breakpoints.md &&
                window.innerWidth < breakpoints.lg
            ) {
                setMaxVisibleDepartment(2) // Tablets
            } else if (
                window.innerWidth >= breakpoints.lg &&
                window.innerWidth < breakpoints.xl
            ) {
                setMaxVisibleDepartment(4) // Small screens, laptops
            } else if (
                window.innerWidth >= breakpoints.xl &&
                window.innerWidth < breakpoints.xxl
            ) {
                setMaxVisibleDepartment(6) // Desktops, large screens
            } else if (window.innerWidth > breakpoints.xxl) {
                setMaxVisibleDepartment(7) // Desktops, large screens
            }
        }

        // Call once to set initial value
        updateMaxWidth()

        // Add event listener
        window.addEventListener('resize', updateMaxWidth)

        // Cleanup function
        return () => window.removeEventListener('resize', updateMaxWidth)
    }, [])
    return maxVisibleDepartment
}

export default useMaxVisibleDepartments
