import Header from 'components/Header/Header'
import ManagementHeader from '../../components/ManagementEntries/ManagementHeader/ManagementHeader'
import OrEfficiency from '../../components/OrStatus/OrEfficiency/OrEfficiency'
import ORrealTime from '../../components/OrStatus/OrRealTime/ORrealTime'
import TrendsSection from '../../components/OrStatus/TrendsSection/TrendsSection'

import styles from './OperatingRoom.module.scss'

const OperatingRoom = () => {
    return (
        <div className={styles.content}>
            {/* <ManagementHeader /> */}
            <Header />
            <div className={styles.content__body}>
                <div className={styles.content__body_left}>
                    <ORrealTime />
                </div>
                <div className={styles.content__body_line}></div>
                <div className={styles.content__body_right}>
                    <TrendsSection />
                    <OrEfficiency />
                </div>
            </div>
        </div>
    )
}

export default OperatingRoom
