import React from 'react'
import AreaChart from '../../AreaChart/AreaChart'
import {
    useGetOrEfficiencyQuery,
    useGetWaitListNumbersQuery,
} from 'redux/rtk/orStatus/OrStatusApi'

import styles from './EfficiencyItem.module.scss'
import { useFetchStatus } from 'hooks'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

type EfficiencyItemProps = {
    title: string
    queryHook:
        | ReturnType<typeof useGetOrEfficiencyQuery>
        | ReturnType<typeof useGetWaitListNumbersQuery>

    xAxisKeyName: string
    yAxisKeyName: string
    valueKey: string
    tooltipLable?: string
    documentationKey: string
}

const EfficiencyItem: React.FC<EfficiencyItemProps> = ({
    title,
    queryHook,
    xAxisKeyName,
    yAxisKeyName,
    valueKey,
    tooltipLable,
    documentationKey,
}) => {
    const { data, error, isFetching, isSuccess } = queryHook

    const fetchStatus = useFetchStatus({
        data,
        error,
        isFetching,
        isSuccess,
    })

    return (
        <div className={styles.container}>
            <div className={styles.container__title}>
                {title}
                <CorporateDocLink
                    baseKey="operatingRoom"
                    sectionKey={documentationKey}
                />
            </div>
            <div className={styles.container__chart}>
                {fetchStatus ? (
                    fetchStatus
                ) : (
                    <AreaChart
                        dataObj={data?.[valueKey] ?? []}
                        xAxisKeyName={xAxisKeyName}
                        yAxisKeyName={yAxisKeyName}
                        tooltipLable={tooltipLable ?? ''}
                    />
                )}
            </div>
        </div>
    )
}

export default EfficiencyItem
