import { createSlice } from '@reduxjs/toolkit'
import {
    getDischargeStatuses,
    getDownloadPatientsOnCsv,
    getLabsDataperDay,
    getLengthOfStays,
    getPatientsdata,
    getUpdatesForPatient,
    getUpdatesForPatients,
    updateDischargeStatuses,
    markPendingLabsAsSeen,
    getUpdatePatientsData,
} from './asyncAction'
import {
    DashboardPopupPatientType,
    DischargeData,
    LocationChecbox,
    PatientsDataType,
    PredefinedFilterCountsType,
    Status,
    UpdatesForPatientType,
} from './types'
import FileSaver from 'file-saver'
import { visitListMockData } from '../../../utils/__Mocks__/VisitListMockData'

const PredefinedFilterCounts: PredefinedFilterCountsType = {
    pendingLabs: 0,
    pendingConsultations: 0,
    alerts: 0,
    tasks: 0,
    hazards: 0,
    disch_today: 0,
    disch_within: 0,
    disch_over: 0,
}

const updatesForPatients = {} as UpdatesForPatientType
const updatesForPatient = {} as UpdatesForPatientType
const patientsDataAPI = [] as PatientsDataType[]
const modifiedPatientsDataAPI = [] as any
const labsDataperDay = {} as any
const initialState = {
    PredefinedFilterCounts,
    patientsDataAPI,
    modifiedPatientsDataAPI,
    // status: Status.LOADING,
    lengthOfStay: [
        {
            name: '0d',
            count: 0,
        },
    ],
    lengthOfStayStatus: Status.LOADING,
    dashboardPatientsLocations: [] as {
        id: number | string
        name: string
        isChecked: boolean
    }[],
    dashboardSelectedPatient: {} as PatientsDataType,
    // dashboardPopupPatient,
    dashboardPopupPatientId: '',
    dashboardPopupVisitId: '',
    dashboardPopupPatientHazards: [],
    patientSUpdateDataFetchDate: {} as any,
    updatesForPatients,
    updatesForPatient,
    IsLoadingPatientsData: Status.LOADING,
    downloadingCsvStatus: '',
    labsDataperDay,
    labsDataperDayStatus: Status.LOADING,
    dischargeStatuses: {} as DischargeData,
    updateDischargeStatus: Status.LOADING,
    markPendingLabsAsSeenStatus: Status.SUCCESS,
}

const patientsTableSlice = createSlice({
    name: 'patientsTable',
    initialState,
    reducers: {
        setPatientsDataAPI(state, action) {
            state.patientsDataAPI = action?.payload
        },
        setModifiedPatientsDataAPI(state, action) {
            state.modifiedPatientsDataAPI = action?.payload
        },
        setDashboardPatientsLocations(state, action) {
            state.dashboardPatientsLocations = action.payload
        },
        setDashboardSelectedPatient(state, action) {
            state.dashboardSelectedPatient = action.payload
        },
        // setDashboardPopupPatient(state, action) {
        //     state.dashboardPopupPatient = action.payload
        // },
        setDashboardPopupPatientId(state, action) {
            state.dashboardPopupPatientId = action.payload
        },
        setDashboardPopupVisitId(state, action) {
            state.dashboardPopupVisitId = action.payload
        },
        setDashboardPopupPatientHazards(state, action) {
            state.dashboardPopupPatientHazards = action.payload
        },
        setPatientSUpdateDataFetchDate(state, action) {
            state.patientSUpdateDataFetchDate = action.payload
        },
        updatePendingLabs: (state, action) => {
            const { visitId, pendingLabs } = action.payload
            const visit = state.patientsDataAPI.find(
                (visit) => visit.visitId === visitId

            )
            if (visit) {
                visit.pendingLabs = pendingLabs
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPatientsdata.pending, (state, action) => {
            state.IsLoadingPatientsData = Status.LOADING
            state.PredefinedFilterCounts = PredefinedFilterCounts
        })

        builder.addCase(getPatientsdata.fulfilled, (state, action) => {
            state.IsLoadingPatientsData = Status.SUCCESS
            if (action?.payload?.visits) {
                state.patientsDataAPI = [
                    ...state.patientsDataAPI,
                    ...action?.payload?.visits,
                    // ...visitListMockData
                ]
            } else {
                // state.patientsDataAPI = [
                // ...state.patientsDataAPI,
                // ]
            }
            state.PredefinedFilterCounts = {
                ...{ pendingLabs: action.payload.hasPendingLabs },
                ...{
                    pendingConsultations:
                        action.payload.hasPendingConsultations,
                },
                ...{ alerts: action.payload.hasAlerts },
                ...{ tasks: action.payload.hasOpenTasks },
                ...{ hazards: action.payload.hasHazards },
                ...{ disch_today: action.payload.dischargeGroup1 },
                ...{ disch_within: action.payload.dischargeGroup2 },
                ...{ disch_over: action.payload.dischargeGroup3 },
            }
            state.patientSUpdateDataFetchDate = action.payload.fetchDate
            const uniqueVisitIds = new Set(
                state.dashboardPatientsLocations.map(
                    (location) => location.name
                )
            )

            // Create a new array for dashboardPatientsLocations
            const newLocations: LocationChecbox[] = []

            // Filter out duplicates and add new unique items to newLocations
            action?.payload?.visits.forEach((visit) => {
                if (!uniqueVisitIds.has(visit.location.department)) {
                    uniqueVisitIds.add(visit.location.department)
                    newLocations.push({
                        id: visit.visitId,
                        name: visit.location.department,
                        isChecked: true,
                    })
                }
            })

            // Concatenate newLocations with the existing state.dashboardPatientsLocations
            state.dashboardPatientsLocations = [
                ...state.dashboardPatientsLocations,
                ...newLocations,
            ]
        })

        builder.addCase(getPatientsdata.rejected, (state, action) => {
            state.IsLoadingPatientsData = Status.ERROR
            // state.patientsDataAPI = []
            state.PredefinedFilterCounts = PredefinedFilterCounts
        })

        // // getUpdatePatientsData temporary solution for updating.Should be replaced after rebuilding the table to new approach
        // builder.addCase(getUpdatePatientsData.pending, (state, action) => {
        //     // state.IsLoadingPatientsData = Status.LOADING
        //     // state.PredefinedFilterCounts = PredefinedFilterCounts
        // })

        // builder.addCase(getUpdatePatientsData.fulfilled, (state, action) => {
        //     // state.patientsDataAPI = [
        //     //     ...action?.payload?.visits,
        //     // ]
        // })

        // builder.addCase(getUpdatePatientsData.rejected, (state, action) => {
        //     // state.IsLoadingPatientsData = Status.ERROR
        //     // // state.patientsDataAPI = []
        //     // state.PredefinedFilterCounts = PredefinedFilterCounts
        // })

        builder.addCase(getLengthOfStays.pending, (state, action) => {
            state.lengthOfStayStatus = Status.LOADING
            state.lengthOfStay = initialState.lengthOfStay
        })

        builder.addCase(getLengthOfStays.fulfilled, (state, action) => {
            state.lengthOfStayStatus = Status.SUCCESS
            state.lengthOfStay = action.payload.lengthOfStays
        })

        builder.addCase(getUpdatesForPatients.pending, (state, action) => {
            // state.lengthOfStayStatus = Status.LOADING
            state.updatesForPatients = initialState.updatesForPatients
        })

        builder.addCase(getUpdatesForPatients.fulfilled, (state, action) => {
            // state.lengthOfStayStatus = Status.SUCCESS
            state.updatesForPatients = action.payload.events
            state.patientSUpdateDataFetchDate = action.payload.fetchDate
        })
        builder.addCase(getUpdatesForPatients.rejected, (state, action) => {
            // state.status = Status.ERROR
            state.updatesForPatients = updatesForPatients
        })

        builder.addCase(getDownloadPatientsOnCsv.pending, (state, action) => {
            state.downloadingCsvStatus = Status.LOADING
        })

        builder.addCase(getDownloadPatientsOnCsv.fulfilled, (state, action) => {
            state.downloadingCsvStatus = Status.SUCCESS
            FileSaver.saveAs(action.payload, 'patients.csv')
        })
        builder.addCase(getDownloadPatientsOnCsv.rejected, (state, action) => {
            state.downloadingCsvStatus = ''
        })

        builder.addCase(getLabsDataperDay.pending, (state, action) => {
            state.labsDataperDayStatus = Status.LOADING
            state.labsDataperDay = initialState.labsDataperDay
        })

        builder.addCase(getLabsDataperDay.fulfilled, (state, action) => {
            state.labsDataperDayStatus = Status.SUCCESS
            state.labsDataperDay = action.payload
        })
        builder.addCase(getLabsDataperDay.rejected, (state, action) => {
            state.labsDataperDayStatus = Status.ERROR
            state.labsDataperDay = labsDataperDay
        })

        builder.addCase(getDischargeStatuses.pending, (state, action) => {
            // state.dischargeStatusesStatus = Status.LOADING
            state.dischargeStatuses = initialState.dischargeStatuses
        })

        builder.addCase(getDischargeStatuses.fulfilled, (state, action) => {
            // state.dischargeStatusesStatus = Status.SUCCESS
            state.dischargeStatuses = action.payload
        })
        builder.addCase(getDischargeStatuses.rejected, (state, action) => {
            // state.dischargeStatusesStatus = Status.ERROR
            state.dischargeStatuses = initialState.dischargeStatuses
        })

        builder.addCase(updateDischargeStatuses.pending, (state, action) => {
            state.updateDischargeStatus = Status.LOADING
        })

        builder.addCase(updateDischargeStatuses.fulfilled, (state, action) => {
            state.updateDischargeStatus = Status.SUCCESS
        })

        builder.addCase(updateDischargeStatuses.rejected, (state, action) => {
            state.updateDischargeStatus = Status.ERROR
        })

        builder.addCase(markPendingLabsAsSeen.pending, (state, action) => {
            state.markPendingLabsAsSeenStatus = Status.LOADING
        })

        builder.addCase(markPendingLabsAsSeen.fulfilled, (state, action) => {
            state.markPendingLabsAsSeenStatus = Status.SUCCESS
        })

        builder.addCase(markPendingLabsAsSeen.rejected, (state, action) => {
            state.markPendingLabsAsSeenStatus = Status.ERROR
        })
    },
})

export const {
    setModifiedPatientsDataAPI,
    setDashboardPatientsLocations,
    setDashboardSelectedPatient,
    // setDashboardPopupPatient,
    setDashboardPopupPatientId,
    setDashboardPopupVisitId,
    setDashboardPopupPatientHazards,
    setPatientSUpdateDataFetchDate,
    setPatientsDataAPI,
    updatePendingLabs,
} = patientsTableSlice.actions

export default patientsTableSlice.reducer
