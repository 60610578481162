import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSelectedItemsByKey } from './helpers'
import { Option } from './types'

const GenderDefault: Option[] = [
    { id: 0, name: 'Male', isChecked: true },
    { id: 1, name: 'Female', isChecked: true },
    { id: 2, name: 'Other', isChecked: true },
]

const initialState = {
    searchValue: '',
    SortBy: 0,
    SortOrder: 1,
    customFilter: {} as any,
    hospitalIdSearch: '',
    patientNameSearch: '',
    departmenSearch: '',
    pageNumber: 1,
    hasNextPage: true,
    isLoading: false,
    predictiveMode: false,
    activeDepartment: '',
    genderOptions: GenderDefault,
    genderFilter: [] as (number | string)[],
    unitsOptions: [] as Option[]
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setSortOrder(state, action) {
            state.SortOrder = action.payload
        },
        setSortSortBy(state, action) {
            state.SortBy = action.payload
        },
        setSearchValue(state, action) {
            state.searchValue = action.payload
        },
        setCustomFilter(state, action) {
            state.customFilter = action.payload
        },
        setHospitalIdSearch(state, action) {
            state.hospitalIdSearch = action.payload
        },
        setDepartmenSearch(state, action) {
            state.departmenSearch = action.payload
        },
        setPetientNameSearch(state, action) {
            state.patientNameSearch = action.payload
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload
        },
        setHasNextPage(state, action) {
            state.hasNextPage = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setPredictiveMode(state, action) {
            state.predictiveMode = action.payload
        },
        setActiveDepartment(state, action) {
            state.activeDepartment = action.payload
        },
        setGenderOptions(state, action) {
            state.genderOptions = action.payload
            const items = getSelectedItemsByKey(action.payload, 'id') as Array<string | number>
            let isTrue = items.length > 0 && items.length < state.genderOptions.length
            state.genderFilter = isTrue ? items : []
        },
        setGenderFilter(state, action) {
            state.genderFilter = action.payload
        },
        setUnitsOptions(state, action) {
            state.unitsOptions = action.payload
        },
        resetFilter: (state, action) => {
            action.payload.forEach((prop: keyof typeof initialState) => {
                if (state.hasOwnProperty(prop)) {
                    (state as Record<keyof typeof initialState, any>)[prop] = initialState[prop];
                }
            });
        },
    },
})

export const {
    setSortOrder,
    setSortSortBy,
    setSearchValue,
    setCustomFilter,
    setHospitalIdSearch,
    setDepartmenSearch,
    setPetientNameSearch,
    setPageNumber,
    setHasNextPage,
    resetFilter,
    setIsLoading,
    setActiveDepartment,
    setPredictiveMode,
    setGenderFilter,
    setGenderOptions,
    setUnitsOptions
} = filtersSlice.actions

export default filtersSlice.reducer
