import React from 'react'
import {
    Button,
    ButtonProps,
    MantineProvider,
    createTheme,
} from '@mantine/core'

import styles from './FiltersButton.module.scss'

interface FiltersButtonProps extends ButtonProps {
    customProp?: string
    onClick?: (e: any) => void
    customClassNames?: {
        root?: string
        loader?: string
        inner?: string
        section?: string
        label?: string
    }
    isActive?: boolean
}

const theme = createTheme({
    components: {
        Button: Button.extend({
            classNames: styles,
        }),
    },
})

const FiltersButton: React.FC<FiltersButtonProps> = ({
    children,
    customClassNames,
    onClick,
    isActive,
    ...props
}) => {
    const combinedClassNames = {
        root: `${styles.button__root} ${customClassNames?.root || ''} ${
            isActive ? styles.root__isActive : ''
        }`,
        loader: `${styles.button__loader} ${customClassNames?.loader || ''}`,
        inner: `${styles.button__inner} ${customClassNames?.inner || ''}`,
        section: `${styles.button__section} ${customClassNames?.section || ''}`,
        label: `${styles.button__label} ${customClassNames?.label || ''}`,
    }

    return (
        // <MantineProvider theme={theme}>
        <MantineProvider theme={theme} defaultColorScheme="dark">
            <Button
                classNames={combinedClassNames}
                {...props}
                onClick={onClick}
            >
                {children}
            </Button>
        </MantineProvider>
    )
}

export default FiltersButton
