import React from 'react'
import CustomAccordion from '../../common/CustomAccordion/CustomAccordion'
import { icons } from '../../../utils/iconsMap'
import CcTitle from '../../common/CcTitle/CcTitle'
import BgWrapper from '../../common/BgWrapper/BgWrapper'
import ListItem from './ListItem/ListItem'
import { AdmissionType, OrInflowResponce } from 'redux/rtk/orStatus/types'
import { useGetOrInflowDataQuery } from 'redux/rtk/orStatus/OrStatusApi'

import styles from './OrList.module.scss'
import { useFetchStatus } from 'hooks'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'

type OrListProps = {
    title: string
    admissionType: AdmissionType
}

const OrList: React.FC<OrListProps> = ({ title, admissionType }) => {
    const { data, error, isFetching, isSuccess } = useGetOrInflowDataQuery(
        admissionType,
        { pollingInterval: 300000 }
    )

    const fetchStatus = useFetchStatus({
        data,
        error,
        isFetching,
        isSuccess,
    })

    const renderItems = (data: OrInflowResponce) => (
        <CustomAccordion.Item key="ed" value="ed">
            <CustomAccordion.Control>
                <CcTitle>
                    {title} ({data?.inflowList?.length || 0})
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {data?.inflowList?.length > 0 ? (
                        <div className={styles.container}>
                            {data?.inflowList?.map((item, index: number) => (
                                <ListItem {...item} key={index} />
                            ))}
                        </div>
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <>
            {/* {fetchStatus ? (
                fetchStatus
            ) : ( */}
            <CustomAccordion
                chevronPosition="left"
                chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
            >
                {isSuccess && data && renderItems(data)}
            </CustomAccordion>
            {/* )} */}
        </>
    )
}

export default OrList
