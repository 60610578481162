import React from 'react'
import { IconTypeProps } from '../IconType'

const PlannedDischarge: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_11989_12577)">
                <path
                    d="M11.4124 3.33081V4.65276M3.48071 3.33081V4.65276"
                    stroke="url(#paint0_linear_11989_12577)"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.77696 16.5504H6.81855C4.1545 16.5504 2.82247 16.5504 1.99485 15.6556C1.16724 14.7609 1.16724 13.3209 1.16724 10.4408V10.1014C1.16724 7.22135 1.16724 5.78132 1.99485 4.8866C2.82247 3.99188 4.1545 3.99188 6.81855 3.99188H8.0744C10.7385 3.99188 12.0705 3.99188 12.8981 4.8866C13.6773 5.72893 13.7229 7.05459 13.7255 9.61015"
                    stroke="url(#paint1_linear_11989_12577)"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M1.4978 7.29657H13.3953"
                    stroke="url(#paint2_linear_11989_12577)"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20.4998 11.7184L16.1718 15.0082V13.2807C13.1974 12.9952 10.3415 14.1332 9.32474 17.2626C9.31355 13.237 12.6295 10.9561 16.1718 10.1855V8.42859L20.4998 11.7184Z"
                    fill="url(#paint3_linear_11989_12577)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12577"
                    x1="3.48071"
                    y1="3.99675"
                    x2="6.55191"
                    y2="7.86036"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12577"
                    x1="1.16724"
                    y1="10.3183"
                    x2="13.1967"
                    y2="12.8405"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11989_12577"
                    x1="1.4978"
                    y1="7.80033"
                    x2="3.14504"
                    y2="11.9094"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11989_12577"
                    x1="9.32471"
                    y1="12.8788"
                    x2="19.7653"
                    y2="15.648"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <clipPath id="clip0_11989_12577">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5 0.296875)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PlannedDischarge
