import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { nsaBaseUrl } from '../../../utils/API'
import { ViralMockData } from '../../../utils/ManagementStatusMock'
// import { INFECTION_MOCK_DATA } from '../../../utils/__Mocks__/InfectionControlMockData'
import { NEW_MSSS_NSA_MOCK_DATA } from '../../../utils/__Mocks__/MsssNsaNewMockData'
import { MSSS_NSA_PREDICTION_MOCK } from '../../../utils/__Mocks__/MsssNsaPredictionMock'
import { NEW_MSSS_NSA_MOCK } from '../../../utils/__Mocks__/newMsssNsaMock'
import { NSA_DEPARTMENT_MOCK_DATA } from '../../../utils/__Mocks__/NsaDepartmentMock'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { WaitingData, CategoryData, DataStructure, Category } from './types'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT === 'Development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development'

export const nsaApi = createApi({
    reducerPath: 'nasApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: nsaBaseUrl,
        prepareHeaders: (headers, { getState }) => {
            const userRole = selectActiveUserRole(getState() as RootState)
            const token = selectToken(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }
            return headers
        },
    }),

    tagTypes: ['Categories', 'Departments', 'NsaInfections'],
    endpoints: (builder) => ({
        getNsaCategories: builder.query<CategoryData, any>({
            query: () => `v1/NSA/categories`,
            providesTags: [{ type: 'Categories', id: 'LIST_CATEGORIES' }],
            transformResponse: (response: CategoryData): CategoryData => {
                if (isDevelopment) {
                    return NEW_MSSS_NSA_MOCK
                }
                return response
            },
            // for the mocking line chart data to the MSSS NSA
            // transformResponse: (response: CategoryData): CategoryData => {

            //     for (let i = 0; i < response?.categories?.length; i++) {
            //         const category = response?.categories[i];
            //         // const newCategoryData = NEW_MSSS_NSA_MOCK_DATA.categories.find((newCategory) => newCategory.name === category.name);
            //         const newCategoryData = NEW_MSSS_NSA_MOCK_DATA.categories[i];

            //         if (newCategoryData) {
            //             // Replace the fields in the current category with the corresponding fields from the new data
            //             category.hoursDelaysStatistics = newCategoryData.hoursDelaysStatistics;
            //             category.inPatientsStatistics = newCategoryData.inPatientsStatistics;
            //             category.outPatientsStatistics = newCategoryData.outPatientsStatistics;
            //             category.hoursDelaysStatisticsPrediction = newCategoryData.hoursDelaysStatisticsPrediction;
            //             category.inPatientsStatisticsPrediction = newCategoryData.inPatientsStatisticsPrediction;
            //             category.outPatientsStatisticsPrediction = newCategoryData.outPatientsStatisticsPrediction;
            //         }
            //     }
            //     return response
            // }
        }),
        getNsaDepartments: builder.query<WaitingData, any>({
            query: () => `v1/NSA/departments`,
            providesTags: [{ type: 'Departments', id: 'LIST_Departments' }],
            transformResponse: (response: WaitingData): WaitingData => {
                if (isDevelopment) {
                    return NSA_DEPARTMENT_MOCK_DATA
                }
                return response
            },
        }),
        getNsaInfection: builder.query<DataStructure, any>({
            query: () => `v1/NSA/infections`,
            providesTags: [{ type: 'NsaInfections', id: 'LIST_Nsa_Infections' }],
            // transformResponse: (response: DataStructure): DataStructure => {
            //     return INFECTION_MOCK_DATA;
            // }
        }),
        getNsaCategoriesPrediction: builder.query<any, any>({
            queryFn: async (_, { getState }) => {
                try {
                    const token = selectToken(getState() as RootState);
                    const userRole = selectActiveUserRole(getState() as RootState);

                    const headers = new Headers();
                    if (token) {
                        headers.set('bearer', `${token}`);
                        headers.set(
                            'Ocp-Apim-Subscription-Key',
                            `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                        );
                        headers.set('Role', userRole);
                    }

                    const response = await fetch(
                        `${nsaBaseUrl}/v1/NSA/NsaPredictions`, { headers }
                    );

                    if (isDevelopment && !response.ok) {
                        // Handling HTTP error statuses
                        return { data: MSSS_NSA_PREDICTION_MOCK };
                    }

                    const data = await response.json();
                    return { data };
                } catch (error) {
                    // Handling network or unexpected errors
                    return {
                        error: {
                            status: 'FETCH_ERROR',
                            error: 'An error occurred',
                        },
                    };
                }
            },
        }),
    }),
})

export const {
    useGetNsaCategoriesQuery,
    useGetNsaDepartmentsQuery,
    useGetNsaInfectionQuery,
    useGetNsaCategoriesPredictionQuery
} = nsaApi
