import {
    InflowHistoricalItem,
    LosHistoricalItem,
    OutflowHistoricalItem,
} from 'redux/rtk/orStatus/types'
import { sortByDateKey } from 'utils/date/sortByDateKey'

export const colorMap: Record<string, string> = {
    SDS: '#56D5E5',
    ODS: '#FFB979',
    'IP-ED': '#7ABD83',
    IpEd: '#7ABD83',
    'IP-units': '#FF7783',
    IPUnits: '#FF7783',
    MSICU: '#7795FF',
    PACU: '#9E5F5F',
    Periop: '#FCFF79',
    PERIOP: '#FCFF79',
    UNK: '#56D5E5',
    FBC: '#FFB979',
    CVICU: '#7ABD83',
    Other: '#BD7A9A',
    OTHER: '#BD7A9A',
}

export const getTrendColor = (label: string) => colorMap[label] || '#CCCCCC'

const unitLegendsMap: Record<string, string> = {
    IpEd: 'IP-ED',
    ODS: 'ODS',
    IPUnits: 'IP-Units',
    SDS: 'SDS',
    PERIOP: 'PERI-OP',
}

export const getUnitsLegendsMap = (name: string) => unitLegendsMap[name] || name

export const extractUniqueDestinations = <
    T extends InflowHistoricalItem | OutflowHistoricalItem | LosHistoricalItem
>(
    data: T[],
    typeKey: keyof T
): string[] => {
    const destinations = data?.map((item) => item[typeKey] as string)
    return [...new Set(destinations)]
}

interface DataSetConfig {
    label: string
    data: any[]
    xAxisKey: string
    yAxisKey: string
}

const removeZfromTheEnd = (items: any[], dateKey: string) =>
    items?.map((item) => ({
        ...item,
        [dateKey]: item[dateKey]?.endsWith('Z')
            ? item[dateKey].slice(0, -1)
            : item[dateKey],
    }))

export const transformOutflowsToDataSetsConfig = (
    dataArray: any[],
    typeKey: string
) => {
    return dataArray?.map((element) => ({
        label: element[typeKey],
        data: removeZfromTheEnd(element.items, 'surgeryScheduleDate'),
    }))
}

export const createDataSet = ({
    label,
    data,
    xAxisKey,
    yAxisKey,
}: DataSetConfig) => {
    const color = getTrendColor(label)
    const sortedData = sortByDateKey(data, xAxisKey)

    return {
        label,
        data: sortedData,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        parsing: {
            xAxisKey: xAxisKey,
            yAxisKey: yAxisKey,
        },
        spanGaps: true,
        pointHoverRadius: 7,
        pointHoverBorderWidth: 3,
    }
}

export function formatDateToMonthDay(dateString: string): string {
    const date = new Date(dateString)

    // Format the date to 'Aug 5'
    const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
    })
    return formatter.format(date)
}
