import { RootState } from '../index'

export const selectFilters = (state: RootState) => state.filters
export const selectSearchValue = (state: RootState) => state.filters.searchValue
export const selectSortBy = (state: RootState) => state.filters.SortBy
export const selectSortOrder = (state: RootState) => state.filters.SortOrder
export const selectCustomFilter = (state: RootState) =>
    state.filters.customFilter
export const selectHospitalIdSearch = (state: RootState) =>
    state.filters.hospitalIdSearch
export const selectPatientNameSearch = (state: RootState) =>
    state.filters.patientNameSearch
export const selectDepartmenSearch = (state: RootState) =>
    state.filters.departmenSearch
export const selectPageNumber = (state: RootState) => state.filters.pageNumber
export const selectHasNextPage = (state: RootState) => state.filters.hasNextPage
export const selectIsLoading = (state: RootState) => state.filters.hasNextPage
export const selectPredictiveMode = (state: RootState) => state.filters.predictiveMode
export const selectActiveDepartment = (state: RootState) => state.filters.activeDepartment
export const selectGenderFilter = (state: RootState) => state.filters.genderFilter
export const selectGenderOptions = (state: RootState) => state.filters.genderOptions
export const selectUnitsOptions = (state: RootState) => state.filters.unitsOptions