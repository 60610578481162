import React from 'react'
import { IconTypeProps } from '../IconType'

const TransferType: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="9"
                cy="8.5"
                r="7.5"
                stroke="url(#paint0_linear_9897_10991)"
                stroke-width="1.5"
            />
            <path
                d="M11.629 6.85324H6M6 6.85324L7.5 5.5M6 6.85324L7.5 8.125M6 10.8777H12M12 10.8777L10.5 12.25M12 10.8777L10.5 9.625"
                stroke="url(#paint1_linear_9897_10991)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9897_10991"
                    x1="1.5"
                    y1="8.55639"
                    x2="15.8684"
                    y2="11.569"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_9897_10991"
                    x1="6"
                    y1="8.90038"
                    x2="11.7986"
                    y2="9.98107"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default TransferType
