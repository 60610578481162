import { generateDate, generateFurureDate } from '../date/generateDate'
// {
//     "date": generateDate(11),
//     "value": 15.22
// },
// {
//     "date": generateDate(10),
//     "value": 16.91
// },
// {
//     "date": generateDate(9),
//     "value": 15.37
// },
// {
//     "date": generateDate(8),
//     "value": 15.37
// },
// {
//     "date": generateDate(7),
//     "value": 13.97
// },
// {
//     "date": generateDate(6),
//     "value": 15.52
// },
// {
//     "date": generateDate(5),
//     "value": 14.11
// },
// {
//     "date": generateDate(4),
//     "value": 15.68
// },
// {
//     "date": generateDate(3),
//     "value": 17.42
// },
// {
//     "date": generateDate(2),
//     "value": 15.84
// },
// {
//     "date": generateDate(1),
//     "value": 14.4
// },
// {
//     "date": generateDate(0),
//     "value": 14.4
// },
// {
//     "date": generateDate(0),
//     "value": 14.4
// }

export const EmergencyDepartmentDataMock = {
    stretcherSection: {
        loS: {
            currentValue: '17h',
            avgValue: 0,
            positive: false,
            values: [
                {
                    date: generateDate(11),
                    value: 17,
                },
                {
                    date: generateDate(10),
                    value: 13,
                },
                {
                    date: generateDate(9),
                    value: 10.5,
                },
                {
                    date: generateDate(8),
                    value: 6.9,
                },
                {
                    date: generateDate(7),
                    value: 3.9,
                },
                {
                    date: generateDate(6),
                    value: 1.6,
                },
                {
                    date: generateDate(5),
                    value: 14.1,
                },
                {
                    date: generateDate(4),
                    value: 22.2,
                },
                {
                    date: generateDate(3),
                    value: 22.3,
                },
                {
                    date: generateDate(2),
                    value: 18.3,
                },
                {
                    date: generateDate(1),
                    value: 16.5,
                },
                {
                    date: generateDate(0),
                    value: 18,
                },
            ],
        },
        loSOver75: {
            currentValue: '17.2h',
            avgValue: 0,
            positive: false,
            values: [
                {
                    date: generateDate(11),
                    value: 17.2,
                },
                {
                    date: generateDate(10),
                    value: 13.2,
                },
                {
                    date: generateDate(9),
                    value: 10.1,
                },
                {
                    date: generateDate(8),
                    value: 6.1,
                },
                {
                    date: generateDate(7),
                    value: 3.2,
                },
                {
                    date: generateDate(6),
                    value: 1.4,
                },
                {
                    date: generateDate(5),
                    value: 1.4,
                },
                {
                    date: generateDate(4),
                    value: 16.8,
                },
                {
                    date: generateDate(3),
                    value: 16.4,
                },
                {
                    date: generateDate(2),
                    value: 12.4,
                },
                {
                    date: generateDate(1),
                    value: 8.4,
                },
                {
                    date: generateDate(0),
                    value: 10.3,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '89',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 89,
                },
                {
                    date: generateDate(10),
                    value: 89,
                },
                {
                    date: generateDate(9),
                    value: 78,
                },
                {
                    date: generateDate(8),
                    value: 75,
                },
                {
                    date: generateDate(7),
                    value: 67,
                },
                {
                    date: generateDate(6),
                    value: 53,
                },
                {
                    date: generateDate(5),
                    value: 33,
                },
                {
                    date: generateDate(4),
                    value: 21,
                },
                {
                    date: generateDate(3),
                    value: 18,
                },
                {
                    date: generateDate(2),
                    value: 18,
                },
                {
                    date: generateDate(1),
                    value: 17,
                },
                {
                    date: generateDate(0),
                    value: 14,
                },
            ],
        },
    },
    ambulatorySection: {
        loS: {
            currentValue: '1.7h',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 1.7,
                },
                {
                    date: generateDate(10),
                    value: -2.3,
                },
                {
                    date: generateDate(9),
                    value: 0.4,
                },
                {
                    date: generateDate(8),
                    value: -3,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
        loSUnder4Hours: {
            currentValue: '73.7%',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 73.7,
                },
                {
                    date: generateDate(10),
                    value: 84.2,
                },
                {
                    date: generateDate(9),
                    value: 100,
                },
                {
                    date: generateDate(8),
                    value: 100,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
        totalOccupancy: {
            currentValue: '19',
            avgValue: 0,
            positive: true,
            values: [
                {
                    date: generateDate(11),
                    value: 19,
                },
                {
                    date: generateDate(10),
                    value: 19,
                },
                {
                    date: generateDate(9),
                    value: 5,
                },
                {
                    date: generateDate(8),
                    value: 3,
                },
                {
                    date: generateDate(7),
                    value: 0,
                },
                {
                    date: generateDate(6),
                    value: 0,
                },
                {
                    date: generateDate(5),
                    value: 0,
                },
                {
                    date: generateDate(4),
                    value: 0,
                },
                {
                    date: generateDate(3),
                    value: 0,
                },
                {
                    date: generateDate(2),
                    value: 0,
                },
                {
                    date: generateDate(1),
                    value: 0,
                },
                {
                    date: generateDate(0),
                    value: 0,
                },
            ],
        },
    },
    admDelay: {
        currentValue: '34.3%',
        avgValue: 0,
        positive: true,
        values: [
            {
                date: generateDate(11),
                value: 34.3,
            },
            {
                date: generateDate(10),
                value: 30.6,
            },
            {
                date: generateDate(9),
                value: 32.5,
            },
            {
                date: generateDate(8),
                value: 23.1,
            },
            {
                date: generateDate(7),
                value: 22.4,
            },
            {
                date: generateDate(6),
                value: 26.4,
            },
            {
                date: generateDate(5),
                value: 39.4,
            },
            {
                date: generateDate(4),
                value: 52.4,
            },
            {
                date: generateDate(3),
                value: 61.1,
            },
            {
                date: generateDate(2),
                value: 50,
            },
            {
                date: generateDate(1),
                value: 41.2,
            },
            {
                date: generateDate(0),
                value: 35.7,
            },
        ],
    },
    preProd: {
        currentValue: '0',
        avgValue: 0,
        positive: false,
        values: [
            {
                date: generateDate(11),
                value: 5,
            },
            {
                date: generateDate(10),
                value: 0,
            },
            {
                date: generateDate(9),
                value: 0,
            },
            {
                date: generateDate(8),
                value: 0,
            },
            {
                date: generateDate(7),
                value: 0,
            },
            {
                date: generateDate(6),
                value: 0,
            },
            {
                date: generateDate(5),
                value: 0,
            },
            {
                date: generateDate(4),
                value: 0,
            },
            {
                date: generateDate(3),
                value: 0,
            },
            {
                date: generateDate(2),
                value: 0,
            },
            {
                date: generateDate(1),
                value: 0,
            },
            {
                date: generateDate(0),
                value: 0,
            },
        ],
    },
    preProdMoreThen1: {
        currentValue: '0%',
        avgValue: 0,
        positive: true,
        values: [
            {
                date: generateDate(11),
                value: 0,
            },
            {
                date: generateDate(10),
                value: 0,
            },
            {
                date: generateDate(9),
                value: 0,
            },
            {
                date: generateDate(8),
                value: 0,
            },
            {
                date: generateDate(7),
                value: 0,
            },
            {
                date: generateDate(6),
                value: 0,
            },
            {
                date: generateDate(5),
                value: 0,
            },
            {
                date: generateDate(4),
                value: 0,
            },
            {
                date: generateDate(3),
                value: 0,
            },
            {
                date: generateDate(2),
                value: 0,
            },
            {
                date: generateDate(1),
                value: 0,
            },
            {
                date: generateDate(0),
                value: 0,
            },
        ],
    },
}
