import { createSlice } from '@reduxjs/toolkit'
import {
    getActionItems,
    getTasksCount,
    getTasksForHuddlePage,
    getTasksForUpdate,
    setTasksMarkAsDone,
    updateActionItems,
    updateHuddleTasks,
    updateNotes,
} from './asyncActions'
import { Status, tasksCountHuddlePageType, ActionItemsType, TasksForHuddlePageType } from './types'

const tasksCountHuddlePage = {} as tasksCountHuddlePageType
const popupActionItems = {} as ActionItemsType
const tasksForHuddlePage: any = {}
const newTasks: any = []
const initialState = {
    tasksCountHuddlePage,
    tasksForHuddlePage,
    popupActionItems,
    isForceTasksRequest: false,
    getTasksCountStatus: Status.LOADING,
    getTasksForHuddlePageStatus: Status.LOADING,
    updateActionItemsStatus: Status.LOADING,
    updateNotesStatus: '',
    setTasksMarkAsDoneStatus: '',
    MarkAsDoneTaskId: 0,
    newTasks,
    tasksForUpdateHuddlePage: {} as any
}

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setIsForceTasksRequest(state, action) {
            state.isForceTasksRequest = action.payload
        },
        setTasksForHuddlePage(state, action) {
            state.tasksForHuddlePage = action.payload
        },
        setPopupActionItems(state, action) {
            state.popupActionItems = action.payload
        },

        setNewTasks(state, action) {
            state.newTasks = action.payload
        },

        toggleChecked(state, action) {
            // state.popupActionItems = action.payload
            const { checked, id, value } = action.payload
            const stateAfterIndex = action.payload.newTasks.slice(
                action.payload.index + 1,
                action.payload.newTasks && action.payload.newTasks?.length
            )
            const resultBefore = [
                ...action.payload.newTasks.slice(0, action.payload.index),
                {
                    id: id,
                    value: value,
                    checked: !checked,
                },
            ]
            state.newTasks = [...resultBefore, ...stateAfterIndex]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTasksCount.pending, (state, action) => {
            state.getTasksCountStatus = Status.LOADING
            state.tasksCountHuddlePage = initialState.tasksCountHuddlePage
        })

        builder.addCase(getTasksCount.fulfilled, (state, action) => {
            state.getTasksCountStatus = Status.SUCCESS
            state.tasksCountHuddlePage = action.payload
        })

        builder.addCase(getTasksCount.rejected, (state, action) => {
            state.getTasksCountStatus = Status.ERROR
            state.tasksCountHuddlePage = initialState.tasksCountHuddlePage
        })

        builder.addCase(getTasksForHuddlePage.pending, (state, action) => {
            state.getTasksForHuddlePageStatus = Status.LOADING
            state.tasksForHuddlePage = initialState.tasksForHuddlePage
        })

        builder.addCase(getTasksForHuddlePage.fulfilled, (state, action) => {
            state.getTasksForHuddlePageStatus = Status.SUCCESS
            state.tasksForHuddlePage = action.payload
        })

        builder.addCase(getTasksForHuddlePage.rejected, (state, action) => {
            state.getTasksForHuddlePageStatus = Status.ERROR
            state.tasksForHuddlePage = initialState.tasksForHuddlePage
        })

        builder.addCase(getActionItems.pending, (state, action) => {
            // state.getTasksForHuddlePageStatus = Status.LOADING;
            state.popupActionItems = initialState.popupActionItems
        })

        builder.addCase(getActionItems.fulfilled, (state, action) => {
            // state.getTasksForHuddlePageStatus = Status.SUCCESS;
            state.popupActionItems = action.payload
        })

        builder.addCase(getActionItems.rejected, (state, action) => {
            // state.getTasksForHuddlePageStatus = Status.ERROR;
            state.popupActionItems = initialState.popupActionItems
        })

        builder.addCase(updateActionItems.pending, (state, action) => {
            state.updateActionItemsStatus = Status.LOADING
        })

        builder.addCase(updateActionItems.fulfilled, (state, action) => {
            state.updateActionItemsStatus = Status.SUCCESS
        })

        builder.addCase(updateActionItems.rejected, (state, action) => {
            state.updateActionItemsStatus = Status.ERROR
        })

        builder.addCase(updateNotes.pending, (state, action) => {
            state.updateNotesStatus = Status.LOADING
        })

        builder.addCase(updateNotes.fulfilled, (state, action) => {
            state.updateNotesStatus = Status.SUCCESS
        })

        builder.addCase(updateNotes.rejected, (state, action) => {
            state.updateNotesStatus = Status.ERROR
        })

        builder.addCase(updateHuddleTasks.pending, (state, action) => {
            state.updateNotesStatus = Status.LOADING;
            state.isForceTasksRequest = initialState.isForceTasksRequest
        })

        builder.addCase(updateHuddleTasks.fulfilled, (state, action) => {
            state.updateNotesStatus = Status.SUCCESS;
            state.isForceTasksRequest = true
        })

        builder.addCase(updateHuddleTasks.rejected, (state, action) => {
            state.updateNotesStatus = Status.ERROR;
            state.isForceTasksRequest = initialState.isForceTasksRequest
        })

        builder.addCase(setTasksMarkAsDone.pending, (state, action) => {
            state.MarkAsDoneTaskId = action.meta.arg.id
            state.setTasksMarkAsDoneStatus = Status.LOADING;
            state.isForceTasksRequest = initialState.isForceTasksRequest
        })

        builder.addCase(setTasksMarkAsDone.fulfilled, (state, action) => {
            state.setTasksMarkAsDoneStatus = Status.SUCCESS;
            state.isForceTasksRequest = true
            state.MarkAsDoneTaskId = initialState.MarkAsDoneTaskId
        })

        builder.addCase(setTasksMarkAsDone.rejected, (state, action) => {
            state.setTasksMarkAsDoneStatus = Status.ERROR;
            state.isForceTasksRequest = initialState.isForceTasksRequest
            state.MarkAsDoneTaskId = initialState.MarkAsDoneTaskId
        })


        builder.addCase(getTasksForUpdate.pending, (state, action) => {
            // state.updateNotesStatus = Status.LOADING;
            state.tasksForUpdateHuddlePage = initialState.tasksForUpdateHuddlePage
        })

        builder.addCase(getTasksForUpdate.fulfilled, (state, action) => {
            // state.updateNotesStatus = Status.SUCCESS;
            state.tasksForUpdateHuddlePage = action.payload
        })

        builder.addCase(getTasksForUpdate.rejected, (state, action) => {
            // state.updateNotesStatus = Status.ERROR;
            state.tasksForUpdateHuddlePage = initialState.tasksForUpdateHuddlePage
        })
    },
})

export const {
    setIsForceTasksRequest,
    setTasksForHuddlePage,
    setPopupActionItems,
    toggleChecked,
    setNewTasks,
} = tasksSlice.actions

export default tasksSlice.reducer
