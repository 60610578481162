import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useSelector } from 'react-redux'
import storeRtk, { RootState } from '..'
import { usersBaseURL } from '../../../utils/API'
import { termsAndConditionsMock } from '../../../utils/__Mocks__/termsAndConditionMock'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { ShowTermsAndConditionsResponse, GuideAndConditionsResponse } from './type'

export const termsApi = createApi({
    reducerPath: 'termsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: usersBaseURL,
        prepareHeaders: (headers, { getState }) => {
            // const token = storeRtk.getState().authenticate.userToken
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set('Role', userRole)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
            }
            return headers
        },
    }),

    tagTypes: ['Show', 'TermsOfService', 'Inflow', 'Lengthofstay', 'UserGuide'],

    endpoints: (builder) => ({
        getShowTermsOfService: builder.query<ShowTermsAndConditionsResponse, void>({
            query: () => `api/TermsAndConditions/show`,
            providesTags: [{ type: 'Show', id: 'SHOW' }],
            // transformResponse: () => {
            //     return termsAndConditionsMock
            // },
        }),
        getTermsOfServiceForPopup: builder.query<GuideAndConditionsResponse, void>({
            query: () => `/api/TermsAndConditions`,
            providesTags: [{ type: 'TermsOfService', id: 'LIST_TERMS' }],
        }),
        acceptTermsOfService: builder.mutation<any, void>({
            query: () => ({
                url: `/api/TermsAndConditions/accept`,
                method: 'POST',
            }),
        }),
        getUserGuide: builder.query<GuideAndConditionsResponse, void>({
            query: () => `/api/userguide`,
            providesTags: [{ type: 'UserGuide', id: 'USERGUID' }],
        }),
    }),
})

export const selectGetTermsResult = termsApi.endpoints.acceptTermsOfService

export const {
    useGetShowTermsOfServiceQuery,
    useGetTermsOfServiceForPopupQuery,
    useAcceptTermsOfServiceMutation,
    useGetUserGuideQuery,
    useLazyGetTermsOfServiceForPopupQuery,
    useLazyGetShowTermsOfServiceQuery
} = termsApi
