import React from 'react'

import styles from './TrendsChartItem.module.scss'
import { useTranslation } from 'react-i18next'
import ColorLegend from './ColorLegend/ColorLegend'
import {
    InflowHistoricalItem,
    LosHistoricalItem,
    OutflowHistoricalItem,
    OutflowHistoricalResponce,
} from 'redux/rtk/orStatus/types'
import TrendsChart from './TrendsChart/TrendsChart'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

type TrendsChartItemProps = {
    title: string
    data?:
        | InflowHistoricalItem[]
        | OutflowHistoricalItem[]
        | LosHistoricalItem[]
    legends?: Array<string>
    xAxisKey: string
    yAxisKey: string
    type?: string
    lableText?: string
    yAxisTitle?: string
    documentationKey: string
}

const TrendsChartItem: React.FC<TrendsChartItemProps> = ({
    title,
    data,
    legends,
    xAxisKey,
    yAxisKey,
    type,
    lableText,
    yAxisTitle,
    documentationKey,
}) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.container__title}>
                {t(title)}{' '}
                <CorporateDocLink
                    baseKey="operatingRoom"
                    sectionKey={documentationKey}
                />
            </div>
            {data && data?.length > 0 && (
                <div className={styles.container__chart}>
                    <TrendsChart
                        data={data}
                        medianValue={10}
                        xAxisKey={xAxisKey}
                        yAxisKey={yAxisKey}
                        type={type}
                        lableText={lableText}
                        yAxisTitle={yAxisTitle}
                    />
                </div>
            )}
            {legends && <ColorLegend legends={legends} />}
        </div>
    )
}

export default TrendsChartItem
