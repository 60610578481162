import React from 'react'
import { InflowItem } from 'redux/rtk/criticalCare/types'
import PopoverItem from 'components/OrStatus/PopoverItem/PopoverItem'
import IconItem from './IconItem/IconItem'
import ProgressBarWithIcon from 'components/common/ProgressBarWithIcon/ProgressBarWithIcon'
import { convertMinutesToHours } from 'utils/strings/stringToMinutes'

import styles from './CcListItem.module.scss'
import ProgressBarWrapper from 'components/common/ProgressBarWrapper/ProgressBarWrapper'

const TOTAL_NUMBER_MINUTES = 16 * 60
interface CcListItemProps extends InflowItem {
    totalTime?: number
}

const CcListItem: React.FC<CcListItemProps> = ({
    patientRecord,
    patientFirstName,
    patientLastName,
    unitName,
    admittingService,
    timeDelayLevel,
    waitingTimeMinutes,
    waitingTimeSinceAdmissionRequestMinutes,
    timeDelaySinceAdmissionLevel,
    totalTime = 16,
}) => {
    return (
        <div className={styles.item}>
            <PopoverItem
                target={patientRecord}
                items={[
                    {
                        title: 'patient name',
                        data: `${patientFirstName ?? '--'} ${
                            patientLastName ?? '--'
                        }`,
                    },
                ]}
            />
            <div>{unitName ?? '--'}</div>
            {admittingService ? (
                <IconItem text={admittingService} />
            ) : (
                <div style={{ textAlign: 'center' }}>--</div>
            )}
            <ProgressBarWrapper
                time={waitingTimeMinutes}
                delayLevel={timeDelayLevel}
                total={totalTime}
            />
            <ProgressBarWrapper
                time={waitingTimeSinceAdmissionRequestMinutes}
                delayLevel={timeDelaySinceAdmissionLevel}
                total={totalTime}
            />
        </div>
    )
}

export default CcListItem
