import React, { ReactNode } from 'react'

import styles from './CcWraperContainer.module.scss'

interface CcWraperContainerProps {
    children: ReactNode
    withIndicator?: boolean
    style?: React.CSSProperties
}

const CcWraperContainer: React.FC<CcWraperContainerProps> = ({
    children,
    withIndicator = false,
    style,
}) => {
    return (
        <div
            className={`${styles.wrapper} ${
                withIndicator ? styles.wrapper__indicator : ''
            }`}
            style={style}
        >
            {children}
        </div>
    )
}

export default CcWraperContainer
