import React from 'react'
import { IconTypeProps } from './IconType'

import {
    PaliativeCareIcon,
    HomeCareIcon,
    SurgeryIcon,
    FamilyMedicineIcon,
    InternalMedicineIcon,
    CardiologyIcon,
    PsychiatryIcon,
    NeurologyIcon,
    FtuIcon,
    SpecialUnitsIcon,
    CriticalCareIcon,
    OncologyIcon,
    GeriatricsIcon,
} from './DepartmentsIcons'
import { MoonIcon, SunIcon } from './ThemeIcons'
import {
    AskIcon,
    DispositionPlanning,
    HeartIcon,
    IcuIcon,
    ImIcon,
    NoTransfers,
    PendingTransfer,
    PlannedDischarge,
    PlannedTransfer,
    PotentialTransfer,
    SignedDischarge,
    TransferType,
} from './CriticalCareIcons'

const iconComponents: {
    [key: string]: React.FC<IconTypeProps>
} = {
    //department icons
    'Critical Care': CriticalCareIcon,
    'Family Med': FamilyMedicineIcon,
    FTU: FtuIcon,
    SSU: FtuIcon,
    Geriatrics: GeriatricsIcon,
    Palliative: PaliativeCareIcon,
    Cardiology: CardiologyIcon,
    Surgery: SurgeryIcon,
    Neurology: NeurologyIcon,
    Psychiatry: PsychiatryIcon,
    Oncology: OncologyIcon,
    'IM non Teach': CardiologyIcon,
    'IM Teach': PaliativeCareIcon,
    HAD: HomeCareIcon,
    'Special Units': SpecialUnitsIcon,
    Neurosurgery: NeurologyIcon,
    Cardiovascular: CardiologyIcon,
    //theme icons
    Moon: MoonIcon,
    Sun: SunIcon,
    //Critical Care
    MSICU: IcuIcon,
    IM: ImIcon,
    CVICU: HeartIcon,
    Ask: AskIcon,
    TransferType: TransferType,
    'signed discharge': SignedDischarge,
    'planned discharge': PlannedDischarge,
    'planned transfer': PlannedTransfer,
    'pending transfer': PendingTransfer,
    'potential transfer': PotentialTransfer,
    'disposition planning': DispositionPlanning,
    'no transfers': NoTransfers,
    // Add more icons here
}

type IconProps = {
    name: string
    color?: string
}

const Icon: React.FC<IconProps> = ({ name, color = 'black', ...props }) => {
    let IconComponent = iconComponents[name]
    if (!IconComponent) {
        IconComponent = iconComponents['Ask']
    }

    const iconProps = {
        fillColor: color,
        // strokeColor: color,
    }

    return <IconComponent {...iconProps} />
}

export default Icon
