import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import styles from '../App.module.scss'
import Login from './Login/Login'
import { useSelector } from 'react-redux'
import '../i18n'
import PrivateRoute from '../utils/Routes/ProtectedRoute'
import WarningModal from '../components/WarningModal/WarningModal'
import { useKeycloak } from '@react-keycloak/web'
import NotFound from './NotFound/NotFound'
import Loader from '../components/Loader/Loader'
import NoProductionBunner from '../components/common/BunnerNoProdunction/NoProductionBunner'
import { useAppDispatch } from '../redux/rtk'
import { selectTheme } from '../redux/rtk/user/selectors'
import { getAuthData } from '../redux/rtk/auth/authSlice'

import ScrubChart from './ScrubChart/ScrubChart'
import ScrubChartNew from './ScrubChartNew/ScrubChartNew'
import OperatingRoomsStatus from './OperatingRoomsStatus/OperatingRoomsStatus'
import EDLive from './EDLive/EDLive'
import OperatingRoom from './OperatingRoom/OperatingRoom'
import CriticalCare from './CriticalCare/CriticalCare'

const ERStatus = lazy(() => import('./ERStatus/ERStatus'))
const Dashboard = lazy(() => import('./Dashboard/Dashboard'))
const Huddle = lazy(() => import('./Huddle/Huddle'))
const HuddleMeeting = lazy(() => import('./HuddleMeeting/HuddleMeeting'))
const ManagementStatusDashboard = lazy(
    () => import('./ManagementStatusDashboard/ManagementStatusDashboard')
)
const Departments = lazy(() => import('./Departments/Departments'))
const ImagingResources = lazy(
    () => import('./ImagingResources/ImagingResources')
)

const MainPage = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const theme = useSelector(selectTheme)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { initialized, keycloak } = useKeycloak()

    useEffect(() => {
        if (!initialized) return
        if (!keycloak.authenticated) return

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(-1)
        }

        return () => {
            if (keycloak) keycloak.onTokenExpired = () => {}
        }
    }, [initialized, keycloak.authenticated, keycloak])

    useEffect(() => {
        if (keycloak.authenticated && keycloak.token) {
            let name =
                (keycloak?.tokenParsed?.name?.length > 0 &&
                    keycloak?.tokenParsed?.name) ||
                (keycloak?.tokenParsed?.given_name &&
                    keycloak?.tokenParsed?.family_name &&
                    `${keycloak?.tokenParsed?.given_name} ${keycloak?.tokenParsed?.family_name}`) ||
                keycloak?.tokenParsed?.preferred_username
            dispatch(
                getAuthData({
                    userName: name,
                    userRole: keycloak.realmAccess?.roles || [],
                    authorized: keycloak.authenticated || false,
                    userToken: keycloak.token,
                    // lastSinceTime: new Date(),
                })
            )
        }
    }, [keycloak, keycloak.authenticated])

    return (
        <>
            <div
                className={`${styles.wrapper} ${styles[theme + '-theme']}`}
                onPaste={(e) => {
                    e.preventDefault()
                    return false
                }}
                onCopy={(e) => {
                    e.preventDefault()
                    return false
                }}
                // onLoad={() => {
                //     keycloak.authenticated &&
                //         // newUserAuthenticated &&
                //         setIsModalOpen(true)
                // }}
            >
                <section className={styles.section}>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="ward/:department"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <Dashboard />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="imaging-resources"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <ImagingResources />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/*"
                            element={
                                <PrivateRoute>
                                    <NotFound />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="huddle"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <Huddle />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="huddle-meeting"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <HuddleMeeting />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="er-status"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <ERStatus />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/management_status"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <ManagementStatusDashboard />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/departments"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <Departments />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <Navigate replace to="/default" />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/ScrubChart"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <ScrubChart />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/ScrubChartNew"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <ScrubChartNew />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/operating_rooms_status"
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <OperatingRoomsStatus />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/ed_live'}
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <EDLive />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/operating-room'}
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <OperatingRoom />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={'/critical-care'}
                            element={
                                <PrivateRoute>
                                    <Suspense fallback={<Loader />}>
                                        <CriticalCare />
                                    </Suspense>
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </section>
            </div>

            <WarningModal
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            {/* <NoProductionBunner /> */}
        </>
    )
}

export default MainPage
