import { useSelector } from 'react-redux'
import { Menu } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
    selectTheme,
    selectUserDetails,
} from '../../../../redux/rtk/user/selectors'

import styles from './ServiceInfoBlock.module.scss'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

interface ServiceInfo {
    data: {
        version: string
        environment: string
    }
}

const ServiceInfoBlock: React.FC<ServiceInfo> = ({ data }) => {
    const { version, environment } = data
    const userDetails = useSelector(selectUserDetails)
    const { t } = useTranslation()

    //@ts-ignore
    const timeZone = dayjs.tz.guess() // @ts-ignore
    const currentLocalTime = dayjs(userDetails?.lastLoginDate)
        // @ts-ignore
        .tz(timeZone, true)
        .format('MMMM DD, YYYY HH:mm')

    const environmentColor = environment !== '' ? 'red' : '#142644'

    return (
        <Menu.Item className={styles.lang__block} key="Service_Info_Block">
            <div className={styles.info}>
                <span className={styles.info_key}>{`${t(
                    'Last login'
                )}: `}</span>
                <span>{currentLocalTime}</span>
            </div>
            <div className={styles.info}>
                <span className={styles.info_key}>{`${t(
                    'Software version'
                )}: `}</span>
                <span>{version}</span>
            </div>
            <div className={styles.info}>
                <span
                    className={styles.info_key}
                    style={{ color: environmentColor }}
                >
                    {t(environment)}
                </span>
            </div>
        </Menu.Item>
    )
}

export default ServiceInfoBlock
