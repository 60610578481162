export const imaginePatientsFields = [
    {
        name: 'time',
        title: 'time',
        location: 1,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 90,
    },
    {
        name: 'patient',
        title: 'patient',
        location: 2,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 50,
    },
    {
        name: 'patient_id',
        title: 'Patient id',
        location: 3,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 80,
    },
    {
        name: 'age',
        title: 'age',
        location: 4,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 40,
    },
    {
        name: 'requesting_unit',
        title: 'requesting unit',
        location: 5,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 140,
    },
    {
        name: 'type',
        title: 'type',
        location: 6,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 60,
    },
    {
        name: 'urgency',
        title: 'urgency',
        location: 7,
        format: null,
        type: 'button',
        color: '#142644',
        colors_map: {
            urgent: '#9D111D',
            moderate: '#D4641D',
            regular: '#236519',
        },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 120,
    },
    {
        name: 'ewt',
        title: 'EWT',
        location: 8,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 60,
    },
    {
        name: 'expected_discharge',
        title: 'expected discharge',
        location: 9,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 150,
    },
    {
        name: 'wait_time',
        title: 'wait time',
        location: 10,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 65,
    },
    {
        name: 'location',
        title: 'Location',
        location: 11,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
]

export const imagineTableMockData = [
    {
        time: '25/08 12:45',
        patient: 'VB',
        patient_id: '1325510',
        age: '62',
        requesting_unit: 'Med unit 4',
        type: 'CT',
        urgency: 'Urgent',
        ewt: '3h',
        expected_discharge: '2h',
        wait_time: '3h',
        location: 'Ward',
    },
    {
        time: '25/08 13:03',
        patient: 'ED',
        patient_id: '5541543',
        age: '57',
        requesting_unit: 'Orthopedic unit 2',
        type: 'US',
        urgency: 'Moderate',
        ewt: '3h',
        expected_discharge: '8d',
        wait_time: '3h',
        location: 'Imaging',
    },
    {
        time: '25/08 13:08',
        patient: 'GB',
        patient_id: '5512345',
        age: '83',
        requesting_unit: 'Geriatrics A',
        type: 'X-Ray',
        urgency: 'Moderate',
        ewt: '2h',
        expected_discharge: '3h',
        wait_time: '5h',
        location: 'Imaging',
    },
    {
        time: '25/08 13:15',
        patient: 'YB',
        patient_id: '5612534',
        age: '35',
        requesting_unit: 'Med unit 2',
        type: 'MRI',
        urgency: 'Regular',
        ewt: '3d',
        expected_discharge: '2d',
        wait_time: '2d',
        location: 'Med unit 2',
    },
    {
        time: '25/08 12:45',
        patient: 'VB',
        patient_id: '1325511',
        age: '62',
        requesting_unit: 'Med unit 4',
        type: 'CT',
        urgency: 'Urgent',
        ewt: '3h',
        expected_discharge: '2d',
        wait_time: '13h',
        location: 'Ward',
    },
    {
        time: '25/08 13:03',
        patient: 'ED',
        patient_id: '5541545',
        age: '57',
        requesting_unit: 'Orthopedic unit 2',
        type: 'US',
        urgency: 'Moderate',
        ewt: '3h',
        expected_discharge: '4d',
        wait_time: '3h',
        location: 'Imaging',
    },
    {
        time: '25/08 13:08',
        patient: 'GB',
        patient_id: '5512340',
        age: '83',
        requesting_unit: 'Geriatrics A',
        type: 'X-Ray',
        urgency: 'Moderate',
        ewt: '2h',
        expected_discharge: '2d',
        wait_time: '8d',
        location: 'Imaging',
    },
    {
        time: '25/08 13:15',
        patient: 'YB',
        patient_id: '5612535',
        age: '35',
        requesting_unit: 'Med unit 2',
        type: 'MRI',
        urgency: 'Regular',
        ewt: '3d',
        expected_discharge: '2d',
        wait_time: '2h',
        location: 'Med unit 2',
    },
]

export const imagineResourcesFilters = [
    {
        progress: 66,
        label: 'All imaging resources',
        notUrgent: '92',
        midUrgent: '20',
        highUrgent: '8',
    },
    {
        progress: 53,
        label: 'MRI',
        notUrgent: '27',
        midUrgent: '5',
        highUrgent: '2',
        time: 'Opp at 4:30pm',
    },
    {
        progress: 75,
        label: 'CT',
        notUrgent: '15',
        midUrgent: '9',
        highUrgent: '1',
    },
    {
        progress: 60,
        label: 'US',
        notUrgent: '15',
        midUrgent: '4',
        highUrgent: '3',
    },
    {
        progress: 25,
        label: 'X-Ray',
        notUrgent: '35',
        midUrgent: '2',
        highUrgent: '2',
        time: 'Opp at 4:30pm',
    },
]
