import {
    Accordion,
    AccordionItemProps,
    AccordionControlProps,
    AccordionPanelProps,
    AccordionProps,
} from '@mantine/core'

import React from 'react'

import styles from './CustomAccordion.module.scss'

const CustomAccordion: React.FC<AccordionProps> & {
    Item: React.FC<AccordionItemProps>
    Control: React.FC<AccordionControlProps>
    Panel: React.FC<AccordionPanelProps>
} = ({ style, children, ...props }) => {
    return (
        <Accordion
            {...props}
            classNames={{
                root: styles.accordion__root,
                control: styles.accordion__control,
                item: styles.accordion__item,
                chevron: styles.accordion__chevron,
                label: styles.accordion__label,
                icon: styles.accordion__icon,
                itemTitle: styles.accordion__itemTitle,
                panel: styles.accordion__panel,
                content: styles.accordion__content,
            }}
        >
            {children}
        </Accordion>
    )
}

const CustomAccordionItem: React.FC<AccordionItemProps> = ({
    children,
    ...itemProps
}) => {
    return <Accordion.Item {...itemProps}>{children}</Accordion.Item>
}

const CustomAccordionControl: React.FC<AccordionControlProps> = ({
    children,
    ...controlProps
}) => {
    return <Accordion.Control {...controlProps}>{children}</Accordion.Control>
}

const CustomAccordionPanel: React.FC<AccordionPanelProps> = ({
    children,
    ...panelProps
}) => {
    return <Accordion.Panel {...panelProps}>{children}</Accordion.Panel>
}

CustomAccordion.Item = CustomAccordionItem
CustomAccordion.Control = CustomAccordionControl
CustomAccordion.Panel = CustomAccordionPanel

export default CustomAccordion
