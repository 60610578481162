import React from 'react'
import Header from '../../components/Header/Header'

import { icons } from '../../utils/iconsMap'
import styles from './ScrubChartNew.module.scss'

const ScrubChartNew = (): JSX.Element => {
    return (
        <div className={styles.wrapper}>
            <Header />
            <section className={styles.section}>
                <div className={styles.section__title}>POWERED BY</div>
                <img
                    src={icons.scrub_chart}
                    alt={'scrub_chart'}
                    className={styles.section__icon}
                />
                <div className={styles.section__text}>
                    Scrubchart.ai C4: Streamlining Healthcare Operations with
                    Real-time AI Actionable Insights
                </div>
            </section>
        </div>
    )
}

export default ScrubChartNew
