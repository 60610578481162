export enum SortBy {
    createDate = 0,
    department = 1,
    id = 2,
    name = 3,
    age = 4,
    gender = 5,
    pendingConsultations = 6,
    alerts = 7,
    tasks = 8,
    physicianInCharge = 9,
    discharge = 10,
    // hazards = 11,
    neceDesc = 11,
    ews = 12,
    primaryDiagnosis = 13,
    admission = 14,
    status = 16
}

export enum SortOrder {
    ascending = 1,
    descending = 2,
}

export enum DischargeGroupFilter {
    default = 0,
    disch_today = 1,
    disch_within = 2,
    disch_over = 3
}

