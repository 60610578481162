import { createAsyncThunk } from '@reduxjs/toolkit'
import { NURSINGAPIDATA } from '../../../utils/API'

export const getShifts = createAsyncThunk<any, any>(
    'nursing/getShifts ',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await NURSINGAPIDATA.get(
                `/v1/Shifts?Department=${param.department}`,
                {
                    headers: {
                        bearer: `${param.token}`,
                        role: param.role,
                    },
                }
            )
            const data = response?.data
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const setShiftsData = createAsyncThunk<any, any>(
    'nursing/setShifts ',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await NURSINGAPIDATA.post(`/v1/Shifts`, param.body, {
                headers: {
                    bearer: `${param.token}`,
                    role: param.role,
                },
            })
            const data = response?.data
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const getNurses = createAsyncThunk<any, any>(
    'nursing/getNurses ',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const response = await NURSINGAPIDATA.get(`/v1/Shifts/nurses?Department=${param.department}`, {
                headers: {
                    bearer: `${param.token}`,
                    role: param.role,
                },
            })
            const data = response?.data
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
