import React from 'react'
import { IconTypeProps } from '../IconType'

const PotentialTransfer: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.9976 6.60356V7.95893H10.6092C10.4295 7.95893 10.2571 8.03033 10.13 8.15742C10.0029 8.28451 9.9315 8.45688 9.9315 8.63662C9.9315 8.81635 10.0029 8.98872 10.13 9.11581C10.2571 9.2429 10.4295 9.3143 10.6092 9.3143H14.6753C14.855 9.3143 15.0274 9.2429 15.1545 9.11581C15.2816 8.98872 15.353 8.81635 15.353 8.63662V8.00637L17.6842 9.85645L15.353 11.8895V11.3474C15.353 11.1676 15.2816 10.9953 15.1545 10.8682C15.0274 10.7411 14.855 10.6697 14.6753 10.6697H8.57612C8.39639 10.6697 8.22402 10.7411 8.09693 10.8682C7.96984 10.9953 7.89844 11.1676 7.89844 11.3474C7.89844 11.5271 7.96984 11.6995 8.09693 11.8266C8.22402 11.9537 8.39639 12.025 8.57612 12.025H13.9976V13.3804C13.9978 13.5107 14.0355 13.6381 14.1062 13.7475C14.1769 13.8568 14.2776 13.9435 14.3963 13.9971C14.515 14.0508 14.6466 14.0691 14.7754 14.0498C14.9042 14.0306 15.0247 13.9746 15.1226 13.8887L19.1887 10.3444C19.261 10.2809 19.3189 10.2027 19.3586 10.1151C19.3984 10.0274 19.419 9.93234 19.4191 9.83612C19.4166 9.73663 19.3923 9.6389 19.3478 9.54989C19.3033 9.46087 19.2397 9.38275 19.1616 9.32108L15.0955 6.08851C14.9967 6.0104 14.8781 5.96136 14.753 5.94688C14.6279 5.9324 14.5013 5.95306 14.3873 6.00654C14.2733 6.06003 14.1764 6.14422 14.1076 6.24967C14.0388 6.35512 14.0007 6.47767 13.9976 6.60356Z"
                fill="url(#paint0_linear_11989_12520)"
            />
            <path
                d="M4.56497 4.68848C3.82088 4.68848 3.18308 4.89348 2.66677 5.2959C2.4238 5.48572 2.20361 5.70591 2.02138 5.95648C1.83915 6.19945 1.70248 6.4652 1.59618 6.74613C1.55062 6.87521 1.61137 7.01188 1.73285 7.06503L2.849 7.55856C2.97807 7.61171 3.12993 7.55856 3.18308 7.42948C3.18308 7.42948 3.18308 7.42948 3.18308 7.42189C3.28938 7.17133 3.45642 6.92077 3.67661 6.68539C3.87403 6.47279 4.16255 6.37408 4.55738 6.37408H4.58016C4.98258 6.37408 5.28629 6.48038 5.49889 6.70057C5.71908 6.92077 5.82538 7.18651 5.82538 7.5206C5.82538 7.80153 5.74186 8.0521 5.57482 8.30266C5.385 8.576 5.11166 8.87212 4.75479 9.19861C4.69405 9.25176 4.6409 9.30491 4.58775 9.35806C4.26885 9.66937 4.04866 9.95789 3.91958 10.2388C3.84365 10.4059 3.78291 10.6109 3.74495 10.8538C3.69939 11.15 3.67661 11.4385 3.67661 11.7346C3.67661 11.8789 3.78291 11.9928 3.92718 11.9928H5.26351C5.40018 11.9928 5.51407 11.8865 5.51407 11.7422C5.52167 11.1727 5.59 10.8994 5.64315 10.7627C5.6963 10.6337 5.86334 10.3755 6.36447 9.88956C6.78208 9.48714 7.09338 9.0999 7.2832 8.72786C7.48062 8.34822 7.58691 7.91542 7.58691 7.45226C7.58691 6.69298 7.32117 6.04 6.79726 5.49332C6.27336 4.96182 5.52926 4.68848 4.58775 4.68848H4.56497ZM4.56497 12.9419C3.91199 12.9419 3.38049 13.4734 3.38049 14.1263C3.38049 14.7793 3.91199 15.3108 4.56497 15.3108H4.58775C5.24073 15.2956 5.76464 14.7566 5.74945 14.1036C5.73427 13.4658 5.22555 12.9495 4.58775 12.9419H4.56497Z"
                fill="#83BDDC"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12520"
                    x1="7.89844"
                    y1="10.0303"
                    x2="18.4814"
                    y2="13.1805"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default PotentialTransfer
