import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { labsEndBaseUrl, patientsTableDataURL } from '../../../utils/API'
import { responceMock } from '../../../utils/__Mocks__/EdData'
import { admissionsPetientsMock } from '../../../utils/__Mocks__/EDLivePatients'
import { EmergencyDepartmentDataMock } from '../../../utils/__Mocks__/EmergencyDepartmentMockData'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { EdPatient, EdCorporateData, EdWardResponse } from './types'

const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'development'

export const edLiveApi = createApi({
    reducerPath: 'edLiveApi',
    baseQuery: fetchBaseQuery({
        baseUrl: patientsTableDataURL,
        prepareHeaders: (headers, { getState }) => {
            const token = selectToken(getState() as RootState)
            const userRole = selectActiveUserRole(getState() as RootState)
            if (token) {
                headers.set('bearer', `${token}`)
                headers.set(
                    'Ocp-Apim-Subscription-Key',
                    `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
                )
                headers.set('Role', userRole)
            }
            return headers
        },
    }),

    tagTypes: ['EdPatients', 'EdCorporate'],
    endpoints: (builder) => ({
        getEdPatients: builder.query<EdWardResponse, any>({
            query: () => `/v1/ED/edWard`,
            providesTags: [{ type: 'EdPatients', id: 'ED_LIVE_PATIENTS' }],
            // This is for mocking data
            transformResponse: (
                response: EdWardResponse
            ): EdWardResponse => {
                if (isDevelopment) {
                    return admissionsPetientsMock
                }
                return response
            },
            // queryFn: async (_, { getState }) => {
            //     try {
            //         const token = selectToken(getState() as RootState)
            //         const userRole = selectActiveUserRole(
            //             getState() as RootState
            //         )

            //         const headers = new Headers()
            //         if (token) {
            //             headers.set('bearer', `${token}`)
            //             headers.set(
            //                 'Ocp-Apim-Subscription-Key',
            //                 `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            //             )
            //             headers.set('Role', userRole)
            //         }

            //         const response = await fetch(
            //             `${patientsTableDataURL}/v1/ED/edWard`,
            //             { headers }
            //         )

            //         if (isDevelopment) {
            //             // Handling HTTP error statuses
            //             return { data: admissionsPetientsMock }
            //         }

            //         const data = await response.json()
            //         return { data };
            //     } catch (error) {
            //         // Handling network or unexpected errors
            //         return {
            //             error: {
            //                 status: 'FETCH_ERROR',
            //                 error: 'An error occurred',
            //             },
            //         }
            //     }
            // },
        }),
        getEdCorporate: builder.query<EdCorporateData, any>({
            query: () => `/v1/ED/edCorporate`,
            providesTags: [{ type: 'EdPatients', id: 'ED_LIVE_PATIENTS' }],
            // This is for mocking data
            transformResponse: (
                response: EdCorporateData
            ): EdCorporateData => {
                if (isDevelopment) {
                    return responceMock
                }
                return response
            },
            // queryFn: async (_, { getState }) => {
            //     try {
            //         const token = selectToken(getState() as RootState)
            //         console.log('🚀 ~ queryFn: ~ token:', token)
            //         const userRole = selectActiveUserRole(
            //             getState() as RootState
            //         )

            //         const headers = new Headers()
            //         if (token) {
            //             headers.set('bearer', `${token}`)
            //             headers.set(
            //                 'Ocp-Apim-Subscription-Key',
            //                 `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            //             )
            //             headers.set('Role', userRole)
            //         }

            //         const response = await fetch(
            //             `${patientsTableDataURL}/v1/ED/edCorporate`,
            //             { headers }
            //         )

            //         if (isDevelopment) {
            //             // Handling HTTP error statuses
            //             return { data: responceMock }
            //         }

            //         const data = await response.json()
            //         // return { data };
            //         return data
            //     } catch (error) {
            //         // Handling network or unexpected errors
            //         return {
            //             error: {
            //                 status: 'FETCH_ERROR',
            //                 error: 'An error occurred',
            //             },
            //         }
            //     }
            // },
        }),
    }),
})

export const {
    useGetEdPatientsQuery,
    useGetEdCorporateQuery,
    useLazyGetEdPatientsQuery,
    useLazyGetEdCorporateQuery,
} = edLiveApi
