import { useState, useEffect } from 'react';

// Custom hook to get the current window width
const useWindowWidth = (): number => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Update the width state
            setWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

export default useWindowWidth;