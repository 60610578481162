import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { selectToken } from '../redux/rtk/auth/selectors'
import { selectActiveUserRole } from '../redux/rtk/user/selectors'
import { selectDashboardPatientModalPopup } from '../redux/rtk/patientModal/selectors'
import {
    getUpdatesForPatient,
    getUpdatesForPatients,
} from '../redux/rtk/patientsTable/asyncAction'
import { selectDashboardPopupPatientId } from '../redux/rtk/patientsTable/selectors'

export default function useFetchUpdates() {
    const { keycloak } = useKeycloak()
    const dispatch = useDispatch()

    const isAuthorized = keycloak.authenticated
    const dashboardPatientModalPopup = useSelector(
        selectDashboardPatientModalPopup
    )
    const dashboardPopupPatientId = useSelector(selectDashboardPopupPatientId)
    const userToken = useSelector(selectToken)
    const activeUserRole = useSelector(selectActiveUserRole)
    const getUpdates = (
        fetchDate: any,
        patientId: string | number | null,
        getUpdatesAction: string
    ) => {
        if (!isAuthorized) return

        if (keycloak.isTokenExpired()) return

        const options = {
            token: userToken || keycloak.token,
            after: fetchDate,
            patientId: dashboardPopupPatientId,
            role: activeUserRole,
        }
        if (keycloak.token && fetchDate?.length) {
            dashboardPatientModalPopup && dashboardPopupPatientId
                ? dispatch(getUpdatesForPatient(options))
                : dispatch(getUpdatesForPatients(options))
        }
    }
    return getUpdates
}
