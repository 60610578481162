import React from 'react'
import CcTitle from '../../common/CcTitle/CcTitle'
import { useTranslation } from 'react-i18next'

import styles from './ORStatusInflow.module.scss'
import OrList from '../OrList/OrList'
import CustomScrollArea from '../../common/CustomScrollArea/CustomScrollArea'
import OrSectionHeaders from '../OrSectionHeaders/OrSectionHeaders'
import GridHeader from 'components/common/GridHeader/GridHeader'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const ORStatusInflow = () => {
    const { t } = useTranslation()
    const inflowHeaders = ['MRN', 'Location', 'Surgery Schedule', 'Theater']
    return (
        <div className={styles.container}>
            <CcTitle style={{ paddingLeft: '3rem' }}>
                {t('Inflow')}
                <CorporateDocLink
                    baseKey="operatingRoom"
                    sectionKey="OrInflow"
                />
            </CcTitle>
            <div className={styles.container__body}>
                {/* <OrSectionHeaders
                    items={inflowHeaders}
                    style={{
                        gridTemplateColumns: '1fr 1fr 2fr 1fr',
                        marginRight: '5.2rem',
                        gap: '0px 0.5rem',
                    }}
                /> */}
                <GridHeader
                    style={{
                        gridTemplateColumns: '1fr 1fr 2fr 1fr',
                        marginRight: '5.2rem',
                        gap: '0px 0.5rem',
                    }}
                >
                    <div style={{ textAlign: 'left' }}>{t('MRN')}</div>
                    <div style={{ textAlign: 'left' }}>{t('Location')}</div>
                    <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        {t('Surgery Schedule')}
                    </div>
                    <div style={{ textAlign: 'center' }}>{t('Theater')}</div>
                </GridHeader>
                <CustomScrollArea.Autosize mah={'75vh'} mx="auto">
                    <OrList title={t('SDS')} admissionType={'SDS'} />
                    <OrList title={t('IP-ED')} admissionType={'IP-ED'} />
                    <OrList title={t('IP-UNITS')} admissionType={'IP-UNITS'} />
                    <OrList title={t('ODS')} admissionType={'ODS'} />
                </CustomScrollArea.Autosize>
            </div>
        </div>
    )
}

export default ORStatusInflow
