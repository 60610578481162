const ManagementMenuOptions = [
    // {
    //     title: 'Dashboard',
    //     link: '/dashboard',
    // },
    {
        title: 'JGH Management View',
        link: '/management_status',
        width: 210,
    },
    // {
    //     title: 'Emergency department',
    //     link: '/ed_live',
    //     width: 210,
    // },
    {
        title: 'Critical Care View',
        link: '/critical-care',
        width: 180,
    },
    {
        title: 'Operating Room View',
        link: '/operating-room',
        width: 180,
    },
    // {
    //     title: 'Departments',
    //     link: '/departments',
    //     width: 150
    // },
    // {
    //     title: 'OR statuses',
    //     link: '/operating_rooms_status',
    //     width: 210
    // },
]

export default ManagementMenuOptions
