import {
    createTheme,
    CSSVariablesResolver,
    MantineProvider,
    rem,
} from '@mantine/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AddmisionExport from '../../components/AdmissionExport/AddmisionExport'
import AdmissionsFilter from '../../components/AdmissionsFilter/AdmissionsFilter'
import AdmissionsTable from '../../components/AdmissionsTable/AdmissionsTable'
import DashboardSidebar from '../../components/DashboardSidebar/DashboardSidebar'
import Header from '../../components/Header/Header'
import CorporateDocLink from '../../components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'
import { SelectedButtonsProvider } from '../../contexts/SelectedButtonsContext'
import { selectTheme } from '../../redux/rtk/user/selectors'

import styles from './EDLive.module.scss'
type SelectedButton = {
    id: string
    value: string[]
}

const EDLive = () => {
    const theme = useSelector(selectTheme)
    const themeOverride = createTheme({
        other: {
            deepOrangeLight: '#E17900',
            deepOrangeDark: '#FC8C0C',
            customBlue: '#1970B9',
            heroHeight: rem(400),
        },
    })

    const resolver: CSSVariablesResolver = (theme) => ({
        variables: {
            '--mantine-hero-height': theme.other.heroHeight,
            '--ai-hover': theme.other.customBlue,
            '--mantine-color-gray-1': theme.other.customBlue,
        },
        light: {
            '--mantine-color-deep-orange': theme.other.deepOrangeLight,
            '--ai-hover': theme.other.customBlue,
            '--mantine-color-gray-1': theme.other.customBlue,
        },
        dark: {
            '--mantine-color-deep-orange': theme.other.deepOrangeDark,
            '--ai-hover': theme.other.customBlue,
            '--mantine-color-gray-1': theme.other.customBlue,
        },
    })
    return (
        <div className={styles.wrapper}>
            <Header />
            <SelectedButtonsProvider>
                <section className={styles.section}>
                    <div
                        className={`${styles.section_item} ${styles.section_main}`}
                    >
                        <div className={styles.section_item_header}>
                            <div className={styles.section_item_header_left}>
                                <AdmissionsFilter />
                                <AddmisionExport />
                            </div>
                            <CorporateDocLink
                                baseKey="ward"
                                sectionKey="wardEd"
                            />
                        </div>
                        <MantineProvider
                            theme={themeOverride}
                            cssVariablesResolver={resolver}
                            // defaultColorScheme={theme}
                            defaultColorScheme="dark"
                        >
                            <AdmissionsTable />
                        </MantineProvider>
                    </div>
                    {/* <div
                    className={`${styles.section_item} ${styles.section_sidebar}`}
                >
                    <DashboardSidebar />
                </div> */}
                </section>
            </SelectedButtonsProvider>
        </div>
    )
}

export default EDLive
