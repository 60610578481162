import React from 'react'
import ResusContainer from './ResusContainer/ResusContainer'
import EDPods from './EDPods/EDPods'
import PACU from './PACU/PACU'
import OperatingRoom from './OperatingRoom/OperatingRoom'
import CcWraperContainer from '../../common/CcWraperContainer/CcWraperContainer'
import InflowSummary from './InflowSummary/InflowSummary'

import styles from './CriticalCareInflow.module.scss'
import OrSectionHeaders from 'components/OrStatus/OrSectionHeaders/OrSectionHeaders'
import CustomScrollArea from 'components/common/CustomScrollArea/CustomScrollArea'
import GridHeader from 'components/common/GridHeader/GridHeader'
import { useTranslation } from 'react-i18next'

const CriticalCareInflow: React.FC = () => {
    const CcHeaders = [
        'MRN',
        'Bed',
        'Admitting Service',
        'LOS / Schedule',
        'Time since admission / Transfer delay',
    ]
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.container__content}>
                <div className={styles.container__content__main}>
                    <div className={styles.container__title}>
                        {t('cc.inflow.title')}
                    </div>
                    {/* <OrSectionHeaders
                        items={CcHeaders}
                        // style={{ gridTemplateColumns: '1fr 1fr 2fr 2fr 2fr' }}
                        style={{
                            gridTemplateColumns: '1fr 1fr 1.5fr 2fr 2fr',
                            // marginLeft: '3.5rem',
                            // marginRight: '4rem',
                            marginLeft: '4.2rem',
                            marginRight: '7.2rem',
                        }}
                    /> */}
                    <GridHeader
                        style={{
                            gridTemplateColumns: '1fr 1fr 1.5fr 2fr 2fr',
                            marginLeft: '4.2rem',
                            marginRight: '7.2rem',
                            gap: '0px 10px',
                            height: '5rem',
                        }}
                    >
                        <div style={{ textAlign: 'left' }}>
                            {t('cc.inflow.grid.MRN')}
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            {t('cc.inflow.grid.Bed')}
                        </div>
                        <div
                            style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                        >
                            {t('cc.inflow.grid.Admitting Service')}
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            {t('cc.inflow.grid.Schedule')}
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            {t('cc.inflow.grid.delay')}
                        </div>
                    </GridHeader>
                    <CcWraperContainer style={{ minHeight: '70vh', flex: '1' }}>
                        <CustomScrollArea.Autosize mah={'80vh'} mx="auto">
                            <ResusContainer />
                            <EDPods />
                            <OperatingRoom />
                            <PACU />
                        </CustomScrollArea.Autosize>
                    </CcWraperContainer>
                </div>
                <div className={styles.container__content__summary}>
                    {/* <div
                        className={`${styles.container__title} ${styles.container__title_secondary}`}
                    >
                        {t('cc.inflow.summary.title')}
                    </div>
                    <div className={styles.container__content__summary_body}> */}
                    <InflowSummary />
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default CriticalCareInflow
