import React from 'react'
import { IconTypeProps } from '../IconType'

const SignedDischarge: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_11989_12575)">
                <path
                    d="M7.54734 15.0121H6.64612C4.14103 15.0121 2.88849 15.0121 2.11026 14.1707C1.33203 13.3294 1.33203 11.9753 1.33203 9.2671V8.94793C1.33203 6.23973 1.33203 4.88563 2.11026 4.0443C2.88849 3.20297 4.14103 3.20297 6.64612 3.20297H7.82703C10.3321 3.20297 11.5847 3.20297 12.3629 4.0443C13.0955 4.83637 13.1384 6.08292 13.141 8.48598"
                    stroke="url(#paint0_linear_11989_12575)"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.5166 10.0374C3.5166 10.0374 4.4466 10.0374 5.37659 11.8974C5.37659 11.8974 8.3307 7.24738 10.9566 6.31738"
                    stroke="url(#paint1_linear_11989_12575)"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.6885 11.8468L15.3605 15.1366V13.4091C12.3861 13.1236 9.53022 14.2616 8.51346 17.391C8.50227 13.3654 11.8182 11.0845 15.3605 10.3139V8.55698L19.6885 11.8468Z"
                    fill="url(#paint2_linear_11989_12575)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12575"
                    x1="1.33203"
                    y1="9.15191"
                    x2="12.6437"
                    y2="11.5236"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12575"
                    x1="3.5166"
                    y1="9.12835"
                    x2="10.4173"
                    y2="11.0575"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11989_12575"
                    x1="8.51343"
                    y1="13.0072"
                    x2="18.954"
                    y2="15.7764"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <clipPath id="clip0_11989_12575">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.160156 0.296875)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SignedDischarge
