import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../types/Status'
import { getNewLabsResult } from './asyncActions'
import { labResultType } from './types'

const initialState = {
    LabResultsData: [] as labResultType[],
    LabResultsDataStatus: Status.LOADING
}

const labsSlice = createSlice({
    name: 'labs',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getNewLabsResult.pending, (state, action) => {
            state.LabResultsDataStatus = Status.LOADING
            state.LabResultsData = initialState.LabResultsData
        })

        builder.addCase(getNewLabsResult.fulfilled, (state, action) => {
            state.LabResultsDataStatus = Status.SUCCESS
            state.LabResultsData = action.payload.labResults
        })

        builder.addCase(getNewLabsResult.rejected, (state, action) => {
            state.LabResultsDataStatus = Status.ERROR
            state.LabResultsData = initialState.LabResultsData
        })
    },
})

export const { } = labsSlice.actions

export default labsSlice.reducer