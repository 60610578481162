export const calculatePosition = (
    time: number,
    startTime: number,
    endTime: number
) => {
    return ((time - startTime) / (endTime - startTime)) * 100
}

export const calculateWidth = (
    start: number,
    end: number,
    startTime: number,
    endTime: number
) => {
    return ((end - start) / (endTime - startTime)) * 100
}
