export enum Status {
    LOADING = 'loading',
    SUCCESS = 'completed',
    ERROR = 'error',
}



export type AlertType = {
    patientId: string,
    alerts: string[]
    count: number
}

export type AlertsDataType = {
    alerts: AlertType[]
}


export interface Alert {
    id: number;
    alert: string;
    createdAt: string;
    occuredOn: string
}

export interface GeneralAlertsData {
    totalCount: number;
    alerts: Alert[];
    lastFetchDateInUtc: string
}
