import React, { useMemo } from 'react'
import { useGetPacuInflowDataQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import BgWrapper from '../../../common/BgWrapper/BgWrapper'
import CcTitle from '../../../common/CcTitle/CcTitle'
import CustomAccordion from 'components/common/CustomAccordion/CustomAccordion'
import CcListItem from '../CcListItem/CcListItem'

import { icons } from '../../../../utils/iconsMap'
import styles from './PACU.module.scss'
import { useTranslation } from 'react-i18next'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const PACU: React.FC = () => {
    const { data, error, isFetching, isSuccess } = useGetPacuInflowDataQuery(
        undefined,
        {
            pollingInterval: 300000,
        }
    )
    const { t } = useTranslation()

    const pacuItems = useMemo(() => {
        if (!isSuccess || !data?.criticalCarePacuInflows) return []

        return data?.criticalCarePacuInflows?.map((item) => ({
            unitName: item.surgeryRoom,
            waitingTimeMinutes: item.minutesSinceEnteredPacu,
            waitingTimeSinceAdmissionRequestMinutes:
                item.waitingTimePacuAfterRequestAdmissionMinutes,
            patientRecord: item.patientRecord,
            patientFirstName: item.patientFirstName,
            patientLastName: item.patientLastName,
            admittingService: item.admittingService,
            timeDelayLevel: item.timeDelayLevel,
            timeDelaySinceAdmissionLevel: item.timeDelaySinceAdmissionLevel,
        }))
    }, [isSuccess, data])

    const renderItems = () => (
        <CustomAccordion.Item key="pacu" value="pacu">
            <CustomAccordion.Control>
                <CcTitle>
                    {t('cc.inflow.PACU')} ({pacuItems.length}){' '}
                    <CorporateDocLink
                        baseKey="criticalCare"
                        sectionKey="inflowPacu"
                    />
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    {pacuItems?.length > 0 ? (
                        pacuItems?.map((pacuItem, index) => (
                            <CcListItem
                                {...pacuItem}
                                key={index}
                                totalTime={16}
                            />
                        ))
                    ) : (
                        <CenteredWrapper>
                            <NoMoreEntries text={'No patients'} />
                        </CenteredWrapper>
                    )}
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <div>
            <CustomAccordion
                chevronPosition="left"
                defaultValue="ed"
                chevron={<img src={icons.chevron} alt="sort arrow" />}
            >
                {renderItems()}
            </CustomAccordion>
        </div>
    )
}

export default PACU
