import React from 'react'
import { IconTypeProps } from '../IconType'

const NoTransfers: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.86084 7.45117L4.31201 10M4.31201 10L1.76318 12.5488M4.31201 10L6.86084 12.5488M4.31201 10L1.76318 7.45117"
                stroke="url(#paint0_linear_11989_12518)"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.3462 6.60356V7.95893H10.9578C10.7781 7.95893 10.6057 8.03033 10.4786 8.15742C10.3515 8.28451 10.2801 8.45688 10.2801 8.63662C10.2801 8.81635 10.3515 8.98872 10.4786 9.11581C10.6057 9.2429 10.7781 9.3143 10.9578 9.3143H15.0239C15.2037 9.3143 15.376 9.2429 15.5031 9.11581C15.6302 8.98872 15.7016 8.81635 15.7016 8.63662V8.00637L18.0329 9.85645L15.7016 11.8895V11.3474C15.7016 11.1676 15.6302 10.9953 15.5031 10.8682C15.376 10.7411 15.2037 10.6697 15.0239 10.6697H8.92476C8.74502 10.6697 8.57265 10.7411 8.44556 10.8682C8.31847 10.9953 8.24707 11.1676 8.24707 11.3474C8.24707 11.5271 8.31847 11.6995 8.44556 11.8266C8.57265 11.9537 8.74502 12.025 8.92476 12.025H14.3462V13.3804C14.3464 13.5107 14.3841 13.6381 14.4548 13.7475C14.5255 13.8568 14.6262 13.9435 14.7449 13.9971C14.8636 14.0508 14.9952 14.0691 15.124 14.0498C15.2528 14.0306 15.3734 13.9746 15.4712 13.8887L19.5373 10.3444C19.6096 10.2809 19.6676 10.2027 19.7073 10.1151C19.747 10.0274 19.7676 9.93234 19.7677 9.83612C19.7653 9.73663 19.7409 9.6389 19.6964 9.54989C19.6519 9.46087 19.5883 9.38275 19.5102 9.32108L15.4441 6.08851C15.3453 6.0104 15.2267 5.96136 15.1017 5.94688C14.9766 5.9324 14.8499 5.95306 14.7359 6.00654C14.6219 6.06003 14.5251 6.14422 14.4562 6.24967C14.3874 6.35512 14.3493 6.47767 14.3462 6.60356Z"
                fill="url(#paint1_linear_11989_12518)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12518"
                    x1="1.76318"
                    y1="10.0192"
                    x2="6.64618"
                    y2="11.043"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12518"
                    x1="8.24707"
                    y1="10.0303"
                    x2="18.83"
                    y2="13.1805"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default NoTransfers
