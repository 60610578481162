import React, { ReactNode } from 'react'
import indicatorIconMapper from '../../../../utils/indicatorIconMaper'

import styles from './IdColumn.module.scss'

type IdColumnProps = {
    triage: string
    number: string | number | ReactNode
}

const IdColumn: React.FC<IdColumnProps> = ({ triage, number }) => {
    return (
        <div className={styles.id}>
            <img
                src={indicatorIconMapper({ indicatorValue: triage })}
                className={`${styles.id__img_indicator}`}
                alt={`indicator: ${number}`}
            />
            <span>{number}</span>
        </div>
    )
}

export default IdColumn
