import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import storeRtk, { RootState } from '..'
import { format } from 'date-fns'
import { selectToken } from '../auth/selectors'
import { selectActiveUserRole } from '../user/selectors'
import { patientsTableDataURL } from '../../../utils/API'
const username = 'misha@scrubchart.ai';
const password = '8PMP})1(h031';
// Encode in base64
const base64Credentials = btoa(`${username}:${password}`);

const currentDate = new Date()
const formattedDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ssXXX")

export const alayaCareApi = createApi({
    reducerPath: 'AlayaCareApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: patientsTableDataURL,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Authorization', `Basic ${base64Credentials}`)
            headers.set(
                'Ocp-Apim-Subscription-Key',
                `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
            )
            // headers.set('Content-Type', 'application/json')
            // headers.set('Connection', 'keep-alive')
            // headers.set('Accept-Encoding', 'gzip, deflate, br')
            // headers.set('Host', 'partnerships.uat.alayacare.ca')
            // headers.set('Cache-Control', 'no-cache')
            // headers.set('Accept', '*/*')
            // headers.set('Cookie', 'PHPSESSID=g96l10mnhjfkt2p595lj7q9c1c')
            // headers.set('Referrer-Policy', "no-referrer-when-downgrade")
            return headers
        },
    }),

    tagTypes: [
        'Clients',
        'Patient'
    ],
    endpoints: (builder) => ({
        getClients: builder.query<any, any>({
            query: () => `/v1/patients/?page=1&count=25`,
            // query: () => `/v2/intake/referrals/generic`,
            providesTags: [{ type: 'Clients', id: 'LIST_Clients' }],
        }),

        publishPatient: builder.mutation<any, any>({
            query: (payload) => ({
                url: '/api/AlayaCare/CreateAlayaCareReferral',
                // url: '/v2/intake/referrals/caredove',
                method: 'POST',
                body: {
                    status: "Posted",
                    transmission_date: formattedDate,
                    ...payload
                },
            }),
            invalidatesTags: ['Patient'],
            // query: () => `/v2/intake/referrals/generic`,
            // providesTags: [{ type: 'Clients', id: 'LIST_Clients' }],
        }),
    }),
})

export const {
    useLazyGetClientsQuery,
    usePublishPatientMutation
} = alayaCareApi
