import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUserDetails, setUpdatePreferences } from './asyncAction'
import { UserInfoSliceState, UserInfo, Status, Locale, Theme } from './types'

const userDetailsInit: UserInfo = {
    userName: '',
    name: '',
    language: 0,
    colorTheme: 1,
    lastLoginDate: '',
    roles: [''],
    role: '',
}

const initialState: UserInfoSliceState = {
    userDetails: userDetailsInit,
    status: Status.LOADING,
    theme: Theme.DARK,
    locale: Locale.EN,
    activeUserRole: '',
}

const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {
        setUserDetails(state, action: PayloadAction<UserInfo>) {
            state.userDetails = action.payload
        },
        setUserTheme(state, action) {
            state.theme = action.payload
        },
        setUserLocale(state, action) {
            state.locale = action.payload
        },
        setAtiveUserRole(state, action) {
            state.activeUserRole = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDetails.pending, (state, action) => {
            state.status = Status.LOADING
            state.userDetails = userDetailsInit
        })

        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.userDetails = action.payload
            state.status = Status.SUCCESS
            // state.theme =
            //     action.payload.colorTheme === 1 ? Theme.DARK : Theme.LIGHT
            state.theme = Theme.DARK
            state.locale = action.payload.language === 1 ? Locale.FR : Locale.EN
            state.activeUserRole = action.payload.role
                ? action.payload.role
                : action.payload.roles[0]
        })

        builder.addCase(getUserDetails.rejected, (state, action) => {
            state.status = Status.ERROR
            state.userDetails = userDetailsInit
        })

        builder.addCase(setUpdatePreferences.fulfilled, (state, action) => {
            state.theme = action.meta.arg.theme === 1 ? Theme.DARK : Theme.LIGHT
            state.locale =
                action.meta.arg.language === 1 ? Locale.FR : Locale.EN
            state.activeUserRole = action.meta.arg.role
        })

        // setUpdatePreferences
    },
})

export const { setUserDetails, setUserLocale, setUserTheme, setAtiveUserRole } =
    userDetailsSlice.actions

export default userDetailsSlice.reducer
