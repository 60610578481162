import {
    ScrollArea,
    ScrollAreaProps,
    ScrollAreaAutosizeProps,
} from '@mantine/core'
import React from 'react'

import styles from './CustomScrollArea.module.scss'

interface CustomClassNames {
    root?: string
    viewport?: string
    scrollbar?: string
    thumb?: string
    corner?: string
}

interface CustomScrollAreaProps extends ScrollAreaProps {
    customClassNames?: CustomClassNames
}

interface CustomScrollAreaAutosizeProps extends ScrollAreaAutosizeProps {
    customClassNames?: CustomClassNames
}

const CustomScrollArea: React.FC<CustomScrollAreaProps> & {
    Autosize: React.FC<ScrollAreaAutosizeProps>
} = ({ children, customClassNames, ...props }) => {
    return (
        <ScrollArea
            {...props}
            classNames={{
                root: customClassNames?.root || styles.scrollarea__root,
                viewport:
                    customClassNames?.viewport || styles.scrollarea__viewport,
                scrollbar:
                    customClassNames?.scrollbar || styles.scrollarea__scrollbar,
                thumb: customClassNames?.thumb || styles.scrollarea__thumb,
                corner: customClassNames?.corner || styles.scrollarea__corner,
            }}
        >
            {children}
        </ScrollArea>
    )
}

const CustomScrollAreaAutosize: React.FC<CustomScrollAreaAutosizeProps> = ({
    children,
    customClassNames,
    ...props
}) => {
    return (
        <ScrollArea.Autosize
            {...props}
            classNames={{
                root: customClassNames?.root || styles.scrollarea__root,
                viewport:
                    customClassNames?.viewport || styles.scrollarea__viewport,
                scrollbar:
                    customClassNames?.scrollbar || styles.scrollarea__scrollbar,
                thumb: customClassNames?.thumb || styles.scrollarea__thumb,
                corner: customClassNames?.corner || styles.scrollarea__corner,
            }}
        >
            {children}
        </ScrollArea.Autosize>
    )
}

CustomScrollArea.Autosize = CustomScrollAreaAutosize

export default CustomScrollArea
