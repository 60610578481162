import React from 'react'

import styles from './SandIndicator.module.scss'

type SandIndicatorProps = {
    delay: string
}

const SandIndicator: React.FC<SandIndicatorProps> = ({ delay }) => {
    return (
        <div className={styles.container}>
            <svg
                width="14"
                height="14"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.74297 10.0109C9.53812 8.20876 8.7157 7.4618 8.115 6.91711C7.69969 6.53907 7.5 6.34243 7.5 6.00001C7.5 5.66227 7.69898 5.46938 8.11312 5.09907C8.72086 4.55602 9.55312 3.81211 9.74344 1.98563C9.75865 1.83218 9.74148 1.67725 9.69303 1.53085C9.64458 1.38446 9.56594 1.24987 9.46219 1.13579C9.35139 1.01394 9.21629 0.916655 9.06559 0.850209C8.9149 0.783764 8.75196 0.749629 8.58726 0.750006H3.41273C3.24781 0.749491 3.08462 0.783556 2.93368 0.850004C2.78273 0.916453 2.6474 1.01381 2.5364 1.13579C2.43296 1.25002 2.35462 1.38467 2.30642 1.53105C2.25822 1.67743 2.24123 1.83229 2.25656 1.98563C2.44617 3.80626 3.27539 4.54477 3.88078 5.08383C4.2989 5.45626 4.5 5.65055 4.5 6.00001C4.5 6.35391 4.29844 6.55219 3.8789 6.93165C3.28125 7.47305 2.46094 8.21438 2.25703 10.0109C2.24047 10.1636 2.25634 10.3181 2.30359 10.4643C2.35085 10.6105 2.42842 10.7451 2.53125 10.8593C2.64248 10.9827 2.77846 11.0813 2.93035 11.1486C3.08223 11.2159 3.2466 11.2505 3.41273 11.25H8.58726C8.7534 11.2505 8.91777 11.2159 9.06965 11.1486C9.22153 11.0813 9.35752 10.9827 9.46875 10.8593C9.57158 10.7451 9.64915 10.6105 9.6964 10.4643C9.74366 10.3181 9.75952 10.1636 9.74297 10.0109ZM8.04609 10.125H3.96398C3.59836 10.125 3.49523 9.70313 3.75164 9.44157C4.37226 8.8125 5.625 8.36204 5.625 7.64063V5.25001C5.625 4.78477 4.73437 4.42969 4.18336 3.67501C4.09242 3.55055 4.10156 3.37501 4.33265 3.37501H7.67789C7.875 3.37501 7.91742 3.54915 7.82789 3.67383C7.28484 4.42969 6.375 4.78243 6.375 5.25001V7.64063C6.375 8.35618 7.6807 8.74219 8.25937 9.44227C8.49258 9.72446 8.41101 10.125 8.04609 10.125Z"
                    fill="#B0D3E7"
                />
            </svg>
            <div className={styles.container__text}>{delay}</div>
        </div>
    )
}

export default SandIndicator
