import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AcceptTermsType } from './type'

const initialState = {
    isAcceptedTerms: false,
}

const termsAndOtherSlice = createSlice({
    name: 'termsAndOther',
    initialState,
    reducers: {
        setAcceptTerms(state, action) {
            state.isAcceptedTerms = action.payload
        },
    }
})

export const {
    setAcceptTerms,
} = termsAndOtherSlice.actions

export default termsAndOtherSlice.reducer
