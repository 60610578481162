import React from 'react'
import { IconTypeProps } from '../IconType'

const DispositionPlanning: React.FC<IconTypeProps> = ({ fillColor }) => {
    const defaultColor1 = fillColor === 'default' ? '#86B4D9' : fillColor
    const defaultColor2 = fillColor === 'default' ? '#7EC6DF' : fillColor
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_11989_12517)">
                <path
                    d="M3.83009 9.95629C3.83009 9.64991 3.83009 9.49672 3.85543 9.36933C3.95948 8.8462 4.36842 8.43726 4.89156 8.3332C5.01895 8.30786 5.17214 8.30786 5.47852 8.30786H8.77539C9.08177 8.30786 9.23497 8.30786 9.36235 8.28252C9.88549 8.17846 10.2944 7.76952 10.3985 7.24639C10.4238 7.119 10.4238 6.96581 10.4238 6.65942"
                    stroke="url(#paint0_linear_11989_12517)"
                    stroke-width="1.0754"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.82911 13.3557V14.0151C3.82911 15.2584 3.82911 15.8801 4.21536 16.2663C4.60161 16.6526 5.22327 16.6526 6.4666 16.6526H7.12598"
                    stroke="url(#paint1_linear_11989_12517)"
                    stroke-width="1.0754"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M6.46729 11.275C6.46729 10.184 6.23958 9.9563 5.14854 9.9563H2.51104C1.41999 9.9563 1.19229 10.184 1.19229 11.275C1.19229 12.3661 1.41999 12.5938 2.51104 12.5938H5.14854C6.23958 12.5938 6.46729 12.3661 6.46729 11.275Z"
                    stroke="url(#paint2_linear_11989_12517)"
                    stroke-width="1.0754"
                />
                <path
                    d="M13.061 4.54751C13.061 3.45646 12.8333 3.22876 11.7423 3.22876H9.10479C8.01375 3.22876 7.78604 3.45646 7.78604 4.54751C7.78604 5.63855 8.01375 5.86626 9.10479 5.86626H11.7423C12.8333 5.86626 13.061 5.63855 13.061 4.54751Z"
                    stroke="url(#paint3_linear_11989_12517)"
                    stroke-width="1.0754"
                />
                <path
                    d="M12.4014 16.652C12.4014 15.561 12.1737 15.3333 11.0826 15.3333H8.44512C7.35408 15.3333 7.12637 15.561 7.12637 16.652C7.12637 17.743 7.35408 17.9707 8.44512 17.9707H11.0826C12.1737 17.9707 12.4014 17.743 12.4014 16.652Z"
                    stroke="url(#paint4_linear_11989_12517)"
                    stroke-width="1.0754"
                />
                <path
                    d="M15.0787 7.87858V9.23395H11.6902C11.5105 9.23395 11.3381 9.30535 11.211 9.43244C11.084 9.55954 11.0126 9.73191 11.0126 9.91164C11.0126 10.0914 11.084 10.2637 11.211 10.3908C11.3381 10.5179 11.5105 10.5893 11.6902 10.5893H15.7564C15.9361 10.5893 16.1085 10.5179 16.2356 10.3908C16.3626 10.2637 16.434 10.0914 16.434 9.91164V9.28139L18.7653 11.1315L16.434 13.1645V12.6224C16.434 12.4427 16.3626 12.2703 16.2356 12.1432C16.1085 12.0161 15.9361 11.9447 15.7564 11.9447H9.65718C9.47745 11.9447 9.30507 12.0161 9.17798 12.1432C9.05089 12.2703 8.97949 12.4427 8.97949 12.6224C8.97949 12.8021 9.05089 12.9745 9.17798 13.1016C9.30507 13.2287 9.47745 13.3001 9.65718 13.3001H15.0787V14.6554C15.0788 14.7857 15.1165 14.9131 15.1872 15.0225C15.2579 15.1319 15.3586 15.2185 15.4773 15.2722C15.596 15.3258 15.7276 15.3441 15.8564 15.3248C15.9853 15.3056 16.1058 15.2497 16.2036 15.1637L20.2698 11.6194C20.342 11.5559 20.4 11.4777 20.4397 11.3901C20.4794 11.3025 20.5 11.2074 20.5002 11.1111C20.4977 11.0117 20.4733 10.9139 20.4288 10.8249C20.3843 10.7359 20.3208 10.6578 20.2426 10.5961L16.1765 7.36354C16.0778 7.28543 15.9592 7.23638 15.8341 7.2219C15.709 7.20742 15.5823 7.22808 15.4683 7.28157C15.3543 7.33505 15.2575 7.41924 15.1887 7.52469C15.1198 7.63015 15.0817 7.75269 15.0787 7.87858Z"
                    fill="url(#paint5_linear_11989_12517)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_11989_12517"
                    x1="10.4238"
                    y1="8.32025"
                    x2="4.81616"
                    y2="10.6718"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11989_12517"
                    x1="7.12598"
                    y1="15.0165"
                    x2="3.96794"
                    y2="15.6787"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11989_12517"
                    x1="6.46729"
                    y1="11.285"
                    x2="1.98115"
                    y2="13.1662"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_11989_12517"
                    x1="13.061"
                    y1="4.55742"
                    x2="8.5749"
                    y2="6.43863"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_11989_12517"
                    x1="12.4014"
                    y1="16.6619"
                    x2="7.91523"
                    y2="18.5431"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_11989_12517"
                    x1="8.97949"
                    y1="11.3053"
                    x2="19.5625"
                    y2="14.4555"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color={defaultColor1} />
                    <stop offset="1" stop-color={defaultColor2} />
                </linearGradient>
                <clipPath id="clip0_11989_12517">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="matrix(-1 0 0 1 20.5 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DispositionPlanning
