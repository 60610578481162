import React from 'react'

import styles from './CurrentList.module.scss'
import CustomAccordion from 'components/common/CustomAccordion/CustomAccordion'
import CcTitle from 'components/common/CcTitle/CcTitle'
import BgWrapper from 'components/common/BgWrapper/BgWrapper'
import { icons } from 'utils/iconsMap'
import { PatientInfo } from 'redux/rtk/orStatus/types'
import CurrentListItem from './CurrentListItem/CurrentListItem'
import CenteredWrapper from 'components/common/CenteredWrapper/CenteredWrapper'
import NoMoreEntries from 'components/common/Messages/NoMoreEntries'

type CurrentListProps = {
    title: string
    data: PatientInfo[]
}

const CurrentList: React.FC<CurrentListProps> = ({ data, title }) => {
    const renderItems = (data: PatientInfo[]) => (
        <CustomAccordion.Item key="ed" value="ed">
            <CustomAccordion.Control>
                <CcTitle>
                    {title} ({data?.length || 0})
                </CcTitle>
            </CustomAccordion.Control>
            <CustomAccordion.Panel>
                <BgWrapper>
                    <div className={styles.container}>
                        {data?.length > 0 ? (
                            data?.map((item, index: number) => (
                                <CurrentListItem {...item} />
                            ))
                        ) : (
                            <CenteredWrapper>
                                <NoMoreEntries text={'No patients'} />
                            </CenteredWrapper>
                        )}
                    </div>
                </BgWrapper>
            </CustomAccordion.Panel>
        </CustomAccordion.Item>
    )

    return (
        <CustomAccordion
            chevronPosition="left"
            chevron={<img src={`${icons.chevron}`} alt={`sort arrow `} />}
        >
            {data && renderItems(data)}
        </CustomAccordion>
    )
}

export default CurrentList
