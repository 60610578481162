import {
    CriticalCareResponce,
    OperaAdmissionsResponce,
    OperaRoomsResponce,
    OperaTransfersResponce,
} from 'redux/rtk/corporateView/types'

export const CRITICAL_CARE_MOCK: CriticalCareResponce = {
    pendingAdmissions: {
        PACU: 0,
        MSICU: 8,
        CVICU: 0,
    },
    pendingTransfers: {
        PACU: 1,
        MSICU: 0,
        CVICU: 1,
    },
}

export const OPERA_ROOMS_MOCK: OperaRoomsResponce = {
    running: 0,
    runningLate: 0,
}

export const OPERA_ADMISSIONS_MOCK: OperaAdmissionsResponce = {
    pendingAdmissions: {
        ER: 10,
        SDS: 8,
        IP: 12,
        ODS: null,
    },
}

export const OPERA_TRANSFER_MOCK: OperaTransfersResponce = {
    pendingTransfers: {
        MSICU: 10,
        CVICU: 8,
        PACU: 12,
        periop: null,
    },
}
