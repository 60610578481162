import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { NavigationItem } from '../../MenuOptions'

import styles from './NavigationPages.module.scss'

type NavigationPagesProps = {
    menuItems: NavigationItem[]
    reset: (e: string) => void
}

const NavigationPages: React.FC<NavigationPagesProps> = ({
    menuItems,
    reset,
}) => {
    const { t } = useTranslation()
    return (
        <>
            {menuItems?.map((item, index) => {
                return (
                    <>
                        <div
                            className={styles.item}
                            key={item.link.replace('/', '')}
                        >
                            <NavLink
                                to={item.link}
                                className={({
                                    isActive,
                                }: {
                                    isActive: boolean
                                }) => (isActive ? styles.active : '')}
                                onClick={() => reset(item.title)}
                            >
                                <span>{t(`${item.title}`)}</span>
                            </NavLink>
                        </div>
                        {menuItems.length - 1 === index ? null : (
                            <div className={styles.divider}></div>
                        )}
                    </>
                )
            })}
        </>
    )
}

export default NavigationPages
