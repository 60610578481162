import { Button } from '@mantine/core'
import React from 'react'

import styles from './OriginFiltersFooter.module.scss'
import { useTranslation } from 'react-i18next'

type OriginFiltersFooterProps = {
    submit: (e: React.MouseEvent<HTMLButtonElement>) => void
    reset: (e: React.MouseEvent<HTMLButtonElement>) => void
    cansel: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const OriginFiltersFooter: React.FC<OriginFiltersFooterProps> = ({
    submit,
    reset,
    cansel,
}) => {
    const { t } = useTranslation()
    return (
        <div className={styles.footer}>
            <Button
                variant="subtle"
                classNames={{
                    root: `${styles.footer__button__root}`,
                    label: `${styles.footer__button__label_gradient}`,
                }}
                onClick={cansel}
            >
                {t('Cansel')}
            </Button>
            <Button
                variant="subtle"
                classNames={{
                    root: `${styles.footer__button__root}`,
                    label: `${styles.footer__button__label_gradient}`,
                }}
                onClick={reset}
            >
                {t('Reset Filters')}
            </Button>
            <Button
                variant="subtle"
                classNames={{
                    root: `${styles.footer__button__root}`,
                    label: `${styles.footer__button__label}`,
                }}
                onClick={submit}
            >
                {t('Ok')}
            </Button>
        </div>
    )
}

export default OriginFiltersFooter
