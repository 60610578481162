import { NumberInput } from '@mantine/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectActiveEventBoxData } from '../../../redux/rtk/operatingRoom/selectors'
import { formatDateToHour } from '../../../utils/date/formatDateToHour'
import { getTimestampForHourAndMinute } from '../../../utils/date/getTimestampForHourAndMinutes'
import WidgetTitle from '../../common/WidgetTitle/WidgetTitle'
import TitleVsTextBox from '../TitleVsTextBox/TitleVsTextBox'

import styles from './BoxStatusDetails.module.scss'
import TimeDelay from './TimeDelay/TimeDelay'

const MOCK__DATA = {
    id: 1,
    patientName: 'Eugene Levy',
    sergeonName: 'Dimitri Raskin',
    procedure: '0FSC4ZZ RAV',
    startTime: getTimestampForHourAndMinute(11, 0),
    delay: 0,
}

const BoxStatusDetails = () => {
    const activeEventBoxData = useSelector(selectActiveEventBoxData)
    const [updatedTime, setUpdatedTime] = useState<any>(
        //@ts-ignore
        activeEventBoxData.start
    )
    const [delayCounter, setDelayCounter] = useState(0)
    const handleDelayChange = (minutes: number) => {
        setDelayCounter(minutes)
        // const time = new Date(MOCK__DATA.startTime)
        // // const milliseconds = minutes * 60 * 1000
        //@ts-ignore
        const newTimestamp = new Date(activeEventBoxData.start)
        newTimestamp.setMinutes(newTimestamp.getMinutes() + minutes)
        setUpdatedTime(newTimestamp.toISOString())
    }

    return (
        <div className={styles.box_details}>
            <TitleVsTextBox
                header={'patient Name'}
                children={
                    <WidgetTitle isLight={false}>
                        {activeEventBoxData.patientName}
                    </WidgetTitle>
                }
            />
            <TitleVsTextBox
                header={'lead surgeon'}
                text={
                    //@ts-ignore
                    activeEventBoxData.surgeonName
                }
            />
            <TitleVsTextBox
                header={'procedure'}
                text={activeEventBoxData.procedure}
            />
            <TitleVsTextBox
                header={'Scheduled start time'}
                text={formatDateToHour(
                    //@ts-ignore
                    activeEventBoxData.start
                )}
            />
            <TimeDelay value={delayCounter} cb={handleDelayChange} />
            <TitleVsTextBox
                header={'Updated start time'}
                children={
                    <WidgetTitle isLight={false} style={{ color: '#7ABD83' }}>
                        {formatDateToHour(updatedTime)}
                    </WidgetTitle>
                }
            />
        </div>
    )
}

export default BoxStatusDetails
