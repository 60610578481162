
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getNurses, getShifts, setShiftsData } from './asyncAction';
import { Status, NursesData, ShiftsData } from './types'


const initialState = {
    shifts: [{}],
    nurses: [{}],
    shiftsStatus: Status.LOADING,
    nursesStatus: Status.LOADING,
    setShiftsDataStatus: Status.LOADING
}


const nursingSlice = createSlice({
    name: 'nursing',
    initialState,
    reducers: {
        setShiftsData(state, action) {
            // state.shifts.push(action?.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getShifts.pending, (state, action) => {
            state.shiftsStatus = Status.LOADING;
            state.shifts = initialState.shifts;
        });

        builder.addCase(getShifts.fulfilled, (state, action) => {
            state.shiftsStatus = Status.SUCCESS;
            state.shifts = action.payload.shifts
        });

        builder.addCase(getShifts.rejected, (state, action) => {
            state.shiftsStatus = Status.ERROR;
            state.shifts = [];
        });

        builder.addCase(getNurses.pending, (state, action) => {
            state.nursesStatus = Status.LOADING;
            state.nurses = initialState.nurses;
        });

        builder.addCase(getNurses.fulfilled, (state, action) => {
            state.nursesStatus = Status.SUCCESS;
            state.nurses = action.payload.nurses
        });

        builder.addCase(getNurses.rejected, (state, action) => {
            state.nursesStatus = Status.ERROR;
            state.nurses = initialState.nurses;
        });

        builder.addCase(setShiftsData.pending, (state, action) => {
            state.setShiftsDataStatus = Status.LOADING;
        });

        builder.addCase(setShiftsData.fulfilled, (state, action) => {
            state.setShiftsDataStatus = Status.SUCCESS;
            // state.shifts.push(action.meta.arg)
            // state.shifts = action.payload.shifts
        });

        builder.addCase(setShiftsData.rejected, (state, action) => {
            state.setShiftsDataStatus = Status.ERROR;
        });
    },
});

export const { } = nursingSlice.actions;

export default nursingSlice.reducer;