import { icons } from './iconsMap'

export const patientsFieldsAPI = [
    {
        name: 'id',
        title: 'mrn',
        location: 1,
        format: null,
        type: 'integer',
        color: '#A6B0B9',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 80,
    },
    {
        name: 'location',
        title: 'Location/ward',
        location: 2,
        format: ['department', 'room', 'bed', 'facility', 'floor'],
        type: 'string',
        color: '#142644',
        colors_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        icons_map: {
            type: 'dictionary',
            mapper: {
                Tooth: 'tooth',
                Cardiac: 'cardio',
                Urology: 'urology',
                Cardiology: 'cardio',
                Gastroenterology: 'cardio',
            },
        },
        // width: 180,
    },
    {
        name: 'name',
        title: 'name',
        location: 3,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 150,
    },
    {
        name: 'age',
        title: 'age',
        location: 4,
        format: null,
        type: 'integer',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 50,
    },
    {
        name: 'gender',
        title: 'gender',
        location: 5,
        format: null,
        type: 'img',
        color: null,
        colors_map: {
            type: 'dictionary',
            mapper: { male: '#6A95BD', female: '#C29999' },
        },
        icons_map: {
            type: 'dictionary',
            mapper: { male: icons.male, female: icons.female },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 45,
    },
    {
        name: 'primaryDiagnosis',
        title: 'primary diagnosis',
        location: 6,
        format: null,
        type: 'string',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 120,
    },
    {
        name: 'severityIndex',
        title: 'severity index',
        location: 7,
        format: null,
        type: 'integer',
        color: null,
        colors_map: {
            type: 'dictionary',
            mapper: {
                high: '#9D111D',
                medium: '#D4641D',
                light: '#236519',
            },
        },
        icons_map: null,
        bold: true,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 95,
    },
    {
        name: 'labs_consultations',
        title: 'Pending labs',
        location: 8,
        format: null,
        type: 'counter',
        color: null,
        colors_map: {
            type: 'range',
            mapper: {
                light: {
                    color: '#236519',
                    background: 'rgba(241, 248, 236, 0.22)',
                },
                medium: {
                    color: '#D4641D',
                    background: 'rgba(248, 242, 236, 0.22)',
                },
                high: {
                    color: '#9D111D',
                    background: 'rgba(248, 236, 236, 0.22)',
                },
            },
        },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 200,
        // width: 180,
    },
    {
        name: 'pendingConsultations',
        title: 'Consultations ',
        location: 7,
        type: 'counter',
        format: null,
        color: null,
        colors_map: {
            type: 'range',
            mapper: {
                light: {
                    color: '#236519',
                    background: 'rgba(241, 248, 236, 0.22)',
                },
                medium: {
                    color: '#D4641D',
                    background: 'rgba(248, 242, 236, 0.22)',
                },
                high: {
                    color: '#9D111D',
                    background: 'rgba(248, 236, 236, 0.22)',
                },
            },
        },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 200,
        // width: 180,
    },
    {
        name: 'alerts',
        title: 'alerts',
        location: 9,
        format: null,
        type: 'counter',
        color: null,
        colors_map: {
            type: 'dictionary',
            mapper: {
                light: {
                    color: '#236519',
                    background: 'rgba(241, 248, 236, 0.22)',
                },
                medium: {
                    color: '#D4641D',
                    background: 'rgba(248, 242, 236, 0.22)',
                },
                high: {
                    color: '#9D111D',
                    background: 'rgba(248, 236, 236, 0.22)',
                },
            },
        },
        icons_map: {
            type: 'dictionary',
            mapper: {
                high: icons.severity_high,
                medium: icons.severity_medium,
            },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 50,
    },
    {
        name: 'tasks',
        title: 'open tasks',
        location: 10,
        format: null,
        type: 'counter',
        color: null,
        colors_map: {
            type: 'range',
            mapper: {
                light: {
                    color: '#236519',
                    background: 'rgba(241, 248, 236, 0.22)',
                },
                medium: {
                    color: '#D4641D',
                    background: 'rgba(248, 242, 236, 0.22)',
                },
                high: {
                    color: '#9D111D',
                    background: 'rgba(248, 236, 236, 0.22)',
                },
            },
        },
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 105,
        // width: 80,
    },
    {
        name: 'physicianInCharge',
        title: 'physician in charge',
        location: 11,
        format: null,
        type: 'string',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 135,
    },
    {
        name: 'admission',
        title: 'admission',
        location: 12,
        format: null,
        type: 'string',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 135,
    },
    {
        name: 'dischargePrediction',
        title: 'discharge prediction',
        location: 13,
        format: null,
        type: 'string',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 135,
    },
    {
        name: 'hazards',
        title: 'hazard',
        location: 14,
        format: null,
        type: 'img_string',
        color: {
            light: '#142644',
            dark: '#FFFFFF;',
        },
        colors_map: null,
        icons_map: {
            type: 'boolean',
            mapper: {
                light_mapper: { true: 'icons.flag', false: 'false_flag' },
                dark_mapper: {
                    true: 'icons.flag_dark',
                    false: 'dark_false_flag',
                },
            },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 95,
    },
    {
        name: 'ews',
        title: 'EWS',
        location: 15,
        format: null,
        type: 'integer',
        color: null,
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 120,
    },
]

export const patientsTableMockData = [
    {
        id: 123,
        patientId: '123',
        visitId: '124542',
        name: 'John Doe',
        age: 28,
        gender: 0,
        location: { department: 'Tooth', room: 'Tooth', bed: 5 },
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '123',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '123',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '123',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [
            { oxygen: 10, time: '2022-08-17T09:16:44.857651Z' },
            { oxygen: 12, time: '2022-08-17T09:14:44.8576511Z' },
            { oxygen: 10, time: '2022-08-17T09:13:44.8576512Z' },
            { oxygen: 15, time: '2022-08-17T09:12:44.8576512Z' },
            { oxygen: 13, time: '2022-08-17T09:11:44.8576513Z' },
        ],
        temperature: [
            { celsius: 36.5, time: '2022-08-17T09:16:44.857692Z' },
            { celsius: 36.4, time: '2022-08-17T09:14:44.8576921Z' },
            { celsius: 36.6, time: '2022-08-17T09:12:44.8576922Z' },
        ],
        bloodPreasure: [
            { sbp: 3, dbp: 4, time: '2022-08-17T09:14:44.8577326Z' },
            { sbp: 5, dbp: 2, time: '2022-08-17T09:12:44.8577327Z' },
            { sbp: 6, dbp: 3, time: '2022-08-17T09:11:44.8577327Z' },
            { sbp: 7, dbp: 5, time: '2022-08-17T09:10:44.8577327Z' },
        ],
        heartRate: [
            { bpm: 34, time: '2022-08-17T09:14:44.857773Z' },
            { bpm: 36, time: '2022-08-17T09:12:44.8577731Z' },
            { bpm: 37, time: '2022-08-17T09:11:44.8577731Z' },
            { bpm: 31, time: '2022-08-17T09:10:44.8577731Z' },
        ],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 685671,
        patientId: '685671',
        visitId: '12435232',
        location: { department: 'Tooth', room: 'Tooth', bed: 5 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 12374,
        patientId: '12374',
        visitId: '1244765842',
        location: { department: 'Tooth', room: 'Tooth', bed: 15 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 68571,
        patientId: '68571',
        visitId: '1232421542',
        location: { department: 'Tooth', room: 'Tooth', bed: 5 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Asthma', isMain: false },
            { name: 'Covid19', isMain: true },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 168571,
        patientId: '168571',
        visitId: '124543241212',
        location: { department: 'Urology', room: 'Urology', bed: 5 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [{ name: 'Covid19', isMain: true }],
        severityIndex: 3,
        alerts: 3,
        tasks: [{ name: 'Task_1', icon: 'string', status: 1, id: 1 }],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [],
    },
    //////////////////////////////////////////////////////
    {
        id: 43546,
        patientId: '43546',
        visitId: '534252',
        location: { department: 'Urology', room: 'Urology', bed: 18 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [
            { name: 'Task_1', icon: 'string', status: 1, id: 1 },
            { name: 'Task_2', icon: 'string', status: 1, id: 2 },
            { name: 'Task_5', icon: 'string', status: 1, id: 3 },
        ],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [{ name: 'Alergy' }, { name: 'Codid19' }],
    },
    {
        id: 2335,
        patientId: '2335',
        visitId: '1247692',
        location: { department: 'Urology', room: 'Urology', bed: 8 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [
            { name: 'Task_1', icon: 'string', status: 1, id: 1 },
            { name: 'Task_2', icon: 'string', status: 1, id: 2 },
            { name: 'Task_3', icon: 'string', status: 1, id: 3 },
            { name: 'Task_4', icon: 'string', status: 1, id: 4 },
            { name: 'Task_5', icon: 'string', status: 1, id: 5 },
        ],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '1d',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 93543,
        patientId: '93543',
        visitId: '12276976542',
        location: { department: 'Dermatology', room: 'Dermatology', bed: 18 },
        name: 'John Doe',
        age: 28,
        gender: 0,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [
            { name: 'Task_1', icon: 'string', status: 1, id: 1 },
            { name: 'Task_2', icon: 'string', status: 1, id: 2 },
            { name: 'Task_3', icon: 'string', status: 1, id: 3 },
            { name: 'Task_4', icon: 'string', status: 1, id: 4 },
            { name: 'Task_5', icon: 'string', status: 1, id: 5 },
        ],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '15d',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 35454,
        patientId: '35454',
        visitId: '126579642',
        location: { department: 'Dermatology', room: 'Dermatology', bed: 1 },
        name: 'John Doe',
        age: 28,
        gender: 1,
        arrivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [
            { name: 'Task_1', icon: 'string', status: 1, id: 1 },
            { name: 'Task_2', icon: 'string', status: 1, id: 2 },
            { name: 'Task_3', icon: 'string', status: 1, id: 3 },
            { name: 'Task_4', icon: 'string', status: 1, id: 4 },
        ],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '2d',
        hazards: [{ name: 'Alergy' }],
    },
    {
        id: 937454,
        patientId: '937454',
        visitId: '134764542',
        location: { department: 'Dermatology', room: 'Dermatology', bed: 3 },
        name: 'John Doe',
        gender: 0,
        rivedFrom: 'Home',
        doctor: 'Gregory House',
        contact: {
            id: '685671',
            name: 'Sam Fisher',
            relationship: 'son',
            phoneNumber: '+380999999999',
        },
        medicalHistory: '',
        painLevel: 2,
        ews: 4,
        adl: 1,
        lenghtOfStay: 7,
        postOperative: 1,
        covidStatus: 0,
        responsible: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        careGiver: {
            id: '685671',
            name: 'Sam Fisher',
            phoneNumber: '+380999999999',
        },
        saturation: [{ oxygen: 2, time: '' }],
        temperature: [{ celsius: 36.9, time: '' }],
        bloodPreasure: [{ sbp: 120, dbp: 80, time: '' }],
        heartRate: [{ bpm: 5, time: '' }],
        diagnoses: [
            { name: 'Covid19', isMain: true },
            { name: 'Asthma', isMain: false },
        ],
        severityIndex: 3,
        alerts: 3,
        tasks: [
            { name: 'Task_1', icon: 'string', status: 1, id: 1 },
            { name: 'Task_2', icon: 'string', status: 1, id: 2 },
            { name: 'Task_3', icon: 'string', status: 1, id: 3 },
            { name: 'Task_5', icon: 'string', status: 1, id: 4 },
        ],
        physicianInCharge: 'Jane Doe',
        dischargePrediction: '24h',
        hazards: [],
    },
]

export const modalPatientsData = {
    modalLabs: [
        {
            id: 1,
            icon: icons.flask_blue,
            title: 'Labs',
            link: 'http://example.com',
        },
        {
            id: 2,
            icon: icons.camera_blue,
            title: 'Imaging',
            link: 'http://example.com',
        },
        {
            id: 3,
            icon: icons.doctor_blue,
            title: 'Consultations',
            link: 'http://example.com',
        },
        {
            id: 4,
            icon: icons.peal,
            title: 'Medications',
            link: 'http://example.com',
        },
    ],
}

export const modalMassage = {
    label: 'Popup Headline Goes Here',
    message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris egestas dignissim dolor sed egestas. Donec enim
                    lectus, mollis quis fermentum ut, euismod et lectus. Cras
                    consequat, lectus in dapibus tincidunt, massa eros mattis
                    justo, vitae efficitur nisi diam sit amet dolor. Aenean
                    suscipit purus velit, non mattis libero fringilla nec.
                    Quisque nec consectetur metus. Duis cursus non elit
                    suscipit.`,
}

export const actionCards = [
    {
        id: 1,
        isOpen: true,
        title: 'Find rehab beds',
        from: 'Susan Perkins',
        to: 'Cheryl Curtis, Austin Jordan',
        medicalDatas: [{ id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' }],
        date: new Date(),
    },
    {
        id: 2,
        isOpen: true,
        title: 'Find rehab beds',
        from: 'Susan Perkins',
        to: 'Cheryl Curtis, Austin Jordan',
        medicalDatas: [{ id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' }],
        date: new Date(),
    },
    {
        id: 3,
        isOpen: true,
        title: 'Find rehab beds',
        from: 'Susan Perkins',
        to: 'Cheryl Curtis, Austin Jordan',
        medicalDatas: [
            { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
            { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
        ],
        date: new Date(),
    },
    {
        id: 4,
        isOpen: true,
        title: 'Find rehab beds',
        from: 'Susan Perkins',
        to: 'Cheryl Curtis, Austin Jordan',
        medicalDatas: [
            { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
            { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
            { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
        ],
        date: new Date(),
    },
]

export const huddleNotes = [
    {
        id: 1,
        title: 'Bed flow daily check in',
        isPublish: false,
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
            },
            {
                id: 3,
                label: 'PM 56 m K1IC',
                text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
            },
        ],
        date: new Date(),
    },
    {
        id: 2,
        title: 'Bed flow daily check in',
        isPublish: true,
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
            },
        ],
        date: new Date(),
    },
    {
        id: 3,
        title: 'Bed flow daily check in',
        isPublish: true,
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
            },
            {
                id: 3,
                label: 'PM 56 m K1IC',
                text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
            },
            {
                id: 4,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
        date: new Date(),
    },
]

export const huddlePatientsTable = [
    {
        id: 1,
        ward: 'K2CU',
        location: 'R305 - 3',
        initials: 'GP',
        age: 59,
        gender: 1,
        chief_complaint: 'Fever',
        los: '3d',
        bp: { value: '121/67 (79)', alert: 1 },
        o2: 100,
        cvd_19: false,
        alerts: true,
        physician: 'Wade Baker',
        nurse: 'Randy Nichols',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
            { id: 3, label: 'Covid-19' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checked' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 4,
                title: 'Bed flow daily check in',
                huddleStatus: 'Archived',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 5,
                title: 'Bed flow daily check in',
                huddleStatus: 'Deleted',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 2,
        ward: 'K1IC',
        location: 'R202 - 4',
        initials: 'OI',
        age: 65,
        gender: 0,
        chief_complaint: 'Fever',
        los: '5d',
        bp: { value: '100/61 (73)', alert: 0 },
        o2: 98,
        cvd_19: true,
        alerts: false,
        physician: 'Shelley Robinson',
        nurse: 'Monica Cunningham',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 3,
        ward: 'K2CU',
        location: 'R202 - 5',
        initials: 'JM',
        age: 62,
        gender: 0,
        chief_complaint: 'Abdominal pain',
        los: '5h',
        bp: { value: '140/69 (94)', alert: 2 },
        o2: 97,
        cvd_19: true,
        alerts: false,
        physician: 'Lauren Torres',
        nurse: 'Rene Brooks',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 4,
        ward: 'K1IC',
        location: 'R441 - 2',
        initials: 'EF',
        age: 75,
        gender: 1,
        chief_complaint: 'Headache',
        los: '3d',
        bp: { value: '128/51 (73)', alert: 1 },
        o2: 92,
        cvd_19: false,
        alerts: true,
        physician: 'Tracey Sullivan',
        nurse: 'Miranda Reed',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 5,
        ward: 'K2CU',
        location: 'R305 - 3',
        initials: 'GP',
        age: 59,
        gender: 1,
        chief_complaint: 'Fever',
        los: '3d',
        bp: { value: '121/67 (79)', alert: 1 },
        o2: 100,
        cvd_19: false,
        alerts: true,
        physician: 'Wade Baker',
        nurse: 'Randy Nichols',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 6,
        ward: 'K1IC',
        location: 'R202 - 4',
        initials: 'IO',
        age: 65,
        gender: 0,
        chief_complaint: 'Fever',
        los: '5d',
        bp: { value: '100/61 (73)', alert: 0 },
        o2: 98,
        cvd_19: true,
        alerts: false,
        physician: 'Shelley Robinson',
        nurse: 'Monica Cunningham',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 7,
        ward: 'K2CU',
        location: 'R202 - 5',
        initials: 'JM',
        age: 62,
        gender: 0,
        chief_complaint: 'Abdominal pain',
        los: '5h',
        bp: { value: '140/69 (94)', alert: 2 },
        o2: 97,
        cvd_19: true,
        alerts: false,
        physician: 'Lauren Torres',
        nurse: 'Rene Brooks',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 8,
        ward: 'K1IC',
        location: 'R441 - 2',
        initials: 'EF',
        age: 75,
        gender: 1,
        chief_complaint: 'Headache',
        los: '3d',
        bp: { value: '128/51 (73)', alert: 1 },
        o2: 92,
        cvd_19: false,
        alerts: true,
        physician: 'Tracey Sullivan',
        nurse: 'Miranda Reed',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 9,
        ward: 'K1IC',
        location: 'R202 - 4',
        initials: 'OI',
        age: 65,
        gender: 0,
        chief_complaint: 'Fever',
        los: '5d',
        bp: { value: '100/61 (73)', alert: 0 },
        o2: 98,
        cvd_19: true,
        alerts: false,
        physician: 'Shelley Robinson',
        nurse: 'Monica Cunningham',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 10,
        ward: 'K2CU',
        location: 'R202 - 5',
        initials: 'JM',
        age: 62,
        gender: 0,
        chief_complaint: 'Abdominal pain',
        los: '5h',
        bp: { value: '140/69 (94)', alert: 2 },
        o2: 97,
        cvd_19: true,
        alerts: false,
        physician: 'Lauren Torres',
        nurse: 'Rene Brooks',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 11,
        ward: 'K1IC',
        location: 'R441 - 2',
        initials: 'EF',
        age: 75,
        gender: 1,
        chief_complaint: 'Headache',
        los: '3d',
        bp: { value: '128/51 (73)', alert: 1 },
        o2: 92,
        cvd_19: false,
        alerts: true,
        physician: 'Tracey Sullivan',
        nurse: 'Miranda Reed',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 12,
        ward: 'K2CU',
        location: 'R305 - 3',
        initials: 'GP',
        age: 59,
        gender: 1,
        chief_complaint: 'Fever',
        los: '3d',
        bp: { value: '121/67 (79)', alert: 1 },
        o2: 100,
        cvd_19: false,
        alerts: true,
        physician: 'Wade Baker',
        nurse: 'Randy Nichols',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Deleted',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 13,
        ward: 'K1IC',
        location: 'R202 - 4',
        initials: 'IO',
        age: 65,
        gender: 0,
        chief_complaint: 'Fever',
        los: '5d',
        bp: { value: '100/61 (73)', alert: 0 },
        o2: 98,
        cvd_19: true,
        alerts: false,
        physician: 'Shelley Robinson',
        nurse: 'Monica Cunningham',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 14,
        ward: 'K2CU',
        location: 'R202 - 5',
        initials: 'JM',
        age: 62,
        gender: 0,
        chief_complaint: 'Abdominal pain',
        los: '5h',
        bp: { value: '140/69 (94)', alert: 2 },
        o2: 97,
        cvd_19: true,
        alerts: false,
        physician: 'Lauren Torres',
        nurse: 'Rene Brooks',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
    {
        id: 15,
        ward: 'K1IC',
        location: 'R441 - 2',
        initials: 'EF',
        age: 75,
        gender: 1,
        chief_complaint: 'Headache',
        los: '3d',
        bp: { value: '128/51 (73)', alert: 1 },
        o2: 92,
        cvd_19: false,
        alerts: true,
        physician: 'Tracey Sullivan',
        nurse: 'Miranda Reed',
        background: [
            { id: 1, label: 'Hypertension' },
            { id: 2, label: 'Diabetes' },
        ],
        medicalHistory: [
            {
                id: 1,
                title: 'Medical History',
                value: `59 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on 
        altace for 8 years awoke from sleep around 2:30 am this morning of a 
        sore throat and swelling of tongue. She came immediately to the ED b/c 
        she was having difficulty swallowing and some trouble breathing due to 
        obstruction caused by the swelling.`,
            },
            {
                id: 2,
                title: 'Family and supportive surroundings',
                value: `She has never had a similar reaction ever before and she did not
         have any associated SOB, chest pain, itching, or nausea. She has not 
         noticed any rashes, and has been afebrile. She says that she feels like
          it is swollen down in her esophagus as well. In the ED she was given 
          25mg benadryl IV, 125 mg solumedrol IV and pepcid 20 mg IV. She has 
          never had a similar reaction ever before and she did not have any 
          associated SOB, chest pain, itching, or nausea. She has not noticed 
          any rashes, and`,
            },
        ],
        commentsCheckboxes: {
            row: [
                { id: 1, title: 'Discharge', icon: 'checkbox' },
                { id: 2, title: 'Home', icon: 'radio' },
                { id: 3, title: 'Rehabilitation', icon: 'radio' },
            ],
            column: [
                {
                    id: 1,
                    title: 'Social assistance reqiured',
                    icon: 'checked',
                },
                { id: 2, title: 'Checkbox 3', icon: 'checkbox' },
                { id: 3, title: 'Checkbox 4', icon: 'checkbox' },
            ],
        },
        huddleNotes: [
            {
                id: 1,
                title: 'Bed flow daily check in',
                huddleStatus: 'Draft',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 2,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                ],
                date: new Date(),
            },
            {
                id: 3,
                title: 'Bed flow daily check in',
                huddleStatus: 'Published',
                items: [
                    {
                        id: 1,
                        label: 'JM 65 f K1IC',
                        text: `72 y o woman in NAD with a h/o CAD, DM2,
                                    asthma and HTN on altace for 8 years #awoke
                                    from..`,
                    },
                    {
                        id: 2,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever
                                   before and she did not have any associated
                                    SOB, #chest pain, itching, or nausea. She
                                    has not noticed any rashes.`,
                    },
                    {
                        id: 3,
                        label: 'PM 56 m K1IC',
                        text: `She has not noticed any rashes, and has been
                                    afebrile. She says that she feels like it is
                                    #swollen down.`,
                    },
                    {
                        id: 4,
                        label: 'DR 64 f K2CU',
                        text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
                    },
                ],
                date: new Date(),
            },
        ],
        actionCards: [
            {
                id: 1,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 2,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 3,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
            {
                id: 4,
                isOpen: true,
                title: 'Find rehab beds',
                from: 'Susan Perkins',
                to: 'Cheryl Curtis, Austin Jordan',
                medicalDatas: [
                    { id: 1, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 2, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                    { id: 3, from: 'JM 65 f K1IC', to: 'DR 64 f K2CU' },
                ],
                date: new Date(),
            },
        ],
    },
]

export const huddleActionItemChat = [
    {
        id: 1,
        author: 'Susan Perkins',
        text: 'Fames ac turpis egestas integer eget aliquet nibh. Egestas diam in arcu cursus euismod quis viverra.',
        time: '1 min',
        isAuthor: true,
    },
    {
        id: 2,
        author: 'Austin Jordan',
        text: 'Fames ac turpis egestas integer eget aliquet nibh. Egestas diam in arcu cursus euismod quis viverra.',
        time: '1 min',
        isAuthor: false,
    },
    {
        id: 3,
        author: 'Austin Jordan',
        text: 'Fames ac turpis egestas integer eget aliquet nibh. Egestas diam in arcu cursus euismod quis viverra.',
        time: '1 min',
        isAuthor: false,
    },
]

// export const tableFilters = {
//     new: [
//         {
//             name: 'pending',
//             label: 'Has pending labs/consultations',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'alerts',
//             label: 'Has alerts',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'tasks',
//             label: 'Has open tasks',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'hazards',
//             label: 'Has hazards',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'disch_today',
//             label: 'Discharge today',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'disch_within',
//             label: 'Discharge within 3 days',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'disch_over',
//             label: 'Discharge in >3 days',
//             filterClassName: 'filters_secondary__all',
//             fontColor: '#FFFFFF',
//             group: 'pending',
//         },
//         {
//             name: 'default',
//             label: 'Reset all filters',
//             filterClassName: 'filters_secondary__reset',
//             fontColor: '#3F73A7',
//             group: 'pending',
//         },
//     ],
// }

export const huddlePatientsFields = [
    {
        name: 'Ward',
        title: 'Ward',
        location: 1,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 42,
    },
    {
        name: 'location',
        title: 'Location',
        location: 2,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 62,
    },
    {
        name: 'initials',
        title: 'initials',
        location: 3,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 52,
    },
    {
        name: 'age',
        title: 'age',
        location: 4,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 32,
    },
    {
        name: 'gender',
        title: 'gender',
        location: 5,
        format: null,
        type: 'img',
        color: null,
        colors_map: {
            type: 'dictionary',
            mapper: { male: '#6A95BD', female: '#C29999' },
        },
        icons_map: {
            type: 'dictionary',
            mapper: { male: icons.male, female: icons.female },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 60,
    },
    {
        name: 'chief_complaint',
        title: 'chief complaint',
        location: 6,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 122,
    },
    {
        name: 'los',
        title: 'los',
        location: 7,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 32,
    },
    {
        name: 'bp',
        title: 'bp',
        location: 8,
        format: null,
        type: 'img_string',
        color: {
            light: '#142644',
            dark: '#FFFFFF;',
        },
        colors_map: null,
        icons_map: {
            type: 'dictionary',
            mapper: {
                high: icons.severity_high,
                medium: icons.severity_medium,
            },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 100,
    },
    {
        name: 'o2',
        title: 'o2',
        location: 9,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: {
            type: 'dictionary',
            mapper: {
                high: icons.severity_high,
                medium: icons.severity_medium,
            },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 35,
    },
    {
        name: 'cvd_19',
        title: 'cvd-19',
        location: 10,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: {
            type: 'dictionary',
            mapper: {
                positive: icons.plus_huddle,
                negative: icons.minus,
            },
        },
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 50,
    },
    {
        name: 'alerts',
        title: 'alerts',
        location: 11,
        format: null,
        type: 'img',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 48,
    },
    {
        name: 'physician',
        title: 'physician',
        location: 12,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 115,
    },
    {
        name: 'nurse',
        title: 'nurse',
        location: 13,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        width: 96,
    },
]

export const newLabsResultsFields = [
    {
        name: 'type',
        title: 'Lab type',
        location: 1,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 42,
    },
    // {
    //     name: 'orderTime',
    //     title: 'Order Time',
    //     location: 2,
    //     format: null,
    //     type: 'date',
    //     color: '#142644',
    //     colors_map: null,
    //     icons_map: null,
    //     bold: false,
    //     italic: false,
    //     date_format: null,
    //     time_delta_format: null,
    //     // width: 100,
    // },
    {
        name: 'resultTime',
        title: 'Result Time',
        location: 3,
        format: null,
        type: 'date',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 150,
    },
    {
        name: 'result',
        title: 'Result',
        location: 4,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 200,
    },
    {
        name: 'units',
        title: 'Units',
        location: 5,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 200,
    },
    {
        name: 'range',
        title: 'Out of range',
        location: 6,
        format: null,
        type: 'img_string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
        // width: 200,
    },
    {
        name: 'status',
        title: 'status',
        location: 8,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
    },
    {
        name: 'abnormalFlag',
        title: 'Abnormal Status',
        location: 7,
        format: null,
        type: 'string',
        color: '#142644',
        colors_map: null,
        icons_map: null,
        bold: false,
        italic: false,
        date_format: null,
        time_delta_format: null,
    }
]

export const newLabsResultsMockData = [
    {
        id: '1',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '2',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '3',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '4',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '5',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '6',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '7',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '8',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '9',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '10',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '11',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '12',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '13',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '14',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
    {
        id: '15',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'negative' }],
    },
    {
        id: '16',
        test_name: 'Covid-19',
        order_date: new Date(),
        collected_date: new Date(),
        results: [{ id: '1', title: 'positive' }],
    },
]

export const meetingNotes = [
    {
        id: 1,
        publishPerson: 'Richard Meyer',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 2,
        publishPerson: 'Gerdt Muller',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 3,
        publishPerson: 'Richard Meyer',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 4,
        publishPerson: 'Gerdt Muller',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 5,
        publishPerson: 'Richard Meyer',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 6,
        publishPerson: 'Gerdt Muller',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 7,
        publishPerson: 'Richard Meyer',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 8,
        publishPerson: 'Gerdt Muller',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 9,
        publishPerson: 'Richard Meyer',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 10,
        publishPerson: 'Gerdt Muller',
        items: [
            {
                id: 1,
                label: 'JM 65 f K1IC',
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                label: 'DR 64 f K2CU',
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
]

export const meetingDigest = [
    {
        id: 1,
        label: 'JM 65 f K1IC',
        items: [
            {
                id: 1,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
        ],
    },
    {
        id: 2,
        label: 'DR 64 f K2CU',
        items: [
            {
                id: 1,
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
            {
                id: 2,
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 3,
        label: 'JM 65 f K1IC',
        items: [
            {
                id: 1,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
        ],
    },
    {
        id: 4,
        label: 'JM 65 f K1IC',
        items: [
            {
                id: 1,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
        ],
    },
    {
        id: 5,
        label: 'DR 64 f K2CU',
        items: [
            {
                id: 1,
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
            {
                id: 2,
                text: `She has never had a similar reaction ever before and she did not have any associated SOB, #chest pain, itching, or nausea. She has not noticed any rashes.`,
            },
        ],
    },
    {
        id: 6,
        label: 'JM 65 f K1IC',
        items: [
            {
                id: 1,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
            {
                id: 2,
                text: `72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.`,
            },
        ],
    },
]

export const userGuideMockData = {
    sections: [
        {
            languages: [
                {
                    sectionName: 'User Guide',
                    language: 'en',
                    text: 'English text: 72 y o woman in NAD with a h/o CAD, DM2, asthma and HTN on altace for 8 years #awoke from.',
                },
                {
                    sectionName: "Mode d'emploi",
                    language: 'fr',
                    text: 'French text: Femme de 72 ans en NAD avec h / o CAD, DM2, asthme et HTN sous altace depuis 8 ans #woke from.',
                },
            ],
        },
    ],
}
