import { createAsyncThunk } from '@reduxjs/toolkit';
import { TASKSAPIDATA } from '../../../utils/API';
import { actionCards, huddleActionItemChat, huddleNotes } from '../../../utils/mockData';

export const getHuddleNotes = createAsyncThunk<any, any>(
    'huddle/getHuddleNotes',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            // const response = await LABSAPIDATA.get(`/v1/LabResults?VisitId=${param.visitId}`, {
            //     headers: {
            //         bearer: `${param.token}`,
            //         role: param.role,
            //     },
            // })
            // return response.data
            return huddleNotes
        } catch (error) {
            console.log('Error fetch New Lab Results data: ', error)
            // return rejectWithValue(error);
        }
    }
);


export const getActionCards = createAsyncThunk<any, any>(
    'huddle/getActionCards',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            // const response = await LABSAPIDATA.get(`/v1/LabResults?VisitId=${param.visitId}`, {
            //     headers: {
            //         bearer: `${param.token}`,
            //         role: param.role,
            //     },
            // })
            // return response.data
            return actionCards
        } catch (error) {
            console.log('Error fetch New Lab Results data: ', error)
            // return rejectWithValue(error);
        }
    }
);

export const getHuddleActionItemChat = createAsyncThunk<any, any>(
    'huddle/getHuddleActionItemChat',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            // const response = await LABSAPIDATA.get(`/v1/LabResults?VisitId=${param.visitId}`, {
            //     headers: {
            //         bearer: `${param.token}`,
            //         role: param.role,
            //     },
            // })
            // return response.data
            return huddleActionItemChat
        } catch (error) {
            console.log('Error fetch New Lab Results data: ', error)
            // return rejectWithValue(error);
        }
    }
);


export const getHuddlePageNotes = createAsyncThunk<any, any>(
    'huddle/getHuddlePageNotes',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, PatientIds, CreatedAfter } = param
            const patients = PatientIds.map(
                (PatientId: string) => `PatientIds=${PatientId}`
            )
            const response = await TASKSAPIDATA.get(
                `/v1/Notes?${patients.join('&')}`,
                {
                    // headers: {
                    //     bearer: token,
                    // },
                    params: { CreatedAfter },
                }
            )
            const data = response.data
            return data
        } catch (error) {
            console.log('Error can not get notes from server: ', error)
            return rejectWithValue(error);
        }
    }
);


export const getHuddleUpdates = createAsyncThunk<any, any>(
    'huddle/getHuddleUpdates',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            const { token, After } = param
            const response = await TASKSAPIDATA.get(`v1/Updates`, {
                headers: {
                    bearer: token,
                },
                params: { After },
            }
            )
            const eventDatas = response.data.events.map(
                (eventData: {
                    patientId: string
                    type: number
                    eventDataJson: string
                    occuredOn: Date | string
                }) => {
                    return {
                        patientId: eventData.patientId,
                        type: eventData.type,
                        eventDataJson: JSON.parse(eventData.eventDataJson),
                        occuredOn: eventData.occuredOn,
                    }
                }
            )
            // return { events: { events: eventDatas }, fetchDate: response.data.fetchDate }
            return eventDatas
        } catch (error) {
            console.log('Error can not get huddle updates from server: ', error)
            return rejectWithValue(error);
        }
    }
);