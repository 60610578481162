import { createAsyncThunk } from '@reduxjs/toolkit';
import { LABSAPIDATA } from '../../../utils/API';
import { LabResultsMOCK } from '../../../utils/__Mocks__/LabResultsMock';
import { LabResultsData } from './types';

export const getNewLabsResult = createAsyncThunk<LabResultsData, any>(
    'labs/getNewLabsResult',
    async function (param, { rejectWithValue, dispatch }) {
        try {
            // const response = await LABSAPIDATA.get(`/v1/LabResults?PatientId=${param.patientId}`, {
            const response = await LABSAPIDATA.get(`/v1/LabResults?VisitId=${param.visitId}`, {
                headers: {
                    bearer: `${param.token}`,
                    role: param.role,
                },
            })
            return response.data
            // return LabResultsMOCK
        } catch (error) {
            console.log('Error fetch New Lab Results data: ', error)
            return rejectWithValue(error);
        }
    }
);

export const markPendingLabsAsSeenLabs = createAsyncThunk<any, any>('markPendingLabsAsSeenLabs',
    async function (params, { rejectWithValue, dispatch }) {
        const { body, role } = params
        try {

            const customHeaders = {
                'role': role,
            };

            const response = await LABSAPIDATA.post(`/v1/LabResults/mark-pending-labs-as-seen`, body, {
                headers: customHeaders
            });

            const data = response.data
            return data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);