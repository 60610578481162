export const resusMock = {
    resusList: [
        {
            patientRecord: '1800689   ',
            patientFirstName: 'BORAWSKI',
            patientLastName: 'STANISLAW',
            unitName: 'R03      ',
            admittingService: 'CVICU',
            waitingTimeMinutes: 1636,
            waitingTimeSinceAdmissionRequestMinutes: 611,
            timeDelayLevel: 2,
            timeDelaySinceAdmissionLevel: 1,
            emergencyCategory: 'RESUS',
        },
        {
            patientRecord: '1803576   ',
            patientFirstName: 'HAMILTON',
            patientLastName: 'PETER',
            unitName: 'R02      ',
            admittingService: null,
            waitingTimeMinutes: 1300,
            waitingTimeSinceAdmissionRequestMinutes: 0,
            timeDelayLevel: 2,
            timeDelaySinceAdmissionLevel: 0,
            emergencyCategory: 'RESUS',
        },
        {
            patientRecord: '479545    ',
            patientFirstName: 'FILO',
            patientLastName: 'CHARLES',
            unitName: 'R06      ',
            admittingService: 'Internal medicine',
            waitingTimeMinutes: 511,
            waitingTimeSinceAdmissionRequestMinutes: 556,
            timeDelayLevel: 0,
            timeDelaySinceAdmissionLevel: 0,
            emergencyCategory: 'RESUS',
        },
        {
            patientRecord: '629959    ',
            patientFirstName: 'DORVAL',
            patientLastName: 'ADELINE',
            unitName: 'R03      ',
            admittingService: null,
            waitingTimeMinutes: 800,
            waitingTimeSinceAdmissionRequestMinutes: 960,
            timeDelayLevel: 1,
            timeDelaySinceAdmissionLevel: 2,
            emergencyCategory: 'RESUS',
        },
        {
            patientRecord: '652292    ',
            patientFirstName: 'KARTHIGESU',
            patientLastName: 'THAVAGANTHARAJA',
            unitName: 'R06      ',
            admittingService: null,
            waitingTimeMinutes: 1336,
            waitingTimeSinceAdmissionRequestMinutes: 1311,
            timeDelayLevel: 2,
            timeDelaySinceAdmissionLevel: 2,
            emergencyCategory: 'RESUS',
        },
    ],
}
//  {
//     resusList: [
//         {
//             patientRecord: '10021',
//             unitName: 'RO3',
//             patientFirstName: 'FRANCOIS',
//             patientLastName: 'ROY',
//             admittingService: 'Service_1',
//             waitingTimeMinutes: 25,
//             waitingTimeSinceAdmissionRequestMinutes: 15,
//             timeDelayLevel: 1,
//             timeDelaySinceAdmissionLevel: 0,
//             emergencyCategory: 'RESUS',
//         },
//         {
//             patientRecord: '2310023',
//             unitName: 'RO2',
//             patientFirstName: 'LUCIE',
//             patientLastName: 'TREMBLAY',
//             admittingService: 'Service_1',
//             waitingTimeMinutes: 15,
//             waitingTimeSinceAdmissionRequestMinutes: 25,
//             timeDelayLevel: 0,
//             timeDelaySinceAdmissionLevel: 1,
//             emergencyCategory: 'RESUS',
//         },
//         {
//             patientRecord: '100244',
//             unitName: 'RO1',
//             admittingService: 'Service_4',
//             patientFirstName: 'PIERRE',
//             patientLastName: 'DUBOIS',
//             waitingTimeMinutes: 25,
//             waitingTimeSinceAdmissionRequestMinutes: 45,
//             timeDelayLevel: 1,
//             timeDelaySinceAdmissionLevel: 2,
//             emergencyCategory: 'RESUS',
//         },
//         {
//             patientRecord: '450022',
//             unitName: 'RO6',
//             admittingService: 'Service_2',
//             patientFirstName: 'MARIE',
//             patientLastName: 'GAGNON',
//             waitingTimeMinutes: 15,
//             waitingTimeSinceAdmissionRequestMinutes: 15,
//             timeDelayLevel: 0,
//             timeDelaySinceAdmissionLevel: 0,
//             emergencyCategory: 'RESUS',
//         },
//         {
//             patientRecord: '90023',
//             unitName: 'RO7',
//             admittingService: 'Service_3',
//             patientFirstName: 'JACQUES',
//             patientLastName: 'LEMIEUX',
//             waitingTimeMinutes: 60,
//             waitingTimeSinceAdmissionRequestMinutes: 60,
//             timeDelayLevel: 2,
//             timeDelaySinceAdmissionLevel: 2,
//             emergencyCategory: 'RESUS',
//         },
//         {
//             patientRecord: '400233',
//             unitName: 'RO8',
//             admittingService: 'Service_3',
//             patientFirstName: 'JEAN-MARIE',
//             patientLastName: 'THIBEAULT',
//             waitingTimeMinutes: 25,
//             waitingTimeSinceAdmissionRequestMinutes: 15,
//             timeDelayLevel: 1,
//             timeDelaySinceAdmissionLevel: 0,
//             emergencyCategory: 'RESUS',
//         },
//     ],
// }

export const edPodsMock = {
    resusList: [
        {
            patientRecord: '10021',
            unitName: 'RO3',
            admittingService: 'Service_1',
            patientFirstName: 'EMILIE',
            patientLastName: 'BEAUCHAMP',
            waitingTimeMinutes: 250,
            waitingTimeSinceAdmissionRequestMinutes: 1500,
            timeDelayLevel: 0,
            timeDelaySinceAdmissionLevel: 2,
            emergencyCategory: 'PODS',
        },
        {
            patientRecord: '2310023',
            unitName: 'RO2',
            admittingService: 'Service_1',
            patientFirstName: 'ALEXANDRE',
            patientLastName: 'LAPOINTE',
            waitingTimeMinutes: 450,
            waitingTimeSinceAdmissionRequestMinutes: null,
            timeDelayLevel: 0,
            timeDelaySinceAdmissionLevel: 2,
            emergencyCategory: 'PODS',
        },
        {
            patientRecord: '100244',
            unitName: 'RO1',
            admittingService: 'Service_4',
            patientFirstName: 'CATHERINE',
            patientLastName: 'DESROSIERS',
            waitingTimeMinutes: 550,
            waitingTimeSinceAdmissionRequestMinutes: 450,
            timeDelayLevel: 1,
            timeDelaySinceAdmissionLevel: 0,
            emergencyCategory: 'PODS',
        },
        {
            patientRecord: '450022',
            unitName: 'RO6',
            admittingService: 'Service_2',
            patientFirstName: 'SIMON',
            patientLastName: 'MORIN',
            waitingTimeMinutes: null,
            waitingTimeSinceAdmissionRequestMinutes: 150,
            timeDelayLevel: 0,
            timeDelaySinceAdmissionLevel: 0,
            emergencyCategory: 'PODS',
        },
        {
            patientRecord: '90023',
            unitName: 'RO7',
            admittingService: 'Service_3',
            patientFirstName: 'SOPHIE',
            patientLastName: 'BELLEMARE',
            waitingTimeMinutes: 600,
            waitingTimeSinceAdmissionRequestMinutes: 600,
            timeDelayLevel: 1,
            timeDelaySinceAdmissionLevel: 1,
            emergencyCategory: 'PODS',
        },
        {
            patientRecord: '400233',
            unitName: 'RO8',
            admittingService: 'Service_3',
            patientFirstName: 'MAXIME',
            patientLastName: 'CÔTÉ',
            waitingTimeMinutes: 250,
            waitingTimeSinceAdmissionRequestMinutes: 650,
            timeDelayLevel: 0,
            timeDelaySinceAdmissionLevel: 1,
            emergencyCategory: 'PODS',
        },
    ],
}

export const orRoomsMock = {
    operatingRoomCurrentInflows: [
        {
            patientRecord: '1802181',
            patientFirstName: 'JEAN-MARIE',
            patientLastName: 'THIBEAULT',
            surgeryRoom: '03',
            admittingService: 'MSICU',
            surgeryScheduleDateTime: '2024-08-16T13:20:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T15:20:00',
            timeDelayLevel: 0,
            waitingTimePostOperationMinutes: null,
            timeDelaySinceAdmissionLevel: 0,
        },
        {
            patientRecord: '1152120',
            patientFirstName: 'JACQUES',
            patientLastName: 'LEMIEUX',
            surgeryRoom: '03',
            admittingService: 'MSICU',
            surgeryScheduleDateTime: '2024-08-16T07:45:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T09:55:00',
            timeDelayLevel: 2,
            waitingTimePostOperationMinutes: 15,
            timeDelaySinceAdmissionLevel: 1,
        },
        {
            patientRecord: '1425362',
            patientFirstName: 'MARIE',
            patientLastName: 'GAGNON',
            surgeryRoom: '01',
            admittingService: 'CVICU',
            surgeryScheduleDateTime: '2024-08-16T09:30:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T11:30:00',
            timeDelayLevel: 1,
            waitingTimePostOperationMinutes: 30,
            timeDelaySinceAdmissionLevel: 2,
        },
        {
            patientRecord: '1930847',
            patientFirstName: 'PIERRE',
            patientLastName: 'DUBOIS',
            surgeryRoom: '02',
            admittingService: 'PACU',
            surgeryScheduleDateTime: '2024-08-16T11:00:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T13:30:00',
            timeDelayLevel: 0,
            waitingTimePostOperationMinutes: null,
            timeDelaySinceAdmissionLevel: 0,
        },
        {
            patientRecord: '1876543',
            patientFirstName: 'LUCIE',
            patientLastName: 'TREMBLAY',
            surgeryRoom: '04',
            admittingService: 'MSICU',
            surgeryScheduleDateTime: '2024-08-16T14:15:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T15:00:00',
            timeDelayLevel: 2,
            waitingTimePostOperationMinutes: 30,
            timeDelaySinceAdmissionLevel: 2,
        },
        {
            patientRecord: '1992834',
            patientFirstName: 'FRANCOIS',
            patientLastName: 'ROY',
            surgeryRoom: '05',
            admittingService: 'CVICU',
            surgeryScheduleDateTime: '2024-08-16T08:00:00',
            expectedSurgeryScheduleEndDateTime: '2024-08-16T11:00:00',
            timeDelayLevel: 0,
            waitingTimePostOperationMinutes: 10,
            timeDelaySinceAdmissionLevel: 1,
        },
    ],
}

export const pacuMock = {
    criticalCarePacuInflows: [
        {
            patientRecord: '1801726',
            patientFirstName: 'GABRIEL',
            patientLastName: 'DANIEL',
            surgeryRoom: '03',
            minutesSinceEnteredPacu: 4000,
            timeDelayLevel: 2,
            admittingService: 'Service 1',
            waitingTimePacuAfterRequestAdmissionMinutes: 450,
            timeDelaySinceAdmissionLevel: 0,
        },
        {
            patientRecord: '1353477',
            patientFirstName: 'MARK EMIL',
            patientLastName: 'KROFINA',
            surgeryRoom: '03',
            minutesSinceEnteredPacu: 1500,
            timeDelayLevel: 0,
            admittingService: 'Service 2',
            waitingTimePacuAfterRequestAdmissionMinutes: 2500,
            timeDelaySinceAdmissionLevel: 1,
        },
        {
            patientRecord: '282166',
            patientFirstName: 'GWENDOLINE ADA',
            patientLastName: 'WRIGHT',
            surgeryRoom: '01',
            minutesSinceEnteredPacu: 2200,
            timeDelayLevel: 1,
            admittingService: 'Service 3',
            waitingTimePacuAfterRequestAdmissionMinutes: 3500,
            timeDelaySinceAdmissionLevel: 2,
        },
    ],
}

export const msicuSummaryMock = {
    criticalCareTotalCurrentInflows: [
        {
            originCategoryType: 'MSICU',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 1500,
            count: 2,
            waitingOver24Hrs: 2,
        },
        {
            originCategoryType: 'CVICU',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 350,
            count: 1,
            waitingOver24Hrs: 0,
        },
        {
            originCategoryType: 'OtherUnits',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 150,
            count: 1,
            waitingOver24Hrs: 0,
        },
        {
            originCategoryType: 'FBC',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 2500,
            count: 2,
            waitingOver24Hrs: 2,
        },
        {
            originCategoryType: 'PACU',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 1000,
            count: 2,
            waitingOver24Hrs: 0,
        },
        {
            originCategoryType: 'UNK',
            admittingType: 'MSICU',
            waitingTimeSinceAdmissionMinutes: 500,
            count: 1,
            waitingOver24Hrs: 0,
        },
    ],
}

export const cvicuSummaryMock = {
    criticalCareTotalCurrentInflows: [
        {
            originCategoryType: 'MSICU',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 2519,
            count: 1,
            waitingOver24Hrs: 1,
        },
        {
            originCategoryType: 'PACU',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 2000,
            count: 3,
            waitingOver24Hrs: 3,
        },
        {
            originCategoryType: 'ER',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 450,
            count: 1,
            waitingOver24Hrs: 0,
        },
        {
            originCategoryType: 'VirtualLabs',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 1560,
            count: 1,
            waitingOver24Hrs: 1,
        },
        {
            originCategoryType: 'CVICU',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 500,
            count: 1,
            waitingOver24Hrs: 0,
        },
        {
            originCategoryType: 'OtherHospitals',
            admittingType: 'CVICU',
            waitingTimeSinceAdmissionMinutes: 2300,
            count: 2,
            waitingOver24Hrs: 2,
        },
    ],
}

export const msicuOccupancyMock = {
    currentDemographics: [
        {
            criticalUnitCategory: 'MSICU',
            occupancy: 22,
            capacityUnit: 24,
        },
    ],
}

export const cvicuOccupancyMock = {
    currentDemographics: [
        {
            criticalUnitCategory: 'CVICU',
            occupancy: 16,
            capacityUnit: 14,
        },
    ],
}

export const addmisionsMsicuCvicuMock = {
    msicu: {
        admissions: [
            { admissionSource: 'ED', count: 1 },
            { admissionSource: 'Family Med', count: 1 },
            { admissionSource: 'OR', count: 2 },
            { admissionSource: 'PACU', count: 0 },
            { admissionSource: 'Surgery', count: 0 },
            { admissionSource: 'Other Hosp', count: 1 },
        ],
        waitingOver: { admissionSource: 'Waiting over 24 HRS', count: 3 },
    },
    cvicu: {
        admissions: [
            { admissionSource: 'ED', count: 1 },
            { admissionSource: 'MSICU', count: 1 },
            { admissionSource: 'Home', count: 2 },
            { admissionSource: 'Other Hosp', count: 0 },
            { admissionSource: 'Cath Lab', count: 1 },
            { admissionSource: 'VW', count: 0 },
            { admissionSource: 'Other', count: 1 },
        ],
        waitingOver: { admissionSource: 'Waiting over 24 HRS', count: 2 },
    },
}

export const cvicuOutflowMock = {
    criticalCareTotalCurrentOutflows: [
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'CVICU',
            patientRecord: '1801922',
            bedName: 'K0110',
            transferService: 'MSICU',
            transferType: 'Planned Discharge',
            waitingTimeMinutes: 30,
            timeDelayLevel: 2,
            firstName: 'Robert',
            lastName: 'Baratheon',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801933',
            bedName: 'K0111',
            transferService: 'FBC',
            transferType: 'Planned transfer',
            waitingTimeMinutes: null,
            timeDelayLevel: 0,
            firstName: 'Jaime',
            lastName: 'Lannister',
        },
        {
            criticalUnitCategory: 'CVICU',
            patientRecord: '1801944',
            bedName: 'K0112',
            transferService: 'PACU',
            transferType: 'Pending transfer',
            waitingTimeMinutes: 25,
            timeDelayLevel: 1,
            firstName: 'Robb',
            lastName: 'Stark',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801955',
            bedName: 'K0113',
            transferService: null,
            transferType: 'Potential transfer',
            waitingTimeMinutes: 15,
            timeDelayLevel: 0,
            firstName: 'Catelyn',
            lastName: 'Stark',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801911',
            bedName: 'K0109',
            transferService: null,
            transferType: 'Signed Discharge',
            waitingTimeMinutes: 10,
            timeDelayLevel: 0,
            firstName: 'Jon',
            lastName: 'Snow',
        },
    ],
}

export const msicuOutflowMock = {
    criticalCareTotalCurrentOutflows: [
        {
            criticalUnitCategory: 'CVICU',
            patientRecord: '1801966',
            bedName: 'K0114',
            transferService: 'MSICU',
            transferType: 'Disposition planning',
            waitingTimeMinutes: 25,
            timeDelayLevel: 1,
            firstName: 'Jorah',
            lastName: 'Mormont',
        },
        {
            criticalUnitCategory: 'MSICU',
            patientRecord: '1801977',
            bedName: 'K0115',
            transferService: 'FBC',
            transferType: 'No transfers',
            waitingTimeMinutes: null,
            timeDelayLevel: 0,
            firstName: 'Arya',
            lastName: 'Stark',
        },
        {
            criticalUnitCategory: 'PACU',
            patientRecord: '1801988',
            bedName: 'K0116',
            transferService: 'MSICU',
            transferType: 'Signed discharge',
            waitingTimeMinutes: 50,
            timeDelayLevel: 2,
            firstName: 'Bran',
            lastName: 'Stark',
        },
        {
            criticalUnitCategory: null,
            patientRecord: '1801999',
            bedName: 'K0117',
            transferService: 'CVICU',
            transferType: 'Planned transfer',
            waitingTimeMinutes: 20,
            timeDelayLevel: 1,
            firstName: 'Khal',
            lastName: 'Drogo',
        },
        {
            criticalUnitCategory: 'FBC',
            patientRecord: '1802000',
            bedName: 'K0118',
            transferService: 'MSICU',
            transferType: 'Potential transfer',
            waitingTimeMinutes: null,
            timeDelayLevel: 0,
            firstName: 'Stannis',
            lastName: 'Baratheon',
        },
    ],
}
