import React, { useMemo } from 'react'
import { useGetCurrentDemographicsQuery } from 'redux/rtk/criticalCare/criticalCareApi'
import OccupancyItem from './OccupancyItem/OccupancyItem'
import CapacityPercentage from './CapacityPercentage/CapacityPercentage'

import styles from './CriticalCareOccupancy.module.scss'
import CorporateDocLink from 'components/ManagementEntries/CorporateDocumentation/CorporateDocumentation'

const CriticalCareOccupancy: React.FC = () => {
    const {
        data: msicuData,
        isLoading: msicuLoading,
        isSuccess: msicuSuccess,
    } = useGetCurrentDemographicsQuery('MSICU', {
        pollingInterval: 300000,
    })

    const {
        data: cvicuData,
        isLoading: cvicuLoading,
        isSuccess: cvicuSuccess,
    } = useGetCurrentDemographicsQuery('CVICU', {
        pollingInterval: 300000,
    })

    const msicuDemographics = useMemo(() => {
        return (
            msicuData?.currentDemographics?.[0] || {
                criticalUnitCategory: '',
                occupancy: 0,
                capacityUnit: 0,
            }
        )
    }, [msicuData])

    const cvicuDemographics = useMemo(() => {
        return (
            cvicuData?.currentDemographics?.[0] || {
                criticalUnitCategory: '',
                occupancy: 0,
                capacityUnit: 0,
            }
        )
    }, [cvicuData])

    // Calculate totals
    const totalOccupied = useMemo(() => {
        const msicuOccupancy = msicuDemographics?.occupancy || 0
        const cvicuOccupancy = cvicuDemographics?.occupancy || 0
        return msicuOccupancy + cvicuOccupancy
    }, [msicuDemographics, cvicuDemographics])

    const totalCapacity = useMemo(() => {
        const msicuCapacity = msicuDemographics?.capacityUnit || 0
        const cvicuCapacity = cvicuDemographics?.capacityUnit || 0
        return msicuCapacity + cvicuCapacity
    }, [msicuDemographics, cvicuDemographics])

    // Render component
    return (
        <div className={styles.container}>
            <div className={styles.container__documentation}>
                <CorporateDocLink
                    baseKey="criticalCare"
                    sectionKey="inflowDemographics"
                />
            </div>
            <div className={styles.container__content}>
                {msicuDemographics && (
                    <OccupancyItem
                        UnitName={'MSICU'}
                        OccupiedBeds={msicuDemographics.occupancy}
                        TotalCapacity={msicuDemographics.capacityUnit}
                    />
                )}
                <CapacityPercentage
                    totalOccupied={totalOccupied}
                    totalCapacity={totalCapacity}
                />
                {cvicuDemographics && (
                    <OccupancyItem
                        UnitName={'CVICU'}
                        OccupiedBeds={cvicuDemographics.occupancy}
                        TotalCapacity={cvicuDemographics.capacityUnit}
                    />
                )}
            </div>
        </div>
    )
}

export default CriticalCareOccupancy
