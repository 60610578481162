export const findMinAndMax = (arr: number[]) => {
    if (arr.length === 0) {
        return { min: 0, max: 0 }
    }

    let min = arr[0]
    let max = arr[0]

    for (let i = 1; i < arr.length; i++) {
        if (arr[i] < min) {
            min = arr[i]
        } else if (arr[i] > max) {
            max = arr[i]
        }
    }

    return { min, max }
}

export const updateMinYMaxYWithAverage = (
    min: number,
    max: number,
    averageValue: number
) => {
    if (averageValue < min) {
        min = averageValue
    } else if (averageValue > max) {
        max = averageValue
    }

    return { minY: min - 5, maxY: max + 5 }
}

export function calculateMinMaxMedian<T>(data: T[], valueKey: keyof T) {
    // Extract values from the provided key
    const values = data
        .map((item) => Math.round(item[valueKey] as unknown as number)) // Rounding to nearest integer
        .sort((a, b) => a - b)

    // Calculate min, max, and median
    const min = values[0]
    const max = values[values.length - 1]
    const median =
        values.length % 2 === 0
            ? Math.round(
                  (values[values.length / 2 - 1] + values[values.length / 2]) /
                      2
              )
            : values[Math.floor(values.length / 2)]

    return { min, max, median }
}

interface SummaryItem {
    [key: string]: any
    surgeryScheduleDate?: string
}

export function removeTrailingZ<T extends SummaryItem>(
    items: T[],
    dateKey: keyof T
): T[] {
    return items.map((item) => ({
        ...item,
        [dateKey]: item[dateKey]?.endsWith('Z')
            ? item[dateKey].slice(0, -1)
            : item[dateKey],
    }))
}

export function getWeekendDates<T>(data: T[], dateKey: keyof T): string[] {
    return data
        .map((item) => item[dateKey] as unknown as string)
        .filter((dateString) => {
            const date = new Date(dateString)
            const day = date.getUTCDay()
            return day === 0 || day === 6 // 0 is Sunday, 6 is Saturday
        })
}
