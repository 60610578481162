type Consultation = {
    consultationDelayFlag: string
    consultationTime: string
    consultationTitle: string
}

type TimeFilterKey = '0-2' | '2-4' | '4+'

const timeFilterMap: Record<TimeFilterKey, (timeInSeconds: number) => boolean> =
{
    '0-2': (timeInSeconds: number) =>
        timeInSeconds >= 0 && timeInSeconds < 2 * 3600,
    '2-4': (timeInSeconds: number) =>
        timeInSeconds >= 2 * 3600 && timeInSeconds < 4 * 3600,
    '4+': (timeInSeconds: number) => timeInSeconds >= 4 * 3600,
}

function convertTimeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds
}

export function filterConsultations(
    consultations: Consultation[],
    filters: string[]
): Consultation[] {
    return consultations.filter((consultation) => {
        const consultationTimeInSeconds = convertTimeToSeconds(
            consultation.consultationTime
        )
        return filters.some((filter) => {
            if (
                filter === 'Delays in Care' &&
                consultation.consultationDelayFlag
            ) {
                return true
            }
            const timeFilter = filter as TimeFilterKey
            if (
                timeFilter in timeFilterMap &&
                timeFilterMap[timeFilter](consultationTimeInSeconds)
            ) {
                return consultation
            }
            return false
        })
    })
}

export const calculateTime = (startTime: Date): string => {
    const currentTime = new Date()
    let difference = currentTime.getTime() - startTime.getTime()
    let hours = Math.floor(difference / 1000 / 60 / 60)
    difference -= hours * 1000 * 60 * 60
    let minutes = Math.floor(difference / 1000 / 60)

    const getZeroPad = (num: number) => (num < 10 ? `0${num}` : num.toString())
    hours = parseInt(getZeroPad(hours))
    minutes = parseInt(getZeroPad(minutes))

    return `${hours}:${minutes}`
}

export const multiSelectOptions = (options: { name: string, label: string }[]) => {
    const exceptions = ['reset all filters', 'all', 'select all']

    return options
        .filter(filter => !exceptions.includes(filter.label.toLowerCase()))
        .map(filter => filter.label);

}