import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    setHasNextPage,
    setPageNumber,
    setSortOrder,
    setSortSortBy,
} from '../redux/rtk/filter/filterSlice'
import { setPatientsDataAPI } from '../redux/rtk/patientsTable/patientsTableSlice'
import { SortBy, SortOrder } from '../utils/models/dashboardPatientsSortModel'

export default function useSorting() {
    const dispatch = useDispatch()
    const [sortItemName, setSortItemName] = useState<string>('')
    const sorting = useCallback((sortingItem: any, sortType: any): void => {
        dispatch(setPageNumber(1))
        dispatch(setHasNextPage(true))
        dispatch(setPatientsDataAPI([]))

        setSortItemName(sortingItem)
        const sortBy = SortBy[sortingItem] || SortBy.createDate
        dispatch(setSortSortBy(sortBy))

        const sortOrder = SortOrder[sortType] || SortOrder.ascending
        dispatch(setSortOrder(sortOrder))
    }, [])

    return { sortItemName, sorting }
}
