import React from 'react'
import { IconTypeProps } from '../IconType'

const HomeCareIcon: React.FC<IconTypeProps> = ({ fillColor }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 10.5938C4 10.3016 4.12784 10.024 4.3499 9.83397L10 5L15.6501 9.83397C15.8722 10.024 16 10.3016 16 10.5938V15C16 16.6569 14.6569 18 13 18H7C5.34315 18 4 16.6569 4 15V10.5938Z"
                fill={fillColor}
            />
            <path
                d="M3 8L10.1795 2L17 8"
                stroke={fillColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default HomeCareIcon
