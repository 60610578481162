import React from 'react'
import Icon from '../../../../common/IconMaper/Icons'

import styles from './IconCell.module.scss'

type IconCellProps = {
    text: string
}

const IconCell: React.FC<IconCellProps> = ({ text }) => {
    return (
        <div className={styles.container}>
            {/* <div className={styles.container__icon}>
                <Icon name={text} color={'default'} />
            </div> */}
            <div className={styles.container__text}>{text}</div>
        </div>
    )
}

export default IconCell
