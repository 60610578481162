import React, { useMemo } from 'react'

import styles from './TriageColumn.module.scss'

type TriageColumnProps = {
    triage: string
}
type TriageType = '1' | '2' | '3' | '4' | '5'

type TriageColorMapping = {
    [key in TriageType]: string
}

const triageColorMapping: TriageColorMapping = {
    '1': '#1970B9',
    '2': '#FF7783',
    '3': '#FFB979',
    '4': '#7ABD83',
    '5': '#889BBA',
}

const TriageColumn: React.FC<TriageColumnProps> = ({ triage }) => {
    const triageColor: string = useMemo(
        () => triageColorMapping[triage as TriageType] || 'inherit',
        [triage]
    )

    return (
        <div
            className={styles.triage_cell}
            style={{
                color: triageColor,
            }}
        >
            <span className={styles.triage_cell__text}>{triage}</span>
        </div>
    )
}

export default TriageColumn
