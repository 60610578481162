import { useKeycloak } from '@react-keycloak/web'
import Loader from '../../components/Loader/Loader'
import Login from '../../pages/Login/Login'

const PrivateRoute = ({ children }: any) => {
    const { keycloak, initialized } = useKeycloak()

    return !initialized ? (
        <Loader />
    ) : keycloak.authenticated === true ? (
        children
    ) : (
        <Login />
    )
}

export default PrivateRoute
